import { useState } from "react";
import useAxios from "../../shared/hooks/use-axios";
import { success } from "../../shared/hooks/use-toastify";
import { CustomerClose, LoadingBtn } from "../svg/AllSvg";
import PrimaryInput from "../form/PrimaryInput";
import PrimaryBtn from "../form/PrimaryBtn";
import SingleSelect from "../form/SingleSelect";

const AddStorageLocation = ({ isOpen, setIsOpen, setApiCall, buildingList, floorList, roomList, storageList }: any) => {
    const [submit, setSubmit] = useState<boolean>(false);
    const [defaultLocation, setDefaultLocation] = useState<boolean>(false);
    const [building, setBuilding] = useState<any>(null)
    const [floor, setFloor] = useState<any>(null);
    const [room, setRoom] = useState<any>(null);
    const [storageBox, setStorageBox] = useState<any>(null);
    const [location, setLocation] = useState<string>("");

    const onSubmitHandler = async () => {
        setSubmit(true)
        let payload = {
            "building": building?.value,
            "floor": floor?.value,
            "room": room?.value,
            "storage_box_type": storageBox?.value,
            "compartment_name": location,
            "is_default_storage_box": defaultLocation
        }

        try {
            await useAxios.post(`inventory/master-storage-locations/`, payload);
            success("Storage Location Created successfully.");
            setIsOpen(false)
            setApiCall(true)
            setSubmit(false)
        } catch (error) {
            setSubmit(false)
            console.error("error while creating storage location", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            setIsOpen(false)
        }
    };

    return (
        <>
            {isOpen && <div className="fixed inset-0 z-20 overflow-y-hidden top-0">
                <div className="fixed inset-0 z-[31] overflow-y-hidden top-0">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[21px] flex flex-col w-[320px] sm:w-[480px] md:h-auto z-[10]">
                            <img src={require("../../assets/images/Mask group.png")} alt="" className="absolute bottom-0 right-0 rounded-b-[20px] !rounded-l-none opacity-[0.4]" />
                            <div className="flex items-start justify-between pl-[28px] pr-[15px] pt-[23px] rounded-t-[21px] bg-white z-[10]">
                                <h3 className="text-primaryLight font-semibold text-lg">Create New Storage Location</h3>
                                <div className="cursor-pointer z-[10]" onClick={() => setIsOpen(false)}>
                                    <CustomerClose />
                                </div>
                            </div>

                            <div className="p-[28px] flex flex-col gap-[30px] z-[10]">
                                <SingleSelect title="Building*" name="building" options={buildingList} value={building} onChange={(e: any) => setBuilding(e)} placeholder="Building" />
                                <SingleSelect title="Floor*" name="floor" options={floorList} value={floor} onChange={(e: any) => setFloor(e)} placeholder="Floor" />
                                <SingleSelect title="Room*" name="room" options={roomList} value={room} onChange={(e: any) => setRoom(e)} placeholder="Room" />
                                <SingleSelect title="Storage Box Type*" name="storage box" value={storageBox} options={storageList} onChange={(e: any) => setStorageBox(e)} placeholder="Storage Box Type" />
                                <PrimaryInput type="text" title="Location*" name="location" value={location} onChange={(e: any) => setLocation(e.target.value)} />

                                <div className="w-full flex items-center justify-between gap-[8px]">
                                    <div className="flex flex-col gap-[4px]">
                                        <h3 className="text-dGray font-normal text-sm">Default Storage Location</h3>
                                        <p className="text-mGray text-xs font-normal">Turn on the switch defines override all default storage location.</p>
                                    </div>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" checked={defaultLocation} className="sr-only peer" onChange={() => setDefaultLocation(!defaultLocation)} />
                                        <div className="w-9 h-5 bg-[#E8EBFF] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all after:peer-checked:bg-dGray" ></div>
                                    </label>
                                </div>
                            </div>

                            <div className="w-full flex items-end justify-end px-[24px] pb-[33px] gap-[15px] z-[10]">
                                <PrimaryBtn disabled={(!!building && !!room && !!floor && !!storageBox && !!location && !submit) ? false : true} onClick={onSubmitHandler} title={!submit ? "Create" :
                                    <>
                                        <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                        Create...
                                    </>} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default AddStorageLocation;