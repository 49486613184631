import { Skeleton } from 'primereact/skeleton';

const GridView: React.FC<any> = ({ group }: any) => {
    const cols = [];
    for (let i = 0; i < (!!group && group ? 3 : 10); i++) {
        cols.push(
            <tr className="!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[50px]" key={i}>
                <td className="px-[12px] h-full !text-center">
                    <Skeleton className='!w-[29px] !h-[29px] !rounded-full'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='3rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <div className='flex items-center gap-[10px]'>
                        <Skeleton size="1.4rem"></Skeleton>
                        <Skeleton size="1.4rem"></Skeleton>
                    </div>
                </td>
            </tr>
        )
    };

    return (
        <table className="table-auto overflow-x-scroll w-full skeleton-table">
            <tbody>
                {cols}
            </tbody>
        </table>
    );
};

export default GridView;