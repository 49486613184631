import { SampleTestClose } from "../svg/AllSvg";

const ViewAll = ({ setViewAll, viewAll, layout }: any) => {
    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            setViewAll(false)
        }
    };

    return (
        <>
            {viewAll && <div className="fixed inset-0 z-30 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] sm:w-[600px] md:w-[745px] lg:w-[915px] xl:w-[1100px] 1xl:w-[1400px] 2xl:w-[1500px]">
                        <div className="flex items-end w-full justify-end px-[20px] py-[16px] rounded-t-[15px]">
                            <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => setViewAll(false)}>
                                <SampleTestClose />
                            </div>
                        </div>

                        <div className="px-[20px] pb-[16px] w-full">
                            <div className="w-full rounded-[10px] py-[16px] px-[20px] bg-white flex flex-col gap-[16px] shadow-[0px_2px_4px_rgba(24,39,75,0.06),0px_0px_4px_rgba(24,39,75,0.12)]">
                                {layout}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default ViewAll;
