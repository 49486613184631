import { createSlice } from '@reduxjs/toolkit';

interface AdminState {
    isOpen: boolean,
    sideBarOption: string,
    screenShotList: any[],
    chatUsersList: any[],
    chatUser: any,
}

const initialState: AdminState = {
    isOpen: false,
    sideBarOption: "All",
    screenShotList: JSON.parse(localStorage.getItem('screenshot') as any) || [],
    chatUsersList: [
        { id: 1, logo: "V", bg: "bg-[#0297a7]", name: "Vista Lims", email: "harshita.kachhadiya@arohatech.com" },
        { id: 2, logo: "P", bg: "bg-[#502aaf]", name: "Project Meeting", email: "harshita.kachhadiya@arohatech.com" },
        { id: 3, logo: "N", bg: "bg-[#689f38]", name: "New Project", email: "harshita.kachhadiya@arohatech.com" },
    ],
    chatUser: {},
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        Open: (state) => {
            state.isOpen = true;
        },
        switchOption: (state, action) => {
            state.sideBarOption = action.payload;
        },
        updateScreenShotList: (state, action) => {
            state.screenShotList = [...state.screenShotList, action.payload];
        },
        assignScreenShotList: (state, action) => {
           state.screenShotList = action.payload;
        },
        setChatUser: (state, action) => {
            state.chatUser = action.payload;
        },
    }
});

export default adminSlice;
export const adminActions = adminSlice.actions;