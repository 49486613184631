import { AddMoreTag, Assign, Box, Check, Clock, DangerClose, Dots, InputPlus, SettingRemove } from "../../svg/AllSvg";
import { useDispatch } from "react-redux";
import { employeeActions } from "../../../shared/store/employee-Slice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useSelectPrimary}  from "../../../shared/hooks/use-selectPrimary";
import Moment from 'moment';
import { Mention } from 'primereact/mention';
import { Tooltip } from "primereact/tooltip";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';

const option: any[] = [
    { label: "Does not repeat", value: "Does not repeat" },
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
    { label: "Custom", value: "Custom" },
];

let data: any[] = [
    { id: 1, logo: "A", bg: "bg-[#0297a7]", name: "Abhineet Baranwal", email: "abhineet.baranwal@arohatech.com" },
    { id: 2, logo: "H", bg: "bg-[#502aaf]", name: "Harshita Kachhadiya", email: "harshita.kachhadiya@arohatech.com" },
    { id: 3, logo: "J", bg: "bg-[#00897b]", name: "Jasani Patel", email: "jasani.patel@arohatech.com" },
    { id: 4, logo: "N", bg: "bg-[#ef6c00]", name: "Nidhi Kotiyal", email: "nidhi.kotiyal@arohatech.com" },
    { id: 5, logo: "N", bg: "bg-[#689f38]", name: "Nishant Tyagi", email: "nishant.tyagi@arohatech.com" },
    { id: 6, logo: "R", bg: "bg-[#5c6ac0]", name: "Ritesh Bajpai", email: "ritesh.bajpai@arohatech.com" },
];

const Footer: React.FC<any> = ({ handleSubmit, hours, setDatePickerOpen, date, userAssign }: any) => {
    return (
        <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-[8px]">
                <button className="rounded-full bg-[#ebebeb] flex items-center gap-[4px] px-[6px] py-0.5 cursor-pointer"><Clock /> <p className="text-[#3c4043] font-normal text-[0.688rem]" onClick={() => setDatePickerOpen(true)}>{new Date().toLocaleDateString("sv") === date ? "Today" : Moment(date).format("MMM Do YY")}, {hours}{hours.toString().split(":")[0] >= 12 ? 'PM' : 'AM'}</p></button>
                <button className="custom-tooltip-btn rounded-full bg-[#ebebeb] p-[6px] cursor-pointer flex items-center justify-center"><Assign /></button>
            </div>
            <button type="submit" className="bg-[#EEF4FC] rounded-md px-[6px] h-[30px] text-primary text-sm font-medium flex items-center justify-center cursor-pointer" onClick={handleSubmit}><AddMoreTag className="w-[20px] h-[20px] !stroke-primaryLight" /></button>

            {userAssign?.length > 0 && <Tooltip position="bottom" target=".custom-tooltip-btn">
                <div className="flex flex-col gap-[12px] overflow-y-scroll w-full max-h-[20rem] !bg-transparent">
                    <h3 className="text-primary text-base font-medium">Collaborators</h3>

                    {Array.isArray(userAssign) && userAssign.map((item: any, index: number) => (
                        <div className="flex items-center justify-between px-[8px]" key={index}>
                            <div className="flex items-center gap-[12px] py-[4px]">
                                <div className={`${item?.bg} text-white text-lg font-medium !w-[2.25rem] !h-[2.25rem] flex items-center justify-center rounded-full`}>{item?.logo}</div>
                                <div className="flex flex-col">
                                    <h3 className="text-sm text-[#202124] font-normal">{item?.name}</h3>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Tooltip>}
        </div>
    );
};

const Note: React.FC<any> = () => {
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const dispatch = useDispatch();
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [checkFormOpen, setCheckFormOpen] = useState<boolean>(false);
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
    const [userAssignOpen, setUserAssignOpen] = useState<boolean>(false);
    const [noteList, setNoteList] = useState<any[]>([]);
    // eslint-disable-next-line 
    const [userList, setUserList] = useState<any[]>(data);
    const [checkList, setCheckList] = useState<any[]>([{ id: Date.now(), title: "", tag: "double", user: [], hours: ((new Date().getHours()) + 3) + ":00", date: new Date().toLocaleDateString("sv") }]);
    const [title, setTitle] = useState<any>("");
    // eslint-disable-next-line 
    const [edit, setEdit] = useState<any>({ id: "", tag: "" });
    const [hours, setHours] = useState<any>(((new Date().getHours()) + 3) + ":00");
    const [date, setDate] = useState<any>(new Date().toLocaleDateString("sv"));
    const [selectHours, setSelectHours] = useState<any>(((new Date().getHours()) + 3) + ":00");
    const [selectDate, setSelectDate] = useState<any>(new Date().toLocaleDateString("sv"));
    const [reminder, setReminder] = useState<any>({ label: "Does not repeat", value: "Does not repeat" });
    const [userAssign, setUserAssign] = useState<any>([]);
    const [complete, setComplete] = useState<any>([]);
    const [suggestions, setSuggestions] = useState<any[]>([]);

    const handleDelete = (recordId: any, tag: any) => {
        if (tag === "single") {
            const updatedData: any[] = noteList.filter(record => record?.note?.id !== recordId);
            setNoteList(updatedData);
        } else {
            const updatedData: any[] = noteList.filter(record => record?.note[0]?.id !== recordId);
            setNoteList(updatedData);
        }
    };

    let styles = useSelectPrimary();

    useEffect(() => setFormOpen(false), [isOpen]);

    useEffect(() => {
        if (formOpen) {
            document.getElementById("todo")?.focus();
        }
    }, [formOpen]);

    useEffect(() => {
        if (checkFormOpen) {
            document.getElementById("List0")?.focus();
        }
    }, [checkFormOpen]);

    useEffect(() => {
        let arr: any[] = [];
        for (let i = 0; i < userAssign.length; i++) {
            const el = userAssign[i];
            let name: string = "@" + el?.name?.split(" ").join("");

            if (title?.toString().includes(name)) {
                arr.push(el);
            }
        }
        setUserAssign(arr);
        // eslint-disable-next-line
    }, [title]);

    const handleSubmit = () => {
        if (!!!title) { setFormOpen(false); return };
        let obj = { note: { id: Date.now(), title: title, tag: "single", user: userAssign, hours: hours, date: date } }
        setNoteList([...noteList, obj]);
        setFormOpen(false);
        setTitle("");
        setUserAssign([]);
        setDate(new Date().toLocaleDateString("sv"));
        setHours(((new Date().getHours()) + 3) + ":00");
    }

    const handleSubmitList = () => {
        if (checkList.length === 1 && !!!checkList[0]?.title) { setCheckFormOpen(false); return };
        let arr: any[] = [];
        for (let i = 0; i < checkList.length; i++) {
            const el = checkList[i];
            if (!!el?.title) {
                el.user = userAssign;
                arr.push(el);
            }
        }
        setNoteList([...noteList, { note: arr }]);
        setCheckFormOpen(false);
        setCheckList([{ id: Date.now(), title: "", tag: "double", user: [], hours: ((new Date().getHours()) + 3) + ":00", date: new Date().toLocaleDateString("sv") }]);
        setUserAssign([]);
        setDate(new Date().toLocaleDateString("sv"));
        setHours(((new Date().getHours()) + 3) + ":00");
        setComplete([]);
    }

    const addCheckList = (e: any, index: number) => {
        let list = [...checkList];
        list[index] = { id: Date.now(), title: e.target.value, tag: "double", user: userAssign, hours: hours, date: date };
        list[index + 1] = !!list[index + 1] ? list[index + 1] : { id: Date.now(), title: "", tag: "double", user: userAssign, hours: hours, date: date };
        setCheckList(list)
    }

    const deleteHandler = (id: any) => {
        let list = complete.filter((item: any) => id !== item);
        setComplete(list);
    }

    const onSearch = (event: any) => {
        setTimeout(() => {
            const query = event.query;
            let suggestions: any[] = [];

            if (!query.trim().length) {
                suggestions = [...userList];
            }
            else {
                suggestions = userList.filter((user) => {
                    return user.name.toLowerCase().startsWith(query.toLowerCase());
                });
            }

            setSuggestions(suggestions);
        }, 250);
    }

    const itemTemplate = (suggestion: any) => {
        return (
            <div className="flex items-center gap-[12px] cursor-pointer">
                <div className={`${suggestion?.bg} text-white text-lg font-medium !w-[2.25rem] !h-[2.25rem] flex items-center justify-center rounded-full`}>{suggestion?.logo}</div>
                <div className="flex flex-col">
                    <h3 className="text-sm text-[#202124] font-normal">{suggestion?.name}</h3>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-[16px] py-[24px] w-full">
            <div className="flex items-center justify-between border-b border-[#E7F0FF] px-[20px] pb-[20px]">
                <h3 className="text-lg font-normal text-lGray pt-[6px]">Notes</h3>

                <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full !flex !items-center bg-[#f4f8ff] !rounded-full !justify-center !w-6 !h-6 sm:!hidden" onClick={() => dispatch(employeeActions.Close())}>
                    <DangerClose color='#133F97' />
                </div>
            </div>

            <div className="pt-[4px] px-[20px] flex flex-col gap-[12px]">
                {(!formOpen && !checkFormOpen) &&
                    <div className="flex items-center gap-[8px] w-full">
                        <button className="flex items-center gap-[8px] cursor-pointer focus:bg-[#F4F4F4] hover:bg-[#F4F4F4] rounded-full py-[8px] px-[12px] w-full" onClick={() => { setFormOpen(!formOpen); }}>
                            <AddMoreTag className="w-[20px] h-[20px] !stroke-primaryLight" />
                            <h3 className="text-primaryLight text-[0.938rem] font-medium">Take a note...</h3>
                        </button>

                        <div className="cursor-pointer focus:bg-[#F4F4F4] hover:bg-[#F4F4F4] rounded-full p-[10px]" onClick={() => setCheckFormOpen(!checkFormOpen)}>
                            <Check color="#20328F" />
                        </div>
                    </div>
                }

                {checkFormOpen && <div data-aos="fade-down" className="flex flex-col gap-[12px] bg-white shadow-lg rounded-lg pt-[16px] px-[8px] pb-[8px]">
                    {Array.isArray(checkList) && checkList.map((item: any, index: number) => (
                        <div className="flex items-center" key={index}>
                            {!!item?.note?.title && <div className="cursor-pointer">
                                <Box />
                            </div>}
                            {!!!item?.note?.title && <div className="cursor-pointer">
                                <InputPlus />
                            </div>}
                            <input id={`List` + index} type="text" placeholder="List item" className="pl-[8px] pr-[12px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal appearance-none peer border-none" value={item?.title} onChange={(e: any) => addCheckList(e, index)} />
                        </div>
                    ))}

                    <Footer handleSubmit={handleSubmitList} hours={hours} setDatePickerOpen={setDatePickerOpen} setUserAssignOpen={setUserAssignOpen} date={date} userAssign={userAssign} userAssignOpen={userAssignOpen} />
                </div>}

                {formOpen && <div data-aos="fade-down" className="flex flex-col gap-[12px] bg-white shadow-lg rounded-lg pt-[16px] px-[8px] pb-[8px]">
                    <Mention id="todo" value={title} onSelect={(props: any) => { setUserAssign([...userAssign, props.suggestion]); }} onChange={(e: any) => setTitle(e.target.value)} suggestions={suggestions} onSearch={onSearch} field="name" placeholder="Take a note..." itemTemplate={itemTemplate} className="!w-full assign-user" />

                    <Footer handleSubmit={handleSubmit} hours={hours} setDatePickerOpen={setDatePickerOpen} setUserAssignOpen={setUserAssignOpen} date={date} userAssign={userAssign} userAssignOpen={userAssignOpen} />
                </div>}

                <div className="relative">
                    {(datePickerOpen && (formOpen || checkFormOpen)) && <div className="p-[16px] flex flex-col gap-[24px] overflow-y-scroll w-[18rem] max-h-[20rem] absolute top-0 z-10 bg-white rounded-[10px] shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)]">
                        <div className="flex items-center justify-between">
                            <h3 className="text-primary text-base font-medium">Pick date & time</h3>
                            <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full !flex !items-center bg-[#f4f8ff] !rounded-full !justify-center !w-6 !h-6" onClick={() => setDatePickerOpen(false)}>
                                <DangerClose color='#133F97' />
                            </div>
                        </div>
                        <div className="flex flex-col gap-[16px]">
                            <div className="relative w-full">
                                <input
                                    type="date"
                                    id="date"
                                    className="relative bg-transparent block py-[9px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] focus:shadow-[0_0_0_0.2rem_#EAF9FF] hover:border-[#8D9FFF] focus:border-[#8D9FFF] disabled:border-[#CBCBCB]"
                                    placeholder=" "
                                    name="date"
                                    onChange={(e: any) => setSelectDate(e.target.value)}
                                    value={selectDate}
                                />
                                <label
                                    htmlFor="date"
                                    className="absolute whitespace-nowrap font-normal !text-xs xl:!text-[0.813rem] text-dGray duration-300 transform -translate-y-4 scale-[0.85] top-[4px] z-[5] origin-[0] bg-white px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text"
                                >
                                    Date
                                </label>
                            </div>

                            <div className="relative w-full">
                                <input
                                    type="time"
                                    id="time"
                                    className="relative bg-transparent block py-[9px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] focus:shadow-[0_0_0_0.2rem_#EAF9FF] hover:border-[#8D9FFF] focus:border-[#8D9FFF] disabled:border-[#CBCBCB]"
                                    placeholder=" "
                                    name="time"
                                    onChange={(e: any) => setSelectHours(e.target.value)}
                                    value={selectHours}
                                />
                                <label
                                    htmlFor="time"
                                    className="absolute whitespace-nowrap font-normal !text-xs xl:!text-[0.813rem] text-dGray duration-300 transform -translate-y-4 scale-[0.85] top-[4px] z-[5] origin-[0] bg-white px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text"
                                >
                                    Time
                                </label>
                            </div>
                            <div className="flex flex-col w-full">
                                <Select options={option} value={reminder} styles={styles} onChange={(e) => setReminder(e)} menuPortalTarget={document.body} />
                            </div>
                            <div className="w-full flex items-end justify-end">
                                <button className="bg-[#EEF4FC] rounded-[10px] px-[20px] h-[35px] text-primary text-sm font-medium cursor-pointer focus:border focus:border-primary" type="submit" onClick={() => { setDatePickerOpen(false); setHours(selectHours); setDate(selectDate); }}>Save</button>
                            </div>
                        </div>
                    </div>}
                </div>

                {Array.isArray(noteList) && noteList.map((item: any, index: number) => (
                    <div key={index} className="border shadow-md pt-[12px] px-[12px] pb-[6px] rounded-lg flex items-start min-h-[60px]">
                        {item?.note?.tag === "single" ?
                            <div className="flex flex-col gap-[8px] w-full">
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center gap-[8px]">
                                        {complete.includes(item?.note?.id) ? <div className="cursor-pointer" onClick={() => deleteHandler(item?.note?.id)}>
                                            <Check color="#6c6c6c" />
                                        </div> :
                                            <div className="cursor-pointer" onClick={() => setComplete([...complete, item?.note?.id])}>
                                                <Box />
                                            </div>
                                        }

                                        <h3 className="text-sm !text-mGray font-normal">{complete.includes(item?.note?.id) ? <del>{item?.note?.title}</del> : item?.note?.title}</h3>
                                    </div>
                                    <Menu align="end" menuButton={<MenuButton>
                                        <div className="cursor-pointer">
                                            <Dots color="#6B7280" />
                                        </div>
                                    </MenuButton>}>
                                        <MenuItem onClick={() => { handleDelete(item?.note?.tag === "single" ? item?.note?.id : item?.note[0]?.id, item?.note?.tag === "single" ? "single" : "double") }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><SettingRemove width="18" height="18" /><p className='text-sm'>Delete</p></div></div></MenuItem>
                                    </Menu>
                                </div>

                                <div className="w-full flex items-center justify-between pt-[4px]">
                                    <div className="flex items-center gap-[8px]">
                                        <button className="rounded-full bg-[#ebebeb] flex items-center gap-[4px] px-[6px] py-0.5 cursor-pointer"><Clock /> <p className="text-[#3c4043] font-normal text-[0.688rem]">{new Date().toLocaleDateString("sv") === item?.note?.date ? "Today" : Moment(item?.note?.date).format("MMM Do YY")}, {item?.note?.hours}{item?.note?.hours.toString().split(":")[0] >= 12 ? 'PM' : 'AM'}</p></button>
                                        <button className={`${`custom-tooltip-btn` + index} rounded-full bg-[#ebebeb] p-[6px] cursor-pointer flex items-center justify-center`}><Assign /></button>

                                        {item?.note?.user?.length > 0 && <Tooltip position="bottom" target={`.custom-tooltip-btn` + index}>
                                            <div className="flex flex-col gap-[12px] overflow-y-scroll w-full max-h-[20rem] !bg-transparent">
                                                <h3 className="text-primary text-base font-medium">Collaborators</h3>

                                                {Array.isArray(item?.note?.user) && item?.note?.user.map((u: any, idx: number) => (
                                                    <div className="flex items-center justify-between px-[8px]" key={idx}>
                                                        <div className="flex items-center gap-[12px] py-[4px]">
                                                            <div className={`${u?.bg} text-white text-lg font-medium !w-[2.25rem] !h-[2.25rem] flex items-center justify-center rounded-full`}>{u?.logo}</div>
                                                            <div className="flex flex-col">
                                                                <h3 className="text-sm text-[#202124] font-normal">{u?.name}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tooltip>}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="flex items-start justify-between w-full">
                                <div className="flex flex-col gap-[4px]" >
                                    {Array.isArray(item?.note) && item?.note.map((i: any, idx: number) => (
                                        <div className="flex items-center" key={idx}>
                                            {complete.includes(i?.id) ? <div className="cursor-pointer" onClick={() => deleteHandler(i?.id)}>
                                                <Check color="#6c6c6c" />
                                            </div> :
                                                <div className="cursor-pointer" onClick={() => setComplete([...complete, i?.id])}>
                                                    <Box />
                                                </div>
                                            }
                                            <div className="pl-[8px] pr-[12px] mt-[-2px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal">{complete.includes(i?.id) ? <del>{i?.title}</del> : i?.title}</div>

                                        </div>
                                    ))}

                                    {Array.isArray(item?.note) && item?.note.map((i: any, idx: number) => (
                                        <div className="w-full flex items-center justify-between pt-[4px]" key={idx}>
                                            {item?.note?.length === (idx + 1) && <div className="flex items-center gap-[8px]">
                                                <button className="rounded-full bg-[#ebebeb] flex items-center gap-[4px] px-[6px] py-0.5 cursor-pointer"><Clock /> <p className="text-[#3c4043] font-normal text-[0.688rem]">{new Date().toLocaleDateString("sv") === i?.date ? "Today" : Moment(i?.date).format("MMM Do YY")}, {i?.hours}{i?.hours.toString().split(":")[0] >= 12 ? 'PM' : 'AM'}</p></button>
                                                <button className={`${`custom-tooltip-btn` + index} rounded-full bg-[#ebebeb] p-[6px] cursor-pointer flex items-center justify-center`}><Assign /></button>

                                                {i?.user?.length > 0 && <Tooltip position="bottom" target={`.custom-tooltip-btn` + index}>
                                                    <div className="flex flex-col gap-[12px] overflow-y-scroll w-full max-h-[20rem] !bg-transparent">
                                                        <h3 className="text-primary text-base font-medium">Collaborators</h3>

                                                        {Array.isArray(i?.user) && i?.user.map((u: any, index: number) => (
                                                            <div className="flex items-center justify-between px-[8px]" key={index}>
                                                                <div className="flex items-center gap-[12px] py-[4px]">
                                                                    <div className={`${u?.bg} text-white text-lg font-medium !w-[2.25rem] !h-[2.25rem] flex items-center justify-center rounded-full`}>{u?.logo}</div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="text-sm text-[#202124] font-normal">{u?.name}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Tooltip>}

                                            </div>}
                                        </div>
                                    ))}
                                </div>


                                <Menu align="end" menuButton={<MenuButton>
                                    <div className="cursor-pointer">
                                        <Dots color="#6B7280" />
                                    </div>
                                </MenuButton>}>
                                    <MenuItem onClick={() => { handleDelete(item?.note?.tag === "single" ? item?.note?.id : item?.note[0]?.id, item?.note?.tag === "single" ? "single" : "double") }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><SettingRemove width="18" height="18" /><p className='text-sm'>Delete</p></div></div></MenuItem>
                                </Menu>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div >
    );
};

export default Note;