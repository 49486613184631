import React from "react";
import { Route, Routes } from "react-router-dom";
import { settingMenu } from "../../shared/utils/settingMenu";
import { useSelector } from "react-redux";
import TemplateSetting from "../../pages/setting/template-setting/TemplateSetting";

const LabParameter = React.lazy(() => import("../../pages/setting/scope-parameter-master/LabParameter"));
const Department = React.lazy(() => import("../../pages/setting/department/Department"));
const ParameterMasterList = React.lazy(() => import("../../pages/setting/standard-parameter-master/ParameterMaster"));
const Group = React.lazy(() => import("../../pages/setting/group/Group"));
const BillingManagement = React.lazy(() => import("../../pages/setting/billing-management/BillingManagement"));
const RemarksTemplate = React.lazy(() => import("../../pages/setting/remarks-template/RemarksTemplate"));

const SettingRoutes: React.FC<any> = ({ path }) => {
    const settingPermission: string = useSelector((state: any) => state.setting.settingPermission);

    return (
        <Routes>
            {Array.isArray(settingMenu) && settingMenu?.map((item: any, index: number) => (
                <React.Fragment key={index}>
                    {settingPermission?.hasOwnProperty(item?.name) && <Route path={item?.path} element={<item.component />} />}
                </React.Fragment>
            ))}

            {settingPermission?.hasOwnProperty("Scope Management") && <Route path="/lab-parameter/:id" element={<LabParameter />} />}
            {settingPermission?.hasOwnProperty("Standard & Parameter Master") && <Route path="/standard-parameter/:id" element={<ParameterMasterList />} />}
            {settingPermission?.hasOwnProperty("Department Master") && <Route path="/department/technical" element={<Department />} />}
            {settingPermission?.hasOwnProperty("Customer Group") && <Route path="/group/:id" element={<Group />} />}
            {settingPermission?.hasOwnProperty("Remarks Master") && <Route path="/remaks-template/:id" element={<RemarksTemplate />} />}
            {settingPermission?.hasOwnProperty("Billing Management") && <Route path="/tds" element={<BillingManagement />} />}
            {settingPermission?.hasOwnProperty("Template Management") && <Route path="/template-management/:mode" element={<TemplateSetting />} />}
            {settingPermission?.hasOwnProperty("Template Management") && <Route path="/template-management/:mode/:id" element={<TemplateSetting />} />}
            {/* <Route path="*" element={<Navigate to={path} />} /> */}
        </Routes>
    );
};

export default SettingRoutes;