/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from "react-redux";
import { CustProfile, CustomerClose, LoadingBtn, RegisterArrow, SettingAddMore } from "../../../svg/AllSvg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useSelectPrimary } from '../../../../shared/hooks/use-selectPrimary';
import Select, { components } from "react-select";
import AddOrg from "./AddOrg";
import { useNavigate } from "react-router-dom";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { errors } from "../../../../shared/utils/errors";
import useAxios from "../../../../shared/hooks/use-axios";
import { useSelectSecondary } from "../../../../shared/hooks/use-selectSecondary";
import { warning } from "../../../../shared/hooks/use-toastify";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import PrimaryInput from "../../../form/PrimaryInput";
import PrimaryTextarea from "../../../form/PrimaryTextarea";
import SingleSelect from "../../../form/SingleSelect";
import { useTranslation } from "react-i18next";

let CustomerTypeList: any[] = [
  { value: "Individual", label: 'Individual' },
  { value: "Organization", label: 'Organization' }
]

const AddCustomer = () => {
  const isCustomerCreate: boolean = useSelector((state: any) => state.invoice.isCustomerCreate);
  const isOrgOpen: boolean = useSelector((state: any) => state.invoice.isOrgOpen);
  const settingPath: string = useSelector((state: any) => state.ui.settingPath);
  // const [selectImage, setSelectImage] = useState<any>(null);
  // const [isLogin, setIsLogin] = useState<any>(false);
  const dispatch = useDispatch();
  let styles = useSelectPrimary();
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState<any[]>([])
  const [selectGroup, setSelectGroup] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [orgTypeList, setOrgTypeList] = useState<any[]>([])
  let stylesSec = useSelectSecondary();
  const [submit, setSubmit] = useState<any>(false);
  const { t } = useTranslation('common');

  const CreateGroup = (props: any) => {
    return (
      <components.MenuList  {...props}>
        {props.children}
        <div className='bg-[#F2F2F2] flex items-center justify-center gap-[8px] p-[8px] cursor-pointer' onClick={() => { navigate(`${settingPath}/setting/group`); dispatch(invoiceActions.setIsCustomerCreate(false)); }}><SettingAddMore color="#20328F" width="16" height="16" /><h3 className='text-sm font-medium text-primaryLight'>Add New</h3></div>
      </components.MenuList >
    )
  }

  const customerObj: any = {
    first_name: "",
    last_name: "",
    designation: "",
    gender: "",
    dob: "",
    mobile: "",
    email: "",
    note: "",
    org_name: "",
    org_type: "",
    description: "",
    customer_type: "",
    parent_org: "",
    tds: ""
  };

  const customerSchema = yup.object().shape({
    first_name: yup.string().required(errors.firstName),
    last_name: yup.string().required(errors.lastName),
    designation: yup.string(),
    gender: yup.object(),
    dob: yup.date(),
    mobile: yup.string().matches(/^\d{10}$/, "Please Enter valid Phone number").required(errors.mobile),
    email: yup.string().email(errors.wrongEmail).required(errors.email),
    note: yup.string(),
    org_name: yup.string(),
    org_type: yup.object(),
    description: yup.string(),
    customer_type: yup.object().required("Customer Type required"),
    parent_org: yup.object(),
    tds: yup.number()
  });

  const handleSubmit = async (values: any) => {
    try {
      let group: any[] = selectGroup?.map((item: any) => item?.value)

      let formdata = new FormData();
      formdata.append("first_name", values?.first_name);
      formdata.append("last_name", values?.last_name);
      formdata.append("designation", values?.designation);
      formdata.append("customer_type", values?.customer_type?.value);
      formdata.append("system_group", JSON.stringify(group));
      formdata.append("mobile", values?.mobile);
      formdata.append("email", values?.email);
      formdata.append("note", values?.note);
      !!values?.parent_org && formdata.append("parent_org", values?.parent_org?.value);
      formdata.append("org_name", values?.org_name);
      !!values?.org_type && formdata.append("org_type", values?.org_type?.value);
      formdata.append("org_addresses", JSON.stringify([]));
      formdata.append("org_description", values?.description);
      formdata.append("is_login_create", false as any);
      formdata.append("other_customer", true as any);

      setSubmit(true)
      const res: any = await useAxios.post("customers/api/create-customer/", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });

      if (res && res.status === 201) {
        dispatch(invoiceActions.setFullName(`${values?.first_name + " " + values?.last_name}`))
        localStorage.removeItem('create-customer');
        navigate("/invoice/customer");
        dispatch(invoiceActions.setTagApiCall(true))
        dispatch(invoiceActions.setApiCall("customer"))
        dispatch(invoiceActions.setSuccessCreateGroup(true));
        dispatch(invoiceActions.setIsCustomerCreate(false))
      }
    } catch (error: any) {
      setSubmit(false)
      if (error && error?.response?.status === 400) {
        warning(error?.response?.data?.results?.user?.toString())
      }
      console.error("error while creating customer", error);
    }
  }

  const getOrgType = async () => {
    try {
      const res: any = await useAxios.get("/utils/organization-type-list/");

      if (res && res.status === 200) {
        setOrgTypeList(Array.isArray(res?.data?.results) && res?.data?.results.map((item: any) => ({
          label: item?.text,
          value: item?.id
        })))
      }
    } catch (error) {
      console.error("error while fetching Org Type", error);
    }
  }

  const getGroup = async () => {
    try {
      const res: any = await useAxios.get("utility/system-group-list/");

      if (res && res.status === 200) {
        setGroupList(Array.isArray(res?.data?.results) && res?.data?.results.map((item: any) => ({
          label: item?.text,
          value: item?.id
        })))
      }
    } catch (error) {
      console.error("error while fetching Org Type", error);
    }
  }

  const getCustomer = async () => {
    try {
      const res: any = await useAxios.get("customers/api/customers-list/");

      if (res && res?.status === 200) {
        setCustomers(res?.data?.results?.map((item: any) => ({
          label: item?.first_name + " " + item?.last_name,
          value: item?.id
        })))
      }
    } catch (error) {
      console.error("error while afetch customers", error);
    }
  }

  useEffect(() => {
    getOrgType();
    getGroup();
    getCustomer();
    // eslint-disable-next-line
  }, [])

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(invoiceActions.setIsCustomerCreate(false))
    }
  };


  return (
    <>
      {isCustomerCreate && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
        <div className="flex justify-center items-center">
          <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[645px] z-[10]">
            <div className="relative">
              <img src={require("../../../../assets/images/invoice-customer.png")} alt="" className="absolute top-0 right-0 rounded-t-[20px] !rounded-l-none opacity-[0.4]" />

              <div className="cursor-pointer absolute top-[22px] right-[19px] z-[10]" onClick={() => dispatch(invoiceActions.setIsCustomerCreate(false))}>
                <CustomerClose />
              </div>
            </div>
            <div className="flex items-center justify-between px-[28px] pt-[23px] rounded-t-[20px] bg-white">
              <div className="flex items-center gap-[15px]">
                <div className="cursor-pointer">
                  <CustProfile />
                </div>
                <div className="flex flex-col items-start">
                  <h3 className="text-primaryLight font-semibold text-lg">{t("crm.customer.title")}</h3>
                  <p className="text-mGray font-normal text-sm">{t("crm.customer.desc")}</p>
                </div>
              </div>
            </div>
            <Formik
              initialValues={JSON.parse(localStorage.getItem('create-customer') as any) || customerObj}
              validationSchema={customerSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
                <Form>
                  {isOrgOpen && <AddOrg setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} handleChange={handleChange} handleBlur={handleBlur} values={values} customers={customers} orgTypeList={orgTypeList} />}
                  <div className="flex flex-col px-[26px] py-[21px] z-[10]">
                    <>
                      {localStorage.setItem('create-customer', JSON.stringify(values) as any)}
                    </>
                    <h3 className="text-primaryLight text-sm font-medium">{t("crm.customer.personal_details")}</h3>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-[25px] mt-[16px] mb-[31px] z-[10]">
                      <div className="w-full flex flex-col">
                        <PrimaryInput type="text" title={t("common_form.field.first_name")} name="first_name" required={true} value={values?.first_name} onBlur={handleBlur} onChange={handleChange} />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="w-full flex flex-col">
                        <PrimaryInput bg="bg-white sm:bg-[#f4fbf8]" type="text" title={t("common_form.field.last_name")} name="last_name" required={true} value={values?.last_name} onBlur={handleBlur} onChange={handleChange} />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>

                      <div className="w-full flex flex-col">
                        <PrimaryInput type="text" title={t("common_form.field.email")} name="email" required={true} onChange={handleChange} onBlur={handleBlur} value={values.email} />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>

                      <div className="w-full flex flex-col">
                        <PrimaryInput bg="bg-white sm:bg-[#f4fbf8]" type="text" title={t("common_form.field.phone")} name="mobile" required={true} onChange={handleChange} onBlur={handleBlur} value={values.mobile} />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>

                      <div className="w-full flex flex-col sm:col-span-2">
                        <SingleSelect options={CustomerTypeList} name="billcountry" placeholder={t("crm.customer.form.placeholder.customer_type")} title={t("crm.customer.form.field.customer_type")} onChange={(e: any) => setFieldValue("customer_type", e)} value={values?.customer_type} onBlur={(e: any) => setFieldTouched("customer_type", e)} />
                        <ErrorMessage
                          name="customer_type"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>

                      <div className="w-full flex flex-col sm:col-span-2">
                        <h3 className="text-mGray text-xs font-normal pb-[4px]">{t("crm.customer.form.field.add_group")}</h3>
                        <Select options={groupList} placeholder={t("crm.customer.form.field.add_group")} name="group" styles={stylesSec} className="!w-full" onChange={(e: any) => setSelectGroup(e)} value={selectGroup} isSearchable={true} isMulti maxMenuHeight={200} components={{ MenuList: CreateGroup }} />
                      </div>

                      {/* <div className="relative w-full">
                        <input
                          type="text"
                          id="add_group"
                          className="relative bg-transparent block py-[9px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-[#222] font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] focus:shadow-[0_0_0_0.2rem_#EAF9FF] hover:border-[#8D9FFF] focus:border-[#8D9FFF] disabled:border-[#CBCBCB]"
                          placeholder=" "
                          name="add_group"
                        />
                        <div className="absolute top-[8px] right-[8px]">
                          <AddGroupIcon />
                        </div>
                        <label
                          htmlFor="add_group"
                          className="absolute whitespace-nowrap font-normal !text-xs xl:!text-[0.813rem] text-[#5A5A5A] duration-300 transform -translate-y-4 scale-[0.85] top-[4px] z-[5] origin-[0] bg-white px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text"
                        >
                          Add Group
                        </label>
                      </div> */}
                    </div>

                    <PrimaryTextarea title={t("crm.customer.form.field.add_note")} name="note" value={values?.note} onChange={handleChange} onBlur={handleBlur} />

                    {values?.customer_type?.value === "Organization" && <>
                      {!!!values?.org_name ? <h3 className="text-primaryLight text-sm font-medium mt-[15px] cursor-pointer z-[10]" onClick={() => { dispatch(invoiceActions.setIsOrgOpen(true)); }}><span className="text-primaryLight text-lg font-medium pr-[4px]">+</span> {t("crm.customer.form.btn.add_org")}</h3> :

                        <div className="flex flex-col mt-[15px] gap-[15px] w-full">
                          <h3 className="text-primaryLight text-sm font-medium">{t("crm.customer.org.org_detail")}</h3>
                          <div className="grid  w-full grid-cols-1 sm:grid-cols-2 !cursor-pointer" onClick={() => { dispatch(invoiceActions.setIsOrgOpen(true)); }}>
                            <PrimaryInput type="text" title="Organization Name" name="org_name" value={values?.org_name} />
                          </div>
                        </div>
                      }
                    </>}
                  </div>

                  <div className="flex items-end justify-end w-full pb-[21px] px-[26px]">
                    {/* <button className="border border-[rgba(32,50,143,0.30)] h-[40px] bg-[rgba(183,194,255,0.10)] px-[32px] text-primaryLight text-sm font-medium rounded-[10px]">Expand Form</button> */}
                    <button type="submit" className={`${!submit && "disabled:opacity-[0.3]"} bg-primaryLight flex items-center justify-center px-[22px] gap-[10px] h-[40px] shadow-[0px_-1px_1px_1px_rgba(67,94,171,0.25)_inset,0px_1px_1px_1px_rgba(255,255,255,0.25)_inset,0px_0px_0px_1px_#435EAB_inset,0px_1px_2px_0px_rgba(20,14,62,0.25)] rounded-[10px] cursor-pointer disabled:cursor-not-allowed z-[10]`} disabled={values?.customer_type?.value === "Organization" ? ((!submit && !!values?.org_name) ? false : true) : (!submit ? false : true)}>

                      {submit && <LoadingBtn className="w-5 h-5 text-white animate-spin" />}
                      {submit ? <span className="text-white text-sm font-normal">{`${t("common_btn.save")}...`}</span> :
                        <>
                          <span className="text-white text-sm font-normal">{t("crm.customer.form.btn.create_cust")}</span><RegisterArrow className="fill-white" />
                        </>}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default AddCustomer;