import { createSlice } from '@reduxjs/toolkit';

interface CRMState {
    isOpen: boolean,
    view: string,
    groupBy: boolean,
    groupByOption: string,
    groupByOptionValue: string,
    screenShotList: any[],
    option: string,
    sortBy: string,
    sortByList: any[],
    groupByList: any[],
    crmData: any,
    activityFeed: any[],
    todoList: any[],
    sampleDetail: any[],
    userGroupByData: any,
    tagList: any[],
    crmLoading: boolean,
    sideBarOption: string,
    chatUser: any,
    chatUsersList: any[],
    select: string,
    isCustomerCreate: boolean,
    isSuccess: boolean,
    page: number,
    total: number,
    size: number,
    filterList: any[],
    filterResult: string,
    empName: string,
    defaultField: any[],
    detailField: any[],
    successType: string,
    isEmployeeEdit: boolean,
    selectedId: string,
    groupByExpand: any,
    qrcodePopup: boolean,
    sampleDetailPopup: any,
    tab: string,
    toggleTab: string,
    confirmation: boolean,
    detail: any,
    createGroup: boolean,
    successCreateGroup: boolean,
    customer: any,
    apiCall: boolean,
    isOrgOpen: boolean,
    fullName: string,
    creditLimitOpen: boolean,
    creditGroup: any,
    tagApiCall: boolean,
    frontField: any
}

const initialState: CRMState = {
    isOpen: false,
    view: "card",
    groupBy: false,
    groupByOption: "",
    groupByOptionValue: "",
    screenShotList: JSON.parse(localStorage.getItem('screenshot') as any) || [],
    option: "",
    sortBy: "-created_at",
    sortByList: [],
    groupByList: [],
    crmData: null,
    activityFeed: [],
    todoList: [],
    sampleDetail: [],
    userGroupByData: null,
    tagList: [],
    crmLoading: true,
    isSuccess: false,
    sideBarOption: "All",
    chatUser: {},
    chatUsersList: [
        { id: 1, logo: "V", bg: "bg-[#0297a7]", name: "Vista Lims", email: "harshita.kachhadiya@arohatech.com" },
        { id: 2, logo: "P", bg: "bg-[#502aaf]", name: "Project Meeting", email: "harshita.kachhadiya@arohatech.com" },
        { id: 3, logo: "N", bg: "bg-[#689f38]", name: "New Project", email: "harshita.kachhadiya@arohatech.com" },
    ],
    select: "",
    isCustomerCreate: false,
    page: 1, 
    total: 0,
    size: 10,
    filterList: [],
    filterResult: "",
    empName: "",
    defaultField: [],
    detailField: [],
    successType: "",
    isEmployeeEdit: false,
    selectedId: "",
    groupByExpand: {},
    qrcodePopup: false,
    sampleDetailPopup: 0,
    tab: "Todo List",
    toggleTab: "Sample Information",
    confirmation: false,
    detail: null,
    createGroup: false,
    successCreateGroup: false,
    customer: null,
    apiCall: false,
    isOrgOpen: false,
    fullName: "",
    creditLimitOpen: false,
    creditGroup: null,
    tagApiCall: false,
    frontField: null
}

const crmSlice = createSlice({
    name: 'crm',
    initialState,
    reducers: {
        Open: (state) => {
            state.isOpen = true;
        },
        Close: (state) => {
            state.isOpen = false;
        },
        toggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        groupByToggle: (state) => {
            state.groupBy = true;
        },
        groupToggle: (state) => {
            state.groupBy = false;
            state.groupByOption = "";
            state.groupByOptionValue = "";
            state.page = 1;
        },
        handleView: (state, action) => {
            state.view = action.payload;
        },
        handleGroupOption: (state, action) => {
            state.groupByOption = action.payload;
        },
        handleGroupOptionValue: (state, action) => {
            state.groupByOptionValue = action.payload;
        },
        updateScreenShotList: (state, action) => {
            state.screenShotList = [...state.screenShotList, action.payload];
        },
        assignScreenShotList: (state, action) => {
           state.screenShotList = action.payload;
        },
        changeOption: (state, action) => {
            state.option = action.payload;
        },
        switchOption: (state, action) => {
            state.sideBarOption = action.payload;
        },
        setChatUser: (state, action) => {
            state.chatUser = action.payload;
        },
        setSelect: (state, action) => {
            state.select = action.payload;
        },
        setIsCustomerCreate: (state, action) => {
            state.isCustomerCreate = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setSortByList: (state, action) => {
            state.sortByList = action.payload;
        },
        setCRMList: (state, action) => {
            state.crmData = action.payload;
        },
        setFeedList: (state, action) => {
            state.activityFeed = action.payload;
        },
        setTodoList: (state, action) => {
            state.todoList = action.payload;
        },
        setSampleDetail: (state, action) => {
            state.sampleDetail = action.payload;
        },
        setUserGroupByList: (state, action) => {
            state.userGroupByData = action.payload;
        },
        setTagList: (state, action) => {
            state.tagList = action.payload;
        },
        setCRMLoading: (state, action) => {
            state.crmLoading = action.payload;
        },
        setGroupByList: (state, action) => {
            state.groupByList = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setSize: (state, action) => {
            state.size = action.payload
        },
        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload
        },
        setFilterList: (state, action) => {
            state.filterList = action.payload
        },
        setFilterResult: (state, action) => {
            state.filterResult = action.payload
        },
        setEmpName: (state, action) => {
            state.empName = action.payload
        },
        setDefaultField: (state, action) => {
            state.defaultField = action.payload
        },
        setDetailField: (state, action) => {
            state.detailField = action.payload
        },
        setIsEmployeeEdit: (state, action) => {
            state.isEmployeeEdit = action.payload;
        },
        setSuccessType: (state, action) => {
            state.successType = action.payload
        },
        setSelectedId: (state, action) => {
            state.selectedId = action.payload
        },
        setGroupByExpand: (state, action) => {
            state.groupByExpand = action.payload
        },
        setQRCodePopup: (state, action) => {
            state.qrcodePopup = action.payload
        },
        setSampleDetailPopup: (state, action) => {
            state.sampleDetailPopup = action.payload
        },
        setTab: (state, action) => {
            state.tab = action.payload
        },
        setToggleTab: (state, action) => {
            state.toggleTab = action.payload
        },
        setConformation: (state, action) => {
            state.confirmation = action.payload
        },
        setDetail: (state, action) => {
            state.detail = action.payload
        },
        setCreateGroup: (state, action) => {
            state.createGroup = action.payload
        },
        setCustomer: (state, action) => {
            state.customer = action.payload
        },
        setApiCall: (state, action) => {
            state.apiCall = action.payload
        },
        setSuccessCreateGroup: (state, action) => {
            state.successCreateGroup = action.payload
        },
        setIsOrgOpen: (state, action) => {
            state.isOrgOpen = action.payload
        },
        setFullName: (state, action) => {
            state.fullName = action.payload
        },
        setCreditLimitOpen: (state, action) => {
            state.creditLimitOpen = action.payload
        },
        setCreditGroup: (state, action) => {
            state.creditGroup = action.payload
        },
        setTagApiCall: (state, action) => {
            state.tagApiCall = action.payload
        },
        setFrontField: (state, action) => {
            state.frontField = action.payload
        },
    }
});

export default crmSlice;
export const crmActions = crmSlice.actions;