import { createSlice } from '@reduxjs/toolkit';

interface EmployeeState {
    isOpen: boolean,
    view: string,
    groupBy: boolean,
    groupByOption: string,
    groupByOptionValue: string,
    screenShotList: any[],
    option: string,
    sortBy: string,
    sortByList: any[],
    groupByList: any[],
    userData: any[],
    userGroupByData: any[],
    tagList: any[],
    userLoading: boolean,
    sideBarOption: string,
    chatUser: any,
    chatUsersList: any[],
    select: string,
    isEmployeeCreate: boolean,
    isSuccess: boolean,
    page: number,
    total: number,
    size: number,
    filterList: any[],
    filterResult: string,
    empName: string,
    defaultField: any[],
    detailField: any[],
    successType: string,
    isEmployeeEdit: boolean,
    selectedId: string,
    groupByExpand: any,
    employeeDetailPopup: string,
    employeeDetail: any,
    modifyRole: boolean,
    frontField: any
}

const initialState: EmployeeState = {
    isOpen: false,
    view: "card",
    groupBy: false,
    groupByOption: "",
    groupByOptionValue: "",
    screenShotList: JSON.parse(localStorage.getItem('screenshot') as any) || [],
    option: "",
    sortBy: "-created_at",
    sortByList: [],
    groupByList: [],
    userData: [],
    userGroupByData: [],
    tagList: [],
    userLoading: true,
    isSuccess: false,
    sideBarOption: "All",
    chatUser: {},
    chatUsersList: [
        { id: 1, logo: "V", bg: "bg-[#0297a7]", name: "Vista Lims", email: "harshita.kachhadiya@arohatech.com" },
        { id: 2, logo: "P", bg: "bg-[#502aaf]", name: "Project Meeting", email: "harshita.kachhadiya@arohatech.com" },
        { id: 3, logo: "N", bg: "bg-[#689f38]", name: "New Project", email: "harshita.kachhadiya@arohatech.com" },
    ],
    select: "",
    isEmployeeCreate: false,
    page: 1, 
    total: 0,
    size: 10,
    filterList: [],
    filterResult: "",
    empName: "",
    defaultField: [],
    detailField: [],
    successType: "",
    isEmployeeEdit: false,
    selectedId: "",
    groupByExpand: {},
    employeeDetailPopup: "",
    employeeDetail: {},
    modifyRole: false,
    frontField: null
}

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        Open: (state) => {
            state.isOpen = true;
        },
        Close: (state) => {
            state.isOpen = false;
        },
        toggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        groupByToggle: (state) => {
            state.groupBy = true;
        },
        groupToggle: (state) => {
            state.groupBy = false;
            state.groupByOption = "";
            state.groupByOptionValue = "";
            state.page = 1;
        },
        handleView: (state, action) => {
            state.view = action.payload;
        },
        handleGroupOption: (state, action) => {
            state.groupByOption = action.payload;
        },
        handleGroupOptionValue: (state, action) => {
            state.groupByOptionValue = action.payload;
        },
        updateScreenShotList: (state, action) => {
            state.screenShotList = [...state.screenShotList, action.payload];
        },
        assignScreenShotList: (state, action) => {
           state.screenShotList = action.payload;
        },
        changeOption: (state, action) => {
            state.option = action.payload;
        },
        switchOption: (state, action) => {
            state.sideBarOption = action.payload;
        },
        setChatUser: (state, action) => {
            state.chatUser = action.payload;
        },
        setSelect: (state, action) => {
            state.select = action.payload;
        },
        setIsEmployeeCreate: (state, action) => {
            state.isEmployeeCreate = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setSortByList: (state, action) => {
            state.sortByList = action.payload;
        },
        setUserList: (state, action) => {
            state.userData = action.payload;
        },
        setUserGroupByList: (state, action) => {
            state.userGroupByData = action.payload;
        },
        setTagList: (state, action) => {
            state.tagList = action.payload;
        },
        setUserLoading: (state, action) => {
            state.userLoading = action.payload;
        },
        setGroupByList: (state, action) => {
            state.groupByList = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setSize: (state, action) => {
            state.size = action.payload
        },
        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload
        },
        setFilterList: (state, action) => {
            state.filterList = action.payload
        },
        setFilterResult: (state, action) => {
            state.filterResult = action.payload
        },
        setEmpName: (state, action) => {
            state.empName = action.payload
        },
        setDefaultField: (state, action) => {
            state.defaultField = action.payload
        },
        setDetailField: (state, action) => {
            state.detailField = action.payload
        },
        setIsEmployeeEdit: (state, action) => {
            state.isEmployeeEdit = action.payload;
        },
        setSuccessType: (state, action) => {
            state.successType = action.payload
        },
        setSelectedId: (state, action) => {
            state.selectedId = action.payload
        },
        setGroupByExpand: (state, action) => {
            state.groupByExpand = action.payload
        },
        setEmployeeDetailPopup: (state, action) => {
            state.employeeDetailPopup = action.payload
        },
        setEmployeeDetail: (state, action) => {
            state.employeeDetail = action.payload
        },
        setModifyRole: (state, action) => {
            state.modifyRole = action.payload
        },
        setFrontField: (state, action) => {
            state.frontField = action.payload
        },
    }
});

export default employeeSlice;
export const employeeActions = employeeSlice.actions;