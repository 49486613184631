import { createSlice } from '@reduxjs/toolkit';

interface UIState {
    spliter: boolean,
    theme: any,
    settingPath: string,
    module: string,
    moduleName: string,
    fullView: boolean,
    transition: boolean,
    transtionRoute: string,
    screenShotList: any[],
    isOpen: boolean,
    sideBarOption: string,
    chatUser: any,
    chatUsersList: any[],
    subModule: string,
    scrollY: boolean,
    screen: string
}

const initialState: UIState = {
    spliter: false,
    theme: localStorage.getItem("theme") || "light",
    settingPath: "",
    module: "",
    moduleName: "",
    fullView: !!JSON.parse(localStorage.getItem("full-view") as any) ? true : false,
    transition: true,
    transtionRoute: "",
    screenShotList: JSON.parse(localStorage.getItem('screenshot') as any) || [],
    isOpen: false,
    sideBarOption: "Todo",
    chatUser: {},
    chatUsersList: [
        { id: 1, logo: "V", bg: "bg-[#0297a7]", name: "Vista Lims", email: "harshita.kachhadiya@arohatech.com" },
        { id: 2, logo: "P", bg: "bg-[#502aaf]", name: "Project Meeting", email: "harshita.kachhadiya@arohatech.com" },
        { id: 3, logo: "N", bg: "bg-[#689f38]", name: "New Project", email: "harshita.kachhadiya@arohatech.com" },
    ],
    subModule: "",
    scrollY: false,
    screen: ""
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        changeSpliter: (state) => {
            state.spliter = !state.spliter;
        },
        changeTheme: (state, action) => {
            state.theme = action.payload;
        },
        setSettingPath: (state, action) => {
            state.settingPath = action.payload;
        },
        setModule: (state, action) => {
            state.module = action.payload;
        },
        setSubModule: (state, action) => {
            state.subModule = action.payload;
        },
        setModuleName:  (state, action) => {
            state.moduleName = action.payload;
        },
        setScreen:  (state, action) => {
            state.screen = action.payload;
        },
        setFullView: (state, action) => {
            state.fullView = action.payload
            if (action.payload === false) {
                state.transtionRoute = window.location.href
                state.transition = false
            }
        },
        setScrollY: (state, action) => {
            state.scrollY = action.payload
        },
        setTransition: (state, action) => {
            state.transition = action.payload
        },
        setTransitionRoute: (state, action) => {
            state.transtionRoute = action.payload
        },
        updateScreenShotList: (state, action) => {
            state.screenShotList = [...state.screenShotList, action.payload];
        },
        assignScreenShotList: (state, action) => {
            state.screenShotList = action.payload;
        },
        Open: (state) => {
            state.isOpen = true;
        },
        Close: (state) => {
            state.isOpen = false;
        },
        toggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        switchOption: (state, action) => {
            state.sideBarOption = action.payload;
        },
        setChatUser: (state, action) => {
            state.chatUser = action.payload;
        },
    }
});

export default uiSlice;
export const uiActions = uiSlice.actions;