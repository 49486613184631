import { Skeleton } from "primereact/skeleton";

const GroupView = () => {
  const cols = [];
  for (let i = 0; i < 5; i++) {
    cols.push(
      <div className="w-full mt-[12px]" key={i}>
        <Skeleton className="!w-full !mb-[20px]" height="55px"></Skeleton>
      </div>
    )
  };

  return (
    <>{cols}</>
  );
};

export default GroupView;