import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const Dashboard = React.lazy(() => import("../../pages/admin/dashboard/Dashboard"));
const BIReport = React.lazy(() => import("../../pages/admin/biReport/BIReport"));
const Laboratory = React.lazy(() => import("../../pages/laboratory/dashboard/Laboratory"));
const LaboratorySpliter = React.lazy(() => import("../../pages/laboratory/dashboard/LaboratorySpliter"));
const LaboratoryDetail = React.lazy(() => import("../../pages/laboratory/laboratoryDetail/LaboratoryDetail"));
const LaboratoryTask = React.lazy(() => import("../../pages/laboratory/laboratoryTask/LaboratoryTask"));
const LaboratoryProfile = React.lazy(() => import("../../pages/laboratory/laboratoryProfile/LaboratoryProfile"));
const Premium = React.lazy(() => import("../../pages/premium/Premium"));

const LaboratoryRoutes: React.FC<any> = ({ role }) => {
    const spliter: boolean = useSelector((state: any) => state.ui.spliter);
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);

    return (
        <>
            {permissionRole?.length > 0 && <>
                {permissionRole?.includes(role) ? <Routes>
                    {spliter ?
                        <>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/bi-report" element={<BIReport />} />
                            <Route path="/" element={<LaboratorySpliter />} />
                            <Route path="/:tab" element={<LaboratorySpliter />} />
                            <Route path="/:tab/:id" element={<LaboratoryDetail />} />
                            <Route path="/task/:tab/:id" element={<LaboratoryTask />} />
                            <Route path="/profile/:tab/:id" element={<LaboratoryProfile />} />
                        </> :
                        <>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/bi-report" element={<BIReport />} />
                            <Route path="/" element={<Laboratory />} />
                            <Route path="/:tab" element={<Laboratory />} />
                            <Route path="/:tab/:id" element={<Laboratory />} />
                            <Route path="/task/:tab/:id" element={<Laboratory />} />
                            <Route path="/profile/:tab/:id" element={<Laboratory />} />
                        </>
                    }
                    <Route path="*" element={<Navigate to="/laboratory/dashboard" />} />
                </Routes>
                    :
                    <Routes>
                        <Route path="/premium" element={<Premium />} />
                        <Route path="*" element={<Navigate to="/laboratory/premium" />} />
                    </Routes>
                }
            </>}
        </>
    );
};

export default LaboratoryRoutes;