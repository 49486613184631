import AdminLayout from "../../routes/main/AdminLayout";
import CRMLoyout from "../../routes/main/CRMLoyout";
import InvoiceLayout from "../../routes/main/InvoiceLayout";
import LabSampleLayout from "../../routes/main/LabSampleLayout";
import LaboratoryLayout from "../../routes/main/LaboratoryLayout";
import AdminRoutes from "../../routes/subroutes/AdminRoutes";
import CRMRoutes from "../../routes/subroutes/CRMRoutes";
import InvoiveRoutes from "../../routes/subroutes/InvoiceRoutes";
import LabSampleRoutes from "../../routes/subroutes/LabSampleRoutes";
import LabTestRoutes from "../../routes/subroutes/LabTestRoutes";
import LaboratoryRoutes from "../../routes/subroutes/LaboratoryRoutes";
import SettingRoutes from "../../routes/subroutes/SettingRoutes";

export const permission = [
    {id: 1, role: "Lab Coordinator", layout: <LabSampleLayout role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} path="/lims/samples" />, routes: <LabSampleRoutes role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} />, test_routes: <LabTestRoutes page="lims" />, navigate: "/lims/samples/dashboard", test_create: true, path: "/lims/samples/*", test_path: "/lims/test/*", setting_path: "/lims/samples/setting/*", setting_routes: <SettingRoutes path="/lims/samples/setting" />},
    {id: 2, role: "Lab Coordinator", layout: <CRMLoyout role ={["Lab Coordinator", "CRM"]} path="/crm" />, routes: <CRMRoutes role ={["Lab Coordinator", "CRM"]} />, test_routes: <LabTestRoutes page="crm" />, navigate: "/lims/samples/dashboard", test_create: true, path: "/crm/*", test_path: "/lims/test/*",  setting_path: "/crm/setting/*", setting_routes: <SettingRoutes path="/crm/setting" />},
    {id: 3,  role:"CRM", layout: <CRMLoyout role ={["Lab Coordinator", "CRM"]} path="/crm" />, routes: <CRMRoutes role ={["Lab Coordinator", "CRM"]} />, navigate: "/crm/dashboard", test_create: false, path: "/crm/*", setting_path: "/crm/setting/*", setting_routes: <SettingRoutes path="/crm/setting" />},
    {id: 4, role: "Lab Admin", layout: <LaboratoryLayout role="Lab Admin" path="/laboratory" />, routes: <LaboratoryRoutes role="Lab Admin" />, navigate: "/laboratory/dashboard", test_create: false, path: "/laboratory/*", setting_path: "/laboratory/setting/*", setting_routes: <SettingRoutes path="/laboratory/setting" />},
    {id: 5, role: "Account Officer", layout: <InvoiceLayout role={["Account Officer", "Lab Head"]} path="/invoice" />, routes: <InvoiveRoutes role={["Account Officer", "Lab Head"]} />, navigate: "/invoice/dashboard", test_create: false, path: "/invoice/*",  setting_path: "/invoice/setting/*", setting_routes: <SettingRoutes path="/invoice/setting" />},
    {id: 6, role: "Lab Scientist", layout: <LabSampleLayout role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} path="/lims/samples" />, routes: <LabSampleRoutes role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} />, navigate: "/lims/samples/dashboard", test_create: false, path: "/lims/samples/*",  setting_path: "/lims/samples/setting/*", setting_routes: <SettingRoutes path="/lims/samples/setting" />},
    {id: 7, role: "Sample Receiver", layout: <LabSampleLayout role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} path="/lims/samples" />, routes: <LabSampleRoutes role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} />, navigate: "/lims/samples/dashboard", test_create: false, path: "/lims/samples/*",  setting_path: "/lims/samples/setting/*", setting_routes: <SettingRoutes path="/lims/samples/setting" />},
    {id: 8, role: "LabIncharge", layout: <LabSampleLayout role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge"]} path="/lims/samples" />, routes: <LabSampleRoutes role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge"]} />, navigate: "/lims/samples/dashboard", test_create: false, path: "/lims/samples/*", setting_path: "/lims/samples/setting/*", setting_routes: <SettingRoutes path="/lims/samples/setting" />},
    {id: 9, role: "Lab Head", layout: <InvoiceLayout role={["Account Officer", "Lab Head"]} path="/invoice" />, routes: <InvoiveRoutes role={["Account Officer", "Lab Head"]} />, navigate: "/invoice/dashboard", test_create: false, path: "/invoice/*", setting_path: "/invoice/setting/*", setting_routes: <SettingRoutes path="/invoice/setting" />},
    {id: 10, role: "Remnant", layout: <LabSampleLayout role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} path="/lims/samples" />, routes: <LabSampleRoutes role ={["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"]} />, test_routes: <LabTestRoutes page="lims" />, navigate: "/lims/samples/dashboard", test_create: true, path: "/lims/samples/*", test_path: "/lims/test/*", setting_path: "/lims/samples/setting/*", setting_routes: <SettingRoutes path="/lims/samples/setting" />},
    {id: 11,  role:"SuperAdmin", layout: <AdminLayout role ="SuperAdmin" path="/admin" />, routes: <AdminRoutes role ="SuperAdmin" />, navigate: "/admin/dashboard", test_create: false, path: "/admin/*", setting_path: "/admin/setting/*", setting_routes: <SettingRoutes path="/admin/setting" />},
]