import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './shared/utils/i18next';
import './assets/css/main.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'react-toastify/dist/ReactToastify.css';
import 'aos/dist/aos.css';
import 'react-chat-widget/lib/styles.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-circular-progressbar/dist/styles.css';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import store from './shared/store/index';
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import aws from './shared/utils/aws';

// AWS AppSync config initialize

const url: any = aws.aws_appsync_graphqlEndpoint;
const region: any = aws.aws_appsync_region;
const auth: any = {
  type: aws.aws_appsync_authenticationType,
  apiKey: aws.aws_appsync_apiKey
};
const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

// Sentry config initialize

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();