import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AlertIcon, SampleTestClose } from "../../svg/AllSvg";
import { labSampleActions } from "../../../shared/store/labSample-Slice";

const DebitNoteAlert = () => {
    const debitNoteAlert: boolean = useSelector((state: any) => state.labSample.debitNoteAlert);
    const dispatch = useDispatch();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(labSampleActions.setDebitNoteAlert(false))
        }
    };

    return (
        <>
            {debitNoteAlert &&
                <div className="absolute inset-0 z-[34] overflow-y-hidden top-0 rounded-[10px]">
                    <div
                        className="absolute inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-[346px] sm:w-[467px]">
                            <div className="absolute top-[12px] right-[12px] z-30">
                                <div className="cursor-pointer" onClick={() => dispatch(labSampleActions.setDebitNoteAlert(false))}>
                                    <SampleTestClose />
                                </div>
                            </div>

                            <div className="w-full h-full flex flex-col items-center justify-center py-[16px]">
                                <AlertIcon width="188" height="125" />
                                <p className="max-w-[344px] text-sm font-normal text-dGray text-center pt-[15px]">There’s 2 debit note pending from this test request worth ₹2500, do you want to proceed?</p>

                                <div className="flex items-center gap-[15px] mt-[15px]">
                                    <button className="bg-primaryLight w-[163px] gap-[8px] ss:py-[10px] shadow-[0px_-1px_1px_1px_rgba(67,94,171,0.25)_inset,0px_1px_1px_1px_rgba(255,255,255,0.25)_inset,0px_0px_0px_1px_#435EAB_inset,0px_1px_2px_0px_rgba(20,14,62,0.25)] rounded-[10px] text-white text-sm font-medium whitespace-nowrap flex items-center justify-center" onClick={() => { dispatch(labSampleActions.setDebitNoteAlert(false)) }}>Notify User</button>
                                    <button className="bg-[#EEF4FC] rounded-[10px] w-[163px] h-[40px] text-primaryLight text-sm font-medium border border-[rgba(32,50,143,0.30)] flex items-center justify-center cursor-pointer" onClick={() => { dispatch(labSampleActions.setDebitNoteAlert(false)) }}>close</button>
                                </div>

                                <p className="text-lGray text-sm font-normal pt-[15px]">Generate Report Anyway</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default DebitNoteAlert;