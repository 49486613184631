/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { AddGroup, ArrowRightIcon, CloseGroup, CustomerClose, GroupClose, HeaderSearch, LoadingBtn, SearchRightFilter } from "../../../svg/AllSvg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useAxios from "../../../../shared/hooks/use-axios";
import { success } from "../../../../shared/hooks/use-toastify";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import PrimaryBtn from "../../../form/PrimaryBtn";

const AssignGroup = () => {
    const [submit, setSubmit] = useState<boolean>(false);
    const [category, setCategory] = useState<any[]>([])
    const [group, setGroup] = useState<any[]>([])
    const [allGroup, setAllGroup] = useState<any[]>([])
    const createGroup: boolean = useSelector((state: any) => state.invoice.createGroup);
    const customer: any = useSelector((state: any) => state.invoice.customer);
    const [categoryId, setCategoryId] = useState<string>("");
    const [selectCategory, setSelectCategory] = useState<string>("");
    const [selectDiscount, setSelectDiscount] = useState<any[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!customer?.system_group_list && customer?.system_group_list?.length > 0) {
            setSelectDiscount(customer?.system_group_list?.map((item: any) => item?.id))
        }
    }, [customer])

    const handleClose = (i: any) => {
        if (selectDiscount?.includes(i?.id)) {
            let arr: any[] = selectDiscount.filter((item: any) => item !== i?.id)
            setSelectDiscount(arr);
        } else {
            setSelectDiscount([...selectDiscount, i?.id])
        }
    }

    const handleSave = (g: any) => {
        let arr: any[] = selectDiscount;
        for (let i = 0; i < group?.length; i++) {
            const el = group[i];
            if (selectDiscount?.includes(el?.id)) {
                if (g?.group_type_name === el?.group_type_name) {
                    arr = selectDiscount.filter((item: any) => item !== el?.id)
                    setSelectDiscount(arr);
                }
            }
        }

        setSelectDiscount([...arr, g?.id])
    }

    const getCategory = async () => {
        try {
            const res: any = await useAxios.get(`/utility/group-type-list/`);

            if (res && res?.status === 200) {
                setCategory(res?.data.results);
                setCategoryId(res?.data?.results[0]?.id)
                setSelectCategory(res?.data?.results[0]?.text)
            }
        } catch (error) {
            console.error("error while feching test request", error);
        }
    }

    const getGroupAll = async () => {
        try {
            const res: any = await useAxios.get(`utility/api/system-groups/`);

            if (res && res?.status === 200) {
                setAllGroup(res?.data);
            }
        } catch (error) {
            console.error("error while feching test request", error);
        }
    }

    const getGroup = async () => {
        try {
            const res: any = await useAxios.get(`utility/api/system-groups/?type=${categoryId}`);

            if (res && res?.status === 200) {
                setGroup(res?.data)
            }
        } catch (error) {
            console.error("error while feching test request", error);
        }
    }

    useEffect(() => {
        getGroup();
    }, [categoryId])

    useEffect(() => {
        getCategory();
        getGroupAll();
    }, [])

    const handleSubmit = async () => {
        setSubmit(true)
        let payload = {
            "system_group": selectDiscount
        }

        try {
            await useAxios.patch(`/customers/api/customer-group-assign/${customer?.id}/`, payload);
            success("Group Assign successfully.");
            dispatch(invoiceActions.setCreateGroup(false))
            dispatch(invoiceActions.setApiCall("customer"))
            setSubmit(false)
        } catch (error) {
            setSubmit(false)
            console.error("error while feching test request", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(invoiceActions.setCreateGroup(false))
        }
    };

    return (
        <>
            {createGroup && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-[#FBFBFB] rounded-[20px] flex flex-col gap-[14px] w-[320px] sm:w-[600px] md:w-[745px] z-[10]">
                        <div className="flex items-center justify-between gap-[8px] rounded-t-[20px] px-[23px] pt-[20px]">
                            <div className="flex flex-col items-start">
                                <h3 className="text-primaryLight font-semibold text-lg">Customer Group</h3>
                                <p className="text-mGray font-normal text-xs">Cum nostrum est quia laudantium sed asperiores vero...</p>
                            </div>
                            <div className="cursor-pointer z-[10]" onClick={() => dispatch(invoiceActions.setCreateGroup(false))}>
                                <CustomerClose />
                            </div>
                        </div>

                        <div className="px-[23px] py-[20px] flex flex-col gap-[14px]">
                            {selectDiscount?.length === 0 ? <div className="bg-[#EEE] rounded-[10px] border-[2px] border-dashed border-[#DFDFDF] w-full !min-h-[75px] flex items-center justify-center text-lGray text-xs font-normal">
                                Selected Tags will Appear here
                            </div>
                                :
                                <div className="rounded-[10px] bg-white border-[2px] border-dashed border-[#DFDFDF] p-[9px] flex flex-wrap gap-[8px] w-full !min-h-[75px]">
                                    {Array.isArray(allGroup) && allGroup.map((item: any, index: number) => (
                                        <div key={index} className={`${!selectDiscount.includes(item?.id) && "hidden"}`}>
                                            {selectDiscount.includes(item?.id) && <div className="bg-[#D7E8FF] rounded-[5px] flex items-center gap-[10px] h-[25px] justify-center px-[24px]">
                                                <h3 className="text-primaryLight text-sm font-normal">{item?.value}{`${item?.group_type_name === "Discount" ? "% " : ""}`} &nbsp;&nbsp;{item?.group_type_name}</h3>
                                                <div className="cursor-pointer" onClick={() => handleClose(item)}>
                                                    <GroupClose />
                                                </div>
                                            </div>}
                                        </div>
                                    ))}
                                </div>
                            }

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[20px] w-full !min-h-[450px]">
                                <div className="bg-white rounded-[20px] w-full py-[16px] px-[22px]">
                                    <div className="flex items-center justify-between">
                                        <h3 className="text-primaryLight text-base font-semibold">Categories</h3>

                                        <div className='flex items-center gap-[10px]'>
                                            <div className='bg-white rounded-[10px] p-[9px] flex items-center justify-center cursor-pointer border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)]'>
                                                <HeaderSearch className="fill-mGray" />
                                            </div>

                                            <div className="rounded-[10px] bg-white p-[6px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] cursor-pointer">
                                                <SearchRightFilter color="#6B7280" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-[10px] mt-[15px]">
                                        {Array.isArray(category) && category.map((item: any, index: number) => (
                                            <div key={index} className={`${categoryId === item?.id ? "border-[#567CF0] bg-[#ECF1FF]" : "border-[#EFEFEF] bg-white"} rounded-[10px] px-[24px] py-[12px] border w-full flex items-center justify-between cursor-pointer hover:bg-[#ECF1FF]`} onClick={() => { setCategoryId(item?.id); setSelectCategory(item?.text); }}>
                                                <h3 className={`${categoryId === item?.id ? "text-primaryLight" : "text-mGray"} font-normal text-sm`}>{item?.text}</h3>
                                                <ArrowRightIcon className={`${categoryId === item?.id ? "stroke-primaryLight" : "stroke-mGray"}`} />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="bg-white rounded-[20px] w-full py-[16px] px-[22px]">
                                    <div className="flex items-center justify-between">
                                        <h3 className="text-primaryLight text-base font-semibold">General Group</h3>

                                        <div className='bg-white rounded-[10px] p-[9px] flex items-center justify-center cursor-pointer border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)]'>
                                            <HeaderSearch className="fill-mGray" />
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-[10px] mt-[15px]">
                                        {Array.isArray(group) && group.map((item: any, index: number) => (
                                            <div key={index} className="border-[#EFEFEF] bg-white rounded-[10px] px-[24px] py-[6px] border w-full flex items-center justify-between">
                                                <h3 className="text-mGray font-normal text-sm">{item?.value}{`${item?.group_type_name === "Discount" ? "% " : ""}`} &nbsp;&nbsp;{item?.group_type_name}</h3>
                                                {selectDiscount.includes(item?.id) ?
                                                    <div className="cursor-pointer" onClick={() => handleClose(item)}>
                                                        <CloseGroup />
                                                    </div>
                                                    :
                                                    <div className="cursor-pointer" onClick={() => selectCategory === "General" ? handleClose(item) : handleSave(item)}>
                                                        <AddGroup />
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex items-end justify-end px-[23px] pb-[20px]">
                            <PrimaryBtn disabled={(selectDiscount?.length > 0 && !submit) ? false : true} onClick={handleSubmit} title={!submit ? "Submit" :
                                <>
                                    <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                    Submit...
                                </>} />
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default AssignGroup;