/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AllTabs from '../Common/AllTabs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uiActions } from '../../../shared/store/ui-Slice';
import { sidebarData } from '../../../shared/utils/sidebarData';
import { tableActions } from '../../../shared/store/table-Slice';
import { theme } from '../../../shared/utils/theme';
import { SettingRightArrow, SubMenuDot } from '../../svg/AllSvg';

const MiniSidebar: React.FC<any> = () => {
    const user: any = useSelector((state: any) => state.auth.user);
    const screenShotList: any[] = useSelector((state: any) => state.ui.screenShotList);
    const settingPath: string = useSelector((state: any) => state.ui.settingPath);
    const module: string = useSelector((state: any) => state.ui.module);
    const subModule: string = useSelector((state: any) => state.ui.subModule);
    const moduleName: string = useSelector((state: any) => state.ui.moduleName);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [menu, setMenu] = useState<boolean>(false);
    const [tag, setTag] = useState<string>("");
    const [subTag, setSubTag] = useState<string>("");
    const [subMenu, setSubMenu] = useState<string>("");
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const data = sidebarData(t, settingPath);

    useEffect(() => {
        if (!!JSON.parse(localStorage.getItem('screenshot') as any)) {
            dispatch(uiActions.assignScreenShotList(JSON.parse(localStorage.getItem('screenshot') as any)))
        } else {
            dispatch(uiActions.assignScreenShotList([]))
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    useEffect(() => {
        if (!menu) {
            setTag("")
            setSubTag("")
            setSubMenu("")
        }
    }, [menu])

    // dispatch(invoiceActions.setApiCall("invoice"))
    return (
        <>
            <AllTabs isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={`${scrollY ? "top-[75px] h-[calc(100vh-75px)]" : "top-[70px] h-[calc(100vh-70px)]"} flex justify-start items-start fixed z-[3] bg-white overflow-y-scroll no-scrollbar`}>
                <div className={`!h-full ${!menu ? "w-[50px]" : "w-[250px]"} main-menu bg-white flex items-center flex-col justify-between relative z-[3]`}>
                    <div className="flex items-center flex-col w-full h-full">
                        {Array.isArray(data[moduleName]?.top_menu) && data[moduleName]?.top_menu?.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                {(item?.role?.includes(user?.role) || item?.role?.includes("all")) && <div className='w-full flex items-center cursor-pointer h-[50px]' onClick={() => {
                                    if (item?.submenu?.length === 0) {
                                        if (module !== item?.tag) {
                                            dispatch(tableActions.setClear());
                                            dispatch(uiActions.setModule(""));
                                            dispatch(uiActions.setSubModule(""));
                                            setSubTag("")
                                        }
                                        navigate(item?.url);
                                    } else {
                                        subMenu === item?.tag ? setSubMenu("") : setSubMenu(item?.tag)
                                    }
                                }}
                                    onMouseEnter={() => { setTag(item?.tag); setMenu(true); }}
                                    onMouseLeave={() => { setMenu(false); setTag(""); }}>
                                    <div className={`${module === item?.tag && "bg-[#F8FAFB]"} min-w-[50px] flex items-center justify-center h-full ${tag === item?.tag && "bg-[#F8FAFB]"}`}>
                                        <item.icon className={`${module === item?.tag ?
                                            `${item?.colorType === "fill-stroke" ?
                                                `${(moduleName === "customer") ?
                                                    "fill-primaryLight stroke-primaryLight" : `${theme[moduleName]?.fill} ${theme[moduleName]?.stroke}`}` :
                                                item?.colorType === "fill" ? `${(moduleName === "customer") ?
                                                    "fill-primaryLight" : `${theme[moduleName]?.fill}`}` : `${(moduleName === "customer") ?
                                                        "stroke-primaryLight" : `${theme[moduleName]?.stroke}`}`}` : `${item?.colorType === "fill-stroke" ? "fill-lGray stroke-lGray" : item?.colorType === "fill" ? "fill-lGray" : "stroke-lGray"}`} ${tag === item?.tag && `${item?.colorType === "fill-stroke" ? `${(moduleName === "customer") ?
                                                            "fill-primaryLight stroke-primaryLight" : `${theme[moduleName]?.fill} ${theme[moduleName]?.stroke}`}` : item?.colorType === "fill" ? `${(moduleName === "customer") ?
                                                                "fill-primaryLight" : `${theme[moduleName]?.fill}`}` : `${(moduleName === "customer") ?
                                                                    "stroke-primaryLight" : `${theme[moduleName]?.stroke}`}`}`}`} />

                                    </div>
                                    {menu && <h3 key={index} className={`${module === item?.tag ? `${(moduleName === "customer") ?
                                        "text-primaryLight" : `${theme[moduleName]?.text}`} bg-[#F8FAFB]` : "text-mGray"} font-normal text-base flex items-center w-full h-full ${tag === item?.tag && `${(moduleName === "customer") ?
                                            "text-primaryLight" : `${theme[moduleName]?.text}`} bg-[#F8FAFB]`} justify-between pl-[2px] pr-[16px] line-clamp-1`}>{item?.name}
                                        {item?.submenu?.length > 0 &&
                                            <div className={`${subMenu !== item?.tag ? "caret-right" : "caret-down"}`}>
                                                <SettingRightArrow className={`${module === item?.tag ? `${(moduleName === "customer") ?
                                                    "stroke-primaryLight" : `${theme[moduleName]?.stroke}`}` : "stroke-lGray"} ${tag === item?.tag && `${(moduleName === "customer") ?
                                                        "stroke-primaryLight" : `${theme[moduleName]?.stroke}`}`}`} />
                                            </div>
                                        }
                                    </h3>}
                                </div>}
                                <div className={`flex flex-col w-full ${(item?.submenu?.length > 0 && (subMenu === item?.tag)) ? "max-h-[300px] opacity-1" : "max-h-0 opacity-0"} submenu`}>
                                    {Array.isArray(item?.submenu) && item?.submenu?.map((i: any, idx: number) => (
                                        <div key={idx} className={`w-full flex items-center ${(item?.submenu?.length > 0 && (subMenu === item?.tag)) ? "h-[50px] block" : "h-0 hidden"} cursor-pointer`} onMouseEnter={() => { setTag(item?.tag); setSubTag(i?.tag); setMenu(true); }}
                                            onMouseLeave={() => { setMenu(false); setTag(""); }} onClick={() => {
                                                if (subModule !== i?.tag) {
                                                    dispatch(tableActions.setClear());
                                                    dispatch(uiActions.setModule(""));
                                                    dispatch(uiActions.setSubModule(""));
                                                    setTag(item?.tag)
                                                    setSubTag(i?.tag)
                                                }
                                                navigate(i?.url);
                                            }}>
                                            <div className={`${subModule === i?.tag ? "text-primaryLight bg-[#F8FAFB]" : "text-mGray"} h-full min-w-[50px] flex items-center justify-center ${subTag === i?.tag && "bg-[#F8FAFB]"}`}>
                                                <SubMenuDot className={`${subModule === i?.tag ? "stroke-primaryLight fill-primaryLight w-[18px] h-[18px]" : "stroke-lGray fill-lGray w-[10px] h-[10px]"}`} />
                                            </div>
                                            {menu && <h4 className={`${subModule === i?.tag ? "text-primaryLight bg-[#F8FAFB]" : "text-mGray"} font-normal text-base h-full flex items-center w-full justify-start pl-[2px] pr-[16px] ${subTag === i?.tag && "bg-[#F8FAFB]"} line-clamp-1`}>{i?.name}</h4>}
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        ))}
                        {/* <div className={`h-[50px] w-full flex items-center justify-center cursor-pointer ${tag === "campaign" && "bg-[#F8FAFB]"}`} onMouseEnter={() => setTag("campaign")}>
                            <ERP className={`fill-lGray ${tag === "campaign" && "fill-primaryLight"}`} />
                        </div>
                        <div className={`h-[50px] w-full flex items-center justify-center cursor-pointer ${tag === "accounts" && "bg-[#F8FAFB]"}`} onMouseEnter={() => setTag("accounts")}>
                            <Accounts className={`fill-lGray ${tag === "accounts" && "fill-primaryLight"}`} />
                        </div> */}
                    </div>
                    <div className="flex items-center justify-end flex-col w-full">
                        {Array.isArray(data[moduleName]?.bottom_menu) && data[moduleName]?.bottom_menu?.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                {(item?.role?.includes(user?.role) || item?.role?.includes("all")) && <div className='w-full flex items-center h-[50px] cursor-pointer' onClick={() => {
                                    if (module !== item?.tag && item?.tag !== "tabs") {
                                        dispatch(tableActions.setClear());
                                        dispatch(uiActions.setModule(""));
                                    }
                                    item?.tag === "tabs" ? setIsOpen(true) : navigate(item?.url);
                                }}
                                    onMouseEnter={() => { setTag(item?.tag); setMenu(true); }}
                                    onMouseLeave={() => { setMenu(false); setTag(""); }}>
                                    <div className={`${module === item?.tag && "bg-[#F8FAFB]"} h-full min-w-[50px] flex items-center justify-center ${tag === item?.tag && "bg-[#F8FAFB]"} relative`}>
                                        <item.icon className={`${module === item?.tag ? `${item?.colorType === "fill-stroke" ? `${(moduleName === "customer") ?
                                            "fill-primaryLight stroke-primaryLight" : `${theme[moduleName]?.fill} ${theme[moduleName]?.stroke}`}` : item?.colorType === "fill" ? `${(moduleName === "customer") ?
                                                "fill-primaryLight" : `${theme[moduleName]?.fill}`}` : `${(moduleName === "customer") ?
                                                    "stroke-primaryLight" : `${theme[moduleName]?.stroke}`}`}` : `${item?.colorType === "fill-stroke" ? "fill-lGray stroke-lGray" : item?.colorType === "fill" ? "fill-lGray" : "stroke-lGray"}`} ${tag === item?.tag && `${item?.colorType === "fill-stroke" ? `${(moduleName === "customer") ?
                                                        "fill-primaryLight stroke-primaryLight" : `${theme[moduleName]?.fill} ${theme[moduleName]?.stroke}`}` : item?.colorType === "fill" ? `${(moduleName === "customer") ?
                                                            "fill-primaryLight" : `${theme[moduleName]?.fill}`}` : `${(moduleName === "customer") ?
                                                                "stroke-primaryLight" : `${theme[moduleName]?.stroke}`}`}`}`} />
                                        {(screenShotList?.length > 0 && item?.tag === "tabs") && <div className="text-white rounded-[3px] bg-orange absolute top-[14px] right-[8px] text-[0.5rem] font-semibold !w-[15px] !h-[12px] flex items-center justify-center">{screenShotList?.length}</div>}
                                    </div>

                                    {menu && <h3 className={`${module === item?.tag ? `${(moduleName === "customer") ?
                                        "text-primaryLight" : `${theme[moduleName]?.text}`} bg-[#F8FAFB]` : "text-mGray"} font-normal text-base h-full flex items-center justify-start w-full pt-[2px] pl-[4px] pr-[16px] ${tag === item?.tag && `${(moduleName === "customer") ?
                                            "text-primaryLight" : `${theme[moduleName]?.text}`} bg-[#F8FAFB]`} line-clamp-1`}>{item?.name}</h3>}
                                </div>}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MiniSidebar;