import useKeyboardShortcut from "use-keyboard-shortcut";

export const useSortcut = (key: any, handler: any) => {
    useKeyboardShortcut(
        key,
        handler,
        { 
          overrideSystem: true,
          ignoreInputFields: true, 
          repeatOnHold: false 
        }
      );
};