import { createSlice } from '@reduxjs/toolkit';

interface tableState {
    view: string,
    groupBy: boolean,
    groupByOption: string,
    groupByOptionValue: string,
    option: any,
    sortBy: string,
    sortByList: any[],
    groupByList: any[],
    data: any,
    groupByData: any,
    tagList: any[],
    loading: boolean,
    page: number,
    total: number,
    size: number,
    filterList: any[],
    filterResult: string,
    defaultField: any[],
    detailField: any[],
    frontField: any,
    groupByExpand: string,
    childPage: number,
    childTotal: number,
    childSize: number,
    viewAll: boolean,
    filterObj: any,
    success: boolean,
    tagApiCall: boolean,
    selectOption: string,
    first: any, 
    childFirst: any,
    fieldType: any
}

const initialState: tableState = {
    view: "card",
    groupBy: false,
    groupByOption: "",
    groupByOptionValue: "",
    option: null,
    sortBy: "-created_at",
    sortByList: [],
    groupByList: [],
    data: null,
    groupByData: null,
    tagList: [],
    loading: true,
    page: 1,
    total: 0,
    size: 10,
    filterList: [],
    filterResult: "",
    defaultField: [],
    detailField: [],
    frontField: null,
    groupByExpand: "",
    childPage: 1,
    childTotal: 0,
    childSize: 10,
    viewAll: false,
    filterObj: {},
    success: false,
    tagApiCall: false,
    selectOption: "",
    first: 0,
    childFirst: 0,
    fieldType: null
}

const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        groupByToggle: (state) => {
            state.groupBy = true;
        },
        groupToggle: (state) => {
            state.groupBy = false;
            state.groupByOption = "";
            state.groupByOptionValue = "";
            state.page = 1;
            state.groupByExpand = "";
            state.success = true;
        },
        handleView: (state, action) => {
            state.view = action.payload;
        },
        handleGroupOption: (state, action) => {
            state.groupByOption = action.payload;
        },
        handleGroupOptionValue: (state, action) => {
            state.groupByOptionValue = action.payload;
        },
        changeOption: (state, action) => {
            state.option = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setSortByList: (state, action) => {
            state.sortByList = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setGroupByData: (state, action) => {
            state.groupByData = action.payload;
        },
        setTagList: (state, action) => {
            state.tagList = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setGroupByList: (state, action) => {
            state.groupByList = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setSize: (state, action) => {
            state.size = action.payload
        },
        setFilterList: (state, action) => {
            state.filterList = action.payload
        },
        setFilterResult: (state, action) => {
            state.filterResult = action.payload
        },
        setFilterObj: (state, action) => {
            state.filterObj = action.payload
        },
        setDefaultField: (state, action) => {
            state.defaultField = action.payload
        },
        setDetailField: (state, action) => {
            state.detailField = action.payload
        },
        setFrontField: (state, action) => {
            state.frontField = action.payload
        },
        setGroupByExpand: (state, action) => {
            state.groupByExpand = action.payload
        },
        setChildPage: (state, action) => {
            state.childPage = action.payload
        },
        setChildTotal: (state, action) => {
            state.childTotal = action.payload
        },
        setChildSize: (state, action) => {
            state.childSize = action.payload
        },
        setViewAll: (state, action) => {
            state.viewAll = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
        setTagApiCall: (state, action) => {
            state.tagApiCall = action.payload
        },
        setSelectOption: (state, action) => {
            state.selectOption = action.payload
        },
        setFirst: (state, action) => {
            state.first = action.payload
        },
        setChildFirst: (state, action) => {
            state.childFirst = action.payload
        },
        setFieldType: (state, action) => {
            state.fieldType = action.payload
        },
        setClear: (state) => {
            state.data = null;
            state.groupByData = null;
            state.sortBy = "-created_at";
            state.childPage = 1;
            state.childTotal = 0;
            state.childSize = 10;
            state.total = 0;
            state.size = 10;
            state.page = 1;
            state.first = 0;
            state.childFirst = 0;
            state.view = "card";
            state.groupBy = false;
            state.groupByOption = "";
            state.groupByOptionValue = "";
            state.option = null;
            state.groupByExpand = "";
            state.success = false;
            state.sortByList = [];
            state.groupByList = [];
            state.tagList = [];
            state.loading = true;
            state.filterList = [];
            state.filterResult = "";
            state.defaultField = [];
            state.detailField = [];
            state.frontField = null;
            state.fieldType = null;
            state.viewAll = false;
            state.filterObj = {};
            state.tagApiCall = false;
        }
    }
});

export default tableSlice;
export const tableActions = tableSlice.actions;