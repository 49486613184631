const SeconadaryInput: React.FC<any> = ({ type, name, title, onChange, value, onBlur, tabIndex, required, defaultValue }: any) => {
    return (
        <div className="relative w-full">
            <input
                type={type}
                id={name}
                className="relative bg-transparent block py-[9px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-[#222] font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] focus:shadow-[0_0_0_0.2rem_#EAF9FF] hover:border-[#8D9FFF] focus:border-[#8D9FFF] disabled:border-[#CBCBCB]"
                placeholder=" "
                name={name}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                tabIndex={tabIndex}
                defaultValue={defaultValue}
            />
            {required && <div className="absolute top-0 right-0 border-r-[5px] rounded-r-[6px] border-[#ff0000] h-full"></div>}
            <label
                htmlFor={name}
                className="absolute whitespace-nowrap font-normal !text-xs xl:!text-[0.813rem] text-[#5A5A5A] duration-300 transform -translate-y-4 scale-[0.85] top-[4px] origin-[0] bg-white px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text"
            >
                {title}
            </label>
        </div>
    );
};

export default SeconadaryInput;