/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { InvoicePrint, NameOrgIcon, ProformaChange, SortArrow, SortDownArrow, SortUpArrow } from "../../../svg/AllSvg";
import SkeletonGridView from "../../../../skeleton/components/invoice/invoice/GridView";
import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Moment from 'moment';
import { randomColor } from "../../../../shared/utils/randomColor";
import { Paginator } from "primereact/paginator";
import { tableActions } from "../../../../shared/store/table-Slice";
import { Skeleton } from "primereact/skeleton";
import useAxios from "../../../../shared/hooks/use-axios";
import { getAWSFileUrl } from "../../../../shared/utils/getAWSFileUrl";

const TableView: React.FC<any> = ({ type, url }: any) => {
    const user: any = useSelector((state: any) => state.auth.user);
    const frontField: any = useSelector((state: any) => state.table.frontField);
    const data: any[] = useSelector((state: any) => state.table.data);
    const loading: boolean = useSelector((state: any) => state.table.loading);
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const sortByList: any[] = useSelector((state: any) => state.table.sortByList);
    const defaultField: any = useSelector((state: any) => state.table.defaultField);
    const total: number = useSelector((state: any) => state.table.total);
    const size: number = useSelector((state: any) => state.table.size);
    const childTotal: number = useSelector((state: any) => state.table.childTotal);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const childFirst: number = useSelector((state: any) => state.table.childFirst);
    const fieldType: any = useSelector((state: any) => state.table.fieldType);
    const first: number = useSelector((state: any) => state.table.first);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [allCheckBox, setAllCheckBox] = useState<boolean>(false)
    const [list, setList] = useState<any[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const [oneCheck, setOneCheck] = useState<string>("");
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

   const getSortIcon = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                return <SortDownArrow />
            } else if (desc === sortBy) {
                return <SortUpArrow />
            } else {
                return <SortArrow />
            }
        }
    }

    const handleSort = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                dispatch(tableActions.setSortBy(desc))
            } else {
                dispatch(tableActions.setSortBy(asc))
            }
        }
    }

    const onPageChange = (event: any) => {
        if (type === "group") {
            dispatch(tableActions.setChildPage(event?.page + 1));
            dispatch(tableActions.setChildFirst(event.first));
            dispatch(tableActions.setChildSize(event.rows))
        } else {
            dispatch(tableActions.setPage(event?.page + 1));
            dispatch(tableActions.setFirst(event.first));
            dispatch(tableActions.setSize(event.rows))
        }
    };

    const getHeader = () => {
        let arr: any = [];
        defaultField.forEach((el: any) => {
            for (const key in frontField) {
                if (key === el) {
                    arr.push(<th key={key} className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full cursor-pointer" onClick={() => handleSort(key, `-${key}`)}>
                        <div className='flex items-center justify-start w-full gap-[8px]'>
                            <p className='text-lGray font-normal text-sm !text-left'>{frontField[key]}</p>
                            <div className="w-[13px] h-[13px]">
                                {getSortIcon(key, `-${key}`)}
                            </div>
                        </div>
                    </th>);
                }
            }
        });

        return arr;
    };

    useEffect(() => {
        setList(data?.length > 0 ? data?.map((item: any) => ({
            ...item,
            flag: false
        })) : [])
    }, [data])

    const printInvoice = async (id: any, invoiceNumber: any) => {
        try {
            setIsLoading(true)
            const res: any = await useAxios.post("invoices/generate-invoice-pdf/", { invoice: id });

            if (res && res.status === 200) {
                if (!!res?.data?.results?.url) {
                    const file = await getAWSFileUrl(`invoice/${invoiceNumber}.pdf`, user?.tenant_name)
                    const a = document.createElement('a');
                    a.href = file;
                    a.target = "_blank"
                    a.download = `${invoiceNumber}.pdf`;
                    a.click();
                    setIsLoading(false)
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error("error while fetching download invoice", error);
        }
    };

    return (
        <>
            {(loading || data === null) ? <div className="w-full min-h-[600px]"><SkeletonGridView /></div> :
                <>
                    {(!loading && data && data?.length > 0) &&
                        <div className="flex flex-col justify-between min-h-[600px] mt-[25px]">
                            <div className="overflow-x-scroll overflow-y-hidden">
                                <table className='table-auto w-full'>
                                    {!!!params?.id && <>
                                        <thead>
                                            <tr className="border border-[#DDD] border-b-0 !mt-[15px] bg-[#F4F8F9] h-[55px]">
                                                <th className="border-r border-[#DDD] px-[16px]">
                                                    <div className='flex items-center justify-center w-full' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                                        <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                    </div>
                                                </th>
                                                {getHeader()}
                                                <th className="border-r border-[#EBEAF2] px-[16px] h-full cursor-pointer">
                                                    <div className='flex items-center justify-center w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Actions</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                    </>}
                                    <tbody>
                                        {Array.isArray(list) && list.map((item: any, index: number) => (
                                            <tr className={`${(index + 1 === list?.length) ? "border" : "border border-b-0"} ${params?.id === item?.id ? "!border-b border-[#DFDAFF] !bg-[#FAFAFF]" : "border-[#DDD]"}   ${(!!!type) && "cursor-pointer hover:bg-[#FAFAFF] hover:!border hover:!border-[#DFDAFF] hover:!border-r"} ${(((index + 1) % 2) === 0) ? "bg-[#F9F9F9]" : "bg-white"}`} key={index}>
                                                {!!params?.id ?
                                                    <>
                                                        <td onClick={() => navigate(`/invoice/${url}/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")} className="px-[8px] 2xl:px-[16px] py-[12px] border-r border-[#DDD] h-full">
                                                            <div className="flex items-center justify-center">
                                                                {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                    <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                    :
                                                                    <>
                                                                        {!!item?.customer_details?.address?.org_name ?
                                                                            <div style={{ backgroundColor: `${randomColor[item?.customer_details?.address?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.customer_details?.address?.org_name?.charAt(0)}</div>
                                                                            : <div style={{ backgroundColor: `${randomColor[item?.customer_details?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.customer_details?.first_name?.charAt(0)}{item?.customer_details?.last_name?.charAt(0)}</div>}
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>

                                                        <td className="px-[8px] 2xl:px-[16px] py-[12px] h-full" onClick={() => navigate(`/invoice/${url}/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                                            <div className="flex items-center gap-[15px]">
                                                                <h3 className="text-lGray font-normal !text-sm text-left">{url === "proforma-invoice" ? item?.estimate_number : url === "tax-invoice" ? item?.invoice_number : item?.bill_supply_number}</h3>
                                                            </div>
                                                        </td>
                                                    </> :
                                                    <>
                                                        <td onClick={() => (!!!type) && navigate(`/invoice/${url}/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")} className="px-[8px] 2xl:px-[16px] py-[12px] border-r border-[#DDD] h-full">
                                                            <div className="flex items-center justify-center">
                                                                {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                    <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                    :
                                                                    <>
                                                                        {!!item?.customer_details?.address?.org_name ?
                                                                            <div style={{ backgroundColor: `${randomColor[item?.customer_details?.address?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.customer_details?.address?.org_name?.charAt(0)}</div>
                                                                            : <div style={{ backgroundColor: `${randomColor[item?.customer_details?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.customer_details?.first_name?.charAt(0)}{item?.customer_details?.last_name?.charAt(0)}</div>}
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>

                                                        {Array.isArray(defaultField) && defaultField.map((i: any, idx: number) => (
                                                            <React.Fragment key={idx}>
                                                                <td onClick={() => (!!!type) && navigate(`/invoice/${url}/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] py-[12px] border-r border-[#DDD] h-full">
                                                                    {i === "first_name" ?
                                                                        <div className="flex flex-col whitespace-nowrap">
                                                                            <h3 className="text-lGray font-normal !text-sm text-left">{item?.customer_details?.first_name} {" "} {item?.customer_details?.last_name}</h3>
                                                                            {!!item?.customer_details?.address?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#94A3B8" /> <span className="text-lGray font-normal !text-xs text-left">{item?.customer_details?.address?.org_name}</span></p>}
                                                                        </div>
                                                                        : fieldType[i] === "date" ?
                                                                            <h3 className="text-lGray font-normal !text-sm text-left whitespace-nowrap">{!!item[i] ? Moment(item[i]).format('DD MMM YYYY') : "-"}</h3>
                                                                            :
                                                                            (i === "status") ?
                                                                                <div className='flex items-center gap-[8px]'>
                                                                                    <div className={`${item?.status?.toLowerCase() === "draft" ? "bg-[#ECF6FF] text-[#327DC1]" : item?.status?.toLowerCase() === "approved" ? "bg-[#DFE] text-[#008E5A]" : "text-danger bg-[#FEECEC]"} rounded-[20px] h-[25px] flex items-center justify-center text-xs font-normal px-[13px] capitalize`}>{item?.status}</div>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {!!item[i] ?
                                                                                        <h3 className="text-lGray font-normal !text-sm text-left truncate">{item[i]}</h3>
                                                                                        :
                                                                                        <>
                                                                                            {item?.custom_fields_values?.length > 0 ? <>
                                                                                                {Array.isArray(item?.custom_fields_values) && item?.custom_fields_values.map((c: any, cidx: number) => (
                                                                                                    <React.Fragment key={cidx}>
                                                                                                        {c?.slug === i && <h3 key={cidx} className="text-lGray font-normal !text-sm text-left truncate">{!!c?.value ? c?.value : "-"}</h3>}
                                                                                                    </React.Fragment>
                                                                                                ))}
                                                                                            </> : <h3 className="text-lGray font-normal !text-sm text-left truncate">-</h3>}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                    }
                                                                </td>
                                                            </React.Fragment>
                                                        ))}

                                                        <td className="px-[8px] 2xl:px-[16px] py-[12px] h-full">
                                                            <div className="flex items-center justify-center w-full gap-[10px]">
                                                                {url === "proforma-invoice" &&
                                                                    <button className="bg-white rounded-[5px] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] min-w-[30px] min-h-[30px] flex items-center justify-center text-sm text-dGray font-normal disabled:opacity-[0.3] disabled:cursor-not-allowed cursor-pointer" onClick={() => navigate(`/invoice/convert-to-tax-invoice/${item?.id}`)}>
                                                                        <ProformaChange />
                                                                    </button>
                                                                }
                                                                {(option?.name === "UnPaidInvoice" || option?.name === "PartialPaidInvoice") && <button className="bg-white rounded-[5px] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] py-[5px] px-[11.11px] flex items-center justify-center text-sm text-dGray font-normal disabled:opacity-[0.3] disabled:cursor-not-allowed cursor-pointer" onClick={() => { dispatch(invoiceActions.setInvoiceDetail(item)); dispatch(invoiceActions.setRecordPayment(true)); }}>
                                                                    ₹
                                                                </button>}
                                                                {isLoading ?
                                                                    <Skeleton className="!w-[30px] !h-[30px]"></Skeleton>
                                                                    : <>
                                                                        {(url === "tax-invoice" && option?.name === "PaidInvoice") && <button className="bg-white rounded-[5px] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] min-w-[30px] min-h-[30px] flex items-center justify-center text-sm text-dGray font-normal disabled:opacity-[0.3] disabled:cursor-not-allowed cursor-pointer" onClick={() => printInvoice(item?.id, item?.invoice_number)}>
                                                                            <InvoicePrint className="fill-mGray" />
                                                                        </button>}
                                                                    </>}
                                                                {/* {option?.name === "PaidInvoice" && <div className="bg-white rounded-[5px] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] p-[7.5px] flex items-center justify-center text-sm text-dGray font-normal">
                                                                        <GroupIcon />
                                                                    </div>}
                                                                    <div className="cursor-pointer flex items-center justify-center pt-[4px]">
                                                                        <DataDotsIcon />
                                                                    </div> */}
                                                            </div>
                                                        </td>
                                                    </>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {
                                type === "group" ?
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={childFirst} rows={childSize} totalRecords={childTotal} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div> :
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div>
                            }
                        </div>
                    }
                </>
            }

            {
                (data?.length === 0 && !loading) && <div className="flex items-center justify-center min-h-[600px]">
                    <img src={require("../../../../assets/images/no-data-found.jpg")} alt="" className="w-[350px] h-[350px]" />
                </div>
            }
        </>
    );
};

export default TableView;