export const randomColor: any = {
    'a': '#E63632',
    'b': '#D91460',
    'c': '#8F1FAB',
    'd': '#5E32B2',
    'e': '#3647AC',
    'f': '#1889E6',
    'g': '#009CE6',
    'h': '#00ADC2',
    'i': '#008A7C',
    'j': '#41A145',
    'k': '#7DB440',
    'l': '#C1CB30',
    'm': '#FDD932',
    'n': '#FFB400',
    'o': '#FB8D00',
    'p': '#F45018',
    'q': '#6D4B3F',
    'r': '#3F6A6D',
    's': '#3F4F6D',
    't': '#4E37A6',
    'u': '#DDBC0B',
    'v': '#DD7C0B',
    'w': '#0B78DD',
    'x': '#DD0B70',
    'y': '#DDD50B',
    'z': '#0BDD20',
};