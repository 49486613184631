/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { invoiceActions } from '../../../../shared/store/invoice-Slice'
import { useDispatch } from 'react-redux'
import { ClosePopup, HeaderSearch, LoadingBtn, NameOrgIcon, SearchRightFilter } from '../../../svg/AllSvg';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import useAxios from '../../../../shared/hooks/use-axios';
import { Checkbox } from 'primereact/checkbox';
import Moment from 'moment';
import AdjustInvoiceList from '../../../../skeleton/components/invoice/invoice/AdjustInvoiceList';
import PrimaryBtn from '../../../form/PrimaryBtn';
import { useNavigate } from 'react-router-dom';

const AdjustPaymentInvoice = () => {
    const [submit, setSubmit] = useState<boolean>(false);
    const cust: any = useSelector((state: any) => state.invoice.cust);
    const adjustAmount: any = useSelector((state: any) => state.invoice.adjustAmount);
    const adjustPaymentInvoice: boolean = useSelector((state: any) => state.invoice.adjustPaymentInvoice);
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>("")
    const [list, setList] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedInvoice, setSelectedInvoice] = useState<any[]>([])
    let [tAmt, setTAmt] = useState<number>(0)
    const [invoices, setInvoices] = useState<any[]>([])
    const navigate = useNavigate();

    const getInvoice = async () => {
        try {
            setIsLoading(true)
            const res: any = await useAxios.get(`invoices/merge-invoice-list/?customer_id=${cust?.customer}`);
            if (res && res?.status === 200) {
                setList(res?.data?.results)
                setIsLoading(false)
            }
        } catch (error) {
            console.error("error while feching invoice", error);
        }
    }

    useEffect(() => {
        !!cust && getInvoice();
    }, [cust])

    const handleSelect = (i: any) => {
        if (selectedInvoice?.includes(i?.id)) {
            let arr: any[] = selectedInvoice?.filter((item: any) => i?.id !== item)
            let arr1: any[] = invoices?.filter((item: any) => i?.id !== item?.id)
            setSelectedInvoice(arr)
            setInvoices(arr1)
            tAmt = tAmt - +i?.amount_left
            setTAmt(tAmt)
        } else {
            if (selectedInvoice?.length !== 10 && adjustAmount > tAmt) {
                setSelectedInvoice([...selectedInvoice, i?.id])
                setInvoices([...invoices, { id: i?.id, type: i?.invoice_type }])
                tAmt = tAmt + +i?.amount_left
                setTAmt(tAmt)
            }
        }
    }

    const handleSubmit = async () => {
        setSubmit(true)
        try {
            let formdata = new FormData();
            formdata.append("invoices", JSON.stringify(invoices));
            formdata.append("amount", adjustAmount);
            formdata.append("customer", cust?.customer);

            const res: any = await useAxios.post("payment/record-multiple-invoice-payment/", formdata);

            if (res && res.status === 200) {
                dispatch(invoiceActions.setAdjustPaymentInvoice(false))
                dispatch(invoiceActions.setAdjustAmount(0))
                dispatch(invoiceActions.setPaymentSuccess(true))
                dispatch(invoiceActions.setTagApiCall(true))
                dispatch(invoiceActions.setApiCall("invoice"));
                setSubmit(false)
                navigate("/invoice/tax-invoice");
            }
        } catch (error: any) {
            setSubmit(false)
            console.error("error while submit record payment ", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(invoiceActions.setAdjustPaymentInvoice(false))
        }
    };


    return (
        <>
            {adjustPaymentInvoice && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[762px] xl:w-[1090px] z-[10] min-h-[600px]">
                        <div className="relative">
                            <div className="cursor-pointer absolute top-[29px] right-[19px] z-[10]" onClick={() => dispatch(invoiceActions.setAdjustPaymentInvoice(false))}>
                                <ClosePopup />
                            </div>
                        </div>

                        <div className="flex flex-col px-[28px] py-[23px] rounded-[20px] bg-white h-full">
                            <div className="flex items-center gap-[15px]">
                                <div className="flex flex-col items-start">
                                    <h3 className="text-primaryLight font-semibold text-lg">Select Invoice</h3>
                                    <p className="text-mGray font-normal text-sm">Adjust remaining ₹{adjustAmount} balance to invoice list.</p>
                                </div>
                            </div>

                            <div className='pt-[15px] flex items-center justify-between w-full gap-[10px]'>
                                <span className="p-input-icon-left w-full">
                                    <HeaderSearch className="fill-mGray" />
                                    <InputText className="!shadow-none placeholder:!text-lGray !w-full !py-[10px] !rounded-[10px] !border-[#CBD5E1] !text-xs placeholder:!text-sm focus:!shadow-none focus:!border-[#CBD5E1] !bg-[#F2F2F2] !shadow-[0px_1.74571px_4.36429px_0px_rgba(38,51,77,0.03)]" placeholder="Search" value={search} onChange={(e: any) => setSearch(e.target.value)} />
                                </span>

                                <button className="rounded-[10px] bg-white p-[6px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] cursor-pointer">
                                    <SearchRightFilter color="#6B7280" />
                                </button>
                            </div>

                            {(list?.length === 0 && isLoading) ? <AdjustInvoiceList /> :
                                <div className='h-[calc(100%-200px)] flex flex-col justify-between'>
                                    {list?.length > 0 && <table className="table-auto overflow-x-scroll w-full table">
                                        <thead className="bg-white !rounded-[10px]">
                                            <tr className="h-[50px]">
                                                <th className="px-[8px] 2xl:px-[16px] !rounded-l-[10px]"></th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Invoice ID</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Customer</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Invoice Date</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Amount</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Tax</p>
                                                    </div>
                                                </th>
                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Total Amt.</p>
                                                    </div>
                                                </th>

                                                <th className="px-[8px] 2xl:px-[16px]">
                                                    <div className='flex items-center justify-start w-full gap-[8px]'>
                                                        <p className='text-lGray font-medium text-sm !text-left'>Due Amt.</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(list) && list.map((item: any, index: number) => (
                                                <tr className={`!rounded-[10px] bg-white shadow-[0px_2px_10px_0px_rgba(229,229,229,0.40)] h-[50px] hover:!bg-[#FAFAFF] cursor-pointer ${item?.flag && "col-active bg-[#FAFAFF]"}`} key={index}>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <div className="flex items-center justify-center">
                                                            <Checkbox className="tests table-check" disabled={(selectedInvoice?.length !== 10 && adjustAmount > tAmt) ? false : (selectedInvoice?.includes(item?.id) ? false : true)} checked={selectedInvoice?.includes(item?.id) ? true : false} onClick={() => handleSelect(item)}></Checkbox>
                                                        </div>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <h3 className="text-dGray font-normal !text-sm text-left">{item?.invoice_type === "tax" ? item?.invoice_number : item?.bill_supply_number}</h3>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <div className="flex flex-col">
                                                            <h3 className="text-dGray font-medium !text-sm text-left">{item?.customer_details?.first_name + " " + item?.customer_details?.last_name}</h3>
                                                            {!!item?.customer_details?.address?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.customer_details?.address?.org_name}</span></p>}
                                                        </div>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <h3 className="text-dGray font-normal !text-sm text-left">{Moment(item?.created_at).format('DD MMM YYYY')}</h3>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <h3 className="text-dGray font-normal !text-sm text-left">₹ {!!item?.taxable_amount ? item?.taxable_amount : "0"}</h3>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        {item?.gst_type_name === "IGST" ? <div className="flex flex-col">
                                                            <h3 className="text-mGray font-normal !text-xs whitespace-nowrap">IGST &nbsp; ₹ {item?.dynamic_gst_amount?.IGST}</h3>
                                                        </div> :
                                                            <div className="flex flex-col">
                                                                <h3 className="text-mGray font-normal !text-xs whitespace-nowrap">CGST &nbsp; ₹ {item?.dynamic_gst_amount?.CGST}</h3>
                                                                <h3 className="text-mGray font-normal !text-xs whitespace-nowrap">SGST &nbsp; ₹ {item?.dynamic_gst_amount?.SGST}</h3>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <h3 className="text-dGray font-normal !text-sm text-left">₹ {item?.amount_due}</h3>
                                                    </td>
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <h3 className="text-danger font-normal !text-sm text-left">₹ {item?.amount_left}</h3>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>}
                                    <div className="w-full flex items-end justify-end mt-[26px]">
                                        <PrimaryBtn title={!submit ? "Adjust Amount" :
                                            <>
                                                <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                                Adjust Amount...
                                            </>} onClick={handleSubmit} disabled={(selectedInvoice?.length > 0 && !submit && tAmt > adjustAmount) ? false : true} />
                                    </div>
                                </div>
                            }

                            {(list?.length === 0 && !isLoading) && <div className="flex flex-col items-center justify-center min-h-[400px]">
                                <img src={require("../../../../assets/images/step2.png")} alt="" />
                                <p className="text-sm font-medium text-primaryLight text-center max-w-[232px]">No Invoice Found.</p>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default AdjustPaymentInvoice