import { Checkbox } from "primereact/checkbox";
import { OptionDot, PencilEdit, SortArrow, SortDownArrow, SortUpArrow } from "../../svg/AllSvg";
import React, { useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import { useNavigate, useParams } from "react-router-dom";
import SkeletonGridView from "../../../skeleton/components/laboratory/GridView";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { employeeActions } from "../../../shared/store/employee-Slice";
import EmployeeDetailPopup from "./EmployeeDetailPopup";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useTranslation } from "react-i18next";
import { tableActions } from "../../../shared/store/table-Slice";
import Moment from 'moment';

const GridView: React.FC<any> = ({ type }: any) => {
    const employeeDetailPopup: any = useSelector((state: any) => state.employee.employeeDetailPopup);
    const frontField: any = useSelector((state: any) => state.table.frontField);
    const data: any[] = useSelector((state: any) => state.table.data);
    const loading: boolean = useSelector((state: any) => state.table.loading);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const sortByList: any[] = useSelector((state: any) => state.table.sortByList);
    const defaultField: any = useSelector((state: any) => state.table.defaultField);
    const total: number = useSelector((state: any) => state.table.total);
    const size: number = useSelector((state: any) => state.table.size);
    const childTotal: number = useSelector((state: any) => state.table.childTotal);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const option: any = useSelector((state: any) => state.table.option);
    const childFirst: number = useSelector((state: any) => state.table.childFirst);
    const first: number = useSelector((state: any) => state.table.first);
    const [list, setList] = useState<any[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    useEffect(() => {
        if (data?.length > 0) {
            setList(data.map((item: any) => ({
                ...item,
                flag: false
            })));
        } else {
            setList([])
        }
    }, [data]);

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

   const getSortIcon = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                return <SortDownArrow />
            } else if (desc === sortBy) {
                return <SortUpArrow />
            } else {
                return <SortArrow />
            }
        }
    }

    const handleSort = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                dispatch(tableActions.setSortBy(desc))
            } else {
                dispatch(tableActions.setSortBy(asc))
            }
        }
    }

     const onPageChange = (event: any) => {
        if (type === "group") {
            dispatch(tableActions.setChildPage(event?.page + 1));
            dispatch(tableActions.setChildFirst(event.first));
            dispatch(tableActions.setChildSize(event.rows))
        } else {
            dispatch(tableActions.setPage(event?.page + 1));
            dispatch(tableActions.setFirst(event.first));
            dispatch(tableActions.setSize(event.rows))
        }
    };

    const getHeader = () => {
        let arr: any = [];
        defaultField.forEach((el: any) => {
            for (const key in frontField) {
                if (key === el) {
                    arr.push(<th key={key} className="px-[8px] 2xl:px-[16px] h-full cursor-pointer" onClick={() => handleSort(key, `-${key}`)}>
                        <div className='flex items-center justify-start w-full gap-[8px]'>
                            <p className='text-dGray font-medium text-sm !text-left'>{frontField[key]}</p>
                             <div className="w-[13px] h-[13px]">
        {getSortIcon(key, `-${key}`)}
</div>
                        </div>
                    </th>);
                }
            }
        });

        return arr;
    };

    return (
        <>
            {loading ? <SkeletonGridView /> :
                <>
                    {(!loading && list && list?.length > 0) &&
                        <div className="flex flex-col justify-between min-h-[700px]">
                            <table className="table-auto overflow-x-scroll w-full table">
                                {!!!params?.id && <>
                                    <thead>
                                        <tr className="h-[50px]">
                                            <th className="px-[8px] 2xl:px-[16px] h-full">
                                                <div className='flex items-center justify-center w-full'>
                                                    <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                </div>
                                            </th>
                                            {getHeader()}
                                            <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer"></th>
                                        </tr>
                                    </thead>
                                </>}
                                <tbody>
                                    {Array.isArray(list) && list.map((item: any, index: number) => (
                                        <tr className={`!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[55px] hover:!bg-[#FAFAFF] cursor-pointer ${!!params?.id && "first-tr"} ${params?.id === item?.id && "active bg-[#FAFAFF]"}`} key={index}>
                                            {!!!params?.id && <td className="px-[8px] 2xl:px-[16px] h-full !text-center">
                                                <div className='flex items-center justify-center w-full'>
                                                    <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                </div>
                                            </td>}
                                            {!!params?.id ?
                                                <>
                                                    <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] h-full relative" onMouseEnter={() => { dispatch(employeeActions.setEmployeeDetailPopup(item?.id)); dispatch(employeeActions.setEmployeeDetail(item)) }} onMouseLeave={() => dispatch(employeeActions.setEmployeeDetailPopup(0))}>
                                                        <div className="flex items-center gap-[15px] w-full">
                                                            <h3 className={`!text-sm text-left truncate ${params?.id === item?.id ? "text-primaryLight font-medium " : "text-dGray font-normal "}`}>{item?.manpower_detail?.first_name}</h3>
                                                        </div>

                                                        {(employeeDetailPopup === item?.id) && <EmployeeDetailPopup />}
                                                    </td>
                                                </>
                                                : <>
                                                    {Array.isArray(defaultField) && defaultField.map((i: any, idx: number) => (
                                                        <React.Fragment key={idx}>
                                                            <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] h-full relative" onMouseEnter={() => { i === "first_name" && dispatch(employeeActions.setEmployeeDetailPopup(item?.id)); i === "first_name" && dispatch(employeeActions.setEmployeeDetail(item)) }} onMouseLeave={() => dispatch(employeeActions.setEmployeeDetailPopup(0))}>
                                                                {
                                                                    i === "first_name" ?
                                                                        <h3 className="text-mGray font-normal !text-sm text-left truncate">{item?.manpower_detail?.first_name}</h3>
                                                                        :
                                                                        i === "last_name" ?
                                                                            <h3 className="text-mGray font-normal !text-sm text-left truncate">{item?.manpower_detail?.last_name}</h3>
                                                                            :
                                                                            i === "created_at" ?
                                                                            <h3 className="text-mGray font-normal !text-sm text-left">{!!item[i] ? Moment(item[i]).format('DD MMM YYYY') : "-"}</h3> :
                                                                            i === "email" ?
                                                                                <h3 className="text-mGray font-normal !text-sm text-left truncate">{item?.manpower_detail?.email}</h3>
                                                                                :
                                                                                <div>
                                                                                    {!!item[i] ?
                                                                                        <h3 className="text-mGray font-normal !text-sm text-left truncate">{item[i]}</h3>
                                                                                        :
                                                                                        <>
                                                                                            {item?.custom_fields_values?.length > 0 ? <>
                                                                                                {Array.isArray(item?.custom_fields_values) && item?.custom_fields_values.map((c: any, cidx: number) => (
                                                                                                    <React.Fragment key={cidx}>
                                                                                                        {c?.slug === i && <h3 key={cidx} className="text-mGray font-normal !text-sm text-left truncate">{!!c?.value ? c?.value : "-"}</h3>}
                                                                                                    </React.Fragment>
                                                                                                ))}
                                                                                            </> : <h3 className="text-mGray font-normal !text-sm text-left truncate">-</h3>}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                }

                                                                {(i === "first_name" && (employeeDetailPopup === item?.id)) && <EmployeeDetailPopup />}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}

                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <Menu align="end" menuButton={<MenuButton>
                                                            <div className="flex items-center justify-center cursor-pointer w-[25px] h-[25px]">
                                                                <OptionDot color="#6B7280" />
                                                            </div>
                                                        </MenuButton>}>
                                                            <MenuItem onClick={() => { dispatch(employeeActions.setIsEmployeeEdit(true)); dispatch(employeeActions.setSelectedId(item?.id)) }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><PencilEdit width="20" height="20" /><p className='text-sm'>{t("menu.edit")}</p></div></div></MenuItem>
                                                            {/* <MenuItem onClick={() => { dispatch(employeeActions.setModifyRole(true)); dispatch(employeeActions.setSelectedId(item?.id)) }}>
                                                                <div className="group w-full h-full px-[14px] py-[6px]">
                                                                    <div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><UserOption /><p className='text-sm'>{t("laboratory.menu.modify_role")}</p></div>
                                                                </div>
                                                            </MenuItem> */}
                                                            {/* <MenuItem><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium hover:stroke-primaryLight stroke-mGray'><SettingRemove width="18" height="18" /><p className='text-sm'>{t("menu.delete")}</p></div></div></MenuItem> */}
                                                        </Menu>
                                                    </td>
                                                </>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {
                                type === "group" ?
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={childFirst} rows={childSize} totalRecords={childTotal} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div> :
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div>
                            }
                        </div>
                    }
                </>
            }

            {(list?.length === 0 && !loading) && <div className="flex items-center justify-center">
                <img src={require("../../../assets/images/no-data-found.jpg")} alt="" className="w-[350px] h-[350px]" />
            </div>}
        </>
    );
};

export default GridView;