import { SettingRightArrow } from "../../svg/AllSvg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import useAxios from "../../../shared/hooks/use-axios";

const SubMenu = ({ departments, selectedDept, handleChange, handleSubmit }: any) => {
    const { t } = useTranslation('common');

    return (
        <div className='absolute top-0 left-[10.2rem] rounded-[10px] bg-white shadow-[0px_10px_32px_-4px_rgba(24,39,75,0.10),0px_6px_14px_-6px_rgba(24,39,75,0.12)] w-[140px] z-[32]'>
            <div className='flex flex-col gap-[8px] py-[8px]'>
                {Array.isArray(departments) && departments.map((item: any, index: number) => (
                    <div className='flex flex-col gap-[8px]' key={index}>
                        <div className={`${(selectedDept === item?.id) && "bg-[#F4F4F4]"} flex items-center justify-between cursor-pointer hover:bg-[#F4F4F4] pl-[15px] pr-[6px] py-[6px]`}>
                            <div className="flex items-center gap-[8px]">
                                <Checkbox className="tests table-check" name="checkAll" checked={selectedDept === item?.id} onChange={() => handleChange(item)}></Checkbox>
                                <p className={`text-xs font-medium ${selectedDept === item?.id ? "text-primaryLight" : "text-mGray "}`}>{item?.name}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='border-b border-[rgba(0,0,0,0.10]'></div>
            <div className="flex items-center justify-end w-full p-[8px] ">
                <button className="text-primaryLight text-xs h-[30px] font-medium bg-[rgba(183,194,255,0.10)] border border-[rgba(32,50,143,0.30)] rounded-[10px] px-[12px] flex items-center justify-center disabled:cursor-not-allowed disabled:opacity-[0.3]" disabled={!!selectedDept ? false : true} onClick={handleSubmit}>{t("common_btn.apply")}</button>
            </div>
        </div>
    )
}

const RolePopup: React.FC<any> = ({ roleOpen, sidebar, setRoleOpen }: any) => {
    const user: any = useSelector((state: any) => state.auth.user);
    const roles: any = useSelector((state: any) => state.auth.roles);
    const [selectedRole, setSelectedRole] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [selectedRoleOption, setSelectedRoleOption] = useState<string>("");
    const [selectedDeptOption, setSelectedDeptOption] = useState<string>("");
    const [selectedDept, setSelectedDept] = useState<string>("");
    const [roleList, setRoleList] = useState<any[]>([]);

    const getRole = async () => {

        for (let i = 0; i < roles?.length; i++) {
            const el = roles[i];

            if (el?.role === user?.role) {
                setSelectedRole(el?.id)
                setSelectedRoleOption(el?.id);

                for (let j = 0; j < el?.departments.length; j++) {
                    const e = el?.departments[j];
                    if (e?.name === user?.department?.toString()) {
                        setSelectedDeptOption(e?.id)
                        setSelectedDept(e?.id)
                    }
                }
            }
        }
    }

    useEffect(() => {
        setRoleList(roles)
        getRole();
        // eslint-disable-next-line
    }, [roles])

    useEffect(() => {
        if (selectedRole === selectedRoleOption) {
            setSelectedDept(selectedDeptOption)
        } else {
            setSelectedDept("");
        }
        // eslint-disable-next-line
    }, [selectedRole])

    useEffect(() => {
        !roleOpen && setSelectedOption("")
    }, [roleOpen])

    const handleChange = (item: any) => {
        setSelectedDept(item?.id)
    }

    const handleSubmit = async () => {
        try {
            let payload: any = {
                selected_id: selectedRole,
                selected_department_id: selectedDept
            }

            const res: any = await useAxios.post("labs/update-default-role-manpower/", payload);

            if (res && res?.status === 200) {
                let role: any = "";
                let dept: any = "";

                for (let i = 0; i < roleList.length; i++) {
                    const el = roleList[i];

                    if (el?.id === selectedRole) {
                        role = el?.role;
                        for (let j = 0; j < el?.departments.length; j++) {
                            const d = el?.departments[j];

                            if (selectedDept === d?.id) {
                                dept = d?.name
                            }
                        }
                    }
                }

                if (!!role && !!dept) {
                    setRoleOpen(false)
                    let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any)

                    let obj: any = { ...userDetail, role: role, active_department: [dept] }
                    localStorage.setItem('userdetail', JSON.stringify(obj));
                    window.location.href = "/";
                    localStorage.setItem("reload", true as any)
                }
            }
        } catch (error) {
            console.error("error while feching test request", error);
        }
    }

    const handleSave = async (role: any) => {
        if (!!role) {
            setRoleOpen(false)
            let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any)

            let obj: any = { ...userDetail, role: role }
            localStorage.setItem('userdetail', JSON.stringify(obj));
            window.location.href = "/";
            localStorage.setItem("reload", true as any)
        }
    }

    return (
        <>
            {roleList?.length > 0 && <div className={`${sidebar ? "top-[36px] left-[-5px]" : "top-[44px] right-0"} absolute rounded-[10px] bg-white shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)] w-[160px] py-[8px] z-[32]`}>
                <div className='flex flex-col gap-[8px]'>
                    {Array.isArray(roleList) && roleList.map((item: any, index: number) => (
                        <div className='flex flex-col gap-[8px]' key={index}>
                            <div className={`${(selectedRole === item?.id) && "bg-[#F4F4F4]"} flex items-center justify-between cursor-pointer hover:bg-[#F4F4F4] pl-[15px] pr-[6px] py-[8px] relative`} onClick={() => { setSelectedOption(item?.id); setSelectedRole(item?.id); (item?.departments?.length === 0 && user?.role !== item?.role) && handleSave(item?.role); (item?.departments?.length === 0 && user?.role === item?.role) && setRoleOpen(false) }}>
                                <p className={`${(selectedRole === item?.id) ? "text-primaryLight" : "text-mGray"} text-xs font-medium`}>{item?.role}</p>

                                {item?.departments?.length > 0 && <SettingRightArrow className={(selectedRole === item?.id) ? "stroke-primaryLight" : "stroke-mGray"} />}

                                {(selectedOption === item?.id && item?.departments?.length > 0) && <SubMenu departments={item?.departments} selectedDept={selectedDept} handleChange={handleChange} i={item} selectedRole={selectedRole} handleSubmit={handleSubmit} />}
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
        </>
    );
};

export default RolePopup;