/* eslint-disable react-hooks/exhaustive-deps */
import { ParameterBg, SampleTestClose, Selected } from "../../svg/AllSvg"
import { testActions } from "../../../shared/store/test-Slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useAxios from "../../../shared/hooks/use-axios";
import { useSelectPrimary}  from "../../../shared/hooks/use-selectPrimary";
import Select from "react-select";
import SkeletonLab from '../../../skeleton/components/customer/Lab';
import Parameter from "../../../skeleton/components/customer/Parameter";
import StandardDetail from "../../../skeleton/components/customer/StandardDetail";
import { useTranslation } from "react-i18next";

const StandardDetailPopup = () => {
    // eslint-disable-next-line
    const [list, setList] = useState<any[]>([])
    const [data, setData] = useState<any[]>([])
    const [labList, setlabList] = useState<any[]>([])
    const [standardData, setStandardData] = useState<any>(null)
    const standardDetail: boolean = useSelector((state: any) => state.test.standardDetail);
    const standard: any = useSelector((state: any) => state.test.standard);
    const [allBtnShow, setAllBtnShow] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLabLoading, setLabIsLoading] = useState<boolean>(true);
    const [selectLab, setSelectLab] = useState<string>("");
    const [certificateList, setCertificateList] = useState<any[]>([])
    const [selectCerti, setSelectCerti] = useState<any>({ label: "All Accreditation", value: "All Accreditation" });
    const testReqInfo: any = useSelector((state: any) => state.test.testReqInfo);
    const dispatch = useDispatch();
    let styles = useSelectPrimary();
    const { t } = useTranslation('common');

    const getTestDetail = async () => {
        try {
            const response: any = await useAxios.get(`/samples/standard-popup-data/?standard=${standard?.id}`);

            if (response && response?.status === 200) {
                setStandardData(response?.data?.standard_data[0])
                setlabList(response?.data?.lab_recommendation_data)
                setSelectLab(response?.data?.lab_recommendation_data[0]?.id)
                setLabIsLoading(false)
            }
        } catch (error) {
            console.error("error while feching tests", error);
        }
    }

    const getLabs = async () => {
        try {
            const res: any = await useAxios.get(`labs/api/testing-scope-facilities/?standard=${standard?.id}&lab=${selectLab}`);

            if (res && res?.status === 200) {
                setList(res?.data)
                setData(res?.data)

                let arr: any[] = [];
                for (let i = 0; i < res?.data?.length; i++) {
                    const el = res?.data[i];

                    for (let j = 0; j < el?.accreditation_list?.length; j++) {
                        const c = el?.accreditation_list[j];
                        let data: any = arr.filter((item: any) => item?.value === c?.id);

                        if (data?.length === 0) {
                            arr.push({ label: c?.name, value: c?.id })
                        }
                    }
                    let arr1: any = [{ label: "All Accreditation", value: "All Accreditation" }]
                    let arr2: any = arr1.concat(arr);
                    setCertificateList(arr2)
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.error("error while feching lab", error);
        }
    };


    useEffect(() => {
        !!selectLab && getLabs();
        // eslint-disable-next-line
    }, [selectLab])

    useEffect(() => {
        (!!standard?.id && !!testReqInfo?.id) && getTestDetail();
        // eslint-disable-next-line
    }, [standard, testReqInfo])

    useEffect(() => {
        if (!isLoading) {
            if (selectCerti?.value === "All Accreditation") {
                setList(data)
            } else {
                let arr: any[] = [...data];
                // eslint-disable-next-line array-callback-return
                arr = arr.filter((item: any) => {
                    if (item?.certificate?.includes(selectCerti?.value)) {
                        return true;
                    }
                });
                setList(arr)
            }
        }
    }, [selectCerti, isLoading])

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(testActions.setStandardDetail(false))
        }
    };

    return (
        <>
            {standardDetail && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] sm:w-[600px] md:w-[745px] lg:w-[930px] xl:w-[1230px] xxl:w-[1327px] min-h-[calc(100vh-48px)]">
                        <div className="w-full min-h-[calc(100vh-48px)] flex lg:grid lg:grid-cols-3 flex-col gap-[15px] lg:gap-0">
                            <div className="w-full lg:col-span-1 lg:border-r border-[#E8EBFF] p-[24px] h-full flex flex-col gap-[15px] mt-[48px] lg:mt-0">
                                {labList?.length === 0 ? <div className="ml:col-span-1 rounded-[10px] bg-white w-full h-full flex items-center justify-center flex-col">
                                    <img src={require("../../../assets/images/no-lab.png")} alt="" />
                                    <h3 className="text-lGray text-lg font-semibold pt-[15px]">{t("customer.book_test.no_lab.title")}</h3>
                                    <p className="text-lGray text-sm font-normal pt-[15px] max-w-[300px] text-center">{t("customer.book_test.no_lab.desc")}</p>
                                </div> :
                                    <>
                                        <div className="flex flex-col gap-[8px]">
                                            <h3 className="text-primaryLight font-semibold text-lg">{t("customer.book_test.standard_detail.lab")}</h3>
                                            <p className="text-sm text-primaryLight font-normal">{labList?.length} {t("customer.book_test.standard_detail.desc")}</p>
                                        </div>

                                        <div className="flex flex-col h-full justify-between">
                                            <div className='flex flex-col gap-[15px]'>
                                                {isLabLoading ? <SkeletonLab /> :
                                                    <>
                                                        {Array.isArray(labList) && labList.map((item: any, index: number) => (
                                                            <div className={`${item?.id === selectLab ? "bg-primaryLight" : "bg-[#FAFBFF]"} rounded-[10px] py-[14px] px-[20px] relative h-[197px] z-[1] cursor-pointer`} key={index} onClick={() => setSelectLab(item?.id)}>
                                                                {/* rounded-[10px] py-[14px] px-[20px] relative h-[197px] bg-[#FAFBFF] z-20 */}
                                                                <div className="flex items-start gap-[14px] pb-[12px]">
                                                                    {/* <img src={require("../../../assets/images/house.png")} alt="" /> */}
                                                                    <div className="rounded-[10px] bg-[#f4f4f4] w-[50px] h-[50px] text-primaryLight font-semibold text-lg flex items-center justify-center">{item?.lab_name?.charAt(0)}</div>

                                                                    <div className='flex flex-col gap-[5px]'>
                                                                        <h3 className={`${item?.id === selectLab ? "text-white" : "text-dGray"} text-base font-medium`}>{item?.lab_name}</h3>
                                                                        <p className={`${item?.id === selectLab ? "text-[#CBD5E1]" : "text-mGray"} text-xs font-normal`}>{item?.lab_code}</p>
                                                                        <p className={`${item?.id === selectLab ? "text-[#CBD5E1]" : "text-mGray"} text-xs font-normal`}>{item?.lab_address} {!!item?.lab_city && ', ' + item?.lab_city} {!!item?.state_name && ', ' + item?.state_name} {!!item?.country_name && ', ' + item?.country_name} {!!item?.lab_zipcode && '- ' + item?.lab_zipcode}</p>

                                                                        {/* <div className='bg-[#F6ECFF] rounded-[20px] py-[4px] px-[13px] text-[0.625rem] text-primaryLight font-medium whitespace-nowrap w-fit mb-[9px] z-20'>Partial Testing (18/22)</div> */}
                                                                    </div>
                                                                </div>

                                                                {item?.id === selectLab && <div className="absolute z-20 bottom-[14px] left-0 w-[103px] h-[30px] flex items-center justify-center gap-[5px] bg-[#F7F8FA] text-primaryLight font-medium text-sm rounded-r-[10px]">
                                                                    <Selected />
                                                                    {t("customer.book_test.no_lab.selected")}
                                                                </div>}

                                                                {/* <div className="flex items-center gap-[8px] absolute bottom-[14px] right-[20px] rounded-b-[10px] z-20">
                                                                    <div className='bg-white h-[30px] w-[30px] rounded-[6px] shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] text-primaryLight text-xs font-medium flex items-center justify-center gap-[8px]'>
                                                                        <LabLocate />
                                                                    </div>

                                                                    <div className='bg-white h-[30px] w-[30px] rounded-[6px] shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] text-primaryLight text-xs font-medium flex items-center justify-center gap-[8px]'>
                                                                        <LabMail />
                                                                    </div>

                                                                    <div className='bg-white h-[30px] w-[30px] rounded-[6px] shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] text-primaryLight text-xs font-medium flex items-center justify-center gap-[8px]'>
                                                                        <LabCall />
                                                                    </div>
                                                                </div> */}

                                                                {item?.id === selectLab ?
                                                                    <img src={require("../../../assets/images/select-lab.png")} alt="" className="absolute bottom-0 right-0 opacity-[0.2] z-10 rounded-r-[20px]" />
                                                                    :
                                                                    <img src={require("../../../assets/images/default-lab.png")} alt="" className="absolute bottom-0 right-0 opacity-[0.2] z-10 rounded-r-[20px]" />
                                                                }
                                                            </div>
                                                        ))}
                                                    </>}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="w-full mt-[25px] lg:col-span-2 p-[24px] h-full flex flex-col gap-[25px]">
                                {isLoading ? <StandardDetail /> : <div className="w-full p-[30px] flex items-center justify-center bg-white border border-[#EFEFEF] rounded-[10px] shadow-[0px_8px_24px_-4px_rgba(24,39,75,0.08),0px_6px_12px_-6px_rgba(24,39,75,0.12)]">
                                    <div className="w-full flex flex-col gap-[15px]">
                                        <div className="flex items-center flex-wrap gap-[25px] lm:gap-[50px]">
                                            <div className="flex flex-col gap-[4px]">
                                                <h3 className="text-mGray font-normal text-xs">{t("customer.book_test.standard_detail.standard.code")}</h3>
                                                <p className="text-dGray font-normal text-base">{standardData?.standard_code}</p>
                                            </div>

                                            <div className="flex flex-col gap-[4px]">
                                                <h3 className="text-mGray font-normal text-xs">{t("customer.book_test.standard_detail.standard.price")}</h3>
                                                <p className="text-dGray font-normal text-base">{list[0]?.price_methodology === "Parameter Wise" ? t("customer.book_test.standard_detail.standard.as_parameter_select") : `₹${Math.floor(standardData?.complete_test_charges)}`}</p>
                                            </div>

                                            <div className="flex flex-col gap-[4px]">
                                                <h3 className="text-mGray font-normal text-xs">{t("customer.book_test.standard_detail.standard.category")}</h3>
                                                {standardData?.std_category_list?.length > 0 ? <div className="flex items-center gap-[4px]">
                                                    {Array.isArray(standardData?.std_category_list) && standardData?.std_category_list.map((i: any, idx: number) => (
                                                        <div className={`${(((idx + 1) % 2) === 0) ? "text-[#DE9C00] bg-[#FCF6E7]" : "text-[#ED0062] bg-[#FDEAF0]"} rounded-[20px] py-[4px] px-[13px] text-xs font-normal whitespace-nowrap`} key={idx}>
                                                            {i?.name}
                                                        </div>
                                                    ))}
                                                </div> : "-"}
                                            </div>

                                            <div className="flex flex-col gap-[4px]">
                                                <h3 className="text-mGray font-normal text-xs">{t("customer.book_test.standard_detail.standard.dept")}</h3>
                                                {standardData?.std_departments_list?.length > 0 ? <div className="flex items-center gap-[4px]">
                                                    {Array.isArray(standardData?.std_departments_list) && standardData?.std_departments_list.map((i: any, idx: number) => (
                                                        <div className={`${(((idx + 1) % 2) === 0) ? "text-[#DE9C00] bg-[#FCF6E7]" : "text-[#ED0062] bg-[#FDEAF0]"} rounded-[20px] py-[4px] px-[13px] text-xs font-normal whitespace-nowrap`} key={idx}>
                                                            {i?.name}
                                                        </div>
                                                    ))}
                                                </div> : "-"}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full gap-[4px]">
                                            <h3 className="text-[#000001] font-normal text-xs">{t("customer.book_test.standard_detail.standard.title")}</h3>
                                            <p className="text-dGray font-normal text-sm">{standardData?.standard_name}</p>
                                        </div>
                                    </div>
                                </div>}

                                <div className="flex flex-col gap-[20px]">
                                    <div className="flex items-center justify-between">
                                        <h3 className="text-primaryLight text-lg font-semibold">{t("customer.book_test.standard_detail.parameter")}</h3>

                                        {(list?.length > 0 || selectCerti?.value !== "All Accreditation") && <div className="flex items-center gap-[15px]">
                                            <Select
                                                options={certificateList}
                                                name="certificate"
                                                styles={styles}
                                                value={selectCerti}
                                                onChange={(e: any) => setSelectCerti(e)}
                                                className="!w-[160px]"
                                            />
                                        </div>}

                                    </div>

                                    {(!!selectLab && list?.length > 0) ?
                                        <>
                                            {isLoading ? <Parameter /> :
                                                <>
                                                    {list?.length > 0 ? <div className="overflow-x-auto max-h-[350px] lg:max-h-max">
                                                        <div className="inline-block min-w-full">
                                                            <div className="overflow-hidden">
                                                                <table className="min-w-full">
                                                                    <thead className="bg-white !rounded-[10px]">
                                                                        <tr className="h-[50px] !rounded-[10px] border-b border-[#E8EBFF]">
                                                                            <th className="pl-[28px] pr-[8px] 2xl:pr-[16px] h-full cursor-pointer">
                                                                                <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                                    <p className='text-lGray font-normal text-xs !text-left'>Parameter Name</p>
                                                                                </div>
                                                                            </th>
                                                                            <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                                                <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                                    <p className='text-lGray font-normal text-xs !text-left'>Method of Test</p>
                                                                                </div>
                                                                            </th>
                                                                            <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                                                <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                                    <p className='text-lGray font-normal text-xs !text-left'>Accreditation</p>
                                                                                </div>
                                                                            </th>
                                                                            {list[0]?.price_methodology === "Parameter Wise" && <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                                                <div className='flex items-center justify-center w-full gap-[8px]'>
                                                                                    <p className='text-lGray font-normal text-xs !text-center'>Qty</p>
                                                                                </div>
                                                                            </th>}
                                                                            <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                                                <div className='flex items-center justify-center w-full gap-[8px]'>
                                                                                    <p className='text-lGray font-normal text-xs !text-center'>Department</p>
                                                                                </div>
                                                                            </th>
                                                                            {list[0]?.price_methodology === "Parameter Wise" && <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                                                <div className='flex items-center justify-center w-full gap-[8px]'>
                                                                                    <p className='text-lGray font-normal text-xs !text-center'>Price</p>
                                                                                </div>
                                                                            </th>}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {Array.isArray(list) && list.map((item: any, index: number) => (
                                                                            <tr className="border-b border-[#E8EBFF] !rounded-[10px] bg-white cursor-pointer" key={index}>
                                                                                <td className="pl-[28px] pr-[8px] 2xl:pr-[16px] py-[16px]">
                                                                                    <h3 className="text-[#767676] font-normal !text-sm text-left">{(!!item?.clause_no || !!item?.parameter_name) ? `${item?.parameter_name} (${item?.clause_no})` : "-"}</h3>
                                                                                </td>
                                                                                <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                                    {item?.method_test_list?.length > 0 ? <>
                                                                                        {(Array.isArray(item?.method_test_list) && item?.method_test_list.map((i: any, idx: number) =>
                                                                                            <h3 className="text-[#767676] font-normal !text-sm text-left" key={idx}>
                                                                                                {(i?.name) + `${item?.method_test_list?.length !== idx + 1 ? ', ' : ' '}`}
                                                                                            </h3>
                                                                                        ))}
                                                                                    </> : <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>}
                                                                                </td>

                                                                                <td className="px-[8px] 2xl:px-[16px] py-[16px]" onMouseEnter={() => setAllBtnShow(item?.id)} onMouseLeave={() => setAllBtnShow(0)}>
                                                                                    <div>
                                                                                        {item?.accreditation_list?.length > 0 ?
                                                                                            <>
                                                                                                {(allBtnShow !== item?.id || allBtnShow === 0) ? <div className="flex items-center gap-[8px]">
                                                                                                    <button type="button" className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{item?.accreditation_list[0]?.name}</button>
                                                                                                    {item?.accreditation_list?.length > 1 && <button type="button" className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{item?.accreditation_list?.length - 1}+</button>}
                                                                                                </div> :
                                                                                                    <div className="flex items-center gap-[8px]">
                                                                                                        {Array.isArray(item?.accreditation_list) && item?.accreditation_list.map((i: any, idx: number) => (
                                                                                                            <button type="button" key={idx} className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{i?.name}</button>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                {list[0]?.price_methodology === "Parameter Wise" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                                    <h3 className="text-[#767676] font-normal !text-sm text-center">{item?.quantity}</h3>
                                                                                </td>}
                                                                                <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                                    <div className="flex items-center justify-center">
                                                                                        {item?.test_type_name ? <div className="rounded-[20px] bg-[#F6ECFF] text-[#7840A7] font-normal text-xs px-[13px] h-[25px] flex items-center justify-center">{item?.test_type_name}</div> : <h3 className="text-[#767676] font-normal !text-sm text-center">-</h3>}
                                                                                    </div>
                                                                                </td>
                                                                                {list[0]?.price_methodology === "Parameter Wise" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                                    <h3 className="text-[#767676] font-normal !text-sm text-center">₹{item?.charges}</h3>
                                                                                </td>}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                        <div className="w-full h-full flex flex-col items-center justify-center">
                                                            <ParameterBg />
                                                            <h3 className="text-lGray text-base font-normal mt-[-2rem]">No Parameters Added</h3>
                                                        </div>}
                                                </>}
                                        </> :
                                        <div className="w-full h-full flex flex-col items-center justify-center">
                                            <ParameterBg />
                                            <h3 className="text-lGray text-base font-normal mt-[-2rem]">No Parameters Added</h3>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="absolute top-[13px] right-[24px] cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => dispatch(testActions.setStandardDetail(false))}>
                                <SampleTestClose />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            }
        </>
    );
};

export default StandardDetailPopup;