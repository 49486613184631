import { createSlice } from '@reduxjs/toolkit';

interface InventoryState {
    createIndent : boolean
}

const initialState: InventoryState = {
    createIndent: false
}

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setCreateIndent: (state, action) => {
            state.createIndent = action.payload;
        }
    }
});

export default inventorySlice;
export const inventoryActions = inventorySlice.actions;