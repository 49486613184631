import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from "../../translations/en/translation.json";
import common_es from "../../translations/es/translation.json";
import common_it from "../../translations/it/translation.json";
import common_hi from "../../translations/hi/translation.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: common_en
      },
      es: {
        common: common_es
      },
      it: {
        common: common_it
      },
      hi: {
        common: common_hi
      }
    }
  });


export default i18n;