import { Skeleton } from 'primereact/skeleton';
import { useParams } from 'react-router-dom';

const GridView: React.FC<any> = ({ group }: any) => {
    const params = useParams();

    const cols = [];
    for (let i = 0; i < (!!group && group ? 3 : 10); i++) {
        cols.push(
            <tr className="!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[50px]" key={i}>
                <td className="px-[12px] h-full !text-center">
                    <Skeleton className='!w-[29px] !h-[29px] !rounded-full'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                {!!!params?.id && <>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <div className='flex items-center gap-[8px]'>
                            <Skeleton width="118px" height="25px" borderRadius="5px"></Skeleton>
                            <Skeleton width="33px" height="25px" borderRadius="5px"></Skeleton>
                        </div>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='2rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[12px] h-full">
                        <div className='flex items-center gap-[12px]'>
                            <Skeleton size="1.4rem"></Skeleton>
                            <Skeleton size="1.4rem"></Skeleton>
                        </div>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full"><Skeleton size="1.4rem"></Skeleton></td>
                </>
                }
            </tr>
        )
    };

    return (
        <table className="table-auto overflow-x-scroll w-full skeleton-table">
            <tbody>
                {cols}
            </tbody>
        </table>
    );
};

export default GridView;