import { useEffect, useState } from 'react'
import SettingLayout from '../../../components/setting/SettingLayout';
import { TaskArrow } from '../../../components/svg/AllSvg';
import { settingActions } from '../../../shared/store/setting-Slice';
import { useDispatch } from 'react-redux';
import AddStorageLocation from '../../../components/setting/AddStorageLocation';
import EditStorageLocation from '../../../components/setting/EditStorageLocation';
import StorageLocationList from '../../../components/setting/StorageLocationList';
import useAxios from '../../../shared/hooks/use-axios';
import PrimaryDarkLightBtn from '../../../components/form/PrimaryDarkLightBtn';

const StorageLocation = () => {
    const [list, setList] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [apiCall, setApiCall] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>(null);
    const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
    const [prev, setPrev] = useState<boolean>(false);
    const [buildingList, setBuildingList] = useState<any[]>([])
    const [floorList, setFloorList] = useState<any[]>([])
    const [roomList, setRoomList] = useState<any[]>([])
    const [storageList, setStorageList] = useState<any[]>([])
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(settingActions.setTitle("Storage Settings"))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isOpen || isOpenEdit) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen, isOpenEdit]);

    const getBuilding = async () => {
        try {
            const res: any = await useAxios.get("inventory/api/buildings/");

            if (res && res?.status === 200) {
                setBuildingList(res?.data?.map((item: any) => ({ label: item?.name, value: item?.id })))
            }
        } catch (error) {
            console.error("error while feching get building", error);
        }
    }

    const getFloor = async () => {
        try {
            const res: any = await useAxios.get("inventory/api/floors/");

            if (res && res?.status === 200) {
                setFloorList(res?.data?.map((item: any) => ({ label: item?.floor_name, value: item?.id })))
            }
        } catch (error) {
            console.error("error while feching get building", error);
        }
    }

    const getRooms = async () => {
        try {
            const res: any = await useAxios.get("inventory/api/rooms/");

            if (res && res?.status === 200) {
                setRoomList(res?.data?.map((item: any) => ({ label: item?.room_name, value: item?.id })))
            }
        } catch (error) {
            console.error("error while feching get rooms", error);
        }
    }

    const getStorageBox = async () => {
        try {
            const res: any = await useAxios.get("inventory/api/storage-box-types/");

            if (res && res?.status === 200) {
                setStorageList(res?.data?.map((item: any) => ({ label: item?.storage_box_type, value: item?.id })))
            }
        } catch (error) {
            console.error("error while feching get rooms", error);
        }
    }

    useEffect(() => {
        getBuilding();
        getFloor();
        getRooms();
        getStorageBox();
    }, [])

    return (
        <>
            {isOpen && <AddStorageLocation isOpen={isOpen} setIsOpen={setIsOpen} setApiCall={setApiCall} buildingList={buildingList} floorList={floorList} roomList={roomList} storageList={storageList} />}
            {isOpenEdit && <EditStorageLocation isOpenEdit={isOpenEdit} setIsOpenEdit={setIsOpenEdit} setApiCall={setApiCall} detail={detail} buildingList={buildingList} floorList={floorList} roomList={roomList} storageList={storageList} />}
            <div className="w-full xxl:flex grid md:grid-cols-6 lg:grid-cols-8 p-[24px] gap-[16px] min-h-[calc(100vh-70px)]">
                <SettingLayout prev={prev} setPrev={setPrev} />

                {!prev && <div className="w-full md:col-span-4 lg:col-span-6 xxl:w-[calc(100%-306px)] border border-[#dee6e980] rounded-[10px] shadow-[0px_5px_15px_0px_#dfeaff66] flex flex-col overflow-x-hidden md:hidden bg-white">
                    <div className="border-b border-[#dee6e980] flex flex-col xs:flex-row items-start xs:items-center xs:justify-between gap-[8px] p-[10px] xs:px-[16px]">
                        <div className="flex items-center gap-[4px] xs:p-0">
                            <div className="cursor-pointer ml-[16px] p-[4px] hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => setPrev(true)}>
                                <TaskArrow />
                            </div>
                            <h3 className="text-primaryLight text-[1.063rem] xl:text-lg font-medium xs:py-[16px]">Storage Location</h3>
                        </div>
                        <div className="w-full xs:w-auto flex items-center justify-end">
                            <PrimaryDarkLightBtn title="Create New Storage Location" onClick={() => setIsOpen(true)} />
                        </div>
                    </div>
                    <div className="lg:grid lg:grid-cols-8 w-full h-[calc(100%-60px)] xxl:flex items-start">
                        <StorageLocationList apiCall={apiCall} setApiCall={setApiCall} detail={detail} setDetail={setDetail} setIsOpenEdit={setIsOpenEdit} list={list} setList={setList} />
                    </div>
                </div>}

                <div className="w-full md:col-span-4 lg:col-span-6 xxl:w-[calc(100%-306px)] border border-[#dee6e980] rounded-[10px] shadow-[0px_5px_15px_0px_#dfeaff66] hidden md:block bg-white">
                    <div className="border-b border-[#dee6e980] flex items-center justify-between px-[24px]">
                        <h3 className="text-primaryLight text-[1.063rem] xl:text-lg font-medium py-[16px]">Storage Location</h3>
                        <PrimaryDarkLightBtn title="Create New Storage Location" onClick={() => setIsOpen(true)} />
                    </div>
                    <div className="lg:grid lg:grid-cols-8 w-full h-[calc(100%-60px)] xxl:flex items-start">
                        <StorageLocationList apiCall={apiCall} setApiCall={setApiCall} detail={detail} setDetail={setDetail} setIsOpenEdit={setIsOpenEdit} list={list} setList={setList} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default StorageLocation;