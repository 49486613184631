import { useNavigate } from "react-router-dom";
import { ModuleSwitchSearch } from "../../svg/AllSvg";

let list: any[] = [
    { id: 1, name: "HRMS", color: "text-primaryOrange", border: "border-primaryOrange", path: "/laboratory/dashboard"},
    { id: 2, name: "CRM", color: "text-[#2E82F6]", border: "border-[#2E82F6]", path: "/crm/dashboard" },
    { id: 3, name: "LIMS", color: "text-danger", border: "border-danger", path: "/lims/samples/dashboard"  },
    { id: 4, name: "Invoice", color: "text-[#14B370]", border: "border-[#14B370]", path: "/invoice/dashboard"   },
    { id: 5, name: "Documents", color: "text-[#00B3E0]", border: "border-[#00B3E0]", path: "/documents"  },
    { id: 6, name: "Inventory", color: "text-[#91328C]", border: "border-[#91328C]", path: "/inventory"  },
    { id: 7, name: "Admin", color: "text-[#00A6B1]", border: "border-[#00A6B1]", path: "/admin/dashboard"  }
]

const ModuleSwitch = ({setSwitchModule}: any) => {
    const navigate = useNavigate();

    return (
        <div className="absolute top-[51px] left-0 z-[5] rounded-[10px] bg-[#FDFDFD] shadow-[0px_10px_32px_-4px_rgba(24,39,75,0.10),0px_6px_14px_-6px_rgba(24,39,75,0.12)] w-[388px] h-auto p-[14px]">
            <div className="w-full flex items-center justify-between pb-[19px]">
                <h1 className="text-dGray text-base font-normal">All Vista Apps</h1>
                <ModuleSwitchSearch />
            </div>

            <div className="flex flex-col gap-[10px]">
                {Array.isArray(list) && list.map((item: any, index: number) => (
                    <div key={index} className="h-[75px] w-full border border-[#F0F0F0] rounded-[9px] bg-[#F9F9FB] px-[20px] flex flex-col gap-[2px] items-start justify-center cursor-pointer hover:border hover:border-[#8D9FFF]" onClick={() => {navigate(item?.path); setSwitchModule(false);}}>
                        <div className="flex items-center gap-[7px]">
                            <div className='flex items-center tracking-wide gap-[1px]'>
                                <h3 className='text-primaryLight italic font-extrabold text-[19.508px]'>Vista</h3>
                                <span className={`${item?.color} font-extrabold text-[19.508px]`}>ERP</span>
                            </div>
                            <div className={`border-r ${item?.border} h-[15px] `}></div>
                            <div className={`${item?.color} text-[16.947px] font-normal leading-normal`}>{item?.name}</div>
                        </div>
                        <p className="text-lGray text-xs font-normal">The best {item?.name} integrated with Sales, Sign & Invoicing</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ModuleSwitch;
