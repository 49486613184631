/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { uiActions } from "../store/ui-Slice";

export const useModule = (name: any): any => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(uiActions.setModule(name))
    }, [window.location.pathname])
}