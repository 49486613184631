export const useSelectPrimary = (): any => {
  let styles: any = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: "20 !important"
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: "0.781rem",
      fontWeight: "400",
      color: state.isSelected ? "#20328F" : state.isFocused ? "#686868" : "#686868",
      backgroundColor: state.isSelected ? "#EDEDED" : state.isFocused ? "#EDEDED" : "#fff",
      border: "none",
      borderRadius: state.isFocused && "8px",
      "&:hover": {
        backgroundColor: state.isDisabled ? "normal" : "#EDEDED",
        border: "none",
        borderRadius: "8px",
        color: state.isSelected ? "#20328F" : state.isFocused ? "#686868" : state.isDisabled ? "#686868" : "#686868",
      },
      padding: "6px 12px",
      cursor: "pointer"
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: "#6B7280",
      fontSize: "0.781rem",
      fontWeight: "400",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? "#4B5563" : "#4B5563",
      fontSize: "0.781rem",
      fontWeight: "400",
      "&:hover": {
        color: state.isFocused ? "#4B5563" : "#4B5563",
      }
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: state.isFocused ? "1px solid #8D9FFF" : "1px solid  #CBCBCB",
      backgroundColor: state.isFocused ? "none" : "none",
      borderRadius: state.isFocused ? "6px" : "6px",
      boxShadow: state.isFocused && "0 0 0 0.2rem #EAF9FF",
      "&:hover": {
        border: state.isDisabled ? "0" : "1px solid #8D9FFF",
      },
      minHeight: '40px',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: "#6B7280",
      fontSize: "0.781rem",
      fontWeight: "400"
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: "#6B7280",
      fontSize: "0.781rem",
      fontWeight: "400",
    })
  };

  return styles;
};