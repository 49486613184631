import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Dashboard = React.lazy(() => import("../../pages/admin/dashboard/Dashboard"));
const BIReport = React.lazy(() => import("../../pages/admin/biReport/BIReport"));
const Premium = React.lazy(() => import("../../pages/premium/Premium"));

const AdminRoutes: React.FC<any> = ({ role }) => {
    let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any);

    return (
        <>
            {(userDetail?.role === role && userDetail?.role === "SuperAdmin") ?

                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/bi-report" element={<BIReport />} />
                    <Route path="*" element={<Navigate to="/admin/dashboard" />} />
                </Routes>
                :
                <Routes>
                    <Route path="/premium" element={<Premium />} />
                    <Route path="*" element={<Navigate to="/admin/premium" />} />
                </Routes>
            }
        </>
    );
};

export default AdminRoutes;