import { useSelector } from 'react-redux';
import { testActions } from '../../../shared/store/test-Slice';
import { CustomerClose } from '../../svg/AllSvg';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import { useSelectPrimary}  from "../../../shared/hooks/use-selectPrimary";
import Select from "react-select";
import PrimaryTextarea from '../../form/PrimaryTextarea';

const CreditLimitPopup = () => {
    const creditLimitOpen: boolean = useSelector((state: any) => state.test.creditLimitOpen);
    const dispatch = useDispatch();
    let styles = useSelectPrimary();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(testActions.setCreditLimitOpen(false))
        }
    };

    return (
        <>
            {creditLimitOpen && <div className="fixed inset-0 z-20 overflow-y-hidden top-0 ">
                <div className="fixed inset-0 z-[31] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[21px] flex flex-col w-[320px] sm:w-[508px] md:h-auto">
                            <div className="flex items-start justify-between pl-[24px] pr-[26px] pt-[21px] rounded-t-[21px] bg-white">
                                <div className='flex flex-col'>
                                    <h3 className="text-primaryLight font-semibold text-lg">Request Credit Limit</h3>
                                    <p className='text-mGray text-sm font-normal'>Admin will review and extend the credit expire date.</p>
                                </div>
                                <div className="cursor-pointer" onClick={() => dispatch(testActions.setCreditLimitOpen(false))}>
                                    <CustomerClose />
                                </div>
                            </div>


                            <div className="pl-[24px] pr-[26px] flex flex-col">
                                <div className='mt-[20px] w-full rounded-[10px] bg-[#F2F2F2] h-[36px] text-dGray font-medium text-sm px-[15px] flex items-center justify-start'>
                                    Previous Credit Limit Was 90 Days
                                </div>

                                <h4 className='text-sm font-medium text-primaryLight pt-[14px] pb-[10px]'>Select Credit Term Days</h4>
                                <Select
                                    options={[{ label: "90 Days", value: "90 Days" }]}
                                    name="size"
                                    styles={styles}
                                    menuPlacement="auto"
                                    minMenuHeight={300}
                                    value={{ label: "90 Days", value: "90 Days" }}
                                />

                                <div className='w-full pt-[24px]'>
                                    <PrimaryTextarea title="Note" name="note" />
                                </div>

                                <div className='w-full flex items-center gap-[8px] pt-[10px]'>
                                    <Checkbox checked className='tests table-check'></Checkbox>
                                    <p className='text-mGray text-xs font-normal'>I agree to the <span className='text-primaryLight font-normal text-xs'>terms and conditions</span> for credit limit extensions.</p>
                                </div>
                            </div>

                            <div className="w-full flex items-end justify-end pr-[22px] pt-[25px] pb-[21px] gap-[15px]">
                                <button className="rounded-[10px] shadow-[0px_-1px_1px_1px_rgba(67,94,171,0.25)_inset,0px_1px_1px_1px_rgba(255,255,255,0.25)_inset,0px_0px_0px_1px_#435EAB_inset,0px_1px_2px_0px_rgba(20,14,62,0.25)] bg-primaryLight text-white text-sm font-normal flex items-center justify-center px-[30px] h-[40px] disabled:opacity-[0.3] disabled:cursor-not-allowed">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default CreditLimitPopup
