/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ScreenRightArrow } from "../../components/svg/AllSvg";
import { useEffect, useState } from "react";
import Header from "../../components/layout/Common/Header";
import { labSampleActions } from "../../shared/store/labSample-Slice";
import Task from "../../components/layout/Common/Task";
import Note from "../../components/layout/Common/Note";
import Chat from "../../components/layout/Common/Chat";
import AllSamplePopup from "../../components/labSample/labSampleProfile/AllSamplePopup";
import { uiActions } from "../../shared/store/ui-Slice";
import DebitNoteAlert from "../../components/labSample/labSampleTask/DebitNoteAlert";
import AddAlertRemark from "../../components/labSample/labSampleTask/AddAlertRemark";
import AddTestPopup from "../../components/customer/bookTest/AddTestPopup";
// import { useSortcut } from "../../shared/hooks/use-sortcut";
import Search from "../../components/layout/Common/Search";
import ParameterPopup from "../../components/labSample/labSampleDetail/ParameterPopup";
import DepartmentPopup from "../../components/labSample/labSampleDetail/DepartmentPopup";
import ReportDetailPopup from "../../components/labSample/labSampleDetail/ReportDetailPopup";
import ReportPreview from "../../components/labSample/labSampleDetail/ReportPreview";
import ActivitySidebar from "../../components/layout/Common/ActivitySidebar";
import MiniSidebar from "../../components/layout/Common/MiniSidebar";

const LabSampleLayout: React.FC<any> = ({ role, path }) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);
    const sideBarOption: string = useSelector((state: any) => state.ui.sideBarOption);
    const chatUsersList: any[] = useSelector((state: any) => state.ui.chatUsersList);
    const allSampleOpen: boolean = useSelector((state: any) => state.labSample.allSampleOpen);
    const fullView: boolean = useSelector((state: any) => state.ui.fullView);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const debitNoteAlert: boolean = useSelector((state: any) => state.labSample.debitNoteAlert);
    const alertRemark = useSelector((state: any) => state.labSample.alertRemark);
    const mainAlert = useSelector((state: any) => state.labSample.mainAlert);
    const addTestOpen: boolean = useSelector((state: any) => state.test.addTestOpen);
    const standardPopup: boolean = useSelector((state: any) => state.test.standardPopup);
    const parameterPopup: any[] = useSelector((state: any) => state.labSample.parameterPopup);
    const deptPopup: boolean = useSelector((state: any) => state.labSample.deptPopup);
    const reportPopup: boolean = useSelector((state: any) => state.labSample.reportPopup);
    const reportPreview: boolean = useSelector((state: any) => state.labSample.reportPreview);
    const todoDetail: boolean = useSelector((state: any) => state.labSample.todoDetail);
    const labUserOpen: boolean = useSelector((state: any) => state.labSample.labUserOpen);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const [roleName, setRoleName] = useState<string>("");
    // const handleOpen = (): void => {
    //     setIsSearch(true);
    // };

    // useSortcut(["Control", "s"], handleOpen);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        }
    }, [isOpen, sideBarOption]);

    useEffect(() => {
        if (location.pathname !== "/lims/samples") {
            dispatch(labSampleActions.setChatUser(chatUsersList[0]));
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        dispatch(uiActions.setSettingPath(path))
        dispatch(uiActions.setModuleName("lims"))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (permissionRole.length > 0) {
            let getRole: any = role?.filter((item: any) => permissionRole?.toString()?.includes(item))
            setRoleName(getRole[0])
        }
    }, [permissionRole])

    useEffect(() => {
        if (allSampleOpen || debitNoteAlert || mainAlert || addTestOpen || standardPopup || isSearch || parameterPopup || deptPopup || reportPopup || reportPreview || labUserOpen || todoDetail) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [allSampleOpen, debitNoteAlert, mainAlert, addTestOpen, standardPopup, isSearch, parameterPopup, deptPopup, reportPopup, reportPreview, labUserOpen, todoDetail]);

    useEffect(() => {
        if (window.innerWidth <= 1200 && isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [window.innerWidth, isOpen])

    return (
        <>
            {allSampleOpen && <AllSamplePopup />}
            {debitNoteAlert && <DebitNoteAlert />}
            {(mainAlert) && <>
                {alertRemark && <AddAlertRemark />}
            </>}
            {addTestOpen && <AddTestPopup />}
            {parameterPopup && <ParameterPopup />}
            {deptPopup && <DepartmentPopup />}
            {reportPopup && <ReportDetailPopup />}
            {reportPreview && <ReportPreview />}

            <div className="w-full h-full relative">
                {/* Backdrop */}
                <div>
                    <span
                        id="menu-remove"
                        className={`fixed top-0 left-0 z-30 ${isOpen ? "block" : "hidden"
                            } h-screen w-full bg-[#00000080] xlx:hidden`}
                        onClick={() => dispatch(uiActions.Close())}
                    ></span>
                </div>

                {/* Close Section Icon */}
                {(permissionRole?.includes(roleName)) && <>
                    {!fullView && <div className={`bg-white w-[24px] h-[24px] shadow-md flex items-center justify-center rounded-full fixed cursor-pointer z-[5] right-[38px] ${scrollY ? "top-[101px]" : "top-[96px]"}`} onClick={() => { !isOpen && setIsLoading(true); dispatch(uiActions.toggle()); }}>
                        <ScreenRightArrow color="#20328F" />
                    </div>}
                </>}

                {/* Header */}
                {(!fullView && !scrollY) && <Header setIsSearch={setIsSearch} />}

                <div className={`${fullView ? "pt-0" : `${scrollY ? "pt-[75px]" : "pt-[70px]"}`} w-full min-h-screen bg-[#F8FAFB] flex`}>
                    {/* Sidebar */}
                    {(permissionRole?.includes(roleName)) && <>
                        {!fullView && <MiniSidebar />}
                    </>}

                    <div className={`${!fullView && "px-[50px]"} !h-full flex ${isOpen ? "w-full xlx:w-[calc(100%-300px)]" : "w-full"} transition-all`}>
                        <Outlet />

                        <Search isSearch={isSearch} setIsSearch={setIsSearch} />

                        {(!fullView) && <div className={`${isOpen ? "w-full sm:w-[300px] fixed right-0 bottom-0 z-30 xlx:z-[2] xlx:right-[50px] bg-white" : "translate-x-full w-0"} overflow-y-auto overflow-x-hidden transition duration-150 ${scrollY ? "top-0 xlx:top-[75px]" : "top-0 xlx:top-[70px]"}`}>
                            {/* Activity */}
                            {isLoading ? <div className="w-full h-full flex items-center justify-center">
                                <div className="circle circle-1"></div>
                                <div className="circle circle-2"></div>
                                <div className="circle circle-3"></div>
                                <div className="circle circle-4"></div>
                                <div className="circle circle-5"></div>
                            </div> :
                                <>
                                    {(!isLoading && sideBarOption === "Todo") && <Task />}
                                    {(!isLoading && sideBarOption === "Note") && <Note />}
                                    {(!isLoading && sideBarOption === "Chat") && <Chat />}
                                </>
                            }
                        </div>}
                    </div>

                    {/* Activity right Navbar */}
                    {(permissionRole?.includes(roleName)) && <>
                        {!fullView && <ActivitySidebar setIsLoading={setIsLoading} />}
                    </>}
                </div>
            </div>
        </>
    );
};

export default LabSampleLayout;