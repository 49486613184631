/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { labSampleActions } from "../../../shared/store/labSample-Slice";
import { LoadingBtn, SampleTestClose, TaskArrow } from "../../svg/AllSvg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useAxios from "../../../shared/hooks/use-axios";
import { success } from "../../../shared/hooks/use-toastify";
import PrimaryBtn from "../../form/PrimaryBtn";
import Parameter from "../../../skeleton/components/customer/Parameter";
import { useTranslation } from "react-i18next";

const ReportPreview = () => {
    const [list, setList] = useState<any>([])
    const [detail, setDetail] = useState<any>(null);
    const selectDept: any = useSelector((state: any) => state.labSample.selectDept);
    const reportPreview: boolean = useSelector((state: any) => state.labSample.reportPreview);
    const allParameter: any[] = useSelector((state: any) => state.labSample.allParameter);
    const [allBtnShow, setAllBtnShow] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [submit, setSubmit] = useState<boolean>(false)
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const getPreview = async () => {
        try {
            let formdata: any = new FormData();
            formdata.append("sample_part", selectDept?.sample_id);
            formdata.append("forwarded_sample", selectDept?.id);

            const res: any = await useAxios.post(`reports/preview-report`, formdata);

            if (res && res.status === 200) {
                setIsLoading(false)
                setDetail(res?.data)
                let arr: any[] = res?.data?.approved_parameter_result?.filter((item: any) => allParameter?.includes(item?.parameter))
                setList(arr)
            }
        } catch (error) {
            console.error("error while fetching department", error);
        }
    };

    useEffect(() => {
        getPreview();
    }, [])

    const handleSubmit = async () => {
        try {
            setSubmit(true)
            let payload: any = {
                parameters: allParameter,
                forwarded_sample: selectDept?.id
            }

            const res: any = await useAxios.post(`reports/generate-report/`, payload);

            if (res && res.status === 200) {
                setSubmit(false)
                success(t("lab_sample.success.generate_report"))
                dispatch(labSampleActions.setReviewApiCall(true));
                dispatch(labSampleActions.setReportPreview(false));
                dispatch(labSampleActions.setReportPopup(false));
            }
        } catch (error) {
            setSubmit(false)
            console.error("error while feching generate report", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(labSampleActions.setReportPreview(false));
            dispatch(labSampleActions.setReportPopup(true))
        }
    };

    return (
        <>
            {reportPreview && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] sm:w-[600px] md:w-[745px] lg:w-[930px] xl:w-[1230px] xxl:w-[1327px] min-h-[calc(100vh-48px)]">
                        <div className="py-[18px] px-[25px] flex flex-col justify-between min-h-[calc(100vh-50px)]">
                            <div className="w-full flex flex-col gap-[12px]">
                                <div className="flex items-center gap-[4px] cursor-pointer ml-[-5px]" onClick={() => { dispatch(labSampleActions.setReportPreview(false)); dispatch(labSampleActions.setReportPopup(true)) }}>
                                    <TaskArrow />
                                    <h3 className="text-primaryLight font-semibold text-base xl:text-lg">{t("lab_sample.report.report_preview")}</h3>
                                </div>

                                <div className="flex flex-col w-full bg-white shadow-[0px_8px_24px_-4px_rgba(24,39,75,0.08),0px_6px_12px_-6px_rgba(24,39,75,0.12)] rounded-[10px]">
                                    <div className="w-full border-b border-[#EEE]">
                                        <div className="py-[20px] px-[24px]">
                                            <div className="flex items-center justify-between w-full">
                                                <div className="flex flex-col">
                                                    <h3 className="text-primaryLight text-sm font-normal">
                                                        {t("customer.billing_info.test_req_detail.sample.sample_detail")}
                                                    </h3>
                                                    <p className="text-mGray text-xs font-normal">{t("customer.billing_info.test_req_detail.sample.sample_detail_desc")}</p>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-4 gap-[20px] pt-[20px]">
                                                <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.add_sample.sample.form.field.sample_code")}</h3>
                                                    <p className="text-dGray font-normal text-sm">{!!detail?.sample_detail?.sample_code ? detail?.sample_detail?.sample_code : "-"}</p>
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.add_sample.sample.form.field.name")}</h3>
                                                    <p className="text-dGray font-normal text-sm">{!!detail?.sample_detail?.test_name ? detail?.sample_detail?.test_name : "-"}</p>
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.add_sample.sample.form.field.qty")}</h3>
                                                    <p className="text-dGray font-normal text-sm">{!!detail?.sample_detail?.quantity ? detail?.sample_detail?.quantity : "-"}</p>
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.add_sample.sample.form.field.unit")}</h3>
                                                    <p className="text-dGray font-normal text-sm">{!!detail?.sample_detail?.sample_unit_name ? detail?.sample_detail?.sample_unit_name : "-"}</p>
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.add_sample.sample.form.field.status")}</h3>
                                                    <p className="text-dGray font-normal text-sm">{!!detail?.sample_detail?.sealed_status_name ? detail?.sample_detail?.sealed_status_name : "-"}</p>
                                                </div>

                                                {/* <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.billing_info.test_req_detail.sample.field.expire_date")}</h3>
                                                    <p className="text-dGray font-normal text-sm">-</p>
                                                </div> */}

                                                <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.add_sample.sample.form.field.type")}</h3>
                                                    <p className="text-danger font-normal text-sm">{!!detail?.sample_detail?.sample_type_name ? detail?.sample_detail?.sample_type_name : "-"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="py-[20px] px-[24px] w-full">
                                        <div className="flex flex-col gap-[14px]">
                                            <h3 className="text-primaryLight text-sm font-normal">
                                                {t("customer.add_sample.sample.add_sample_desc")}
                                            </h3>
                                            <div className="grid grid-cols-3 gap-[20px]">
                                                <div className="flex flex-col gap-[4px]">
                                                    <h3 className="text-lGray font-normal text-xs">{t("customer.add_sample.sample.form.field.desc")}</h3>
                                                    <p className="text-dGray font-normal text-sm">{!!detail?.sample_detail?.sample_description ? detail?.sample_detail?.sample_description : "-"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* {isLoading ? <SkeletonParameter /> : <div className='flex flex-col gap-[15px] w-full h-full'>
                                    <div className="flex flex-col justify-between h-full">
                                        <h3 className="text-primaryLight text-lg font-semibold py-[12px]">Parameters</h3>

                                        <div className="border-t border-[#F1F1F1] mb-[20px]">
                                            <div className="flex flex-col">
                                                <div className="overflow-x-auto">
                                                    <div className="inline-block min-w-full">
                                                        <div className="overflow-hidden">
                                                            <table className="min-w-full parameter-table">
                                                                <thead className="bg-white !rounded-[10px]">
                                                                    <tr className="h-[38px] !rounded-[10px]">
                                                                        <th className="px-[8px] 2xl:px-[16px] !pl-[24px] h-full">
                                                                            <div className='flex items-center justify-center w-full' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                                                                <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                                            </div>
                                                                        </th>
                                                                        <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                            <div className="flex items-center gap-[8px] justify-start">
                                                                                <h3 className="text-lGray text-sm font-normal text-left">Sample Code</h3>
                                                                                <SortDownArrow />
                                                                            </div>
                                                                        </th>
                                                                        <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                            <div className="flex items-center gap-[8px] justify-start">
                                                                                <h3 className="text-lGray text-sm font-normal text-left">Ref. Range</h3>
                                                                                <SortDownArrow />
                                                                            </div>
                                                                        </th>
                                                                        <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                            <div className="flex items-center gap-[8px] justify-start">
                                                                                <h3 className="text-lGray text-sm font-normal text-left">Result</h3>
                                                                                <SortDownArrow />
                                                                            </div>
                                                                        </th>
                                                                        <th className="px-[8px] 2xl:px-[16px] h-full !pr-[24px]"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Array.isArray(list) && list.map((item: any, index: number) => (
                                                                        <tr className={` !rounded-[10px] hover:bg-[#F7FAFF] cursor-pointer`} key={index}>
                                                                            <td className={`px-[8px] 2xl:px-[16px] py-[12px] !pl-[24px] relative`} onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")}>
                                                                                <div className="flex items-center justify-center">
                                                                                    {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                                        <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                                        : <div style={{ backgroundColor: `${randomColor[item?.assigned_by_detail?.first_name?.charAt(0)?.toLowerCase()]}` }} className="w-[27px] h-[27px] rounded-full flex items-center justify-center text-white text-xs font-normal uppercase">{item?.assigned_by_detail?.first_name?.charAt(0)}{item?.assigned_by_detail?.last_name?.charAt(0)}
                                                                                        </div>}
                                                                                </div>

                                                                                {item?.approval ?
                                                                                    <div className="absolute top-0 left-0 border-l-[4px] border-[#87AE77] h-full"></div>
                                                                                    :
                                                                                    <div className="absolute top-0 left-0 border-l-[4px] border-[#ff0000] h-full"></div>
                                                                                }

                                                                            </td>
                                                                            <td className="px-[8px] 2xl:px-[16px] py-[12px]">
                                                                                <div className="flex items-center justify-between">
                                                                                    <div className="flex flex-col gap-[8px] max-w-[344px]">
                                                                                        <h3 className="text-mGray font-normal !text-sm text-left">{item?.parameters_detail?.parameter}</h3>
                                                                                        <div className="flex items-center flex-col gap-[4px]">
                                                                                            {(item?.parameters_detail?.test_method?.length > 0 && !!item?.parameters_detail?.clause_no) &&
                                                                                                <>
                                                                                                    {Array.isArray(item?.parameters_detail?.test_method) && item?.parameters_detail?.test_method?.map((i: any, idx: number) => (
                                                                                                        <div key={idx} className="border border-[#ECECEC] bg-[#FCFCFC] rounded-[5px] h-[20px] flex items-center justify-center px-[8px] w-fit">
                                                                                                            <h3 className="text-mGray text-xs font-semibold line-clamp-1">Manual of Methods: {`${i?.name} (${item?.parameters_detail?.clause_no})`}</h3>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    {item?.confirm && < div className="w-[21px] h-[21px]"><StatusIcon /></div>}
                                                                                </div>
                                                                            </td>

                                                                            <td className={`px-[8px] 2xl:px-[16px] py-[12px] ${item?.approval && "bg-opa"}`}>
                                                                                {item?.range_limit_details?.length > 0 ? <div className="flex flex-col">
                                                                                    {Array.isArray(item?.range_limit_details) && item?.range_limit_details?.map((i: any, idx: number) => (
                                                                                        <h3 key={idx} className="text-mGray font-normal !text-sm text-left">{!!i?.range_val ? `${i?.range_val} ` : `${i?.range_min} - ${i?.range_max}`} &nbsp; {i?.value_type}</h3>
                                                                                    ))}
                                                                                </div> :
                                                                                    <h3 className="text-mGray font-normal !text-sm text-left whitespace-nowrap">-</h3>
                                                                                }
                                                                            </td>
                                                                            <td className="px-[8px] 2xl:px-[16px] py-[12px]">
                                                                                <div className="flex flex-col items-start gap-[4px]">
                                                                                    <h3 className="text-mGray font-normal !text-sm text-left whitespace-nowrap">{item?.result}</h3>
                                                                                    <div className="flex items-center gap-[4px]">
                                                                                        {!!item?.attachments_details && <div className="border border-[#ECECEC] bg-[#FCFCFC] rounded-[5px] px-[4px] h-[20px] flex items-center justify-center">
                                                                                            <FileCount color="#6B7280" />
                                                                                            <p className="text-mGray text-sm font-normal">{item?.attachments_details?.count}</p>
                                                                                        </div>}
                                                                                        {!!item?.remarks && <div className="border border-[#ECECEC] bg-[#FCFCFC] rounded-[5px] px-[4px] h-[20px] flex items-center gap-[4px] justify-center">
                                                                                            <FileIcon color="#6B7280" />
                                                                                        </div>}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className={`px-[8px] 2xl:px-[16px] py-[12px] !pr-[24px] ${item?.approval && "bg-opa"}`}>
                                                                                <div className="flex items-center justify-end w-full gap-[10px]">
                                                                                    {item?.actual_quantity > item?.quantity && <div className="pr-[13px] pt-[2px]">
                                                                                        <AlertNotiIcon className="w-[32px] h-[33px]" />
                                                                                    </div>}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                } */}
                                {isLoading ? <Parameter /> :
                                    <div className='flex flex-col gap-[15px] w-full h-full'>
                                        <div className="flex flex-col justify-between h-full">
                                            <h3 className="text-primaryLight text-lg font-semibold py-[12px]">{t("customer.book_test.standard_detail.parameter")}</h3>
                                            <div className="overflow-x-auto max-h-[350px] lg:max-h-max">
                                                <div className="inline-block min-w-full">
                                                    <div className="overflow-hidden">
                                                        <table className="min-w-full">
                                                            <thead className="bg-white !rounded-[10px]">
                                                                <tr className="h-[50px] !rounded-[10px] border-b border-[#E8EBFF]">
                                                                    <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                            <p className='text-lGray font-normal text-xs !text-left'>Parameter Name</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                            <p className='text-lGray font-normal text-xs !text-left'>Method of Test</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                            <p className='text-lGray font-normal text-xs !text-left'>Accreditation</p>
                                                                        </div>
                                                                    </th>
                                                                    {list[0]?.pricing_methodology === "parameter" && <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                        <div className='flex items-center justify-center w-full gap-[8px]'>
                                                                            <p className='text-lGray font-normal text-xs !text-center'>Qty</p>
                                                                        </div>
                                                                    </th>}
                                                                    <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                        <div className='flex items-center justify-center w-full gap-[8px]'>
                                                                            <p className='text-lGray font-normal text-xs !text-center'>Department</p>
                                                                        </div>
                                                                    </th>
                                                                    {list[0]?.pricing_methodology === "parameter" && <th className="px-[8px] 2xl:px-[16px] h-full">
                                                                        <div className='flex items-center justify-center w-full gap-[8px]'>
                                                                            <p className='text-lGray font-normal text-xs !text-center'>Price</p>
                                                                        </div>
                                                                    </th>}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.isArray(list) && list.map((item: any, index: number) => (
                                                                    <tr className="border-b border-[#E8EBFF] !rounded-[10px] bg-white" key={index}>
                                                                        <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                            <h3 className="text-[#767676] font-normal !text-sm text-left">{(!!item?.parameters_detail?.clause_no || !!item?.parameters_detail?.parameter) ? `${item?.parameters_detail?.parameter} (${item?.parameters_detail?.clause_no})` : "-"}</h3>
                                                                        </td>
                                                                        <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                            {item?.parameters_detail?.test_method?.length > 0 ? <>
                                                                                {(Array.isArray(item?.parameters_detail?.test_method) && item?.parameters_detail?.test_method.map((i: any, idx: number) =>
                                                                                    <h3 className="text-[#767676] font-normal !text-sm text-left" key={idx}>
                                                                                        {(i?.name) + `${item?.parameters_detail?.test_method?.length !== idx + 1 ? ', ' : ' '}`}
                                                                                    </h3>
                                                                                ))}
                                                                            </> : <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>}
                                                                        </td>

                                                                        <td className="px-[8px] 2xl:px-[16px] py-[16px]" onMouseEnter={() => setAllBtnShow(item?.id)} onMouseLeave={() => setAllBtnShow(0)}>
                                                                            <div>
                                                                                {item?.certificate_detail?.length > 0 ?
                                                                                    <>
                                                                                        {(allBtnShow !== item?.id || allBtnShow === 0) ? <div className="flex items-center gap-[8px]">
                                                                                            <button className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{item?.certificate_detail[0]?.name}</button>
                                                                                            {item?.certificate_detail?.length > 1 && <button className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{item?.certificate_detail?.length - 1}+</button>}
                                                                                        </div> :
                                                                                            <div className="flex items-center gap-[8px]">
                                                                                                {Array.isArray(item?.certificate_detail) && item?.certificate_detail.map((i: any, idx: number) => (
                                                                                                    <button key={idx} className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{i?.name}</button>
                                                                                                ))}
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        {list[0]?.pricing_methodology === "parameter" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                            <h3 className="text-[#767676] font-normal !text-sm text-center">{item?.quantity}</h3>
                                                                        </td>}
                                                                        <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                            <div className="flex items-center justify-center">
                                                                                {item?.parameters_detail?.test_type_details[0] ? <div className="rounded-[20px] bg-[#F6ECFF] text-[#7840A7] font-normal text-xs px-[13px] h-[25px] flex items-center justify-center">{item?.parameters_detail?.test_type_details[0]?.name}</div> : <h3 className="text-[#767676] font-normal !text-sm text-center">-</h3>}
                                                                            </div>
                                                                        </td>
                                                                        {list[0]?.pricing_methodology === "parameter" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                                            <h3 className="text-[#767676] font-normal !text-sm text-center">₹{item?.parameters_detail?.charges}</h3>
                                                                        </td>}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="w-full flex items-center justify-end">
                                <PrimaryBtn disabled={!submit ? false : true} onClick={handleSubmit} title={!submit ? t("lab_sample.add_result.generate_report") :
                                    <>
                                        <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                        {`${t("lab_sample.add_result.generate_report")}...`}
                                    </>} />
                            </div>
                        </div>
                        <div className="absolute top-[13px] right-[24px] cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => dispatch(labSampleActions.setReportPreview(false))}>
                            <SampleTestClose />
                        </div>
                    </div>
                </div>
            </div>}
        </ >
    );
};

export default ReportPreview;
