/* eslint-disable no-unreachable */
import { Dropdown } from "primereact/dropdown";
import { languages } from "../../../shared/utils/languages";
import { DangerClose, DropDownArrow, HeaderLanguageIcon, Logout, RoleChangeIcon } from "../../svg/AllSvg";
import { useDispatch } from "react-redux";
import { authActions } from "../../../shared/store/auth-Slice";
import { useEffect, useRef, useState } from "react";
import RolePopup from "../Common/RolePopup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { randomColor } from "../../../shared/utils/randomColor";
import { theme } from "../../../shared/utils/theme";
import useAxios from "../../../shared/hooks/use-axios";
import { tableActions } from "../../../shared/store/table-Slice";
import { uiActions } from "../../../shared/store/ui-Slice";

const Sidebar: React.FC<any> = ({ sidebarOpen, setSidebarOpen, language, setLanguage, handleClick }: any) => {
    const dispatch = useDispatch();
    const [roleOpen, setRoleOpen] = useState<any>(false);
    const user: any = useSelector((state: any) => state.auth.user);
    const roles: any = useSelector((state: any) => state.auth.roles);
    const moduleName: string = useSelector((state: any) => state.ui.moduleName);
    const ref1: any = useRef();
    const { t } = useTranslation('common');

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                setRoleOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    const handleLogoutApi = async () => {
        try {
            const res: any = await useAxios.post("accounts/logout/");
            if (res && res?.status === 200) {
                return true;
            }
        } catch (error) {
            return false;
            console.error("error while admin logout", error);
        }
    }

    const handleLogout = async (item: any) => {
        const success: any = await handleLogoutApi();
        if (success) {
            dispatch(tableActions.setClear());
            dispatch(uiActions.setModule(""));
            dispatch(uiActions.setModuleName(""))
            dispatch(uiActions.setSubModule(""));
            dispatch(authActions.logout());
        }
    }


    return (
        <div className={`fixed top-0 right-0 left-0 z-50  ${!sidebarOpen ? "-translate-x-full" : ""} !h-screen w-[300px] bg-white shadow-lg transition duration-150 lg:hidden`} id="menu-items">
            {/* User detail */}
            <div className='p-[16px] w-full flex flex-col gap-[15px]'>
                <div className='flex items-start justify-between'>
                    <div className='flex items-center justify-center gap-[10px]'>
                        <div style={{ background: `${randomColor[user?.username?.charAt(0)?.toLowerCase()]}` }} className='w-[40px] h-[40px] rounded-full uppercase text-white font-bold text-lg flex items-center justify-center'>{user?.username?.charAt(0)}{user?.lastName?.charAt(0)}</div>
                        <div className="flex flex-col gap-[8px]">
                            <div className='flex flex-col items-start'>
                                <h3 className='text-primaryLight text-base font-medium flex items-center'>{user?.username} &nbsp; {user?.lastName}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full !flex !items-center bg-[#f4f8ff] !rounded-full !justify-center !w-6 !h-6" onClick={() => setSidebarOpen(false)}>
                        <DangerClose color='#133F97' />
                    </div>
                </div>
            </div>

            {/* Menu */}
            <div className='w-full flex flex-col items-start px-[3px]' >
                {moduleName !== "customer" && <>
                    <div className='w-full border border-[#000000] opacity-[0.05]'></div>
                    <div className='relative cursor-pointer w-full' ref={ref1}>
                        <div className="relative flex items-center gap-[20px] py-[11.5px] !w-full cursor-pointer px-[16px]" onClick={() => setRoleOpen(!roleOpen)}>
                            <RoleChangeIcon className={`${theme[moduleName]?.fill}`} />
                            <div className="relative flex items-center gap-[20px]" >
                                <p className='text-[#7C7C7C] font-medium text-sm'>{user?.role}</p>
                                {(roleOpen && roles?.length > 0) && <DropDownArrow />}
                            </div>
                        </div>
                        {(roleOpen && roles?.length > 0) && <RolePopup roleOpen={roleOpen} setRoleOpen={setRoleOpen} sidebar={true} />}
                    </div>
                </>}
                <div className='w-full border border-[#000000] opacity-[0.05]'></div>
                <div className="flex items-center gap-[20px] py-[11.5px] !w-full cursor-pointer px-[16px]">
                    <HeaderLanguageIcon className={`${theme[moduleName]?.fill}`} />
                    <Dropdown className='!border-none emp-language' value={language} onChange={(e: any) => { setLanguage(e?.value); handleClick(e?.value?.code); }} options={languages} optionLabel="label" />
                </div>
                <div className='w-full border border-[#000000] opacity-[0.05]'></div>
                <div className="flex items-center gap-[28px] py-[15px] !w-full cursor-pointer px-[16px]" onClick={handleLogout}>
                    <Logout className={`${theme[moduleName]?.stroke}`} />
                    <h3 className='text-[#7C7C7C] text-sm font-normal'>{t("header.profile.log_out")}</h3>
                </div>
                <div className='w-full border border-[#000000] opacity-[0.05]'></div>
            </div>
        </div>
    );
};

export default Sidebar;