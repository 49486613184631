import { useSelector } from "react-redux";
import { SampleDetailSug } from "../../svg/AllSvg";
import { randomColor } from "../../../shared/utils/randomColor";
import SeconadaryInput from "../../form/SeconadaryInput";
import SeconadaryTextarea from "../../form/SeconadaryTextarea";

const EmployeeDetailPopup = () => {
    const employeeDetail: any = useSelector((state: any) => state.employee.employeeDetail);

    return (
        <div className="bg-white rounded-[10px] w-[255px] h-auto absolute top-[64px] left-0 z-[1] shadow-md p-[26px] flex flex-col">
            <div className="absolute top-[-7.5px] left-[32px]"><SampleDetailSug /></div>

            <div className="flex items-center gap-[8px]">
                {!!employeeDetail?.image ?
                    <img src={employeeDetail?.image} alt="" className="w-[52px] h-[52px]" />
                    :
                    <div style={{backgroundColor: `${randomColor[employeeDetail?.manpower_detail?.first_name?.charAt(0)?.toLowerCase()]}` }} className='w-[52px] h-[52px] rounded-full uppercase text-white font-bold text-base flex items-center justify-center'>{employeeDetail?.manpower_detail?.first_name?.charAt(0)}{employeeDetail?.manpower_detail?.last_name?.charAt(0)}</div>
                }

                <div className="flex flex-col">
                    <h3 className="text-primaryLight font-normal text-[0.938rem] truncate">{employeeDetail?.manpower_detail?.first_name + " " +employeeDetail?.manpower_detail?.last_name}</h3>
                    <p className="text-mGray text-xs font-normal">{employeeDetail?.designation}</p>
                </div>
            </div>

            {/* <div className="flex items-center gap-[8px] pt-[16px]">
                <button className={`text-[#008E5A] bg-[#DFE] flex items-center justify-center text-xs font-normal rounded-[20px] py-[6px] px-[15px] whitespace-nowrap`}>Top Performer</button>
                <button className={`text-[#008E5A] bg-[#DFE] flex items-center justify-center text-xs font-normal rounded-[20px] py-[6px] px-[15px] whitespace-nowrap`}>Other Tag</button>
            </div> */}

            <div className="flex flex-col gap-[23px] pt-[26px]">
                {!!employeeDetail?.designation && employeeDetail?.designation !== "null" && <SeconadaryInput type="text" title="Designation" name="designation" defaultValue={employeeDetail?.designation} />}
                {
                    !!employeeDetail?.address && employeeDetail?.address !== "null" &&
                    <SeconadaryTextarea title="Address" name="address" defaultValue={employeeDetail?.address} rows={2} />
                }
            </div>
        </div>
    );
};

export default EmployeeDetailPopup;