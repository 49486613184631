import axios from "axios";
import { warning } from "./use-toastify";

const useAxios = axios.create({
    // baseURL: `https://${window.location.host.toString().split(".")[0]}.api.vistaerp.com/`
    // http://fddi.vista-admin.stagemyapp.com/
    // https://enviro.api.vistaerp.com/
    baseURL: "https://fddi.api.vistaerp.com/"
});

const requestHandler = (req: any): any => {
    if (localStorage.getItem('token')) {
        // eslint-disable-next-line
        req.headers['Authorization'] = 'Token' + ' ' + localStorage.getItem('token');
    }
    return req;
};

const responseHandler = (res: any): any => {
    return res;
};

const errorHandler = (error: any): any => {
    if (error && error.response.status === 400) {
        if (Array.isArray(error.response?.data) && error.response?.data?.length > 0) {
            warning(error.response?.data?.[0])
        } else {
            if (Object.keys(error.response?.data)?.length > 0) {
                if (Array.isArray(error.response?.data?.[Object.keys(error.response?.data)[0]]) && error.response?.data?.[Object.keys(error.response?.data)[0]]?.length > 0) {
                    warning(error.response?.data?.[Object.keys(error.response?.data)[0]]?.toString())
                } else {
                    if (Object.keys(error.response?.data)[0] === "success") {
                        if (Object.keys(error.response?.data)?.length > 1) {
                            if (Array.isArray(error.response?.data?.[Object.keys(error.response?.data)[1]]) && error.response?.data?.[Object.keys(error.response?.data)[1]]?.length > 0) {
                                warning(error.response?.data?.[Object.keys(error.response?.data)[1]]?.toString())
                            } else {
                                warning(error.response?.data?.[Object.keys(error.response?.data)[1]])
                            }
                        } else {
                            if (typeof (error.response?.data?.[Object.keys(error.response?.data)[1]]) === "string") {
                                warning(error.response?.data)
                            }
                        }
                    } else {
                        warning(error.response?.data?.[Object.keys(error.response?.data)[0]])
                    }
                }
            } else {
                if (typeof (error.response?.data) === "string") {
                    warning(error.response?.data)
                }
            }
        }
    }
    if (error && error?.response?.status === 401) {
        localStorage.clear();
        window.location.href = '/auth/login';
    }
    if (error && error?.response?.status === 500) {
        warning("Something went wrong! Please try again.");
    }
    return Promise.reject(error);
};

useAxios.interceptors.request.use(
    (req: any) => requestHandler(req),
    (error: any) => errorHandler(error)
);

useAxios.interceptors.response.use(
    (res: any) => responseHandler(res),
    (error: any) => errorHandler(error)
);

export default useAxios;