export const useSelect = (focus: any, arrow: any, height: any, cursor: any, color: any): any => {
  let styles: any = {
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: "0.813rem",
      fontWeight: "400",
      color: state.isSelected ? "#20328F" : state.isFocused ? "#686868" : "#686868",
      backgroundColor: state.isDisabled ? "#fff" : state.isSelected ? "#EDEDED" : state.isFocused ? "#EDEDED" : "#fff",
      border: "none",
      borderRadius: state.isFocused && "8px",
      opacity: state.isDisabled && 0.3,
      "&:hover": {
        backgroundColor: state.isDisabled ? "#fff" : "#EDEDED",
        border: "none",
        borderRadius: "8px",
        color: state.isSelected ? "#20328F" : state.isFocused ? "#686868" : state.isDisabled ? "#686868" : "#686868",
      },
      padding: "6px 12px",
      cursor: "pointer"
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: "20"
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: color,
      fontSize: "0.813rem",
      fontWeight: "400",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? color : color,
      fontSize: "0.813rem",
      fontWeight: "400",
      "&:hover": {
        color: state.isFocused ? color : color,
      },
      display: arrow ? "block" : "none",
      padding: "0",
      paddingRight: "6px",
      cursor: "pointer"
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: state.isFocused ? "0" : "0",
      backgroundColor: state.isFocused ? "none" : "none",
      borderRadius: state.isFocused ? "0" : "0",
      boxShadow: state.isFocused ? "none" : "none",
      minHeight: height
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: color,
      fontSize: "0.813rem",
      fontWeight: "400",
      cursor: cursor
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: color,
      fontSize: "0.813rem",
      fontWeight: "400",
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      boxShadow: focus ? (state.isFocused && "0 0 0 0.2rem #EAF9FF") : "none",
      border: focus ? (state.isFocused && "1px solid #8D9FFF") : "0",
      "&:hover": {
        border: focus ? (state.isDisabled ? "0" : "1px solid #8D9FFF") : "0",
      },
    }),
  };

  return styles;
};