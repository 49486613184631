/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from "react";
import Loader from "./components/layout/Common/Loader";
import CacheBuster from 'react-cache-buster';
import app from '../package.json';
// import { getApp } from "./shared/utils/helpers";
import NoInternet from "./components/noInternet/NoInternet";
import Main from "./routes/main/Main";
// import useAxios from "./shared/hooks/use-axios";
import { useDispatch } from "react-redux";
import { authActions } from "./shared/store/auth-Slice";
import { useSelector } from "react-redux";
import useAxios from "./shared/hooks/use-axios";
import { settingMenu } from "./shared/utils/settingMenu";
import { settingActions } from "./shared/store/setting-Slice";
import { uiActions } from "./shared/store/ui-Slice";
import { useLocation } from "react-router-dom";

const Router: React.FC<any> = () => {
  const theme: any = useSelector((state: any) => state.ui.theme);
  const screen: any = useSelector((state: any) => state.ui.screen);
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
  let [loader, setLoader] = useState<any>(true);
  // const [loader, setLoader] = useState<boolean>(true);
  // const [tenantData, setTanantData] = useState<any[]>([]);
  // const Components: any = !loader && getApp(tenantData);
  const dispatch = useDispatch();
  const location = useLocation();

  const getRole = async () => {
    try {
      loader = true;
      setLoader(loader)
      const res: any = await useAxios.get("labs/manpower-role-dept-list/");

      if (res && res?.status === 200) {
        dispatch(authActions.setRoles(res?.data?.results))
        if (res?.data?.results?.length > 0) {
          dispatch(authActions.setPermissionRoles(res?.data?.results?.map((item: any) => item?.role)))
        } else {
          dispatch(authActions.setPermissionRoles([]))
        }
        loader = false;
        setLoader(loader)
      }
    } catch (error) {
      console.error("error while getting role", error);
    }
  }

  const getSettingPermission = async () => {
    try {
      loader = true;
      setLoader(loader)
      const res: any = await useAxios.get("samples/api/component-permission-sample-detail/?book_type=settings");

      if (res && res?.status === 200) {
        let obj: any = {};
        if (res?.data?.length > 0) {
          for (let i = 0; i < res?.data?.length; i++) {
            const el = res?.data[i];
            let arr: any[] = [];

            for (let j = 0; j < settingMenu.length; j++) {
              const s = settingMenu[j];
              if (s?.name === el?.name) {
                if (el?.component_display_name?.length > 0) {
                  for (let k = 0; k < el?.component_display_name?.length; k++) {
                    const c = el?.component_display_name[k];

                    arr.push(c?.name)
                  }
                }

                obj[s?.name] = arr
              }
            }
          }
          dispatch(settingActions.setSettingPermission(obj))
        }
        loader = false;
        setLoader(loader)
      }
    } catch (error) {
      console.error("error while getting setting permission", error);
    }
  }

  // const handleDataTransfer = () => {
  //   const iframe: any = document.getElementById('myIframe');
  //   const localStorageData = JSON.stringify(Object.entries(localStorage));
  //   iframe.contentWindow.postMessage(localStorageData, 'http://localhost:3000');
  // };

  // useEffect(() => {
  //   window.addEventListener('message', (event) => {
  //     if (event.origin === 'http://localhost:3007') {
  //       try {
  //         if (typeof (event.data) === "string") {
  //           const receivedData = JSON.parse(event.data);
  //           Object.entries(receivedData).forEach(([key, value]: any) => {
  //             localStorage.setItem(key, value);
  //           });
  //         }
  //       } catch (error) {
  //         console.error('Error storing data:', error);
  //       }
  //     }
  //   });

  //   handleDataTransfer();
  // }, []);

  useEffect(() => {
    isLogin && getRole();
    isLogin && getSettingPermission();
  }, [isLogin])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     let session: any = JSON.parse(localStorage.getItem("sessionTimeout") as any);
  //     if (!!session && session > new Date().getTime()) {
  //       dispatch(authActions.logout());
  //     }
  //   }, 3600000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    !!theme && localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    //tenantList();
    const handleOnline = () => {
      setIsOnline(true);
      setIsOpen(false);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setIsOpen(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [])

  const onStorageUpdate = (e: any) => {
    if (!!JSON.parse(localStorage.getItem("reload") as any)) {
      window.location.href = "/"
      localStorage.setItem("reload", false as any)
    }
  };

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  useEffect(() => {
    dispatch(uiActions.setScrollY(false))
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  useEffect(() => {
    if (screen === "doc") {
      const handleScroll = () => {
        if (document.body.offsetHeight > 1150) {
          window.scrollY > 70 ? dispatch(uiActions.setScrollY(true)) : dispatch(uiActions.setScrollY(false))
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      dispatch(uiActions.setScrollY(false))
    }
  }, [screen, window.scrollY])

  // const tenantList = async () => {
  //   try {
  //     const res: any = await useAxios.get('tenant/tenant-list/');

  //     if (res && res?.status === 200) {
  //       setTanantData([
  //         {
  //           subdomain: "vista.localhost",
  //           app: Main,
  //           main: false
  //         }
  //       ]);
  //       setLoader(false);
  //     }
  //   } catch (error: any) {
  //     console.error("error while feching domain list", error);
  //   }
  // };

  return (
    <CacheBuster
      currentVersion={app.version}
      isEnabled={true}
      reloadOnDowngrade={true}
      isVerboseMode={true}
      loadingComponent={<Loader />}
      metaFileDirectory={'.'}
      onCacheClear={(refreshCacheAndReload: () => Promise<void>) => { }}
    >
      <Suspense fallback={<Loader />}>
        {(!isOnline && isOpen) && <NoInternet setIsOpen={setIsOpen} isOpen={isOpen} />}
        {/* {!loader && <Components />} */}
        <Main loader={loader} />
      </Suspense>
    </CacheBuster>
  );
};

export default Router;