import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { testActions } from "../../../shared/store/test-Slice";
import { Calibration, CalibrationInOnsite, LoadingBtn, NameOrgIcon, RegisterArrow, SampleTestClose, SearchCustomerArrow, SearchCustomerIcon, TestLab, TestOnsite } from "../../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import Service from "../../../skeleton/components/customer/Service";
import { randomColor } from "../../../shared/utils/randomColor";
import { useSelect } from "../../../shared/hooks/use-select";

const AddTestPopup = () => {
    const [submit, setSubmit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const addTestOpen: boolean = useSelector((state: any) => state.test.addTestOpen);
    const service: any = useSelector((state: any) => state.test.service);
    const serviceList: any[] = useSelector((state: any) => state.test.serviceList);
    const [customers, setCustomers] = useState<any[]>([]);
    const [selectCustomer, setSelectCustomer] = useState<any>(null);
    const [search, setSearch] = useState<string>("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let styles = useSelect(false, false, "39px", "text", "#6B7280");
    const { t } = useTranslation('common');

    const Option: any = (props: any) => {
        return (
            <components.Option {...props}>{props.data.option}</components.Option>
        );
    };

    useEffect(() => {
        getServices();
        dispatch(testActions.setService({}));
        // eslint-disable-next-line
    }, [])

    const getCustomer = async () => {
        try {
            const res: any = await useAxios.get("customers/api/customers-list/");

            if (res && res?.status === 200) {
                setCustomers(res?.data?.results?.map((item: any) => ({
                    label: item?.first_name + " " + item?.last_name,
                    value: item?.id,
                    option:
                        <div className="rounded-[10px] py-[12px] flex flex-col gap-[10px]">
                            <div className="flex items-center justify-between">
                                <div className='flex items-center gap-[12px]'>
                                    {!!item?.org_name ? <div style={{ backgroundColor: `${randomColor[(item?.org_name)?.charAt(0)?.toLowerCase()]}` }} className='w-[27px] h-[27px] rounded-full uppercase text-white font-semibold text-xs flex items-center justify-center'>{item?.org_name?.charAt(0)}</div> :
                                        <div style={{ backgroundColor: `${randomColor[(item?.first_name)?.charAt(0)?.toLowerCase()]}` }} className='w-[27px] h-[27px] rounded-full uppercase text-white font-semibold text-xs flex items-center justify-center'>{item?.first_name?.charAt(0)}{item?.last_name?.charAt(0)}</div>
                                    }
                                    <div className='flex flex-col'>
                                        <h1 className="text-mGray text-sm font-normal">{item?.first_name + " " + item?.last_name}</h1>
                                        {!!item?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                    </div>
                                </div>
                                <p className="text-dGray text-sm font-normal">{item?.gst}</p>
                            </div>
                            {(!!item?.address || !!item?.city || !!item?.state || !!item?.country || !!item?.zipcode) && <p className="text-mGray text-sm font-normal">{!!item?.address && item?.address + `${!!item?.city ? ', ' : ""}`} {!!item?.city && item?.city + `${!!item?.state ? ', ' : ""}`} {!!item?.state && item?.state + `${!!item?.country ? ', ' : ""}`} {!!item?.country && item?.country + `${!!item?.zipcode ? ', ' : ""}`} {!!item?.zipcode && '- ' + item?.zipcode}</p>}
                        </div>
                })))
            }
        } catch (error) {
            console.error("error while fetch customers", error);
        }
    }

    const getServices = async () => {
        try {
            const res: any = await useAxios.get("sample-configurations/service-categories/");

            if (res && res?.status === 200) {
                dispatch(testActions.setServiceList(res?.data?.results));
                setIsLoading(false)
            }
        } catch (error) {
            console.error("error while feching services", error);
        }
    };

    useEffect(() => {
        if (window.location.pathname !== "/customer/service") {
            getCustomer();
        }
        // eslint-disable-next-line 
    }, [window.location.pathname])

    const saveChange = async () => {
        setSubmit(true)
        try {
            let obj: any = {};
            if (window.location.pathname !== "/customer/service") {
                obj = {
                    i_agreed: false,
                    service_category: service?.id,
                    service_category_name: service?.text,
                    customer: selectCustomer?.value
                }
            } else {
                obj = {
                    i_agreed: false,
                    service_category: service?.id,
                    service_category_name: service?.text
                }
            }

            const res: any = await useAxios.post("samples/api/test-requests/", obj);

            if (res && res?.status === 201) {
                setSubmit(false)
                dispatch(testActions.setService({}));
                dispatch(testActions.setSelectStandard([]));
                dispatch(testActions.setAddTestOpen(false));
                if (window.location.pathname === "/lims/test/service") {
                    navigate(`/lims/test/book-test/${res?.data?.id}`);
                } else if (window.location.pathname === "/crm/test/service") {
                    navigate(`/crm/test/book-test/${res?.data?.id}`);
                } else {
                    navigate(`/customer/book-test/${res?.data?.id}`);
                }
            }
        } catch (error) {
            setSubmit(false)
            console.error("error while adding save changes", error);
        }
    };

    const handleClose = () => {
        if (window.location.pathname === "/lims/test/service") {
            navigate(`/lims`);
        } else if (window.location.pathname === "/crm/test/service") {
            navigate(`/crm`);
        } else {
            navigate(`/customer/dashboard`);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            if (window.location.pathname === "/lims/test/service") {
                navigate(`/lims`);
            } else if (window.location.pathname === "/crm/test/service") {
                navigate(`/crm`);
            } else {
                navigate(`/customer/dashboard`);
            }
            dispatch(testActions.setAddTestOpen(false));
        }
    };

    return (
        <>
            {addTestOpen &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-[382px] sm:w-[600px] sm:h-auto md:w-auto overflow-y-scroll p-[25px] md:p-[40px]">
                            <h3 className="text-primaryLight font-semibold text-base xl:text-lg">{t("customer.service.title") as any}</h3>
                            <p className="text-mGray text-sm font-normal">{t("customer.service.desc") as any}</p>

                            {(window.location.pathname === "/lims/test/service" || window.location.pathname === "/crm/test/service") && <div className="flex flex-col pt-[30px]">
                                <h3 className="text-dGray text-xs font-normal pb-[4px]">{t("customer.service.cust") as any}*</h3>
                                <div className='border border-[#CBCBCB] bg-white h-[40px] rounded-[6px] flex items-center justify-between'>
                                    <Select
                                        options={customers}
                                        onChange={(e: any) => setSelectCustomer(e)}
                                        value={selectCustomer}
                                        name="test_type"
                                        styles={styles}
                                        placeholder={t("customer.service.cust_placeholder") as any}
                                         minMenuHeight={300} menuIsOpen={!!search && true} onMenuClose={() => !!!search && false} onMenuOpen={() => !!search && true} onInputChange={(e: any) => setSearch(e)} className='!w-full md:!min-w-[350px]'
                                        components={{ Option }}
                                    />
                                    <div className="flex items-center gap-[4px]">
                                        <SearchCustomerArrow />
                                        <div className="mt-[9px] mr-[-2px]">
                                            <SearchCustomerIcon className="fill-primaryLight" />
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <div className="flex items-center justify-center flex-col sm:flex-row pt-[30px] gap-[16px] sm:gap-[12px]">
                                {isLoading ?
                                    <Service /> :
                                    <>
                                        {Array.isArray(serviceList) && serviceList.map((item: any, index: number) => (
                                            <div key={index} className={`${item === service ? "border border-primary shadow-[0px_18px_88px_-4px_rgba(24,39,75,0.14),0px_8px_28px_-6px_rgba(24,39,75,0.12)]" : "border border-[#EFEFEF] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)]"} w-[180px] h-[160px] bg-white rounded-[10px] flex flex-col items-center justify-center gap-[4px] hover:bg-[#edfaff] cursor-pointer`} onClick={() => dispatch(testActions.setService(item))}>
                                                {item?.text === "Testing in Lab" ? <TestLab className={`${service === item ? "fill-primaryLight" : "fill-[#CBD5E1]"}`} /> : item?.text === "Testing Onsite" ? <TestOnsite className={`${service === item ? "fill-primaryLight" : "fill-[#CBD5E1]"}`} /> : item?.text === "Calibration in lab" ? <Calibration className={`${service === item ? "fill-primaryLight" : "fill-[#CBD5E1]"}`} /> : <CalibrationInOnsite className={`${service === item ? "fill-primaryLight stroke-primaryLight" : "fill-[#CBD5E1] stroke-[#CBD5E1]"}`} />}
                                                <h3 className={`${item === service ? "text-primaryLight" : "text-mGray"} font-normal text-sm`}>{item?.text}</h3>
                                            </div>
                                        ))}
                                    </>}
                            </div>

                            <div className="w-full pt-[40px] flex items-end justify-end">
                                <button onClick={saveChange} className="bg-primaryLight px-[20px] py-[8px] ss:px-[40px] gap-[8px] ss:py-[10px] shadow-[0px_-1px_1px_1px_rgba(67,94,171,0.25)_inset,0px_1px_1px_1px_rgba(255,255,255,0.25)_inset,0px_0px_0px_1px_#435EAB_inset,0px_1px_2px_0px_rgba(20,14,62,0.25)] rounded-[10px] focus:!shadow-[0_0_0_0.20rem_#b9ccf7] hover:!shadow-[0_0_0_0.20rem_#b9ccf7] text-white text-sm font-medium whitespace-nowrap flex items-center disabled:opacity-[0.3] disabled:cursor-not-allowed" disabled={window.location.pathname !== "/customer/service" ? ((!!selectCustomer && Object.keys(service)?.length > 0 && !submit) ? false : true) : ((Object.keys(service)?.length > 0 && !submit) ? false : true)}>{submit ? <> <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                    {`${t("customer.service.btn")}...`}
                                </> : t("customer.service.btn") as any} <RegisterArrow className="fill-white" /></button>
                            </div>

                            <div className="absolute top-[15px] right-[15px] cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => { dispatch(testActions.setAddTestOpen(false)); handleClose(); }}>
                                <SampleTestClose />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AddTestPopup;