import { useDispatch } from "react-redux";
import { BackArrow, DangerClose } from "../../svg/AllSvg";
import { useEffect } from "react";
import { Widget, addResponseMessage, toggleWidget } from 'react-chat-widget';
import { useSelector } from "react-redux";
import { uiActions } from "../../../shared/store/ui-Slice";

const Chat: React.FC<any> = () => {
    const dispatch = useDispatch();
    const chatUser: any = useSelector((state: any) => state.ui.chatUser);
    const chatUsersList: any[] = useSelector((state: any) => state.ui.chatUsersList);

    useEffect(() => {
        if (Object.keys(chatUser).length > 0) {
            document.body.style.overflow = "auto";
        }
    }, [chatUser])

    useEffect(() => {
        toggleWidget();
        addResponseMessage('Welcome to this awesome chat!');
    }, [])

    const handleNewUserMessage = (newMessage: any) => {
        console.log(`New message incoming! ${newMessage}`);
    };

    const handleQuickButtonClicked = (data: any) => {
        console.log(data);
    };

    return (
        <div className="flex flex-col py-[24px] w-full">
            <div className="flex items-center justify-between border-b border-[#E7F0FF] px-[20px] pb-[20px]">
                {Object.keys(chatUser).length === 0 && <h3 className="text-lg font-normal text-lGray pt-[6px]">CHATS</h3>}
                {Object.keys(chatUser).length > 0 &&
                    <div className="flex items-center gap-[8px] cursor-pointer" onClick={() => dispatch(uiActions.setChatUser({}))}>
                        <div className="!w-[2rem] !h-[2rem] flex items-center justify-center rounded-full hover:bg-[#F4F4F4]">
                            <BackArrow width="23" height="25" />
                        </div>
                        <h3 className="text-base font-normal text-lGray">Back to Groups</h3>
                    </div>
                }

                <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full !flex !items-center bg-[#f4f8ff] !rounded-full !justify-center !w-6 !h-6 sm:!hidden" onClick={() => dispatch(uiActions.Close())}>
                    <DangerClose color='#133F97' />
                </div>
            </div>

            {Object.keys(chatUser).length === 0 && <div className="flex flex-col ">
                {Array.isArray(chatUsersList) && chatUsersList.map((item: any, index: number) => (
                    <div className="flex items-center gap-[12px] py-[10px] px-[20px] hover:bg-[#F4F4F4] cursor-pointer" key={index} onClick={() => dispatch(uiActions.setChatUser(item))}>
                        <div className={`${item?.bg} text-white text-lg font-medium !w-[2.25rem] !h-[2.25rem] flex items-center justify-center rounded-full`}>{item?.logo}</div>
                        <div className="flex flex-col">
                            <h3 className="text-sm text-[#202124] font-normal">{item?.name}</h3>
                        </div>
                    </div>
                ))}
            </div>}

            {Object.keys(chatUser).length > 0 && <Widget
                handleNewUserMessage={handleNewUserMessage}
                handleQuickButtonClicked={handleQuickButtonClicked}
                title={chatUser?.name}
                subtitle={chatUser?.email}
                emojis={false}
                profileAvatar="https://cdn-icons-png.flaticon.com/128/149/149071.png"
                fullScreenMode={true}
                showCloseButton={false}
            />}
        </div>
    );
};

export default Chat;