/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { groupByApi, listApi, tabApi } from "../../../shared/utils/apis";
import { useModule } from "../../../shared/hooks/use-module";
import { useNavigate, useParams } from "react-router-dom";
import { useScreen } from "../../../shared/hooks/use-Screen";
import ViewAll from "../../../components/form/ViewAll";
import ViewLayout from "../../../components/form/ViewLayout";
import Tags from "../../../components/form/Tags";
import GroupView from "../../../components/form/GroupView";
import { CopyLink } from "../../../components/svg/AllSvg";
import { useTranslation } from "react-i18next";

const TestRequest: React.FC<any> = () => {
    const user: any = useSelector((state: any) => state.auth.user);
    const [view, setView] = useState<string>("list");
    const [option, setOption] = useState<string>("");
    const [groupByOption, setGroupByOption] = useState<string>("");
    const [groupBy, setGroupBy] = useState<boolean>(false);
    const [listLoading, setListLoading] = useState<boolean>(true);
    const [groupLoading, setGroupLoading] = useState<boolean>(true);
    const [tagList, setTagList] = useState<any[]>([]);
    const [sortByList, setSortByList] = useState<any[]>([]);
    const [groupByList, setGroupByList] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);
    let [filterObj, setFilterObj] = useState<any>({});
    const [fieldType, setFieldType] = useState<any>(null);
    const [viewAll, setViewAll] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [groupByData, setGroupByData] = useState<any>(null);
    const [first, setFirst] = useState<number>(0);
    const [childFirst, setChildFirst] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [childPage, setChildPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [childSize, setChildSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(10);
    const [childTotal, setChildTotal] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string>("-created_at");
    const [filterResult, setFilterResult] = useState<string>("");
    const [groupByOptionValue, setGroupByOptionValue] = useState<string>("");
    const [groupByExpand, setGroupByExpand] = useState<string>("");
    const [defaultField, setDefaultField] = useState<any[]>([]);
    const [detailField, setDetailField] = useState<any[]>([]);
    const [frontField, setFrontField] = useState<any>(null);
    const [check, setCheck] = useState<boolean>(false);
    const [copy, setCopy] = useState<any>(null);
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const bgColor: any[] = ["bg-[#D5E5FD]", "bg-[#FCFDD5]", "bg-[#F3FFFF]", "bg-[#D5FDE0]", "bg-[#F7D5FD]", "bg-[#FDD5E3]", "bg-[#F3FDD5]", "bg-[#D5EAFD]", "bg-[#FAFDD5]"]
    const textColor: any[] = ["text-[#276ED9]", "text-[#21D81E]", "text-[#17A9C9]", "text-[#18BE82]", "text-[#C91ED8]", "text-[#D81E61]", "text-[#D8AF1E]", "text-[#1E52D8]", "text-[#D8981E]"]
    useModule("tests");
    useScreen("");

    useEffect(() => {
        if (viewAll) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [viewAll]);

    const getTagList = async () => {
        try {
            const { tabArr, sortBy, groupBby, filterArr, status, fieldType }: any = await tabApi("samples/dashboard-tab-configuration/");

            if (status === 200) {
                setTagList(tabArr)
                setSortByList(sortBy);
                setGroupByList(groupBby);
                setFilterList(filterArr);
                setFieldType(fieldType);
                !!!option && setOption(!!params?.tab ? params?.tab : tabArr[0]?.name);
            }
        } catch (error: any) {
            console.error("error while feching tags", error);
        }
    }

    const getList = async () => {
        try {
            setListLoading(true);
            let url: any;
            viewAll ? url = "samples/test-request-list-group-by" : url = "samples/api/test-request-details-list";
            if (!!url) {
                const {
                    data,
                    count,
                    defaultFields,
                    detailFieldArr,
                    frontFieldList,
                    status
                }: any = await listApi(url, option, sortBy, page, size, filterResult, "tr", groupByOptionValue, childPage, childSize, groupByExpand, viewAll);

                if (status === 200) {
                    setListLoading(false);
                    setData(data?.length > 0 ? data?.map((item: any) => ({
                        ...item,
                        flag: false
                    })) : []);
                    viewAll ? setChildTotal(count) : setTotal(count)
                    setDefaultField(defaultFields);
                    setDetailField(detailFieldArr);
                    setFrontField(frontFieldList);
                }
            }
        } catch (error) {
            console.error("error while feching data", error);
        }
    }

    const getListGroupBy = async () => {
        try {
            setGroupLoading(true);
            const {
                data,
                count,
                defaultFields,
                detailFieldArr,
                frontFieldList,
                status
            }: any = await groupByApi("samples/test-request-list-group-by", option, sortBy, page, size, filterResult, groupByOptionValue, "tr");

            if (status === 200) {
                setGroupLoading(false);
                setGroupByData(data?.length > 0 ? data?.map((item: any) => ({
                    ...item,
                    data: item?.data?.map((d: any) => ({
                        ...d,
                        flag: false,
                    })),
                    allFlag: false,
                    flag: groupByExpand === item?.id ? true : false,
                })) : []);
                setTotal(count);
                setDefaultField(defaultFields);
                setDetailField(detailFieldArr);
                setFrontField(frontFieldList);
            }
        } catch (error: any) {
            console.error("error while feching data", error);
        }
    }

    useEffect(() => {
        getTagList();
    }, [])

    useEffect(() => {
        (!!option && (!!!groupByOptionValue || viewAll)) && getList();
    }, [option, sortBy, page, filterResult, size, viewAll, childSize, childPage, groupByOptionValue])

    useEffect(() => {
        (!!option && !!groupByOptionValue) && getListGroupBy();
    }, [groupByOptionValue, filterResult, sortBy, option, page, size])

    useEffect(() => {
        setPage(1);
        setChildPage(1);
        setCheck(false);
    }, [option])

    const notFound = () => {
        return <p className="text-base font-normal text-lGray">No Test Request Found</p>
    };

    const handleNavigate = (i: any, item: any) => {
        i !== "batch_number" && ((window.location.pathname.toString().includes("/lims/test")) ? navigate(`/lims/test/${item?.id}`) : navigate(`/customer/test-request/${item?.id}`))
    };

    const extraRow = (i: any, item: any, idx: number) => {
        return (
            <>
                {i === "batch_number" &&
                    <>
                        {!!item[i] ? <div className="flex items-center gap-[8px]">
                            <div className={`${(idx + 1) % 8 === 1 ? `${bgColor[0]} ${textColor[0]}` : (idx + 1) % 8 === 2 ? `${bgColor[1]} ${textColor[1]}` : (idx + 1) % 8 === 3 ? `${bgColor[2]} ${textColor[2]}` : (idx + 1) % 8 === 4 ? `${bgColor[3]} ${textColor[3]}` : (idx + 1) % 8 === 5 ? `${bgColor[4]} ${textColor[4]}` : (idx + 1) % 8 === 6 ? `${bgColor[5]} ${textColor[5]}` : (idx + 1) % 8 === 7 ? `${bgColor[6]} ${textColor[6]}` : (idx + 1) % 9 === 8 ? `${bgColor[7]} ${textColor[7]}` : `${bgColor[8]} ${textColor[8]}`} rounded-[20px] h-[23px] px-[20px] whitespace-nowrap flex items-center justify-center text-sm font-normal w-fit`}>
                                {item[i]}
                            </div>

                            <button className="cursor-pointer relative" onClick={() => { navigator.clipboard.writeText(item[i]); setCopy(item?.id) }}>
                                <CopyLink className="fill-primaryLight" />

                                {(copy === item?.id) && <span className="absolute right-[30px] bg-gray-800 border border-[rgba(19,63,151,0.1] rounded-full py-[6px] px-[11px] z-20 divide-gray-800 before:absolute before:content-[''] before:bottom-[-9px] before:right-[20px] before:w-[10px] before:h-[10px] before:bg-gray-800 before:-translate-y-1/2 before:-translate-x-1/2 before:rotate-45 top-[-35px] transition-all text-xs text-white scale-100">{t("minimize_window.copied")}</span>}
                            </button>
                        </div> :
                            <h3 className={`${view === "table" ? "text-lGray" : "text-mGray"} font-normal text-sm`}>-</h3>
                        }
                    </>
                }
            </>
        )
    }

    useEffect(() => {
        setTimeout(() => {
            setCopy(null);
        }, 1500)
    }, [copy]);

    return (
        <>
            {viewAll && <ViewAll viewAll={viewAll} setViewAll={setViewAll} layout={<ViewLayout option={option} sortBy={sortBy} filterResult={filterResult} frontField={frontField} view={view} groupBy={groupBy} groupByOption={groupByOption} groupByList={groupByList} defaultField={defaultField} detailField={detailField} viewAll={viewAll} groupByOptionValue={groupByOptionValue} setView={setView} setGroupByOptionValue={setGroupByOptionValue} setGroupByOption={setGroupByOption} setDefaultField={setDefaultField} filterList={filterList} setChildPage={setChildPage} setGroupBy={setGroupBy} setPage={setPage} setFilterResult={setFilterResult} filterObj={filterObj} setFilterObj={setFilterObj} setChildFirst={setChildFirst} setFirst={setFirst} data={data} setChildSize={setChildSize} sortByList={sortByList} setData={setData} listLoading={listLoading} setSortBy={setSortBy} fieldType={fieldType} setSize={setSize} childSize={childSize} childTotal={childTotal} childFirst={childFirst} total={total} size={size} first={first} setCheck={setCheck} check={check} module="tr" notFound={notFound} extraRow={extraRow} handleNavigate={handleNavigate} ignore={["batch_number"]} highLight={[]} height={user?.role !== "Lab Coordinator" ? "" : "min-h-[calc(100vh-203px)]"} />} />}
            <div className="flex-col flex w-full gap-[20px] p-[25px]">
                {user?.role !== "Lab Coordinator" && <Tags module="customer" tagList={tagList} option={option} setOption={setOption} />}

                <div className="bg-white py-[16px] px-[20px] w-full shadow-[0px_-1px_0px_0px_#E1E1E1_inset] flex flex-col gap-[11px]">
                    {!viewAll && <ViewLayout option={option} sortBy={sortBy} filterResult={filterResult} frontField={frontField} view={view} groupBy={groupBy} groupByOption={groupByOption} groupByList={groupByList} defaultField={defaultField} detailField={detailField} viewAll={viewAll} groupByOptionValue={groupByOptionValue} setView={setView} setGroupByOptionValue={setGroupByOptionValue} setGroupByOption={setGroupByOption} setDefaultField={setDefaultField} filterList={filterList} setChildPage={setChildPage} setGroupBy={setGroupBy} setPage={setPage} setFilterResult={setFilterResult} filterObj={filterObj} setFilterObj={setFilterObj} setChildFirst={setChildFirst} setFirst={setFirst} data={data} setChildSize={setChildSize} sortByList={sortByList} setData={setData} listLoading={listLoading} setSortBy={setSortBy} fieldType={fieldType} setSize={setSize} childSize={childSize} childTotal={childTotal} childFirst={childFirst} total={total} size={size} first={first} setCheck={setCheck} check={check} setViewAll={setViewAll} module="tr" notFound={notFound} extraRow={extraRow} handleNavigate={handleNavigate} ignore={["batch_number"]} highLight={[]} height={user?.role !== "Lab Coordinator" ? "" : "min-h-[calc(100vh-203px)]"} />}

                    {(groupBy && !!groupByOption) && <GroupView sortBy={sortBy} frontField={frontField} sortByList={sortByList} groupByData={groupByData} groupLoading={groupLoading} groupByExpand={groupByExpand} defaultField={defaultField} fieldType={fieldType} total={total} size={size} groupByOptionValue={groupByOptionValue} first={first} setGroupByExpand={setGroupByExpand} setChildTotal={setChildTotal} setSortBy={setSortBy} setPage={setPage} setFirst={setFirst} setSize={setSize} setGroupByData={setGroupByData} setViewAll={setViewAll} notFound={notFound} extraRow={extraRow} handleNavigate={handleNavigate} ignore={["batch_number"]} highLight={[]} height={user?.role !== "Lab Coordinator" ? "" : "min-h-[calc(100vh-203px)]"} />}
                </div>
            </div>
        </>
    );
};

export default TestRequest;