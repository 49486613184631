import { Skeleton } from 'primereact/skeleton';

const InvoiceList: React.FC<any> = () => {
    const cols = [];
    for (let i = 0; i < 5; i++) {
        cols.push(
            <tr className="!rounded-[10px] bg-white shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[40px] opacity-[0.4]" key={i}>
                <td className="px-[12px] h-full !text-center">
                    <Skeleton size="1.3rem"></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='4rem'></Skeleton>
                </td><td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='4rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='4rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='4rem'></Skeleton>
                </td>
            </tr >
        )
    };

    return (
        <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
                <div className="overflow-hidden">
                    <table className="table-auto overflow-x-scroll w-full skeleton-table">
                        <tbody>
                            {cols}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default InvoiceList;