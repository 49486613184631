import React from "react";
import { AccountSettingIcon, BillIcon, CertificateIcon, CustGroup, CustomFields, DataField, DepartmentIcon, ManageProd, RangeUnit, Remarks, SampleConfigIcon, Scope, StandardSetting, StorageLocationIcon, TemplateSettingIcon, Terms, TestMethodMaster, UIUX } from "../../components/svg/AllSvg";
import StorageLocation from "../../pages/setting/storage-location/StorageLocation";
import TemplateSetting from "../../pages/setting/template-setting/TemplateSetting";
const AccountSetting = React.lazy(() => import("../../pages/setting/account-setting/AccountSetting"));
const CustomField = React.lazy(() => import("../../pages/setting/custom-field/CustomField"));
const GeneralSetting = React.lazy(() => import("../../pages/setting/general-setting/GeneralSetting"));
const ScopeMaster = React.lazy(() => import("../../pages/setting/scope-parameter-master/ScopeMaster"));
const TermsCondition = React.lazy(() => import("../../pages/setting/terms-condition/TermsCondition"));
const Certificate = React.lazy(() => import("../../pages/setting/certificate/Certificate"));
const Department = React.lazy(() => import("../../pages/setting/department/Department"));
const StandardMaster = React.lazy(() => import("../../pages/setting/standard-parameter-master/StandardMaster"));
const Group = React.lazy(() => import("../../pages/setting/group/Group"));
const TestMaster = React.lazy(() => import("../../pages/setting/test-master/TestMaster"));
const RangeUnitMaster = React.lazy(() => import("../../pages/setting/range-unit/RangeUnit"));
const ManageProduct = React.lazy(() => import("../../pages/setting/manage-product/ManageProduct"));
const BillingManagement = React.lazy(() => import("../../pages/setting/billing-management/BillingManagement"));
const SampleManagement = React.lazy(() => import("../../pages/setting/sample-management/SampleManagement"));
const DataFieldsMasking = React.lazy(() => import("../../pages/setting/data-fields-masking/DataFieldsMasking"));

export const settingMenu = [
    { id: 1, name: "Account Settings", icon: AccountSettingIcon, component: AccountSetting, fields: true, toggle: false, flag: true, path: "/" },
    { id: 2, name: "Custom Fields", icon: CustomFields, component: CustomField, fields: true, toggle: false, flag: true, path: "/custom-field" },
    { id: 3, name: "General Setting", flag: false, icon: UIUX, component: GeneralSetting, path: "/general-setting" },
    { id: 4, name: "Standard & Parameter Master", icon: StandardSetting, component: StandardMaster, path: "/standard-master" },
    { id: 5, name: "Scope Management", icon: Scope, component: ScopeMaster, path: "/scope-master" },
    { id: 6, name: "Terms & Conditions", icon: Terms, component: TermsCondition, path: "/terms-condition" },
    { id: 7, name: "Accreditations Master", icon: CertificateIcon, component: Certificate, path: "/accreditations" },
    { id: 8, name: "Department Master", icon: DepartmentIcon, component: Department, fields: true, toggle: false, flag: true, path: "/department/administrative" },
    { id: 9, name: "Test Method Master", icon: TestMethodMaster, component: TestMaster, path: "/test-method-master" },
    { id: 10, name: "Range Unit Master", icon: RangeUnit, component: RangeUnitMaster, path: "/range-unit" },
    { id: 11, name: "Customer Group", icon: CustGroup, component: Group, fields: true, toggle: false, flag: true, path: "/group" },
    { id: 12, name: "Manage Product/Service", icon: ManageProd, component: ManageProduct, path: "/manage-product" },
    { id: 13, name: "Billing Management", icon: BillIcon, component: BillingManagement, path: "/billing-config" },
    { id: 14, name: "Sample Management", icon: SampleConfigIcon, component: SampleManagement, path: "/sample-config" },
    { id: 15, name: "Data Fields Masking", icon: DataField, component: DataFieldsMasking, path: "/data-fields-masking" },
    { id: 16, name: "Remarks Master", icon: Remarks, component: DataFieldsMasking, path: "/remaks-template" },
    { id: 17, name: "Storage Settings", icon: StorageLocationIcon, component: StorageLocation, path: "/storage-location" },
    { id: 18, name: "Template Management", icon: TemplateSettingIcon, component: TemplateSetting, path: "/template-management" },
]