/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useSelectPrimary}  from "../../../../shared/hooks/use-selectPrimary";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ClosePopup, PaymentArrow } from "../../../svg/AllSvg";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import { Checkbox } from "primereact/checkbox";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useAxios from "../../../../shared/hooks/use-axios";
import { useEffect, useState } from "react";
import PrimaryInput from "../../../form/PrimaryInput";
import PrimaryTextarea from "../../../form/PrimaryTextarea";

const Shipping = () => {
    const shipOpen: boolean = useSelector((state: any) => state.invoice.shipOpen);
    const shippingData: any = useSelector((state: any) => state.invoice.shippingData);
    const billingSame: any = useSelector((state: any) => state.invoice.billingSame);
    const shippingUpdate: boolean = useSelector((state: any) => state.invoice.shippingUpdate);
    const [countryList, setCountryList] = useState<any[]>([])
    const [stateList, setStateList] = useState<any[]>([])
    const dispatch = useDispatch();
    let styles = useSelectPrimary();
    const { t } = useTranslation('common');
    const [obj, setObj] = useState<any>({
        name: "",
        phone: "",
        email: "",
        address: "",
        country: "",
        state: "",
        city: "",
        district: "",
        code: "",
        gst: ""
    })

    const shippingPartySchema = yup.object().shape({
        name: yup.string().required(t("serviceBill.validation.service_party.name") as any),
        phone: yup.string().matches(/^\d{10}$/, "Please Enter valid Phone No.").required(t("serviceBill.validation.service_party.phone") as any),
        email: yup.string().email(t("serviceBill.validation.service_party.wrong_email") as any).required(t("serviceBill.validation.service_party.email") as any),
        address: yup.string(),
        country: yup.object().required(t("serviceBill.validation.service_party.country") as any),
        state: yup.object().required(t("serviceBill.validation.service_party.state") as any),
        city: yup.string().required(t("serviceBill.validation.service_party.city") as any),
        district: yup.string().required(t("serviceBill.validation.service_party.district") as any),
        code: yup.number().required(t("serviceBill.validation.service_party.code") as any),
        gst: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Please enter valid gst no.").required(t("serviceBill.validation.service_party.gst") as any),
    });

    const getCountry = async () => {
        try {
            const res: any = await useAxios.get("utils/countries/");

            if (res && res.status === 200) {
                setCountryList(Array.isArray(res?.data?.results) && res?.data?.results.map((item: any) => ({
                    label: item?.text,
                    value: item?.id
                })))
            }
        } catch (error) {
            console.error("error while fetching country", error);
        }
    };

    const getState = async () => {
        try {
            const res: any = await useAxios.get("utils/states/");

            if (res && res.status === 200) {
                setStateList(Array.isArray(res?.data?.results) && res?.data?.results.map((item: any) => ({
                    label: item?.text,
                    value: item?.id
                })))
            }
        } catch (error) {
            console.error("error while fetching country", error);
        }
    };

    useEffect(() => {
        getCountry();
        getState();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (Object.keys(shippingData)?.length > 0) {
            setObj(shippingData);
        }
    }, [shippingData])

    const handleSubmit = async (values: any) => {
        if (shippingUpdate) {
            dispatch(invoiceActions.setShippingData(values))
            dispatch(invoiceActions.setShipOpen(false))
        }
        dispatch(invoiceActions.setShippingDisable(false))
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(invoiceActions.setShipOpen(false))
        }
    };

    return (
        <>
            {shipOpen && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[645px] z-[10]">
                        <div className="relative">
                            <div className="cursor-pointer absolute top-[22px] right-[19px] z-[10]" onClick={() => dispatch(invoiceActions.setShipOpen(false))}>
                                <ClosePopup />
                            </div>
                        </div>

                        <div className="flex flex-col px-[28px] py-[23px] rounded-[20px] bg-white">
                            <div className="flex items-center gap-[15px]">
                                <div className="flex flex-col items-start">
                                    <h3 className="text-primaryLight font-semibold text-lg">Service Address</h3>
                                    <p className="text-mGray font-normal text-sm">Add the service address</p>
                                </div>
                            </div>

                            <div className="w-full flex items-center gap-[8px] mt-[29px]">
                                <Checkbox className='tests table-check' checked={billingSame} onChange={() => dispatch(invoiceActions.setBillingSame())}></Checkbox>
                                <p className="text-primaryLight text-xs font-medium">Billing Party Details Same as service to party</p>
                            </div>

                            <Formik
                                enableReinitialize={true}
                                initialValues={obj}
                                validationSchema={shippingPartySchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched, isValid, dirty }) => (
                                    <Form>
                                        <div className='mt-[15px]'>
                                            <h3 className='text-primaryLight text-sm font-medium'>Add Company Name</h3>

                                            <div className='mt-[16px] w-full flex flex-col'>
                                                <PrimaryInput type="text" title="Service party name" name="name" required={true} onChange={handleChange} onBlur={handleBlur} value={values.name} />
                                                <ErrorMessage
                                                    name="name"
                                                    component="div"
                                                    className="text-red-500 text-xs"
                                                />
                                            </div>

                                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-[32px] gap-y-[20px] mt-[22px]'>
                                                <div className="flex flex-col">
                                                    <PrimaryInput type="text" title="GST Number" name="gst" required={true} onChange={handleChange} onBlur={handleBlur} value={values.gst} />
                                                    <ErrorMessage
                                                        name="gst"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                            </div>

                                            <h3 className='text-primaryLight text-sm font-medium mt-[10px] mb-[17px]'>Address</h3>

                                            <PrimaryTextarea title="Address" name="address" onChange={handleChange} onBlur={handleBlur} value={values.address} />

                                            <div className='grid grid-cols-1 sm:grid-cols-3 gap-[20px] mt-[15px]'>
                                                <div className="sm:mt-[20px] flex flex-col">
                                                    <PrimaryInput type="text" title="City" required={true} name="city" value={values?.city} onChange={handleChange} onBlur={handleBlur} />
                                                    <ErrorMessage
                                                        name="city"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <h3 className="text-mGray text-xs font-normal pb-[4px]">State*</h3>
                                                    <Select options={stateList} placeholder="State" name="state" styles={styles} className="!w-full" onChange={(e: any) => setFieldValue("state", e)} value={values?.state} onBlur={(e: any) => setFieldTouched("state", e)} />
                                                    <ErrorMessage
                                                        name="state"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <h3 className="text-mGray text-xs font-normal pb-[4px]">Country*</h3>
                                                    <Select options={countryList} placeholder="Country" name="country" styles={styles} className="!w-full" onChange={(e: any) => setFieldValue("country", e)} value={values?.country} onBlur={(e: any) => setFieldTouched("country", e)} />
                                                    <ErrorMessage
                                                        name="country"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <PrimaryInput type="text" title="District" name="district" onChange={handleChange} onBlur={handleBlur} value={values.district} required={true} />
                                                    <ErrorMessage
                                                        name="district"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full">

                                                    <PrimaryInput type="text" title="Zip Code" required={true} name="code" value={values?.code} onChange={handleChange} onBlur={handleBlur} />
                                                    <ErrorMessage
                                                        name="code"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                            </div>

                                            <h3 className='text-primaryLight text-sm font-medium mt-[20px]'>Contact Information</h3>

                                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-[20px] mt-[15px]'>
                                                <div className='flex flex-col'>
                                                    <PrimaryInput type="text" title="Phone" name="phone" required={true} onChange={handleChange} onBlur={handleBlur} value={values.phone} />
                                                    <ErrorMessage
                                                        name="phone"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                                <div className='flex flex-col'>
                                                    <PrimaryInput type="text" title="Email" name="email" required={true} onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-[20px] bg-[#FEF4EA] rounded-[10px] min-h-[101px] py-[9px] px-[13px] flex flex-col gap-[10px]">
                                                <div className="flex items-start gap-[15px]">
                                                    <Checkbox className='tests table-check' checked={shippingUpdate} onChange={() => dispatch(invoiceActions.setShippingUpdateToggle())}></Checkbox>
                                                    <div className="flex flex-col gap-[10px]">
                                                        <p className="text-mGray text-xs font-normal">Upon checking this box, the new address will be updated in the following transactions:</p>
                                                        <ul className="list-disc pl-[16px]">
                                                            <li className="text-mGray text-xs font-normal">Draft Invoices</li>
                                                            <li className="text-mGray text-xs font-normal">Draft Quotes</li>
                                                            <li className="text-mGray text-xs font-normal">Open Credit Notes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <p className="mt-[10px] text-mGray text-sm font-normal">Note: Changes made here will be updated to this contact.</p>

                                            <div className="w-full flex items-end justify-end mt-[25px]">
                                                <button className="flex items-center gap-[4px] border border-[rgba(32,50,143,0.30)] h-[40px] bg-[rgba(183,194,255,0.10)] pl-[20px] pr-[15px] text-primaryLight text-sm font-medium rounded-[10px] disabled:opacity-[0.3] disabled:cursor-not-allowed" disabled={!shippingUpdate ? true : Object.keys(shippingData)?.length > 0 ? !(isValid) : !(isValid && dirty)}>Submit <PaymentArrow /></button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Shipping;