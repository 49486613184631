/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { EditIS, LeftArr, TempPattern } from "../svg/AllSvg";
import { useEffect, useState } from "react";
import SingleSelect from "../form/SingleSelect";
import useAxios from "../../shared/hooks/use-axios";
import PrimaryTextarea from "../form/PrimaryTextarea";
import AddNotesComponent from "../labSample/common/AddNotesComponent";
import { success } from "../../shared/hooks/use-toastify";
import PrimaryDarkLightBtn from "../form/PrimaryDarkLightBtn";
import { useNavigate } from "react-router-dom";

const EditTemplate = ({ detail, setApiCall, setIsEdit }: any) => {
    const isActivityOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const settingPath: string = useSelector((state: any) => state.ui.settingPath);
    const [name, setName] = useState<string>("")
    const [edit, setEdit] = useState<boolean>(false)
    const [scopeList, setScopeList] = useState<any[]>([]);
    const [scope, setScope] = useState<any>(null);
    const [dept, setDept] = useState<any>(null);
    const [deptList, setDeptList] = useState<any[]>([]);
    const [desc, setDesc] = useState<string>("")
    const [temp, setTemp] = useState<any>([])
    const [submit, setSubmit] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        setScope(!!detail?.item ? { label: detail?.item_name, value: detail?.item } : null)
        setDept(!!detail?.department ? { label: detail?.department_name, value: detail?.department } : null)
        setName(detail?.name)
        setTemp(detail?.template_data)
        setDesc(detail?.meta_data)
    }, [detail])

    const getDepartment = async () => {
        try {
            const res: any = await useAxios.get("utility/departments/?type=lab");

            if (res && res.status === 200) {
                setDeptList(res?.data?.results?.length > 0 ? res?.data?.results.map((dept: any) => ({
                    label: dept?.text,
                    value: dept?.id
                })) : [])
            }
        } catch (error) {
            console.error("error while fetching department", error);
        }
    };

    const getScopelist = async () => {
        try {
            const res: any = await useAxios.get(`labs/api/testing-scopes/?template=True`);

            if (res && res?.status === 200) {
                setScopeList(res?.data?.length > 0 ? res?.data.map((item: any) => ({
                    label: item?.standard_name,
                    value: item?.id
                })) : []);
                setApiCall(false);
            }
        } catch (error) {
            console.error("error while feching test request", error);
        }
    }

    useEffect(() => {
        getDepartment();
        getScopelist();
    }, [])

    const onSubmitHandler = async () => {
        setSubmit(true)
        let payload = {
            "name": name,
            "item": !!scope?.value ? scope?.value : null,
            "department": !!dept?.value ? dept?.value : null,
            "template_data": temp,
            "meta_data": desc
        }

        try {
            await useAxios.patch(`labs/api/notes-templates/${detail?.id}/`, payload);
            success("Template update successfully.");
            setApiCall(true)
            navigate(`${settingPath}/setting/template-management`)
            setIsEdit(false)
            setSubmit(false)
        } catch (error) {
            setSubmit(false)
            console.error("error while updating template", error);
        }
    }

    return (
        <div className="w-full h-full flex items-center justify-center px-[24px] pb-[24px]">
            <div className={`flex flex-col gap-y-[15px] h-full ${isActivityOpen ? "min-w-[780px] lg:w-full 2xl:max-w-[990px]" : "min-w-[780px] lg:w-full xxl:max-w-[990px]"}`}>
                <div>
                    <div className="sticky top-[70px] z-[2] transition-all flex w-full items-center justify-between py-[24px] bg-[#F8FAFB]">
                        <div className="flex items-center gap-[15px]">
                            <button className="min-w-[15px] flex items-center justify-center h-full" onClick={() => { setIsEdit(false); navigate(`${settingPath}/setting/template-management`); }}>
                                <LeftArr />
                            </button>
                            <div className="flex flex-col">
                                <h2 className="text-base font-normal text-primaryLight">Edit Template</h2>
                                <p className="text-mGray text-xs font-normal">Edit title & other select scope & department.</p>
                            </div>
                        </div>
                        <PrimaryDarkLightBtn title="Edit Template" disabled={(temp?.length > 0 && !!name && !submit) ? false : true} onClick={onSubmitHandler} />
                    </div>

                    <div className="flex flex-col gap-y-[15px] h-full px-[4px]">
                        <div className="bg-white shadow-[0px_4px_4px_rgba(24,39,75,0.08),0px_2px_4px_rgba(24,39,75,0.12)] rounded-[10px] w-full px-[30px] py-[23px] flex flex-col gap-[20px] relative">
                            {!edit ? <div className="flex items-center gap-[10px]">
                                <h4 className="text-primaryLight text-lg font-semibold">{name}</h4>
                                <button onClick={() => setEdit(true)}>
                                    <EditIS />
                                </button>
                            </div> :
                                <input type="text" className="border-0 shadow-none text-primaryLight text-lg font-semibold" autoFocus={true} onBlur={() => setEdit(false)} value={name} onChange={(e: any) => setName(e.target.value)} />
                            }

                            <div className="grid grid-cols-2 gap-x-[15px] gap-y-[25px] w-[715px]">
                                <div className="col-span-2">
                                    <PrimaryTextarea title="Description" name="description" value={desc} onChange={(e: any) => setDesc(e.target.value)} />
                                </div>

                                <SingleSelect options={scopeList} value={scope} onChange={(e: any) => setScope(e)} name="scope" placeholder="Select Scope" title="Scope" />

                                <SingleSelect options={deptList} value={dept} onChange={(e: any) => setDept(e)} name="dept" placeholder="Select Department" title="Department" />

                            </div>

                            <div className="absolute right-0 bottom-0">
                                <TempPattern />
                            </div>
                        </div>

                        <div className="bg-white shadow-[0px_4px_4px_rgba(24,39,75,0.08),0px_2px_4px_rgba(24,39,75,0.12)] rounded-[10px] w-full px-[30px] py-[23px] flex flex-col gap-[20px] relative editor-section result-notes min-h-[500px]">
                            {temp?.length > 0 && <AddNotesComponent setTemp={setTemp} type="setting" temp={temp} />}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EditTemplate;