/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { AddRightArrow, AddRolePlus, BackLeftArrow, CheckDelete, CheckRight, CloseBtn, DrageDrop, InfoWarnIcon, LoadingBtn, RoleDelete, RolePencil, UploadPhoto } from "../../svg/AllSvg";
import { useSelector } from "react-redux";
import { employeeActions } from "../../../shared/store/employee-Slice";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { country } from "../../../shared/utils/country";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { warning } from "../../../shared/hooks/use-toastify";
import useAxios from "../../../shared/hooks/use-axios";
import { useSelectSecondary } from "../../../shared/hooks/use-selectSecondary";
import Moment from 'moment';
import { randomColor } from "../../../shared/utils/randomColor";
import PrimaryLightBtn from "../../form/PrimaryLightBtn";
import PrimaryInput from "../../form/PrimaryInput";
import PrimaryTextarea from "../../form/PrimaryTextarea";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import SingleSelect from "../../form/SingleSelect";

const EditEmployee = ({ getUserList, getTagList }: any) => {
    const isEmployeeEdit: boolean = useSelector((state: any) => state.employee.isEmployeeEdit);
    const selectedId: string = useSelector((state: any) => state.employee.selectedId);
    const [AddRole, setAddRole] = useState<boolean>(true);
    const [initialRoleList, setInitialRoleList] = useState<any[]>([]);
    let [roleList, setRoleList] = useState<any[]>([]);
    const [newRoleOpen, setNewRoleOpen] = useState<boolean>(false);
    const [loginEnable, setLoginEnable] = useState<any>(false);
    const [departmentList, setDepartmentList] = useState<any[]>([]);
    const [edit, setEdit] = useState<any>({});
    const [name, setName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [img, setImg] = useState<string>("");
    const [userCount, setUserCount] = useState<any>({ active_user: 0, allow_user: 0 });
    let [role, setRole] = useState<any[]>([]);
    const [selectRole, setSelectRole] = useState<any>();
    const [selectDept, setSelectDept] = useState<any>();
    const [inputObj, setInputObj] = useState<any>({});
    const [selectImage, setSelectImage] = useState<any>(null);
    const [submit, setSubmit] = useState<any>(false);
    const { t } = useTranslation('common');

    const dispatch = useDispatch();
    let stylesSec = useSelectSecondary();

    const resetRoleList = () => {
        let arr: any[] = []

        for (let i = 0; i < initialRoleList?.length; i++) {
            const el = initialRoleList[i];

            let exist: any = role.find((item: any) => item?.role?.label === el?.label)
            if (!exist) {
                arr.push(el)
            }
        }

        roleList = arr
        setRoleList(roleList)
    }

    useEffect(() => {
        const getDetail = async () => {
            try {
                const res: any = await useAxios.get(`labs/manpower-details/?id=${selectedId}`);

                if (res && res.status === 200) {
                    const employeeObj: any = {
                        first_name: res?.data?.results[1]?.personal_details?.first_name,
                        last_name: res?.data?.results[1]?.personal_details?.last_name,
                        employee_id: !!res?.data?.results[1]?.personal_details?.emp_number ? res?.data?.results[1]?.personal_details?.emp_number : "",
                        designation: !!res?.data?.results[1]?.personal_details?.designation ? res?.data?.results[1]?.personal_details?.designation : "",
                        doj: !!res?.data?.results[1]?.personal_details?.joining_date ? res?.data?.results[1]?.personal_details?.joining_date : "",
                        gender: !!res?.data?.results[1]?.personal_details?.gender ? { label: res?.data?.results[1]?.personal_details?.gender === 1 ? "Male" : "Female", value: res?.data?.results[1]?.personal_details?.gender === 1 ? "male" : "female", id: res?.data?.results[1]?.personal_details?.gender } : "",
                        dob: !!res?.data?.results[1]?.personal_details?.dob ? res?.data?.results[1]?.personal_details?.dob : "",
                        mobile: !!res?.data?.results[0]?.contact_details?.mobile ? res?.data?.results[0]?.contact_details?.mobile : "",
                        email: !!res?.data?.results[0]?.contact_details?.email ? res?.data?.results[0]?.contact_details?.email : "",
                        address: !!res?.data?.results[1]?.personal_details?.address ? res?.data?.results[1]?.personal_details?.address : "",
                        manpower_type: !!res?.data?.results[1]?.personal_details?.emp_status ? res?.data?.results[1]?.personal_details?.emp_status === "internal" ? { label: "Internal", value: "internal", id: 1 } : { label: "External", value: "external", id: 2 } : "",
                        city: !!res?.data?.results[1]?.personal_details?.city ? res?.data?.results[1]?.personal_details?.city : "",
                        country: !!res?.data?.results[1]?.personal_details?.country ? { label: res?.data?.results[1]?.personal_details?.country, value: res?.data?.results[1]?.personal_details?.country } : "",
                        zip_code: !!res?.data?.results[1]?.personal_details?.zip_code ? res?.data?.results[1]?.personal_details?.zip_code : ""
                    };
                    setInputObj(employeeObj)
                    setImg(!!res?.data?.results[1]?.personal_details?.image ? res?.data?.results[1]?.personal_details?.image : "")
                    setLoginEnable(res?.data?.results[1]?.personal_details?.is_login_create)

                    if (res?.data?.results[1]?.personal_details?.role_dept_list?.length > 0) {
                        let arr: any[] = [];
                        for (let i = 0; i < res?.data?.results[1]?.personal_details?.role_dept_list.length; i++) {
                            const el = res?.data?.results[1]?.personal_details?.role_dept_list[i];

                            let obj: any = { role: {}, dept: [] };
                            obj.role.label = el?.role;
                            obj.role.value = el?.id
                            obj.role.role_type = el?.role_type;

                            if (el?.departments?.length > 0) {
                                for (let j = 0; j < el?.departments?.length; j++) {
                                    const d = el?.departments[j];

                                    let dep: any = {};
                                    dep.label = d?.name;
                                    dep.value = d?.id;

                                    obj.dept.push(dep)
                                }
                            }

                            arr.push(obj)
                        }

                        setRole(arr)
                    }
                }
            } catch (error: any) {
                console.error("error while fetching detail", error);
            }
        }

        getDetail();
        // eslint-disable-next-line
    }, [])

    const employeeSchema = yup.object().shape({
        first_name: yup.string().required(t("common_form.validation.first_name")),
        last_name: yup.string().required(t("common_form.validation.last_name")),
        employee_id: yup.string(),
        designation: yup.string(),
        doj: yup.date(),
        gender: yup.object(),
        dob: yup.date(),
        mobile: yup.string().matches(/^\d{10}$/, t("common_form.validation.wrong_phone")).required(t("common_form.validation.phone")),
        email: yup.string().email(t("common_form.validation.wrong_email")).required(t("common_form.validation.email")),
        address: yup.string(),
        manpower_type: yup.object(),
        city: yup.string(),
        country: yup.object(),
        zip_code: yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, t("common_form.validation.wrong_code") as any)
    });

    const handleSubmit = async (values: any) => {
        setInputObj(values)
        setName(values.first_name + " " + values.last_name);
        setLastName(values.last_name);
        setEmail(values.email);
        dispatch(employeeActions.setEmpName(values?.first_name + " " + values?.last_name));
        setAddRole(false);
    }

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files: FileList | null = e.target.files;
        if (!files) return;

        if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            warning(t("file_validation.file_type"));
            return;
        }

        if (files[0].size > (1 * 1000 * 1024)) {
            warning(t("file_validation.file_size"));
            return;
        }

        setImg(URL.createObjectURL(files[0]));
        setSelectImage(files[0]);
    }

    const createEmployee = async () => {
        try {
            let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any);
            let arr: any[] = [];

            for (let i = 0; i < role?.length; i++) {
                const el = role[i];

                let obj: any = {}
                obj.role_id = el?.role?.value
                obj.dep_id = !!!el?.dept ? [] : el?.dept?.map((item: any) => item?.value)
                obj.order = i + 1
                arr.push(obj)
            }
            setSubmit(true)
            let formdata = new FormData();

            formdata.append("first_name", inputObj?.first_name);
            formdata.append("last_name", inputObj?.last_name);
            formdata.append("email", inputObj?.email);
            formdata.append("designation", !!inputObj?.designation ? inputObj?.designation : "");
            formdata.append("emp_number", !!inputObj?.employee_id ? inputObj?.employee_id : "");
            !!inputObj?.dob && formdata.append("dob", Moment(inputObj?.dob).format('YYYY-MM-DD'));
            !!inputObj?.gender?.id && formdata.append("gender", inputObj?.gender?.id);
            !!inputObj?.doj && formdata.append("doj", Moment(inputObj?.doj).format('YYYY-MM-DD'));
            formdata.append("mobile", !!inputObj?.mobile ? inputObj?.mobile : "");
            formdata.append("address", !!inputObj?.address ? inputObj?.address : "");
            formdata.append("city", !!inputObj?.city ? inputObj?.city : "");
            !!inputObj?.country?.value && formdata.append("country", inputObj?.country?.value);
            formdata.append("zip_code", !!inputObj?.zip_code ? inputObj?.zip_code : "");
            !!inputObj?.manpower_type?.id && formdata.append("emp_status", inputObj?.manpower_type?.id);
            formdata.append("lab", userDetail?.lab_name ? userDetail?.lab_name : "");
            !!selectImage && formdata.append("image", selectImage);
            formdata.append("is_login_create", loginEnable);
            formdata.append("role_dept", JSON.stringify(arr));

            const res: any = await useAxios.patch(`labs/create-manpower-basic-details/?uid=${selectedId}`, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (res && res.status === 201) {
                dispatch(employeeActions.setIsEmployeeEdit(false));
                dispatch(employeeActions.setIsSuccess(true));
                dispatch(employeeActions.setSuccessType("edit"));
                getUserList();
                getTagList();
            }
        } catch (error: any) {
            setSubmit(false)
            if (error && error?.response?.status === 400) {
                warning(error?.response?.data?.error?.toString())
                warning(error?.response?.data?.results?.user?.toString())
            }
            console.error("error while creating employee", error);
        }
    }

    const getRoleList = async () => {
        try {
            const res: any = await useAxios.get("utility/groups");

            if (res && res.status === 200) {
                let arr: any[] = res?.data?.results.map((item: any) => ({
                    value: item?.id,
                    label: item?.text,
                    role_type: item?.role_type
                }))
                roleList = arr
                setInitialRoleList(arr)
                setRoleList(roleList);
            }
        } catch (error: any) {
            console.error("error while fetching role", error);
        }
    }

    const getDepartmentList = async () => {
        try {
            const res: any = await useAxios.get(`utility/departments/?role_type=${selectRole?.role_type}`);

            if (res && res.status === 200) {
                setDepartmentList(res?.data?.results.map((item: any) => ({
                    value: item?.id,
                    label: item?.text
                })));
            }
        } catch (error: any) {
            console.error("error while fetching department", error);
        }
    }

    useEffect(() => {
        !!selectRole && getDepartmentList();
    }, [selectRole])

    useEffect(() => {
        getRoleList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const getLicense = async () => {
            try {
                const res: any = await useAxios.get("tenant/user-license/");

                if (res && res.status === 200) {
                    setUserCount({ active_user: res?.data?.results?.total_active_users, allow_user: res?.data?.results?.total_allowed_users });
                }
            } catch (error) {
                console.error("error while fetching user license", error);
            }
        }

        loginEnable && getLicense();
    }, [loginEnable]);

    const handleSelect = () => {
        if (Object.keys(edit)?.length > 0) {
            let arr: any[] = [];
            for (let i = 0; i < role?.length; i++) {
                const el = role[i];
                let obj: any = {}
                if (el?.role?.label === edit?.role?.label) {
                    obj.role = selectRole;
                    obj.dept = selectDept;
                    arr.push(obj)
                } else {
                    arr.push(el)
                }
            }
            role = arr;
            setRole(role);
            setEdit({});
            resetRoleList();
        } else {
            let obj: any = {
                role: selectRole,
                dept: selectDept
            }
            role = [...role, obj]
            setRole(role);
            setSelectDept(null);
            setSelectRole(null);
            setNewRoleOpen(false)
            resetRoleList();
        }

    }

    const handleClose = () => {
        setSelectDept(null);
        setSelectRole(null);
        setNewRoleOpen(false)
        setEdit({});
    }

    const handleDelete = (i: any) => {
        let arr = role.filter((item: any) => item?.role?.label !== i?.role?.label)
        role = arr
        setRole(role)
        resetRoleList();
        setSelectRole(null);
        setSelectDept(null);
    }

    const handleDrop = async (droppedItem: any): Promise<void> => {
        try {
            if (!droppedItem.destination) return;
            let updatedList = [...role];
            const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
            updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
            setRole(updatedList);
        } catch (error: any) { }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(employeeActions.setIsEmployeeEdit(false))
        }
    };

    return (
        <>
            {isEmployeeEdit && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px]  md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[648px]">
                        <div className="flex items-center justify-between px-[28px] pt-[10px] rounded-t-[20px] bg-white h-[70px]">
                            <div className="flex flex-col items-start">
                                <h3 className="text-primaryLight font-semibold text-lg">{t("laboratory.employee.edit_title")}</h3>
                                <p className="text-mGray font-normal text-sm">{t("laboratory.employee.edit_desc")}</p>
                            </div>
                            <div className="cursor-pointer" onClick={() => dispatch(employeeActions.setIsEmployeeEdit(false))}>
                                <CloseBtn />
                            </div>
                        </div>

                        {AddRole ?
                            <Formik
                                enableReinitialize={true}
                                initialValues={inputObj}
                                validationSchema={employeeSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <div className="py-[25px] px-[28px]">
                                            <div className="flex items-center gap-[11px]">
                                                {!!!img ? <UploadPhoto width="75" height="75" /> : <img src={img} alt="" className="w-[73px] h-[73px] object-contain rounded-full" />}
                                                <div className="relative !cursor-pointer h-fit w-fit">
                                                    <input
                                                        type="file"
                                                        className="relative opacity-0 !cursor-pointer z-10 p-0 w-fit"
                                                        formNoValidate={false}
                                                        onChange={handleUpload}
                                                        multiple={false}
                                                    />
                                                    <h3 className="text-primaryLight font-medium text-sm !cursor-pointer absolute top-[4px] left-0 right-0">{t("common_form.field.photo")}</h3>
                                                </div>
                                            </div>

                                            <div className="pt-[27px] grid grid-cols-1 sm:grid-cols-2 gap-x-[20px] gap-y-[27px]">
                                                <div className="w-full flex flex-col">
                                                    <PrimaryInput type="text" title={t("common_form.field.first_name")} name="first_name" required={true} onChange={handleChange} onBlur={handleBlur} value={values.first_name} />
                                                    <ErrorMessage
                                                        name="first_name"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>

                                                <div className="w-full flex flex-col">
                                                    <PrimaryInput type="text" title={t("common_form.field.last_name")} name="last_name" required={true} onChange={handleChange} onBlur={handleBlur} value={values.last_name} />
                                                    <ErrorMessage
                                                        name="last_name"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>

                                                <PrimaryInput type="text" title={t("laboratory.employee.form.field.emp_id")} name="employee_id" onChange={handleChange} onBlur={handleBlur} value={values.employee_id} />
                                                <PrimaryInput type="text" title={t("laboratory.employee.form.field.designation")} name="designation" onChange={handleChange} onBlur={handleBlur} value={values.designation} />
                                            </div>

                                            <div className="pt-[27px] grid grid-cols-1 sm:grid-cols-3 gap-[27px] sm:gap-[20px]">
                                                <PrimaryInput type="date" title={t("laboratory.employee.form.field.doj")} name="doj" onChange={handleChange} onBlur={handleBlur} value={values.doj} />
                                                <div className="w-full flex flex-col">
                                                    <SingleSelect options={[{ label: "Male", value: "male", id: 1 }, { label: "Female", value: "female", id: 2 }]} name="gender" placeholder={t("common_form.placeholder.gender")} title={t("common_form.field.gender")} onChange={(e: any) => setFieldValue("gender", e)} value={values?.gender} onBlur={(e: any) => setFieldTouched("gender", e)} />
                                                </div>
                                                <PrimaryInput type="date" title={t("common_form.field.dob")} name="dob" onChange={handleChange} onBlur={handleBlur} value={values.dob} />
                                            </div>

                                            <div className="pt-[27px] grid grid-cols-1 sm:grid-cols-2 gap-[27px] sm:gap-[20px]">
                                                <div className="w-full flex flex-col">
                                                    <PrimaryInput type="text" title={t("common_form.field.phone")} name="mobile" required={true} onChange={handleChange} onBlur={handleBlur} value={values.mobile} />
                                                    <ErrorMessage
                                                        name="mobile"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>

                                                <div className="w-full flex flex-col">
                                                    <PrimaryInput type="text" title={t("common_form.field.email")} name="email" required={true} onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                            </div>

                                            <div className="pt-[27px] grid grid-cols-1 sm:grid-cols-2 gap-[27px] sm:gap-[20px]">
                                                <div className="w-full flex flex-col">
                                                    <SingleSelect options={[{ label: "Permanent", value: "permanent", id: 1 }, { label: "Contractual", value: "contractual", id: 2 }]} placeholder={t("laboratory.employee.form.placeholder.type")} title={t("laboratory.employee.form.field.type")} name="manpower_type" className="!w-full emp-type" onChange={(e: any) => setFieldValue("manpower_type", e)} value={values?.manpower_type} onBlur={(e: any) => setFieldTouched("manpower_type", e)} />
                                                </div>

                                                <div className="w-full flex flex-col">
                                                    <SingleSelect menuPlacement="auto" minMenuHeight={300} options={country} placeholder={t("common_form.placeholder.country")} name="country" className="!w-full" onChange={(e: any) => setFieldValue("country", e)} value={values?.country} onBlur={(e: any) => setFieldTouched("country", e)} title={t("common_form.field.country")} />
                                                </div>
                                            </div>

                                            <div className="pt-[27px] sm:pt-[20px] grid grid-cols-1 sm:grid-cols-2 gap-[27px] sm:gap-[20px]">
                                                <PrimaryInput type="text" title={t("common_form.field.city")} name="city" onChange={handleChange} onBlur={handleBlur} value={values.city} />
                                                <PrimaryInput type="text" title={t("common_form.field.code")} name="zip_code" onChange={handleChange} onBlur={handleBlur} value={values.zip_code} />
                                            </div>

                                            <div className="pt-[27px] sm:pt-[20px]">
                                                <PrimaryTextarea title={t("common_form.field.address")} name="address" onChange={handleChange} onBlur={handleBlur} value={values.address} />
                                            </div>

                                        </div>
                                        <div className="w-full flex items-center justify-end h-[70px] pr-[28px]">
                                            <button type="submit" className="bg-[#EEF4FC] rounded-[10px] px-[25px] h-[40px] text-primary text-sm font-medium flex items-center justify-center cursor-pointer gap-[12px] border border-[rgba(32,50,143,0.30)] disabled:cursor-not-allowed disabled:opacity-[0.3]" disabled={(!!!values.first_name || !!!values.email || !!!values.last_name || !!!values.mobile) ? true : false}>{t("laboratory.employee.edit_role_btn")} <AddRightArrow className={`${(!!!values.first_name || !!!values.email || !!!values.last_name || !!!values.mobile) ? "fill-[#b1b1b1]" : "fill-primaryLight"}`} /></button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            :
                            <>
                                <div className="py-[25px] px-[28px]">
                                    <div className="bg-[#FCFCFC] border border-[#EDEDED] rounded-[10px] py-[9px] px-[17px] w-full flex items-center gap-[24px]">
                                        {!!img ? <img src={img} alt="" className="w-[59px] h-[59px] object-contain" /> : <div style={{ backgroundColor: `${randomColor[name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-bold text-lg rounded-full w-[59px] h-[59px] flex items-center justify-center`}>{name?.charAt(0)}{lastName?.charAt(0)}</div>}

                                        <div className="flex flex-col">
                                            <h3 className="text-primaryLight font-semibold text-base">{name}</h3>
                                            <p className="text-xs text-mGray font-normal">{email}</p>
                                        </div>
                                    </div>

                                    <div className="mt-[15px] rounded-[10px] bg-[#F9FAFF] w-full p-[9px]">
                                        <div className="w-full pl-[33px] pr-[22px] pb-[6px] flex items-center">
                                            <p className="text-sm text-mGray font-normal w-[30%]">{t("laboratory.employee.add_role.list.role")}</p>
                                            <p className="text-sm text-mGray font-normal w-[60%]">{t("laboratory.employee.add_role.list.dept")}</p>
                                            <p className="text-sm text-mGray font-normal text-end w-[10%]">{t("laboratory.employee.add_role.list.actions")}</p>
                                        </div>

                                        <div className="bg-white w-full h-[350px] p-[9px] rounded-[10px] flex flex-col gap-[10px] overflow-y-scroll">
                                            {Object?.keys(edit)?.length === 0 ? <DragDropContext onDragEnd={handleDrop}>
                                                <Droppable droppableId="div">
                                                    {(provided: any) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef} className="flex flex-col gap-[10px]"
                                                        >
                                                            {Array.isArray(role) && role.map((item: any, index: number) => (
                                                                <Draggable key={item?.id} draggableId={index?.toString()} index={index}>
                                                                    {(provided: any) => (
                                                                        <div ref={provided.innerRef}
                                                                            {...provided.dragHandleProps}
                                                                            {...provided.draggableProps} className="w-full" key={index}>
                                                                            <div className="rounded-[10px] bg-[#fcfcfc] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)] py-[12px] w-full px-[10px] flex items-center relative">
                                                                                <div className="pr-[8px]">
                                                                                    <DrageDrop color="#6B7280" />
                                                                                </div>
                                                                                <h3 className="text-primaryLight font-medium text-sm w-[30%] line-clamp-1">{item?.role?.label}</h3>
                                                                                {item?.dept?.length > 0 ? <div className="w-[60%] flex items-center gap-[5px]">
                                                                                    {Array.isArray(item?.dept) && item?.dept.map((i: any, idx: number) => (
                                                                                        <div key={idx} className={`${idx < 2 ? "block" : "hidden"}`}>
                                                                                            {idx < 2 && <button className={`${idx === 0 ? "bg-[#ECF6FF]" : "bg-[#FFF9D4]"} text-primaryLight flex items-center justify-center text-sm font-medium rounded-[20px] h-[25px] px-[15px] whitespace-nowrap`}>{i?.label}</button>}
                                                                                        </div>
                                                                                    ))}
                                                                                    {item?.dept?.length > 2 && <button className="flex items-center justify-center text-sm font-medium rounded-[20px] h-[25px] px-[15px] whitespace-normal text-primaryLight bg-[#DFE]">+{item?.dept?.length - 2}</button>}
                                                                                </div> :
                                                                                    <div className="w-[60%] text-sm text-[#C3C3C3] font-medium">{t("group_by_menu.none")}</div>
                                                                                }
                                                                                <div className="w-[10%] flex items-center gap-[5px]">
                                                                                    <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={() => { setEdit(item); setSelectDept(item?.dept); setSelectRole(item?.role); setNewRoleOpen(false); }}>
                                                                                        <RolePencil />
                                                                                    </div>

                                                                                    <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={() => handleDelete(item)}>
                                                                                        <RoleDelete />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext> :
                                                <>
                                                    {Array.isArray(role) && role.map((item: any, index: number) => (
                                                        <React.Fragment key={index}>
                                                            {edit !== item ?
                                                                <div className="rounded-[10px] bg-[#fcfcfc] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)] py-[12px] w-full px-[10px] flex items-center relative">
                                                                    <div className="pr-[8px]">
                                                                        <DrageDrop color="#6B7280" />
                                                                    </div>
                                                                    <h3 className="text-primaryLight font-medium text-sm w-[30%] line-clamp-1">{item?.role?.label}</h3>
                                                                    {item?.dept?.length > 0 ? <div className="w-[60%] flex items-center gap-[5px]">
                                                                        {Array.isArray(item?.dept) && item?.dept.map((i: any, idx: number) => (
                                                                            <div key={idx} className={`${idx < 2 ? "block" : "hidden"}`}>
                                                                                {idx < 2 && <button className={`${idx === 0 ? "bg-[#ECF6FF]" : "bg-[#FFF9D4]"} text-primaryLight flex items-center justify-center text-sm font-medium rounded-[20px] h-[25px] px-[15px] whitespace-nowrap`}>{i?.label}</button>}
                                                                            </div>
                                                                        ))}
                                                                        {item?.dept?.length > 2 && <button className="flex items-center justify-center text-sm font-medium rounded-[20px] h-[25px] px-[15px] whitespace-normal text-primaryLight bg-[#DFE]">+{item?.dept?.length - 2}</button>}
                                                                    </div> :
                                                                        <div className="w-[60%] text-sm text-[#C3C3C3] font-medium">{t("group_by_menu.none")}</div>
                                                                    }
                                                                    <div className="w-[10%] flex items-center gap-[5px]">
                                                                        <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={() => { setEdit(item); setSelectDept(item?.dept); setSelectRole(item?.role); setNewRoleOpen(false); }}>
                                                                            <RolePencil />
                                                                        </div>

                                                                        <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={() => handleDelete(item)}>
                                                                            <RoleDelete />
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                <div className="flex items-center justify-between w-full pt-[10px] gap-[12px]">
                                                                    <div className="w-[90%] flex items-center gap-[12px]">
                                                                        <div className="w-[35%]">
                                                                            <Select options={roleList} placeholder={t("laboratory.employee.add_role.select_role")} name="role" styles={stylesSec} className="!w-full" onChange={(e: any) => { setSelectRole(e); setSelectDept(null); }} value={selectRole} menuPlacement="auto" maxMenuHeight={200} />
                                                                        </div>
                                                                        {selectRole && <div className="w-[65%]">
                                                                            <Select options={departmentList} isMulti placeholder={t("laboratory.employee.add_role.select_dept")} name="department" styles={stylesSec} className="!w-full" onChange={(e: any) => setSelectDept(e)} value={selectDept} menuPlacement="auto" maxMenuHeight={200} />
                                                                        </div>}
                                                                    </div>

                                                                    {(selectRole?.role_type === "technical" ? (!!selectDept && !!selectRole) : (!!selectRole)) && <div className="w-[10%] flex items-center gap-[5px]">
                                                                        <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleSelect}>
                                                                            <CheckRight className="fill-primaryLight" />
                                                                        </div>

                                                                        <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleClose}>
                                                                            <CheckDelete className="fill-[#FF0000]" />
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    ))}

                                                </>
                                            }

                                            {(newRoleOpen) && <div className="flex items-center justify-between w-full pt-[10px] gap-[12px]">
                                                <div className="w-[90%] flex items-center gap-[12px]">
                                                    <div className="w-[35%]">
                                                        <Select options={roleList} placeholder={t("laboratory.employee.add_role.select_role")} name="role" styles={stylesSec} className="!w-full" onChange={(e: any) => { setSelectRole(e); setSelectDept(null); }} value={selectRole} menuPlacement="auto" maxMenuHeight={200} />
                                                    </div>
                                                    {selectRole && <div className="w-[65%]">
                                                        <Select options={departmentList} isMulti placeholder={t("laboratory.employee.add_role.select_dept")} name="department" styles={stylesSec} className="!w-full" onChange={(e: any) => setSelectDept(e)} value={selectDept} menuPlacement="auto" maxMenuHeight={200} />
                                                    </div>}
                                                </div>

                                                {(selectRole?.role_type === "technical" ? (!!selectDept && !!selectRole) : (!!selectRole)) && <div className="w-[10%] flex items-center gap-[5px]">
                                                    <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleSelect}>
                                                        <CheckRight className="fill-primaryLight" />
                                                    </div>

                                                    <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleClose}>
                                                        <CheckDelete className="fill-[#FF0000]" />
                                                    </div>
                                                </div>}
                                            </div>}

                                            <div className="bg-[#FAFAFA] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)] px-[20px] flex items-center justify-center h-[46px] cursor-pointer gap-[8px] rounded-[10px]" onClick={() => { setNewRoleOpen(true); setSelectRole(null); setSelectDept(null); setEdit({}); }}>
                                                <h3 className="text-primaryLight text-xs font-semibold">{t("laboratory.employee.add_role.add_new_role_btn")}</h3>
                                                <AddRolePlus />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mt-[8px] bg-[#FBFBFB] rounded-[10px] py-[11px] px-[18px] flex flex-col gap-[7px]">
                                        <div className="flex items-start justify-between">
                                            <h3 className="text-primaryLight font-medium text-sm">{t("enable_login.title")}</h3>

                                            <div className="flex items-center gap-[8px]">
                                                <p className="text-[0.813rem] text-mGray font-normal">{t("common_switch.no")}</p>
                                                <label className="relative inline-flex items-center cursor-pointer">
                                                    <input type="checkbox" checked={loginEnable} className="sr-only peer" onChange={() => setLoginEnable(!loginEnable)} />
                                                    <div className="w-9 h-5 bg-[#E8EBFF] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all after:peer-checked:bg-dGray" ></div>
                                                </label>
                                                <p className="text-[0.813rem] text-mGray font-normal">{t("common_switch.yes")}</p>
                                            </div>
                                        </div>

                                        <div className="flex items-center gap-[8px]"><InfoWarnIcon className="fill-danger" />
                                            <p className="text-[#FD513B] text-xs font-semibold">{loginEnable ? `${t("enable_login.you_have")} ${userCount?.active_user}/${userCount?.allow_user} ${t("enable_login.licence_remaining")}` : t("enable_login.login_credentials")}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="w-full flex items-center justify-between h-[70px] px-[28px] rounded-b-[20px]">
                                    <div className="flex items-center gap-[12px] cursor-pointer" onClick={() => setAddRole(true)}><BackLeftArrow /> <h3 className="text-primaryLight text-sm font-medium">{t("common_btn.basic_info")}</h3></div>

                                    <PrimaryLightBtn type="button" onClick={createEmployee} title={<>{submit && <LoadingBtn className="w-5 h-5 text-primaryLight animate-spin" />}{submit ? t("common_btn.save") : t("common_btn.finish")}</>} disabled={(!submit && role.length > 0) ? false : true} />
                                </div>
                            </>

                        }
                    </div>
                </div>
            </div >
            }
        </>
    );
};

export default EditEmployee;