import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TestRequest from "../../pages/customer/testRequest/TestRequest";

const BookTest = React.lazy(() => import("../../pages/customer/bookTest/BookTest"));
// const AddSample = React.lazy(() => import("../../pages/customer/addSample/AddSample"));
const NewLabSampleDetail = React.lazy(() => import("../../pages/labSample/labSampleDetail/NewLabSampleDetail"));
const ServiceBilling = React.lazy(() => import("../../pages/customer/serviceBilling/ServiceBilling"));
const ServiceBillingInfo = React.lazy(() => import("../../pages/customer/serviceBillingInfo/ServiceBillingInfo"));
const TestRequestDetail = React.lazy(() => import("../../pages/customer/testRequestDetail/TestRequestDetail"));

const LabTestRoutes: React.FC<any> = ({ page }) => {
    return (
        <Routes>
            <Route path="/" element={<TestRequest />} />
            <Route path="/:id" element={<TestRequestDetail />} />
            <Route path="/service" element={<BookTest />} />
            <Route path="/book-test/:id" element={<BookTest />} />
            {/* <Route path="/add-sample/:id" element={<AddSample />} /> */}
            <Route path="/add-sample/:id" element={<NewLabSampleDetail role="customer" theme="lab" />} />
            <Route path="/service-billing/:id" element={<ServiceBilling />} />
            <Route path="/service-billing-info/:id" element={<ServiceBillingInfo />} />
            {page === "lims" ?
                <Route path="*" element={<Navigate to="/lims/test" />} />
                :
                <Route path="*" element={<Navigate to="/crm/test" />} />
            }
        </Routes>
    );
};

export default LabTestRoutes;