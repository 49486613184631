import { Skeleton } from "primereact/skeleton";

const SettingList: React.FC<any> = () => {
    const cols = [];
    for (let i = 0; i < 5; i++) {
        cols.push(
            <tr className="!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[50px]" key={i}>
                <td className="px-[12px] 2xl:px-[16px] h-full !text-center">
                    <Skeleton size="1.3rem"></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[12px] 2xl:px-[16px] h-full !text-center">
                    <Skeleton height=".8rem" width='1.5rem'></Skeleton>
                </td>
                <td className="px-[12px] 2xl:px-[16px] h-full">
                    <div className="flex items-center gap-[8px] w-full justify-end">
                        <Skeleton size="1.4rem"></Skeleton>
                        <Skeleton size="1.4rem"></Skeleton>
                    </div>
                </td>
            </tr>
        )
    };

    return (
        <table className="table-auto overflow-x-scroll w-full skeleton-table">
            <tbody>
                {cols}
            </tbody>
        </table>
    );
};

export default SettingList;