import { Approve, DataDotsIcon, GroupDownArrow, NameOrgIcon, RegisterArrow, Reject, SortArrow, SortDownArrow, SortUpArrow } from "../../../svg/AllSvg";
import React, { useEffect, useState } from "react";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { Checkbox } from "primereact/checkbox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import useAxios from "../../../../shared/hooks/use-axios";
import { success } from "../../../../shared/hooks/use-toastify";
import { randomColor } from "../../../../shared/utils/randomColor";
import SkeletonGroupView from "../../../../skeleton/components/customer/GroupView";
import Moment from 'moment';
import { tableActions } from "../../../../shared/store/table-Slice";
import { Paginator } from "primereact/paginator";
import { useTranslation } from "react-i18next";
import PrimaryDarkLightBtn from "../../../form/PrimaryDarkLightBtn";

const GroupView: React.FC<any> = () => {
  let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any);
  const sortBy: string = useSelector((state: any) => state.table.sortBy);
  const frontField: any = useSelector((state: any) => state.table.frontField);
  const sortByList: any[] = useSelector((state: any) => state.table.sortByList);
  const groupByData: any = useSelector((state: any) => state.table.groupByData);
  const loading: boolean = useSelector((state: any) => state.table.loading);
  const groupByExpand: any = useSelector((state: any) => state.table.groupByExpand);
  const defaultField: any = useSelector((state: any) => state.table.defaultField);
  const fieldType: any = useSelector((state: any) => state.table.fieldType);
  const total: number = useSelector((state: any) => state.table.total);
  const size: number = useSelector((state: any) => state.table.size);
  const option: any = useSelector((state: any) => state.table.option);
  const groupByOption: any = useSelector((state: any) => state.table.groupByOption);
  const first: number = useSelector((state: any) => state.table.first);
  const [allCheckBox, setAllCheckBox] = useState<boolean>(false)
  const [oneCheck, setOneCheck] = useState<string>("");
  const [list, setList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');


  const handleChange = (e: any, name: any) => {
    let itemName = e.target.name;
    let checked = e.target.checked;

    let arr: any[] = [];
    for (let i = 0; i < list?.length; i++) {
      const el = list[i];

      if (el?.id === name) {
        if (itemName === "checkAll") {
          el.allFlag = checked;
          let result = el?.data.map((item: any) => ({ ...item, flag: checked }));
          el.data = result;
          arr.push(el)
        } else {
          let result = el?.data.map((item: any) =>
            item?.id === itemName ? { ...item, flag: checked } : item
          );
          let check = result.every((item: any) => item.flag);
          el.allFlag = check;
          el.data = result;
          arr.push(el)
        }
      }
    }

    setList(arr);
  };

  const handleSelect = (item: any) => {
    let arr: any[] = [];
    for (let i = 0; i < list?.length; i++) {
      const el = list[i];

      if (item?.id === el?.id) {
        el.flag = !item?.flag;
        groupByExpand === el?.id ? dispatch(tableActions.setGroupByExpand("")) : dispatch(tableActions.setGroupByExpand(el?.id))
        dispatch(tableActions.setChildTotal(item?.count));
        arr.push(el);
      } else {
        let obj: any = { ...groupByExpand };
        if (obj?.hasOwnProperty(el?.value)) {
          obj[el?.value] = false
          dispatch(tableActions.setGroupByExpand(obj))
        }
        el.flag = false;
        arr.push(el);
      }
    }
    setList(arr);
  };

  useEffect(() => {
    if (groupByData?.length > 0) {
      setList(groupByData.map((item: any) => ({
        ...item,
        data: item?.data?.map((d: any) => ({
          ...d,
          flag: false,
        })),
        allFlag: false,
        flag: groupByExpand[item?.value] ? true : false,
      })));
    } else {
      setList([]);
    }
    // eslint-disable-next-line
  }, [groupByData]);

  const getSortIcon = (asc: any, desc: any) => {
    if (sortByList.includes(asc)) {
      if (sortBy === "-created_at") {
        return <SortArrow />
      } else if (asc === sortBy) {
        return <SortDownArrow />
      } else if (desc === sortBy) {
        return <SortUpArrow />
      } else {
        return <SortArrow />
      }
    }
  }

  const handleSort = (asc: any, desc: any) => {
    if (sortByList.includes(asc)) {
      if (sortBy === "-created_at") {
        dispatch(tableActions.setSortBy(asc))
      } else if (asc === sortBy) {
        dispatch(tableActions.setSortBy(desc))
      } else {
        dispatch(tableActions.setSortBy(asc))
      }
    }
  }

  const onPageChange = (event: any) => {
    dispatch(tableActions.setPage(event?.page + 1));
    dispatch(tableActions.setFirst(event.first));
    dispatch(tableActions.setSize(event.rows))
  };

  const getHeader = () => {
    let arr: any = [];
    defaultField.forEach((el: any) => {
      for (const key in frontField) {
        if (key === el) {
          arr.push(<th key={key} className="px-[8px] 2xl:px-[16px] h-full cursor-pointer !rounded-l-[10px]" onClick={() => handleSort(key, `-${key}`)}>
            <div className='flex items-center justify-start w-full gap-[8px]'>
              <p className='text-dGray font-medium text-xs !text-left'>{frontField[key]}</p>
              <div className="w-[13px] h-[13px]">
                {getSortIcon(key, `-${key}`)}
              </div>
            </div>
          </th>);
        }
      }
    });

    return arr;
  };

  const handleSubmit = async (item: any, status: any) => {
    try {
      let payload = {
        "credit_note": item?.id,
        "approved": status?.toString()
      }

      const res: any = await useAxios.post(`workflow/credit-note-approval-api/`, payload);

      if (res && res.status === 200) {
        success(`Credit Note ${status ? "approved" : "disapproved"} successfully.`);
        dispatch(invoiceActions.setTagApiCall(true))
        dispatch(invoiceActions.setApiCall("credit-note"))
      }

    } catch (error) {
      console.error("error while approval credit note", error);
    }
  }

  return (
    <>
      {loading ? <div className="w-full min-h-[600px]"><SkeletonGroupView /></div> :
        <>
          {(!loading && list?.length > 0) &&
            <div className="flex flex-col justify-between min-h-[600px]">
              <div className="mt-[12px] flex flex-col w-full">
                {Array.isArray(list) && list.map((item: any, index: number) => (
                  <div key={index} className="overflow-auto">
                    <div className={`rounded-[8px] border border-[#cbd5e11a] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.08)] flex items-center justify-between h-[55px] hover:bg-[#F3F8FF] w-full mb-[20px] cursor-pointer ${item?.flag ? "bg-[#F3F8FF]" : "bg-white"}`} onClick={() => handleSelect(item)}>
                      <div className="flex items-center gap-[12px] px-[20px]">
                        <div className="flex items-center gap-[10px]">
                          <GroupDownArrow className={`${item?.flag ? "fill-primaryLight" : "fill-lGray"}`} />

                          <h3 className={`font-medium text-base ${item?.flag ? "text-primaryLight" : "text-lGray"} break-all`}>{groupByOption === "Issue Date" ? Moment(item?.value).format('DD MMM YYYY') : item?.value}</h3>

                          <div className={`font-semibold text-xs w-[30px] h-[20px] flex items-center justify-center bg-[rgba(253,246,214,1)] rounded-[5px] ${item?.flag ? "text-primaryLight" : "text-lGray"}`}>{item?.count}</div>
                        </div>

                      </div>

                      {/* <AddMoreTag className={`w-[21px] h-[21px] mr-[20px] cursor-pointer ${item?.flag ? "!stroke-primaryLight" : "!stroke-lGray"}`} /> */}

                    </div>

                    {item?.flag &&
                      <div className="w-full mb-[20px] mt-[-20px] flex flex-col">
                        <div className="overflow-x-scroll overflow-y-hidden">
                          <table className="table-auto w-full skeleton-table">
                            <thead>
                              <tr className="h-[46px]">
                                <th className="h-full w-[53px]">
                                  <div className='flex items-center justify-center w-full px-[17px]' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                    <Checkbox className="tests table-check" name="checkAll" checked={item?.allFlag} onChange={(e: any) => handleChange(e, item?.id)}></Checkbox>
                                  </div>
                                </th>
                                {getHeader()}
                                {(option?.name === "Pending" && userDetail?.role === "Lab Head") && <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer"></th>}
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(item?.data) && item?.data.map((i: any, idx: number) => (
                                <tr className={`!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] ${(((idx + 1) % 2) === 0) ? "bg-[#F9F9F9]" : "bg-white"}`} key={idx}>
                                  <td className="h-full w-[53px] px-[17px]" onMouseEnter={() => setOneCheck(i?.id)} onMouseLeave={() => setOneCheck("")}>
                                    <div className="flex items-center justify-center">
                                      {(allCheckBox || item?.allFlag || oneCheck === i?.id || i?.flag) ?
                                        <Checkbox className="tests table-check" checked={i?.flag} onChange={(e: any) => handleChange(e, item?.id)} name={i?.id}></Checkbox>
                                        :
                                        <>
                                          {!!i?.customer_details?.address?.org_name ?
                                            <div style={{ backgroundColor: `${randomColor[i?.customer_details?.address?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{i?.customer_details?.address?.org_name?.charAt(0)}</div>
                                            : <div style={{ backgroundColor: `${randomColor[i?.customer_details?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{i?.customer_details?.first_name?.charAt(0)}{i?.customer_details?.last_name?.charAt(0)}</div>}
                                        </>
                                      }
                                    </div>
                                  </td>

                                  {Array.isArray(defaultField) && defaultField.map((d: any, idx: number) => (
                                    <React.Fragment key={idx}>
                                      <td className="px-[8px] 2xl:px-[16px] h-full relative py-[15px]">
                                        {d === "first_name" ?
                                          <div className="flex flex-col">
                                            <h3 className="text-dGray font-normal !text-sm text-left">{i?.customer_details?.first_name} {" "} {i?.customer_details?.last_name}</h3>
                                            {!!i?.customer_details?.address?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{i?.customer_details?.address?.org_name}</span></p>}
                                          </div>
                                          : fieldType[d] === "date" ?
                                            <h3 className="text-mGray font-normal !text-sm text-left whitespace-nowrap">{!!i[d] ? Moment(i[d]).format('DD MMM YYYY') : "-"}</h3>
                                            :
                                            d === "approval_status" ?
                                              <div className='flex items-start justify-start gap-[8px]'>
                                                <div className={`${i?.approval_status === "Approved" ? "bg-[#D7FFE2] text-[#208F3F]" : i?.approval_status === "Pending" ? "text-primaryLight bg-[#D7E8FF]" : "text-[#8F2020] bg-[#FFD7D7]"} rounded-[25px] h-[25px] flex items-center justify-center text-xs font-normal px-[12px]`}>{i?.approval_status}</div>
                                              </div> :
                                              <div>
                                                {!!i[d] ?
                                                  <h3 className="text-mGray font-normal !text-sm text-left min-w-[200px]">{i[d]}</h3>
                                                  :
                                                  <>
                                                    {i?.custom_fields_values?.length > 0 ? <>
                                                      {Array.isArray(i?.custom_fields_values) && i?.custom_fields_values.map((c: any, cidx: number) => (
                                                        <React.Fragment key={cidx}>
                                                          {c?.slug === i && <h3 key={cidx} className="text-mGray font-normal !text-sm text-left">{!!c?.value ? c?.value : "-"}</h3>}
                                                        </React.Fragment>
                                                      ))}
                                                    </> : <h3 className="text-mGray font-normal !text-sm text-left">-</h3>}
                                                  </>
                                                }
                                              </div>
                                        }
                                      </td>
                                    </React.Fragment>
                                  ))}
                                  {(option?.name === "Pending" && userDetail?.role === "Lab Head") && <td className="px-[8px] 2xl:px-[16px] h-full py-[15px]">
                                    <div className="flex items-center justify-center gap-[10px]">
                                      <Menu align="end" menuButton={<MenuButton>
                                        <div className="cursor-pointer flex items-center justify-center pt-[4px]">
                                          <DataDotsIcon />
                                        </div>
                                      </MenuButton>}>
                                        <MenuItem onClick={() => handleSubmit(i, true)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray group-hover:fill-primaryLight fill-mGray'><Approve /> Approve</div></div></MenuItem>
                                        <MenuItem onClick={() => handleSubmit(i, false)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><Reject /> Reject</div></div></MenuItem>
                                      </Menu>
                                    </div>
                                  </td>}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {!!item?.next && <div className="flex items-center justify-end w-full">
                          <PrimaryDarkLightBtn onClick={() => dispatch(tableActions.setViewAll(true))} rightIcon={<RegisterArrow className="fill-primaryLight dark:fill-white" />} padding="px-[20px]" height="h-[30px]" title={t("customer.dashboard.title.view") as any} />
                        </div>}
                      </div>
                    }
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-end">
                <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
              </div>
            </div>
          }
        </>}
      {(groupByData?.length === 0 && !loading) && <div className="flex items-center justify-center min-h-[600px]">
        <img src={require("../../../../assets/images/no-data-found.jpg")} alt="" className="w-[350px] h-[350px]" />
      </div>}
    </>
  );
};

export default GroupView;