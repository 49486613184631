import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    isLogin: boolean,
    user: any,
    roles: any[],
    permissionRole: any[]
}

const initialState: AuthState = {
    isLogin: !!JSON.parse(localStorage.getItem("sessionTimeout") as any) ? true : false,
    user: {},
    roles: [],
    permissionRole: []
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.isLogin = true;
        },
        logout: (state) => {
            state.isLogin = false;
            state.permissionRole = [];
            localStorage.clear();
        },
        userDetail: (state, action) => {
            state.user = action.payload;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        setPermissionRoles: (state, action) => {
            state.permissionRole = action.payload;
        }
    }
});

export default authSlice;
export const authActions = authSlice.actions;