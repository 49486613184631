import { InputText } from 'primereact/inputtext';
import { SearchPrimary } from '../svg/AllSvg'

const Search = ({ onChange, placeholder, value, width }: any) => {
    return (
        <span className={`p-input-icon-right ${width ? width : "w-full xl:!w-[350px]"}`}>
            <InputText className="!shadow-none placeholder:!text-mGray !w-full !py-[9px] !rounded-[10px] !border-[#ECECEC] !text-sm placeholder:!text-sm focus:!shadow-none focus:!border-[#ECECEC] !bg-[#F3F3F3]" placeholder={placeholder} onChange={onChange} value={value} />
            <div className='rounded-[8px] border border-[#E8E8E8] bg-white min-w-[35px] h-[30px] flex items-center justify-center shadow-[0px_4px_6px_-4px_rgba(24,39,75,0.12),0px_8px_8px_-4px_rgba(24,39,75,0.08)] absolute right-[5px] top-[5px]'>
                <SearchPrimary />
            </div>
        </span>
    );
};

export default Search;