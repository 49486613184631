import { InputText } from "primereact/inputtext";
import { CloseBtn, ModalSearchIcon, RoundCloseIcon } from "../../svg/AllSvg";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

const Search: React.FC<any> = ({ isSearch, setIsSearch }: any) => {
    const [search, setSearch] = useState<string>("");
    const { t } = useTranslation('common');

    useEffect(() => {
        if (isSearch) {
            let inputSearch: any = document.getElementById("search");
            inputSearch.focus();
        }
    }, [isSearch]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            setIsSearch(false)
        }
    };

    return (
        <>
            {isSearch && <div className="fixed inset-0 z-20 overflow-y-hidden top-0 ">
                <div
                    className="fixed inset-0 w-full h-full"
                ></div>
                <div className="flex justify-center items-center min-h-screen">
                    <div className="relative md:mx-auto p-[16px] sm:p-[20px] xl:p-[24px] bg-[#daf4ffb5] border border-[#B6B6B6] rounded-[10px] backdrop-blur-[33.5px] flex flex-col gap-[15px] sm:gap-[25px] xl:gap-[30px] w-[320px] h-[500px] sm:h-auto overflow-y-auto sm:overflow-y-hidden ss:w-[450px] sm:w-[580px] md:w-auto">
                        <div className="cursor-pointer w-full flex item-end justify-end absolute right-[16px] top-[16px]" onClick={() => setIsSearch(false)}>
                            <CloseBtn />
                        </div>
                        {/* Search bar */}
                        <span className="p-input-icon-right !w-full mt-[20px]">
                            <ModalSearchIcon />
                            <InputText id="search" value={search} onChange={(e: any) => setSearch(e.target.value)} placeholder="Search is Disabled on DEMO server" className="!text-[#3F3F3F] !text-sm !h-[40px] sm:!h-[44px] !border-[#F2F7FF] !font-normal focus:!border-none focus:!shadow-none hover:!border-[#F2F7FF] !rounded-[5px] !bg-white !w-full" />
                        </span>

                        {/* Menu */}
                        <div className="flex flex-col sm:flex-row items-center gap-[12px] sm:justify-center sm:gap-[8px] md:gap-[20px] w-full">
                            <div className="flex items-center sm:justify-between gap-[12px] sm:gap-[8px] md:gap-[20px] w-full">
                                <div className="rounded-[20px] bg-white py-[8px] px-[20px] sm:py-[10px] sm:px-[28px] uppercase text-sm sm:text-base font-medium text-[#000000]">ALL</div>
                                <div className="rounded-[20px] bg-white py-[8px] px-[20px] sm:py-[10px] sm:px-[28px] uppercase text-sm sm:text-base font-medium text-[#000000]">TEST</div>
                                <div className="rounded-[20px] bg-white py-[8px] px-[20px] sm:py-[10px] sm:px-[28px] uppercase text-sm sm:text-base font-medium text-[#000000]">LABORATORY</div>
                            </div>
                            <div className="flex items-center sm:justify-between gap-[12px] sm:gap-[8px] md:gap-[20px] w-full">
                                <div className="rounded-[20px] bg-white py-[8px] px-[20px] sm:py-[10px] sm:px-[28px] uppercase text-sm sm:text-base font-medium text-[#000000]">SETTINGS</div>
                                <div className="rounded-[20px] bg-white py-[8px] px-[20px] sm:py-[10px] sm:px-[28px] uppercase text-sm sm:text-base font-medium text-[#000000]">DMS</div>
                            </div>
                        </div>

                        {/* Recent Search */}
                        <div className="flex items-end justify-end gap-[12px] w-full">
                            <h3 className="text-[#3F3F3F] text-sm font-medium whitespace-nowrap">{t("search.modal.recent")}</h3>
                            <div className="w-full h-[1px] opacity-[0.2] bg-[#3F3F3F]"></div>
                        </div>

                        <div className="flex items-center justify-between gap-[16px]">
                            <h3 className="text-[#3F3F3F] text-sm sm:text-base font-medium md:max-w-[37rem]">Full Body Check-Up for Whole Family</h3>
                            <div className="cursor-pointer">
                                <RoundCloseIcon />
                            </div>
                        </div>

                        <div className="flex items-center justify-between gap-[16px]">
                            <h3 className="text-[#3F3F3F] text-sm sm:text-base font-medium md:max-w-[37rem]">IS-0710</h3>
                            <div className="cursor-pointer">
                                <RoundCloseIcon />
                            </div>
                        </div>

                        <div className="flex items-center justify-between gap-[16px]">
                            <h3 className="text-[#3F3F3F] text-sm sm:text-base font-medium md:max-w-[37rem]">IS-07453331817</h3>
                            <div className="cursor-pointer">
                                <RoundCloseIcon />
                            </div>
                        </div>

                        <div className="flex items-center justify-between gap-[16px]">
                            <h3 className="text-[#3F3F3F] text-sm sm:text-base font-medium md:max-w-[37rem]">System-1 EIL SPECIFICATION NO B 329-100-02-42-PCS-0001 REV 3 - System -1 (F9 + P6+ F6B + F2. Total DFT 345 microns) EIL SPECIFICATION....</h3>
                            <div className="cursor-pointer">
                                <RoundCloseIcon />
                            </div>
                        </div>


                        {/* Laboratory module */}
                        {/* <div className="flex items-end justify-end gap-[12px] w-full">
                            <h3 className="text-[#3F3F3F] text-sm font-medium whitespace-nowrap">{t("search.modal.consult")}</h3>
                            <div className="w-full h-[1px] opacity-[0.2] bg-[#3F3F3F]"></div>
                        </div> */}

                        {/* <div className="rounded-[5px] bg-[#ffffff94] p-[8px] flex flex-col gap-[8px] max-w-[32rem]">
                            <div className="flex items-center gap-[8px]">
                                <img src={require("../../assets/images/house.png")} alt="" className="sm:w-[60px] sm:h-[60px]" />
                                <div className="flex flex-col gap-[8px] !w-full">
                                    <div className="flex items-center gap-[8px]">
                                        <h3 className="text-primary font-medium text-sm sm:text-base">National Test House (NR)</h3>
                                        <p className="text-dbGray font-medium text-xs sm:text-sm">Lab Code : 8101304</p>
                                    </div>
                                    <p className="text-dbGray font-medium text-xs sm:text-sm">Kamla Nehru Nagar, Ghaziabad, Ghaziabad, Uttar Pradesh, India - 201002</p>
                                </div>
                            </div>
                            <div className="!flex !items-end !justify-end !w-full">
                                <Button label={t("search.modal.btn") as any} className="!text-primary !rounded-[3px] !border-opacity-[0.1] !p-0 !w-[80px] !h-[30px] !bg-inherit !border-primary focus:!border-none focus:!shadow-none !tracking-wide btn" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>}
        </>
    );
};

export default Search;