import { Skeleton } from "primereact/skeleton";

const Lab: React.FC<any> = () => {
    const cols = [];
    for (let i = 0; i < 2; i++) {
        cols.push(
            <div className='flex flex-col gap-[15px]' key={i}>
                <div className="border border-[#CBD5E1] bg-white rounded-[10px] py-[11px] px-[8px]">
                    <div className="flex items-start gap-[14px] pb-[12px] w-full">
                        <Skeleton className="mr-[8px] !w-16 !h-16"></Skeleton>

                        <div className='flex flex-col gap-[5px] w-[80%]'>
                            <Skeleton width="8rem" height=".5rem"></Skeleton>
                            <Skeleton width="5rem" height=".5rem"></Skeleton>
                            <Skeleton className="w-full" height=".5rem"></Skeleton>
                            <Skeleton width="8rem" height=".5rem"></Skeleton>

                            <Skeleton className='!w-[100px] !h-[20px]' borderRadius='100px'></Skeleton>
                        </div>

                        <Skeleton shape="circle" className="mr-[8px] !w-[1.5rem] !h-[1.2rem] xxl:!h-[1.5rem]"></Skeleton>
                    </div>

                    <div className="flex items-center gap-[8px] w-full justify-end rounded-b-[10px]">
                        <Skeleton className='!w-[60px] !h-[25px]'></Skeleton>
                        <Skeleton className='!w-[60px] !h-[25px]'></Skeleton>
                        <Skeleton className='!w-[60px] !h-[25px]'></Skeleton>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {cols}
        </>
    );
};

export default Lab;