import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const DMS = React.lazy(() => import("../../pages/dms/dashboard/DMS"));
const DMSDetail = React.lazy(() => import("../../pages/dms/dmsDetail/DMSDetail"));

const DMSRoutes: React.FC<any> = () => {
    return (
        <Routes>
            <Route path="/" element={<DMS />} />
            <Route path="/:tab" element={<DMS />} />
            <Route path="/:tab/:id" element={<DMS />} />
            <Route path="/details/:tab/:id" element={<DMSDetail />} />
            <Route path="*" element={<Navigate to="/documents" />} />
        </Routes>
    );
};

export default DMSRoutes;