/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ScreenRightArrow } from "../../components/svg/AllSvg";
import { useEffect, useState } from "react";
import Task from "../../components/layout/Common/Task";
import Note from "../../components/layout/Common/Note";
import Chat from "../../components/layout/Common/Chat";
import Header from "../../components/layout/Common/Header";
import { adminActions } from "../../shared/store/admin-Slice";
import { uiActions } from "../../shared/store/ui-Slice";
import Search from "../../components/layout/Common/Search";
import { useSortcut } from "../../shared/hooks/use-sortcut";
import ActivitySidebar from "../../components/layout/Common/ActivitySidebar";
import MiniSidebar from "../../components/layout/Common/MiniSidebar";

const DMSLayout: React.FC<any> = ({ path }) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const sideBarOption: string = useSelector((state: any) => state.ui.sideBarOption);
    const chatUsersList: any[] = useSelector((state: any) => state.ui.chatUsersList);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const location = useLocation();

    const handleOpen = (): void => {
        setIsSearch(true);
    };

    useSortcut(["Control", "s"], handleOpen);

    useEffect(() => {
        dispatch(uiActions.setSettingPath(path))
        dispatch(uiActions.setModuleName("documents"))
    }, []);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        }
    }, [isOpen, sideBarOption]);

    useEffect(() => {
        if (location.pathname !== "/documents") {
            dispatch(adminActions.setChatUser(chatUsersList[0]));
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        if (isSearch) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isSearch]);

    useEffect(() => {
        if (window.innerWidth <= 1200 && isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [window.innerWidth, isOpen])

    return (
        <div className="w-full h-full relative">
            {/* Backdrop */}
            <div>
                <span
                    id="menu-remove"
                    className={`fixed top-0 left-0 z-30 ${isOpen ? "block" : "hidden"
                        } h-screen w-full bg-[#00000080] xlx:hidden`}
                    onClick={() => dispatch(uiActions.toggle())}
                ></span>
            </div>

            {/* Close Section Icon */}
            <div className={`bg-white w-[24px] h-[24px] shadow-md flex items-center justify-center rounded-full fixed cursor-pointer z-[5] right-[38px] ${scrollY ? "top-[101px]" : "top-[96px]"}`} onClick={() => { !isOpen && setIsLoading(true); dispatch(uiActions.toggle()); }}>
                <ScreenRightArrow color="#20328F" />
            </div>

            {/* Header */}
            {!scrollY && <Header setIsSearch={setIsSearch} />}

            <div className={`${scrollY ? "pt-[75px]" : "pt-[70px]"} w-full min-h-screen bg-[#F8FAFB] flex`}>
                {/* Sidebar */}
                <MiniSidebar />

                <div className={`!h-full w-full flex px-[50px] ${isOpen ? "w-full xlx:w-[calc(100%-300px)]" : "w-full"} transition-all`}>
                    <Outlet />

                    <Search isSearch={isSearch} setIsSearch={setIsSearch} />

                    <div className={`${isOpen ? "w-full sm:w-[300px] fixed right-0 bottom-0 z-30 xlx:z-[2] xlx:right-[50px] bg-white" : "translate-x-full w-0"} overflow-y-auto overflow-x-hidden transition duration-150 ${scrollY ? "top-0 xlx:top-[75px]" : "top-0 xlx:top-[70px]"}`}>
                        {/* Activity */}
                        {isLoading ? <div className="w-full h-full flex items-center justify-center">
                            <div className="circle circle-1"></div>
                            <div className="circle circle-2"></div>
                            <div className="circle circle-3"></div>
                            <div className="circle circle-4"></div>
                            <div className="circle circle-5"></div>
                        </div> :
                            <>
                                {(!isLoading && sideBarOption === "Todo") && <Task />}
                                {(!isLoading && sideBarOption === "Note") && <Note />}
                                {(!isLoading && sideBarOption === "Chat") && <Chat />}
                            </>
                        }
                    </div>
                </div>

                {/* Activity right Navbar */}
                <ActivitySidebar setIsLoading={setIsLoading} />
            </div>
        </div>
    );
};

export default DMSLayout;