import { useTranslation } from "react-i18next";
import { Alert, AlertClose } from "../../svg/AllSvg";
import { useEffect, useState } from "react";

const Notification: React.FC<any> = () => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const { t } = useTranslation('common');

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(false);
        }, 3000)
    }, []);

    return (
        <div className={`${isOpen ? "flex" : "hidden"} w-[calc(100%-70px)] items-center justify-center px-[32px] fixed bottom-[32px] z-[35] rounded-lg`}>

            <div className="shadow-lg flex h-full w-fit rounded-lg">
                <div className="bg-[#FFDCE4] rounded-l-lg flex items-center justify-center px-[16px] py-[24px]">
                    <Alert />
                </div>

                <div className="flex flex-col items-start gap-[8px] justify-center px-[16px] py-[24px] bg-white rounded-r-lg">
                    <div className="flex gap-[16px]">
                        <p className="text-mGray text-sm font-medium">{t("customer.notification.desc") as any}</p>
                        <div className="cursor-pointer" onClick={() => setIsOpen(false)}>
                            <AlertClose />
                        </div>
                    </div>
                    <h3 className="text-sm text-lGray font-normal">
                        <span className="text-sm text-[#FF507A] font-normal">{t("customer.notification.click") as any}</span> {t("customer.notification.click_desc") as any}
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default Notification;