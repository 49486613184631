/* eslint-disable no-unreachable */
import { useDispatch } from "react-redux";
import { ProfileDot, ProfileLogout, ProfileSetting } from "../../svg/AllSvg";
import { authActions } from "../../../shared/store/auth-Slice";
import { useState } from "react";
import { useSelector } from "react-redux";
import { randomColor } from "../../../shared/utils/randomColor";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../shared/hooks/use-axios";
import { tableActions } from "../../../shared/store/table-Slice";
import { uiActions } from "../../../shared/store/ui-Slice";


const ProfilePopup: React.FC<any> = ({ page }: any) => {
    const settingPath: string = useSelector((state: any) => state.ui.settingPath);
    const { t } = useTranslation('common');

    let option: any[] = [
        // { id: 1, title: t("header.profile.edit_profile") as any, icon: <ProfileUser /> },
        { id: 2, title: t("header.profile.settings") as any, icon: <ProfileSetting /> },
        // { id: 3, title: t("header.profile.support") as any, icon: <ProfileInfo /> },
        { id: 4, title: t("header.profile.log_out") as any, icon: <ProfileLogout width="16" height="16" color={page === "labSample" ? "#F7423A" : page === "invoice" ? "#14B370" : page === "crm" ? "#567CF0" : page === "admin" ? "#00A6B1" : "#FF7E4D"} /> }
    ];

    const user: any = useSelector((state: any) => state.auth.user);
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [title, setTitle] = useState<string>("");
    const navigate = useNavigate();

    const handleLogoutApi = async () => {
        try {
            const res: any = await useAxios.post("accounts/logout/");
            if (res && res?.status === 200) {
                return true;
            }
        } catch (error) {
            return false;
            console.error("error while admin logout", error);
        }
    }

    const handleKey = async (e: any, item: any) => {
        if (e.keyCode === 38) {
            if (selectedOption > 0) {
                setSelectedOption(selectedOption - 1);
                setTitle(option[selectedOption - 1]?.title)
            } else {
                setSelectedOption(0) 
                setTitle(option[0]?.title)
            }
        }
        if (e.keyCode === 40) {
            if (selectedOption < option.length - 1) {
                setSelectedOption(selectedOption + 1);
                setTitle(option[selectedOption + 1]?.title)
            } else {
                setSelectedOption(0) 
                setTitle(option[0]?.title)
            }
        }
        if (e.key === "Enter") {
            if (title === t("header.profile.log_out")) {
                const success: any = await handleLogoutApi();
                if (success) {
                    dispatch(tableActions.setClear());
                    dispatch(uiActions.setModule(""));
                    dispatch(uiActions.setSubModule(""));
                    dispatch(uiActions.setModuleName(""))
                    dispatch(authActions.logout());
                }
            }
            if (title === t("header.profile.settings")) {
                navigate(`${settingPath}/setting`)
            }
        }
    }

    const handleLogout = async (item: any) => {
        if (item?.title === t("header.profile.log_out")) {
            const success: any = await handleLogoutApi();
            if (success) {
                dispatch(tableActions.setClear());
                dispatch(uiActions.setModule(""));
                dispatch(authActions.logout());
            }
        }
        if (item?.title === t("header.profile.settings")) {
            navigate(`${settingPath}/setting`)
        }
    }


    return (
        <div className='absolute top-[44px] right-0 rounded-[10px] bg-white shadow-[0px_10px_32px_-4px_rgba(24,39,75,0.10),0px_6px_14px_-6px_rgba(24,39,75,0.12)] w-[190px] py-[16px] px-[12px] z-[32]' id="popup" tabIndex={-1} onKeyDown={(e: any) => handleKey(e, title)}>
            <div className='flex flex-col gap-[10px]'>
                <div className='flex items-center gap-[10px] pb-[8px]'>
                    {/* <img src={require("../../../assets/images/avatar10.png")} alt="" /> */}
                    <div style={{ backgroundColor: `${randomColor[user?.username?.charAt(0)?.toLowerCase()]}` }} className="w-[45px] h-[45px] rounded-full uppercase text-white font-bold text-lg flex items-center justify-center">{user?.username?.charAt(0)}{user?.lastName?.charAt(0)}</div>
                    <p className="text-primaryLight text-sm font-medium">{user?.username}</p>
                </div>

                {Array.isArray(option) && option.map((item: any, index: number) => (
                    <div className='flex flex-col gap-[10px]' key={index}>
                        <div className={`${(selectedOption === index) && "bg-[#F4F4F4]"} flex items-center gap-[8px] cursor-pointer hover:bg-[#F4F4F4] rounded-sm py-[5px] px-[4px]`} onClick={() => handleLogout(item)} tabIndex={0}>
                            {item?.icon}
                            <p className={`${(item?.title === t("header.profile.log_out")) ? `${page === "labSample" ? "text-primaryDark" : page === "invoice" ? "text-primaryGreen" : page === "crm" ? "text-primaryBlue" : page === "admin" ? "text-primaryLightBlue" : "text-orange"}` : "text-mGray"} text-xs font-normal`}>{item?.title}</p>
                        </div>
                        {item?.title === "Support" && <div className='border-b border-[rgba(0,0,0,0.10]'></div>}
                    </div>
                ))}

                <p className='flex items-center justify-center text-[0.625rem] font-medium text-[rgba(0,0,0,0.30)]'> {t("header.profile.privacy") as any} <span className='px-[4px]'><ProfileDot /></span> {t("header.profile.terms") as any}</p>
            </div>
        </div>
    );
};

export default ProfilePopup;