import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const GridView: React.FC<any> = ({ group }: any) => {
    const [loadingData, setLoadingData] = useState<any[]>([1, 2, 3, 4, 5]);
    const defaultField: any = useSelector((state: any) => state.employee.defaultField);
    const params = useParams();

    useEffect(() => {
        defaultField.length > 0 && setLoadingData(defaultField);
    }, [defaultField]);

    const cols = [];
    for (let i = 0; i < (!!group && group ? 3 : 10); i++) {
        cols.push(
            <tr className="!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[50px]" key={i}>
                {!!!params?.id && <td className="px-[12px] h-full !text-center">
                    <Skeleton size="1.3rem"></Skeleton>
                </td>}
                {!!params?.id ? <td className="px-[8px] 2xl:px-[16px] h-full">
                    <div className='flex items-center gap-[8px] w-full'>
                        <Skeleton shape="circle" size="2rem" className="mr-[8px]"></Skeleton>
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </div>
                </td> :
                    <>
                        {Array.isArray(loadingData) && loadingData.map((item: any, index: number) => (
                            <td className="px-[8px] 2xl:px-[16px] h-full" key={index}>
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </td>
                        ))}
                        <td className="px-[12px] h-full"><Skeleton size="1.4rem"></Skeleton></td>
                    </>
                }
            </tr>
        )
    };

    return (
        <table className="table-auto overflow-x-scroll w-full skeleton-table">
            <tbody>
                {cols}
            </tbody>
        </table>
    );
};

export default GridView;