/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomerAuth from '../subroutes/CustomerAuth';
import SettingRoutes from '../subroutes/SettingRoutes';
import { permission } from '../../shared/utils/permission';
import CustomerLayout from './CustomerLayout';
import CustomerRoutes from '../subroutes/CustomerRoutes';
import UploadData from '../../pages/demo/UploadData';
import DMSLayout from './DMSLayout';
import DMSRoutes from '../subroutes/DMSRoutes';
import InventoryLayout from './InventoryLayout';
import InventoryRoutes from '../subroutes/InventoryRoutes';

const LandingPage = React.lazy(() => import("../../pages/landingPage/LandingPage"));

const Main: React.FC<any> = ({ loader }) => {
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    let userDetail: any = JSON.parse(localStorage.getItem('userdetail') as any);
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);

    return (
        <Routes>
            {!isLogin ?
                <>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/customer/auth/*" element={<CustomerAuth />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </> :
                <>
                    {!loader && <>
                        {userDetail?.role === "Customer" ? <>
                            <Route element={window.location.pathname?.includes("documents") ? <DMSLayout path="/documents" /> : window.location.pathname?.includes("inventory") ? <InventoryLayout path="/inventory" /> : <CustomerLayout path="/customer" />}>
                                <Route path="/customer/*" element={<CustomerRoutes />} />
                                <Route path="/documents/*" element={<DMSRoutes />} />
                                <Route path="/inventory/*" element={<InventoryRoutes />} />
                                <Route path="/customer/setting/*" element={<SettingRoutes path="/customer/setting" />} />
                                <Route path="/documents/setting/*" element={<SettingRoutes path="/documents/setting" />} />
                                <Route path="/inventory/setting/*" element={<SettingRoutes path="/inventory/setting" />} />
                            </Route>
                            <Route path="*" element={<Navigate to="/customer/dashboard" />} />
                        </>
                            :
                            <>
                                {Array.isArray(permission) && permission.map((item: any, index: number) => (
                                    <React.Fragment key={index}>
                                        <Route element={window.location.pathname?.includes("documents") ? <DMSLayout path="/documents" /> : window.location.pathname?.includes("inventory") ? <InventoryLayout path="/inventory" /> : item?.layout}>
                                            {!!item?.routes && <Route path={item?.path} element={item?.routes} />}
                                            {permissionRole?.includes(item?.role) && <>
                                                {item?.test_create && <Route path={item?.test_path} element={item?.test_routes} />}
                                            </>}
                                            {(permissionRole?.includes(item?.role) || userDetail?.role === "SuperAdmin") &&
                                                <Route path={item?.setting_path} element={item?.setting_routes} />
                                            }
                                            <Route path="/documents/*" element={<DMSRoutes />} />
                                            <Route path="/inventory/*" element={<InventoryRoutes />} />
                                            <Route path="/documents/setting/*" element={<SettingRoutes path="/documents/setting" />} />
                                            <Route path="/inventory/setting/*" element={<SettingRoutes path="/inventory/setting" />} />
                                        </Route>
                                        {userDetail?.role === item?.role &&
                                            <Route path="*" element={<Navigate to={item?.navigate} />} />
                                        }
                                    </React.Fragment>
                                ))}
                            </>}
                    </>}
                </>}
            <Route path="/upload" element={<UploadData />} />
        </Routes >
    );
};

export default Main;