import { useDispatch } from "react-redux";
import { testActions } from "../../../shared/store/test-Slice";
import { SampleTestClose, SuccessTest } from "../../svg/AllSvg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SuccessTestReq = () => {
    const isSuccess: boolean = useSelector((state: any) => state.test.isSuccess);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(testActions.setIsSuccess(false))
        }
    };

    return (
        <>
            {isSuccess &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] sm:w-[600px] md:w-[580px] h-auto">
                            <div className="flex items-start justify-end p-[12px] rounded-t-[10px]">
                                <div className="cursor-pointer" onClick={() =>  dispatch(testActions.setIsSuccess(false))}>
                                    <SampleTestClose />
                                </div>
                            </div>

                            <div className="w-full h-full flex flex-col items-center justify-start pb-[47px]">
                                <SuccessTest color="#F5B911" />

                                <h3 className="text-primaryLight text-lg font-semibold pt-[15px]">{t("customer.success.title")}</h3>
                                <p className="text-mGray font-normal text-base max-w-[455px] pt-[5px] text-center">{t("customer.success.desc")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SuccessTestReq;