/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ScreenRightArrow } from "../../components/svg/AllSvg";
import { useEffect, useState } from "react";
import { crmActions } from "../../shared/store/crm-Slice";
import Task from "../../components/layout/Common/Task";
import Note from "../../components/layout/Common/Note";
import Chat from "../../components/layout/Common/Chat";
import ActivitySidebar from "../../components/layout/Common/ActivitySidebar";
import Header from "../../components/layout/Common/Header";
import AddCustomer from "../../components/crm/dashboard/AddCustomer";
import AssignGroup from "../../components/crm/dashboard/AssignGroup";
import SuccessPopup from "../../components/crm/dashboard/SuccessPopup";
import { useSortcut } from "../../shared/hooks/use-sortcut";
import { uiActions } from "../../shared/store/ui-Slice";
import CreditLimitPopup from "../../components/crm/dashboard/CreditLimitPopup";
import AddTestPopup from "../../components/customer/bookTest/AddTestPopup";
import Search from "../../components/layout/Common/Search";
import { groupByApi, listApi, tabApi } from "../../shared/utils/apis";
import { tableActions } from "../../shared/store/table-Slice";
import ViewAllList from "../../components/form/ViewAllList";
import CRMGridView from "../../components/crm/dashboard/GridView";
import CreditPolicyGridView from "../../components/invoice/creditPolicy/dashboard/GridView";
import CRMTableView from "../../components/crm/dashboard/TableView";
import CreditPolicyTableView from "../../components/invoice/creditPolicy/dashboard/TableView";
import MiniSidebar from "../../components/layout/Common/MiniSidebar";

const CRMLoyout: React.FC<any> = ({ role, path }) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);
    const successCreateGroup: boolean = useSelector((state: any) => state.crm.successCreateGroup);
    const sideBarOption: string = useSelector((state: any) => state.ui.sideBarOption);
    const chatUsersList: any[] = useSelector((state: any) => state.ui.chatUsersList);
    const isCustomerCreate: boolean = useSelector((state: any) => state.crm.isCustomerCreate);
    const createGroup: boolean = useSelector((state: any) => state.crm.createGroup);
    const creditLimitOpen: boolean = useSelector((state: any) => state.crm.creditLimitOpen);
    const standardPopup: boolean = useSelector((state: any) => state.test.standardPopup);
    const addTestOpen: boolean = useSelector((state: any) => state.test.addTestOpen);
    const tagApiCall: boolean = useSelector((state: any) => state.table.tagApiCall);
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const filterResult: string = useSelector((state: any) => state.table.filterResult);
    const page: number = useSelector((state: any) => state.table.page);
    const size: number = useSelector((state: any) => state.table.size);
    const groupByOptionValue: string = useSelector((state: any) => state.table.groupByOptionValue);
    const viewAll: boolean = useSelector((state: any) => state.table.viewAll);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const childPage: number = useSelector((state: any) => state.table.childPage);
    const groupByExpand: any = useSelector((state: any) => state.table.groupByExpand);
    const success: boolean = useSelector((state: any) => state.table.success);
    const module: string = useSelector((state: any) => state.ui.module);
    const selectOption: boolean = useSelector((state: any) => state.table.selectOption);
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const dispatch = useDispatch();
    const location = useLocation();
    const [roleName, setRoleName] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleOpen = (): void => {
        setIsSearch(true);
    };

    useSortcut(["Control", "s"], handleOpen);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        }
    }, [isOpen, sideBarOption]);

    useEffect(() => {
        if (location.pathname !== "/crm") {
            dispatch(crmActions.setChatUser(chatUsersList[0]));
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        !!module && getTagList();
    }, [module])

    const getTagList = async () => {
        try {
            let url: any;
            if (module === "crm") {
                url = "customers/api/customers-ui-tab-config/";
                option?.module !== "crm" && dispatch(tableActions.setClear());
            }
            if (module === "credit-policy") {
                url = "customers/api/credit-policy-tab-config-list/";
                option?.module !== "credit-policy" && dispatch(tableActions.setClear());
            }
            if (!!url) {
                const { tabArr, sortBy, groupBby, filterArr, status, fieldType }: any = await tabApi(url);

                if (status === 200) {
                    dispatch(tableActions.setTagList(tabArr));
                    dispatch(tableActions.setSortByList(sortBy));
                    dispatch(tableActions.setGroupByList(groupBby));
                    dispatch(tableActions.setFilterList(filterArr));
                    dispatch(tableActions.setFieldType(fieldType));
                    if (!!module && module === "crm") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'crm' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'crm' }))
                    }
                    if (!!module && module === "credit-policy") {
                        !!!selectOption ? dispatch(tableActions.changeOption({ name: tabArr[0]?.name, module: 'credit-policy' })) : dispatch(tableActions.changeOption({ name: selectOption, module: 'credit-policy' }))
                    }
                }
            }
        } catch (error: any) {
            console.error("error while feching tags", error);
        }
    }

    const getList = async () => {
        try {
            dispatch(tableActions.setLoading(true));
            let url: any;
            if (module === "crm" && option?.module === "crm") {
                viewAll ? url = "customers/api/customers-list-group-by" : url = "customers/api/customers-custom-list";
            }
            if (module === "credit-policy" && option?.module === "credit-policy") {
                viewAll ? url = "customers/api/credit-policy-group-by" : url = "customers/api/custom-credit-policy";
            }
            if (!!url) {
                const {
                    data,
                    count,
                    defaultFields,
                    detailFieldArr,
                    frontFieldList,
                    status
                }: any = await listApi(url, option?.name, sortBy, page, size, filterResult, module, groupByOptionValue, childPage, childSize, groupByExpand, viewAll);

                if (status === 200) {
                    dispatch(tableActions.setLoading(false));
                    dispatch(tableActions.setData(data));
                    viewAll ? dispatch(tableActions.setChildTotal(count)) : dispatch(tableActions.setTotal(count))
                    dispatch(tableActions.setDefaultField(defaultFields));
                    dispatch(tableActions.setDetailField(detailFieldArr));
                    dispatch(tableActions.setFrontField(frontFieldList));
                    dispatch(tableActions.setSuccess(false));
                }
            }
        } catch (error) {
            console.error("error while feching data", error);
        }
    }

    const getListGroupBy = async () => {
        try {
            dispatch(tableActions.setLoading(true));
            let url: any;
            if (module === "crm" && option?.module === "crm") {
                url = "customers/api/customers-list-group-by";
            }
            if (module === "credit-policy" && option?.module === "credit-policy") {
                url = "customers/api/credit-policy-group-by";
            }

            if (!!url) {
                const {
                    data,
                    count,
                    defaultFields,
                    detailFieldArr,
                    frontFieldList,
                    status
                }: any = await groupByApi(url, option?.name, sortBy, page, size, filterResult, groupByOptionValue, module);

                if (status === 200) {
                    dispatch(tableActions.setLoading(false));
                    dispatch(tableActions.setGroupByData(data));
                    dispatch(tableActions.setTotal(count));
                    dispatch(tableActions.setDefaultField(defaultFields));
                    dispatch(tableActions.setDetailField(detailFieldArr));
                    dispatch(tableActions.setFrontField(frontFieldList));
                    dispatch(tableActions.setSuccess(false));
                }
            }
        } catch (error: any) {
            console.error("error while feching data", error);
        }
    }

    useEffect(() => {
        dispatch(uiActions.setSettingPath(path))
        dispatch(uiActions.setModuleName("crm"))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (permissionRole.length > 0) {
            let getRole: any = role?.filter((item: any) => permissionRole?.toString()?.includes(item))
            setRoleName(getRole[0])
        }
    }, [permissionRole])

    useEffect(() => {
        (!!option?.name && (!!!groupByOptionValue || viewAll)) && getList();
        // eslint-disable-next-line
    }, [option, sortBy, page, filterResult, size, viewAll, childSize, childPage, module])

    useEffect(() => {
        (!!option?.name && !!groupByOptionValue) && getListGroupBy();
        // eslint-disable-next-line
    }, [groupByOptionValue, filterResult, sortBy, option, page, size, module])

    useEffect(() => {
        if (success && !!option?.name) {
            getTagList();
            !!!groupByOptionValue && getList();
            !!groupByOptionValue && getListGroupBy();
        }
        // eslint-disable-next-line
    }, [success, option])

    useEffect(() => {
        tagApiCall && getTagList();
        // eslint-disable-next-line
    }, [tagApiCall])

    useEffect(() => {
        if (isCustomerCreate || createGroup || successCreateGroup || creditLimitOpen || addTestOpen || standardPopup || isSearch || viewAll) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isCustomerCreate, createGroup, successCreateGroup, creditLimitOpen, addTestOpen, standardPopup, isSearch, viewAll]);

    useEffect(() => {
        if (window.innerWidth <= 1200 && isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [window.innerWidth, isOpen])

    return (
        <>
            {isCustomerCreate && <AddCustomer />}
            {createGroup && <AssignGroup />}
            {successCreateGroup && <SuccessPopup />}
            {creditLimitOpen && <CreditLimitPopup />}
            {addTestOpen && <AddTestPopup />}
            {viewAll && <ViewAllList grid={module === "crm" ? <CRMGridView type="group" /> : <CreditPolicyGridView type="group" />} table={module === "crm" ? <CRMTableView type="group" /> : <CreditPolicyTableView type="group" />} />}

            <div className="w-full h-full relative overflow-x-hidden">
                {/* Backdrop */}
                <div>
                    <span
                        id="menu-remove"
                        className={`fixed top-0 left-0 z-30 ${isOpen ? "block" : "hidden"
                            } h-screen w-full bg-[#00000080] xlx:hidden`}
                        onClick={() => dispatch(uiActions.toggle())}
                    ></span>
                </div>

                {/* Close Section Icon */}
                {(permissionRole?.includes(roleName)) && <div className={`bg-white w-[24px] h-[24px] shadow-md flex items-center justify-center rounded-full fixed top-[96px] cursor-pointer z-[5] right-[38px]`} onClick={() => { !isOpen && setIsLoading(true); dispatch(uiActions.toggle()); }}>
                    <ScreenRightArrow color="#20328F" />
                </div>}

                {/* Header */}
                <Header setIsSearch={setIsSearch} />

                <div className="w-full min-h-screen bg-[#F8FAFB] flex pt-[70px]">
                    {/* Sidebar */}
                    {(permissionRole?.includes(roleName)) && <MiniSidebar />}

                    <div className={`!h-full ${isOpen ? "w-full xlx:w-[calc(100%-300px)]" : "w-full"} transition-all flex overflow-x-hidden px-[50px]`}>
                        <Outlet />

                        <Search isSearch={isSearch} setIsSearch={setIsSearch} />

                        <div className={`${isOpen ? "w-full sm:w-[300px] fixed right-0 bottom-0 z-30 xlx:z-[2] xlx:right-[50px] bg-white" : "translate-x-full w-0"} overflow-y-auto overflow-x-hidden transition duration-150 ${scrollY ? "top-0 xlx:top-[75px]" : "top-0 xlx:top-[70px]"}`}>
                            {/* Activity */}
                            {isLoading ? <div className="w-full h-full flex items-center justify-center">
                                <div className="circle circle-1"></div>
                                <div className="circle circle-2"></div>
                                <div className="circle circle-3"></div>
                                <div className="circle circle-4"></div>
                                <div className="circle circle-5"></div>
                            </div> :
                                <>
                                    {(!isLoading && sideBarOption === "Todo") && <Task />}
                                    {(!isLoading && sideBarOption === "Note") && <Note />}
                                    {(!isLoading && sideBarOption === "Chat") && <Chat />}
                                </>
                            }
                        </div>
                    </div>

                    {/* Activity right Navbar */}
                    {(permissionRole?.includes(roleName)) && <ActivitySidebar setIsLoading={setIsLoading} />}
                </div>
            </div>
        </>
    );
};

export default CRMLoyout;