import { CustomerClose } from "../../svg/AllSvg";
import { useDispatch } from "react-redux";
import { crmActions } from "../../../shared/store/crm-Slice";
import { useSelector } from "react-redux";
import PrimaryInput from "../../form/PrimaryInput";
import PrimaryTextarea from "../../form/PrimaryTextarea";
import PrimaryLightBtn from "../../form/PrimaryLightBtn";
import { useTranslation } from "react-i18next";
import SingleSelect from "../../form/SingleSelect";

const AddOrg = ({ setFieldValue, setFieldTouched, handleChange, handleBlur, values, customers, orgTypeList }: any) => {
    const dispatch = useDispatch();
    const isOrgOpen: boolean = useSelector((state: any) => state.crm.isOrgOpen);
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(crmActions.setIsOrgOpen(false))
        }
    };

    return (
        <>
            {isOrgOpen && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[80px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[554px] z-[10]">
                        <div className="flex items-center justify-between pl-[27px] pr-[17px] pt-[17px] rounded-t-[20px] bg-white">
                            <h3 className="text-primaryLight font-semibold text-sm">{t("crm.customer.org.title")}</h3>
                            <div className="cursor-pointer z-[10]" onClick={() => dispatch(crmActions.setIsOrgOpen(false))}>
                                <CustomerClose />
                            </div>
                        </div>
                        <div className="flex flex-col px-[27px] py-[21px] z-[10] gap-[20px]">
                            <div className="w-full flex flex-col">
                                <SingleSelect options={customers} name="parent_org" placeholder={t("crm.customer.org.form.placeholder.parent_org")} title={t("crm.customer.org.form.field.parent_org")} onChange={(e: any) => setFieldValue("parent_org", e)} value={values?.parent_org} onBlur={(e: any) => setFieldTouched("parent_org", e)} />
                            </div>

                            <div className="flex items-center gap-x-[11px] w-full">
                                <div className="w-[62%]">
                                    <PrimaryInput type="text" title={t("crm.customer.org.form.field.org_name")} name="org_name" value={values?.org_name} onChange={handleChange} onBlur={handleBlur} />
                                </div>
                                <div className="w-[38%] flex flex-col">
                                    <SingleSelect options={orgTypeList} name="org_type" placeholder={t("crm.customer.org.form.placeholder.org_type")} title={t("crm.customer.org.form.field.org_type")} onChange={(e: any) => setFieldValue("org_type", e)} value={values?.org_type} onBlur={(e: any) => setFieldTouched("org_type", e)} />
                                </div>
                            </div>

                            <PrimaryTextarea title={t("common_form.field.description")} name="description" value={values?.description} onChange={handleChange} onBlur={handleBlur} />

                            {/* <div className="mt-[18px] flex flex-wrap items-center gap-[12px]">
                                <div className="rounded-[10px] border border-[#E9E9E9] bg-[#F9FAFF] h-[29px] flex items-center justify-center gap-[8px] px-[10px]">
                                    <AddPlusTag />
                                    <h3 className="text-dGray text-sm font-normal whitespace-nowrap">{t("crm.customer.org.form.field.pan_no")}</h3>
                                </div>

                                <div className="rounded-[10px] border border-[#E9E9E9] bg-[#F9FAFF] h-[29px] flex items-center justify-center gap-[8px] px-[10px]">
                                    <AddPlusTag />
                                    <h3 className="text-dGray text-sm font-normal whitespace-nowrap">{t("crm.customer.org.form.field.gst_no")}</h3>
                                </div>

                                <div className="rounded-[10px] border border-[#E9E9E9] bg-[#F9FAFF] h-[29px] flex items-center justify-center gap-[8px] px-[10px]">
                                    <AddPlusTag />
                                    <h3 className="text-dGray text-sm font-normal whitespace-nowrap">{t("crm.customer.org.form.field.registration_no")}</h3>
                                </div>
                            </div> */}
                        </div>

                        <div className="flex items-center justify-end w-full pb-[21px] px-[28px]">
                            <PrimaryLightBtn title={t("crm.customer.org.form.btn.save_org")} onClick={() => dispatch(crmActions.setIsOrgOpen(false))} />
                        </div>
                    </div>

                </div>
            </div>
            }
        </>
    );
};

export default AddOrg;