import { BIReport, BookTest, CreditNote, CreditPolicyIcon, DashboardIcon, Documents, ERP, IndentReqIcon, Inventory, InvoiceIconMenu, InvoiceOrderIcon, MyTestIcon, PaymentIconMenu, People, Settings, Tabs } from "../../components/svg/AllSvg";

export const sidebarData = (t: any, settingPath: any) => {
    const data: any = {
        "hrms": {
            "top_menu":
                [
                    { id: 1, name: t("common_side_menu.dashboard"), icon: DashboardIcon, colorType: "fill", url: "/laboratory/dashboard", tag: "dashboard", role: ["Lab Admin"], submenu: [] },
                    { id: 2, name: t("common_side_menu.bi_report"), icon: BIReport, colorType: "fill-stroke", url: "/laboratory/bi-report", tag: "bi-report", role: ["Lab Admin"], submenu: [] },
                    { id: 3, name: t("laboratory.mini_sidebar.employee"), icon: People, colorType: "fill", url: "/laboratory", tag: "employee", role: ["Lab Admin"], submenu: [] },
                    // { id: 4, name: "Campaign", icon: ERP, colorType: "fill", url: "", tag: "campaign", role: ["Lab Admin"], submenu: [] },
                    // { id: 5, name: "Accounts", icon: Accounts, colorType: "fill", url: "", tag: "accounts", role: ["Lab Admin"] }
                ],
            "bottom_menu": [
                { id: 1, name: t("common_side_menu.all_tab"), icon: Tabs, colorType: "fill", url: "", tag: "tabs", role: ["Lab Admin"], submenu: [] },
                { id: 2, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill", url: `${settingPath}/setting`, tag: "setting", role: ["Lab Admin"], submenu: [] }
            ]
        },
        "lims": {
            "top_menu":
                [
                    { id: 1, name: t("common_side_menu.dashboard"), icon: DashboardIcon, colorType: "fill", url: "/lims/samples/dashboard", tag: "dashboard", role: ["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"], submenu: [] },
                    { id: 2, name: t("common_side_menu.bi_report"), icon: BIReport, colorType: "fill-stroke", url: "/lims/samples/bi-report", tag: "bi-report", role: ["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"], submenu: [] },
                    { id: 3, name: t("lab_sample.mini_sidebar.samples"), icon: People, colorType: "fill", url: "/lims/samples", tag: "sample", role: ["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"], submenu: [] },
                    { id: 4, name: t("common_side_menu.tests"), icon: BookTest, colorType: "fill", url: "/lims/test/", tag: "tests", role: ["Lab Coordinator"], submenu: [] },
                ],
            "bottom_menu": [
                { id: 1, name: t("common_side_menu.all_tab"), icon: Tabs, colorType: "fill", url: "", tag: "tabs", role: ["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"], submenu: [] },
                { id: 2, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill", url: `${settingPath}/setting`, tag: "setting", role: ["Lab Coordinator", "Lab Scientist", "Sample Receiver", "LabIncharge", "Remnant"], submenu: [] }
            ]
        },
        "crm": {
            "top_menu":
                [
                    { id: 1, name: t("common_side_menu.dashboard"), icon: DashboardIcon, colorType: "fill", url: "/crm/dashboard", tag: "dashboard", role: ["CRM"], submenu: [] },
                    { id: 2, name: t("common_side_menu.bi_report"), icon: BIReport, colorType: "fill-stroke", url: "/crm/bi-report", tag: "bi-report", role: ["CRM"], submenu: [] },
                    { id: 3, name: t("common_side_menu.customer"), icon: People, colorType: "fill", url: "/crm", tag: "crm", role: ["CRM"], submenu: [] },
                    { id: 4, name: t("common_side_menu.credit_policy"), icon: CreditPolicyIcon, colorType: "fill", url: "/crm/credit-policy", tag: "credit-policy", role: ["CRM"], submenu: [] },
                    { id: 5, name: t("common_side_menu.tests"), icon: BookTest, colorType: "fill", url: "/crm/test/", tag: "tests", role: ["Lab Coordinator"], submenu: [] },
                    { id: 6, name: t("common_side_menu.campaigns"), icon: ERP, colorType: "fill", url: "/crm/campaigns", tag: "campaign", role: ["CRM"], submenu: [] },
                ],
            "bottom_menu": [
                { id: 1, name: t("common_side_menu.all_tab"), icon: Tabs, colorType: "fill", url: "", tag: "tabs", role: ["CRM"], submenu: [] },
                { id: 2, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill", url: `${settingPath}/setting`, tag: "setting", role: ["CRM"], submenu: [] }
            ]
        },
        "invoice": {
            "top_menu":
                [
                    { id: 1, name: t("common_side_menu.dashboard"), icon: DashboardIcon, colorType: "fill", url: "/invoice/dashboard", tag: "dashboard", role: ["Account Officer", "Lab Head"], submenu: [] },
                    { id: 2, name: t("common_side_menu.bi_report"), icon: BIReport, colorType: "fill-stroke", url: "/invoice/bi-report", tag: "bi-report", role: ["Account Officer", "Lab Head"], submenu: [] },
                    { id: 3, name: "Invoice", icon: InvoiceIconMenu, colorType: "fill", url: "/invoice/proforma-invoice", tag: "invoice", role: ["Account Officer"], submenu: [{ id: 1, name: "Proforma Invoice", url: "/invoice/proforma-invoice", tag: "proforma-invoice" }, { id: 2, name: "Tax Invoice", url: "/invoice/tax-invoice", tag: "tax-invoice" }, { id: 3, name: "Bill of Supply", url: "/invoice/bill-of-supply", tag: "bill-of-supply" }] },
                    { id: 4, name: "Customer", icon: People, colorType: "fill", url: "/invoice/customer", tag: "customer", role: ["Account Officer"], submenu: [] },
                    { id: 5, name: "Paymetric", icon: PaymentIconMenu, colorType: "fill", url: "/invoice/payment", tag: "invoice-payment", role: ["Account Officer"], submenu: [] },
                    { id: 6, name: "Order", icon: InvoiceOrderIcon, colorType: "fill", url: "/invoice/order", tag: "order", role: ["Account Officer"], submenu: [] },
                    { id: 7, name: "Credit Note", icon: CreditNote, colorType: "fill", url: "/invoice/credit-note", tag: "credit-note", role: ["Account Officer", "Lab Head"], submenu: [] },
                    { id: 8, name: "Credit Policy", icon: CreditPolicyIcon, colorType: "fill", url: "/invoice/credit-policy", tag: "credit-policy", role: ["Lab Head"], submenu: [] },
                ],
            "bottom_menu": [
                { id: 1, name: t("common_side_menu.all_tab"), icon: Tabs, colorType: "fill", url: "", tag: "tabs", role: ["Account Officer", "Lab Head"], submenu: [] },
                { id: 2, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill", url: `${settingPath}/setting`, tag: "setting", role: ["Account Officer", "Lab Head"], submenu: [] }
            ]
        },
        "admin": {
            "top_menu":
                [
                    { id: 1, name: t("common_side_menu.dashboard"), icon: DashboardIcon, colorType: "fill", url: "/admin/dashboard", tag: "dashboard", role: ["SuperAdmin"], submenu: [] },
                    { id: 2, name: t("common_side_menu.bi_report"), icon: BIReport, colorType: "fill-stroke", url: "/admin/bi-report", tag: "bi-report", role: ["SuperAdmin"], submenu: [] },
                ],
            "bottom_menu": [
                { id: 1, name: t("common_side_menu.all_tab"), icon: Tabs, colorType: "fill", url: "", tag: "tabs", role: ["SuperAdmin"], submenu: [] },
                { id: 2, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill", url: `${settingPath}/setting`, tag: "setting", role: ["SuperAdmin"], submenu: [] }
            ]
        },
        "documents": {
            "top_menu":
                [
                    { id: 1, name: "Documents", icon: Documents, colorType: "fill", url: "/documents", tag: "documents", role: ["all"], submenu: [] }
                ],
            "bottom_menu": [
                { id: 1, name: t("common_side_menu.all_tab"), icon: Tabs, colorType: "fill", url: "", tag: "tabs", role: ["all"], submenu: [] },
                { id: 2, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill", url: `${settingPath}/setting`, tag: "setting", role: ["all"], submenu: [] }
            ]
        },
        "inventory": {
            "top_menu":
                [
                    { id: 1, name: "Inventory", icon: Inventory, colorType: "fill", url: "/inventory", tag: "inventory", role: ["all"], submenu: [] },
                    { id: 2, name: "Indent Request", icon: IndentReqIcon, colorType: "fill", url: "/inventory/indent-request", tag: "indent-request", role: ["all"], submenu: [] }
                ],
            "bottom_menu": [
                { id: 1, name: t("common_side_menu.all_tab"), icon: Tabs, colorType: "fill", url: "", tag: "tabs", role: ["all"], submenu: [] },
                { id: 2, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill", url: `${settingPath}/setting`, tag: "setting", role: ["all"], submenu: [] }
            ]
        },
        "customer": {
            "top_menu": [
                { id: 1, name: t("common_side_menu.dashboard"), icon: DashboardIcon, colorType: "fill", url: "/customer/dashboard", tag: "dashboard", role: ["all"], submenu: [] },
                { id: 2, name: t("customer.dashboard.mini_sidebar.book_test"), icon: BookTest, colorType: "fill", url: "/customer/service", tag: "book-test", role: ["all"], submenu: [] },
                { id: 3, name: t("customer.dashboard.mini_sidebar.invoice"), icon: InvoiceIconMenu, colorType: "fill", url: "/customer/download-invoices", tag: "download-invoice", role: ["all"], submenu: [] },
                { id: 4, name: t("customer.dashboard.mini_sidebar.my_test"), icon: MyTestIcon, colorType: "fill", url: "/customer/tests", tag: "tests", role: ["all"], submenu: [] },
                { id: 5, name: t("customer.dashboard.mini_sidebar.payment"), icon: PaymentIconMenu, colorType: "fill", url: "/customer/payment", tag: "payment", role: ["all"], submenu: [] },
                { id: 6, name: t("common_side_menu.setting"), icon: Settings, colorType: "fill-stroke", url: `${settingPath}/setting`, tag: "setting", role: ["all"], submenu: [] },
              ]
        }
    }

    return data;
};