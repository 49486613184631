import { Checkbox } from "primereact/checkbox";
import React, { useEffect, useState } from "react";
import { OptionDot, PencilEdit, SortArrow, SortDownArrow, SortUpArrow } from "../../svg/AllSvg";
import { Paginator } from "primereact/paginator";
import { useNavigate, useParams } from "react-router-dom";
import SkeletonGridView from "../../../skeleton/components/laboratory/GridView";
import { employeeActions } from "../../../shared/store/employee-Slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmployeeDetailPopup from "./EmployeeDetailPopup";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useTranslation } from "react-i18next";
import { tableActions } from "../../../shared/store/table-Slice";
import Moment from 'moment';

const TableView: React.FC<any> = ({ type }: any) => {
    const employeeDetailPopup: any = useSelector((state: any) => state.employee.employeeDetailPopup);
    const frontField: any = useSelector((state: any) => state.table.frontField);
    const data: any[] = useSelector((state: any) => state.table.data);
    const loading: boolean = useSelector((state: any) => state.table.loading);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const sortByList: any[] = useSelector((state: any) => state.table.sortByList);
    const defaultField: any = useSelector((state: any) => state.table.defaultField);
    const total: number = useSelector((state: any) => state.table.total);
    const size: number = useSelector((state: any) => state.table.size);
    const childTotal: number = useSelector((state: any) => state.table.childTotal);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const option: any = useSelector((state: any) => state.table.option);
    const childFirst: number = useSelector((state: any) => state.table.childFirst);
    const first: number = useSelector((state: any) => state.table.first);
    const [list, setList] = useState<any[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    useEffect(() => {
        if (data?.length > 0) {
            setList(data.map((item: any) => ({
                ...item,
                flag: false
            })));
        } else {
            setList([])
        }
    }, [data]);

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

   const getSortIcon = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                return <SortDownArrow />
            } else if (desc === sortBy) {
                return <SortUpArrow />
            } else {
                return <SortArrow />
            }
        }
    }

    const handleSort = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                dispatch(tableActions.setSortBy(desc))
            } else {
                dispatch(tableActions.setSortBy(asc))
            }
        }
    }

     const onPageChange = (event: any) => {
        if (type === "group") {
            dispatch(tableActions.setChildPage(event?.page + 1));
            dispatch(tableActions.setChildFirst(event.first));
            dispatch(tableActions.setChildSize(event.rows))
        } else {
            dispatch(tableActions.setPage(event?.page + 1));
            dispatch(tableActions.setFirst(event.first));
            dispatch(tableActions.setSize(event.rows))
        }
    };

    const getHeader = () => {
        let arr: any = [];
        for (const key in frontField) {
            if (defaultField?.includes(key)) {
                arr.push(<th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full cursor-pointer" onClick={() => handleSort(key, `-${key}`)}>
                    <div className='flex items-center justify-start w-full gap-[8px]'>
                        <p className='text-lGray font-medium text-sm !text-left'>{frontField[key]}</p>
                         <div className="w-[13px] h-[13px]">
        {getSortIcon(key, `-${key}`)}
</div>
                    </div>
                </th>);
            }
        }

        return arr;
    };

    return (
        <>
            {loading ? <SkeletonGridView /> :
                <>
                    {(!loading && list && list?.length > 0) &&
                        <div className="flex flex-col justify-between min-h-[600px]">
                            <table className='table-auto overflow-x-scroll w-full mt-[12px]'>
                                {!!!params?.id && <>
                                    <thead>
                                        <tr className="border border-[#DDD] border-b-0 !mt-[15px] bg-[#F8F7F7] h-[50px]">
                                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full">
                                                <div className='flex items-center justify-center w-full'>
                                                    <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                </div>
                                            </th>
                                            {getHeader()}
                                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                <div className='flex items-center justify-center w-full gap-[8px]'>
                                                    <p className='text-lGray font-medium text-sm !text-left'>Actions</p>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </>}
                                <tbody>
                                    {Array.isArray(list) && list.map((item: any, index: number) => (
                                        <tr className={`${(index + 1 === list?.length) ? "border" : "border border-b-0"} ${params?.id === item?.id ? "!border-b !border-r border-[#8D9FFF] bg-[#FAFAFF]" : "border-[#DDD]"}  h-[50px] cursor-pointer hover:bg-[#FAFAFF] hover:!border hover:!border-[#8D9FFF] hover:!border-r`} key={index}>
                                            {!!!params?.id && <td className="border-r h-full border-[#DDD] text-center px-[8px] 2xl:px-[16px]">
                                                <div className='flex items-center justify-center w-full'>
                                                    <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                </div>
                                            </td>}
                                            {!!params?.id ?
                                                <>
                                                    <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className={`px-[8px] 2xl:px-[16px] h-full ${item?.id !== params?.id && "border-r border-[#DDD]"} hover:!border-[#8D9FFF] hover:!border-r`} onMouseEnter={() => { dispatch(employeeActions.setEmployeeDetailPopup(item?.id)); dispatch(employeeActions.setEmployeeDetail(item)) }} onMouseLeave={() => dispatch(employeeActions.setEmployeeDetailPopup(0))}>
                                                        <div className="flex items-center gap-[15px] w-full">
                                                            <h3 className={`!text-sm text-left truncate ${params?.id === item?.id ? "text-primaryLight font-medium " : "text-dGray font-normal "}`}>{item?.manpower_detail?.first_name}</h3>
                                                        </div>

                                                        {(employeeDetailPopup === item?.id) && <EmployeeDetailPopup />}
                                                    </td>
                                                </>
                                                : <>
                                                    {Array.isArray(defaultField) && defaultField.map((i: any, idx: number) => (
                                                        <React.Fragment key={idx}>
                                                            <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full relative" onMouseEnter={() => { i === "first_name" && dispatch(employeeActions.setEmployeeDetailPopup(item?.id)); i === "first_name" && dispatch(employeeActions.setEmployeeDetail(item)) }} onMouseLeave={() => dispatch(employeeActions.setEmployeeDetailPopup(0))}>
                                                                {
                                                                    i === "first_name" ?
                                                                        <h3 className="text-lGray font-normal !text-sm text-left truncate">{item?.manpower_detail?.first_name}</h3>
                                                                        :
                                                                        i === "last_name" ?
                                                                            <h3 className="text-lGray font-normal !text-sm text-left truncate">{item?.manpower_detail?.last_name}</h3>
                                                                            :
                                                                            i === "email" ?
                                                                                <h3 className="text-lGray font-normal !text-sm text-left truncate">{item?.manpower_detail?.email}</h3>
                                                                                :
                                                                                i === "created_at" ?
                                                                                <h3 className="text-lGray font-normal !text-sm text-left">{!!item[i] ? Moment(item[i]).format('DD MMM YYYY') : "-"}</h3> :
                                                                                <div>
                                                                                    {!!item[i] ?
                                                                                        <h3 className="text-lGray font-normal !text-sm text-left truncate">{item[i]}</h3>
                                                                                        :
                                                                                        <>
                                                                                            {item?.custom_fields_values?.length > 0 ? <>
                                                                                                {Array.isArray(item?.custom_fields_values) && item?.custom_fields_values.map((c: any, cidx: number) => (
                                                                                                    <React.Fragment key={cidx}>
                                                                                                        {c?.slug === i && <h3 key={cidx} className="text-lGray font-normal !text-sm text-left truncate">{!!c?.value ? c?.value : "-"}</h3>}
                                                                                                    </React.Fragment>
                                                                                                ))}
                                                                                            </> : <h3 className="text-lGray font-normal !text-sm text-left truncate">-</h3>}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                }

                                                                {(i === "first_name" && (employeeDetailPopup === item?.id)) && <EmployeeDetailPopup />}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}

                                                    <td className="px-[8px] 2xl:px-[16px] h-full  text-center">
                                                        <div className="flex items-center justify-center gap-[8px] stroke-lGray">
                                                            {/* <div className="cursor-pointer !w-[25px] !h-[25px] flex items-center justify-center rounded-[5px] bg-[#F8F8F8] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)]">
                                                                <PencilEdit width="15" height="15" />
                                                            </div> */}

                                                            <Menu align="end" menuButton={<MenuButton>
                                                                <div className="cursor-pointer !w-[25px] !h-[25px] flex items-center justify-center rounded-[5px] bg-[#F8F8F8] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)]">
                                                                    <OptionDot color="#94A3B8" />
                                                                </div>
                                                            </MenuButton>}>
                                                                <MenuItem onClick={() => { dispatch(employeeActions.setIsEmployeeEdit(true)); dispatch(employeeActions.setSelectedId(item?.id)) }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-lGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-lGray'><PencilEdit width="20" height="20" /><p className='text-sm'>{t("menu.edit")}</p></div></div></MenuItem>
                                                                {/* <MenuItem onClick={() => { dispatch(employeeActions.setModifyRole(true)); dispatch(employeeActions.setSelectedId(item?.id)) }}>
                                                                    <div className="group w-full h-full px-[14px] py-[6px]">
                                                                        <div className='flex items-center gap-[10px] text-lGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-lGray'><UserOption /><p className='text-sm'>{t("laboratory.menu.modify_role")}</p>
                                                                        </div>
                                                                    </div>
                                                                </MenuItem> */}
                                                                {/* <MenuItem><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-lGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-lGray'><SettingRemove width="18" height="18" /><p className='text-sm'>{t("menu.delete")}</p></div></div></MenuItem> */}
                                                            </Menu>
                                                        </div>
                                                    </td>
                                                </>
                                            }
                                            {/* {!!!params?.id && <>
                                                {obj?.tags && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full ">
                                                <div className="flex items-center gap-[8px]">
                                                    <button className={`${item?.bg} ${item?.text} flex items-center justify-center text-xs font-normal rounded-[20px] py-[6px] px-[15px] whitespace-nowrap`}>{item?.tag}</button>
                                                    {item?.tag === "Trainee" && <button className={`${item?.bg} ${item?.text} flex items-center justify-center text-xs font-normal rounded-[20px] py-[6px] px-[15px] whitespace-normal`}>+1</button>}
                                                </div>
                                            </td>}
                                                {obj?.last_name && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.manpower_detail?.last_name}</h3>
                                                </td>}
                                                {obj?.email && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.manpower_detail?.email}</h3>
                                                </td>}
                                                {obj?.dob && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.manpower_detail?.dob ? item?.manpower_detail?.dob : "-"}</h3>
                                                </td>}
                                                {obj?.gender && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.manpower_detail?.gender ? item?.manpower_detail?.gender : "-"}</h3>
                                                </td>}
                                                {obj?.department && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.department_name ? item?.department_name : "-"}</h3>
                                                </td>}
                                                {obj?.doj && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.joining_date ? item?.joining_date : "-"}</h3>
                                                </td>}
                                                {obj?.lab && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.lab_name ? item?.lab_name : "-"}</h3>
                                                </td>}
                                                {obj?.mobile && <td onClick={() => navigate(`/laboratory/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD] h-full "><h3 className="text-lGray font-normal !text-sm text-left">{item?.manpower_detail?.mobile ? item?.manpower_detail?.mobile : "-"}</h3>
                                                </td>}
                                                <td className="px-[16px] h-full  text-center">
                                                    <div className="flex items-center justify-center gap-[8px] stroke-lGray">
                                                        <div className="cursor-pointer !w-[25px] !h-[25px] flex items-center justify-center rounded-[5px] bg-[#F8F8F8] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)]">
                                                            <PencilEdit width="15" height="15" />
                                                        </div>
                                                        <div className="cursor-pointer !w-[25px] !h-[25px] flex items-center justify-center rounded-[5px] bg-[#F8F8F8] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)]" onClick={(event: any) => menu.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup>
                                                            <OptionDot color="#94A3B8" />
                                                            <TieredMenu
                                                                model={items as any}
                                                                popup
                                                                ref={menu}
                                                                className='sample-menu field-menu !w-[160px] !mt-[4px]'
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </>} */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {
                                type === "group" ?
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={childFirst} rows={childSize} totalRecords={childTotal} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div> :
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div>
                            }
                        </div>
                    }

                </>
            }

            {
                (list === null && !loading) && <div className="flex items-center justify-center">
                    <img src={require("../../../assets/images/no-data-found.jpg")} alt="" className="w-[350px] h-[350px]" />
                </div>
            }
        </>
    );
};

export default TableView;