import React, { useEffect, useState } from "react";
import { Approve, AssignGroup, DataDotsIcon, NameOrgIcon, Reject, SortArrow, SortDownArrow, SortUpArrow } from "../../../svg/AllSvg";
import SkeletonGridView from "../../../../skeleton/components/invoice/customer/GridView";
import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { useDispatch } from "react-redux";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import { useNavigate, useParams } from "react-router-dom";
import { randomColor } from "../../../../shared/utils/randomColor";
import useAxios from "../../../../shared/hooks/use-axios";
import { success } from "../../../../shared/hooks/use-toastify";
import { Paginator } from "primereact/paginator";
import { tableActions } from "../../../../shared/store/table-Slice";
import Moment from 'moment';

const TableView: React.FC<any> = ({ type }: any) => {
    const frontField: any = useSelector((state: any) => state.table.frontField);
    const data: any[] = useSelector((state: any) => state.table.data);
    const loading: boolean = useSelector((state: any) => state.table.loading);
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const sortByList: any[] = useSelector((state: any) => state.table.sortByList);
    const defaultField: any = useSelector((state: any) => state.table.defaultField);
    const total: number = useSelector((state: any) => state.table.total);
    const size: number = useSelector((state: any) => state.table.size);
    const childTotal: number = useSelector((state: any) => state.table.childTotal);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const childFirst: number = useSelector((state: any) => state.table.childFirst);
    const first: number = useSelector((state: any) => state.table.first);
    const [list, setList] = useState<any[]>([]);
    const [allCheckBox, setAllCheckBox] = useState<boolean>(false)
    const [check, setCheck] = useState<boolean>(false);
    const [allBtnShow, setAllBtnShow] = useState<number>(0);
    const [oneCheck, setOneCheck] = useState<string>("");
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

   const getSortIcon = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                return <SortDownArrow />
            } else if (desc === sortBy) {
                return <SortUpArrow />
            } else {
                return <SortArrow />
            }
        }
    }

    const handleSort = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                dispatch(tableActions.setSortBy(desc))
            } else {
                dispatch(tableActions.setSortBy(asc))
            }
        }
    }

    const onPageChange = (event: any) => {
        if (type === "group") {
            dispatch(tableActions.setChildPage(event?.page + 1));
            dispatch(tableActions.setChildFirst(event.first));
            dispatch(tableActions.setChildSize(event.rows))
        } else {
            dispatch(tableActions.setPage(event?.page + 1));
            dispatch(tableActions.setFirst(event.first));
            dispatch(tableActions.setSize(event.rows))
        }
    };

    const getHeader = () => {
        let arr: any = [];
        defaultField.forEach((el: any) => {
            for (const key in frontField) {
                if (key === el) {
                    arr.push(<th key={key} className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full cursor-pointer" onClick={() => handleSort(key, `-${key}`)}>
                        <div className='flex items-center justify-start w-full gap-[8px]'>
                            <p className='text-lGray font-normal text-sm !text-left'>{frontField[key]}</p>
                            <div className="w-[13px] h-[13px]">
                                {getSortIcon(key, `-${key}`)}
                            </div>
                        </div>
                    </th>);
                }
            }
        });

        return arr;
    };

    const handleSubmit = async (item: any, status: any) => {
        try {
            let payload = {
                "customer_id": item?.id,
                "approval_status": status ? "approved" : "disapproved"
            }

            const res: any = await useAxios.post(`workflow/customer-approval-api/`, payload);

            if (res && res.status === 200) {
                success(`Customer ${status ? "approved" : "disapproved"} successfully.`);
                dispatch(invoiceActions.setTagApiCall(true))
                dispatch(invoiceActions.setApiCall("customer"));
            }

        } catch (error) {
            console.error("error while approval credit note", error);
        }
    }

    useEffect(() => {
        setList(data)
    }, [data])

    return (
        <>
            {(loading || data === null) ? <SkeletonGridView /> :
                <>
                    {(!loading && data && data?.length > 0) &&
                        <div className="flex flex-col justify-between min-h-[600px] mt-[12px]">
                            <table className='table-auto overflow-x-scroll w-full mt-[12px]'>
                                {!!!params?.id && <>
                                    <thead>
                                        <tr className="border border-[#DDD] border-b-0 !mt-[15px] bg-[#F4F8F9] h-[50px]">
                                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px]">
                                                <div className='flex items-center justify-center w-full' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                                    <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                </div>
                                            </th>
                                            {getHeader()}
                                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full cursor-pointer"></th>
                                        </tr>
                                    </thead>
                                </>}
                                <tbody>
                                    {Array.isArray(list) && list.map((item: any, index: number) => (
                                        <tr className={`${(index + 1 === list?.length) ? "border" : "border border-b-0"} ${params?.id === item?.id ? "!border-b border-[#DFDAFF] !bg-[#FAFAFF]" : "border-[#DDD]"}  h-[50px] ${(!!!type) && "cursor-pointer hover:bg-[#FAFAFF] hover:!border hover:!border-[#DFDAFF] hover:!border-r"} ${(((index + 1) % 2) === 0) ? "bg-[#F9F9F9]" : "bg-white"}`} key={index}>
                                            {!!params?.id ?
                                                <>
                                                    <td onClick={() => navigate(`/invoice/customer/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="border-r h-full border-[#DDD] text-center px-[8px]" onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")}>
                                                        <div className="flex items-center justify-center">
                                                            {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                :
                                                                <>
                                                                    {!!item?.org_name ?
                                                                        <div style={{ backgroundColor: `${randomColor[item?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.org_name?.charAt(0)}</div>
                                                                        : <div style={{ backgroundColor: `${randomColor[item?.user_detail?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.user_detail?.first_name?.charAt(0)}{item?.user_detail?.last_name?.charAt(0)}</div>}
                                                                </>
                                                            }
                                                        </div>
                                                    </td>

                                                    <td className="px-[8px]" onClick={() => navigate(`/invoice/customer/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                                        <div className="flex flex-col">
                                                            <h3 className="text-lGray font-normal !text-sm text-left">{item?.user_detail?.first_name} {" "} {item?.user_detail?.last_name}</h3>
                                                            {!!item?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#94A3B8" /> <span className="text-lGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                                        </div>
                                                    </td>
                                                </>
                                                : <>
                                                    <td onClick={() => (!!!type) && navigate(`/invoice/customer/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="border-r h-full border-[#DDD] text-center px-[8px] 2xl:px-[16px]" onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")}>
                                                        <div className="flex items-center justify-center">
                                                            {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                :
                                                                <>
                                                                    {!!item?.org_name ?
                                                                        <div style={{ backgroundColor: `${randomColor[item?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.org_name?.charAt(0)}</div>
                                                                        : <div style={{ backgroundColor: `${randomColor[item?.user_detail?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.user_detail?.first_name?.charAt(0)}{item?.user_detail?.last_name?.charAt(0)}</div>}
                                                                </>
                                                            }
                                                        </div>
                                                    </td>
                                                    {Array.isArray(defaultField) && defaultField.map((i: any, idx: number) => (
                                                        <React.Fragment key={idx}>
                                                            <td onClick={() => (!!!type) && navigate(`/invoice/customer/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} onMouseEnter={() => i === "system_group" && setAllBtnShow(item?.id)} onMouseLeave={() => i === "system_group" && setAllBtnShow(0)} className="px-[8px] 2xl:px-[16px] border-r border-[#DDD]">
                                                                {i === "first_name" ?
                                                                    <div className="flex flex-col">
                                                                        <h3 className="text-lGray font-normal !text-sm text-left">{item?.user_detail?.[i]} {" "} {item?.user_detail?.last_name}</h3>
                                                                        {!!item?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#94A3B8" /> <span className="text-lGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                                                    </div>
                                                                    : i === "tds_percentage" ? <div className='w-full flex items-center justify-start'>
                                                                        <h3 className="!text-sm text-lGray font-normal">{!!item[i] ? `${item[i]}%` : "-"}</h3>
                                                                    </div> :
                                                                        (i === "created_at" || i === "credit_expiry") ?
                                                                            <h3 className="text-lGray font-normal !text-sm text-left whitespace-nowrap">{!!item[i] ? Moment(item[i]).format('DD MMM YYYY') : "-"}</h3> :
                                                                            i === "email" ? <div className='w-full flex items-center justify-start'>
                                                                                <h3 className="!text-sm text-lGray font-normal">{!!item?.user_detail?.[i] ? item?.user_detail?.[i] : "-"}</h3>
                                                                            </div>
                                                                                :
                                                                                i === "system_group" ?
                                                                                    <div>
                                                                                        {item?.system_group_list?.length > 0 &&
                                                                                            <>
                                                                                                {(allBtnShow !== item?.id || allBtnShow === 0) ? <div className="flex items-center gap-[8px]">

                                                                                                    <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>{item?.system_group_list[0]?.value}{item?.system_group_list[0]?.amount_type === "Percentage" ? "%" : ""} &nbsp;&nbsp;{item?.system_group_list[0]?.type}</button>
                                                                                                    {item?.system_group_list?.length > 1 && <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-xs font-normal rounded-[5px] py-[6px] px-[10px] whitespace-normal`}>{item?.system_group_list?.length - 1}+</button>}
                                                                                                </div> :
                                                                                                    <div className="flex flex-wrap items-center gap-[8px]">
                                                                                                        {Array.isArray(item?.system_group_list) && item?.system_group_list.map((i: any, idx: number) => (
                                                                                                            <button key={idx} className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>{i?.value}{i?.amount_type === "Percentage" ? "%" : ""} &nbsp;&nbsp;{i?.type}</button>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        {!!item[i] ?
                                                                                            <h3 className="text-lGray font-normal !text-sm text-left truncate">{item[i]}</h3>
                                                                                            :
                                                                                            <>
                                                                                                {item?.custom_fields_values?.length > 0 ? <>
                                                                                                    {Array.isArray(item?.custom_fields_values) && item?.custom_fields_values.map((c: any, cidx: number) => (
                                                                                                        <React.Fragment key={cidx}>
                                                                                                            {c?.slug === i && <h3 key={cidx} className="text-lGray font-normal !text-sm text-left truncate">{!!c?.value ? c?.value : "-"}</h3>}
                                                                                                        </React.Fragment>
                                                                                                    ))}
                                                                                                </> : <h3 className="text-lGray font-normal !text-sm text-left truncate">-</h3>}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                }
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                    <td className="px-[8px] 2xl:px-[16px] h-full text-center">
                                                        <Menu align="end" menuButton={<MenuButton>
                                                            <div className="cursor-pointer flex items-center justify-center h-full w-full pt-[6px]">
                                                                <DataDotsIcon />
                                                            </div>
                                                        </MenuButton>}>
                                                            <MenuItem onClick={() => { dispatch(invoiceActions.setCreateGroup(true)); dispatch(invoiceActions.setCustomer(item)) }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-lGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-lGray fill-lGray group-hover:fill-primaryLight'><AssignGroup width="15" height="15" /><p className='text-sm'>Assign Group</p></div></div></MenuItem>
                                                            {/* <MenuItem><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-lGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-lGray'><SettingRemove width="18" height="18" /><p className='text-sm'>Delete</p></div></div></MenuItem> */}
                                                            {option?.name === "pending" && <>
                                                                <MenuItem onClick={() => handleSubmit(item, true)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-lGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-lGray group-hover:fill-primaryLight fill-lGray'><Approve /> Approve</div></div></MenuItem>
                                                                <MenuItem onClick={() => handleSubmit(item, false)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-lGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium hover:stroke-primaryLight stroke-lGray'><Reject /> Reject</div></div></MenuItem>
                                                            </>}
                                                        </Menu>
                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {
                                type === "group" ?
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={childFirst} rows={childSize} totalRecords={childTotal} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div> :
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div>
                            }
                        </div>

                    }
                </>
            }

            {
                (data?.length === 0 && !loading) &&
                <div className="flex flex-col items-center justify-center min-h-[600px]">
                    <img src={require("../../../../assets/images/notFound-cust.png")} alt="" />
                    <h3 className="text-base text-primaryLight font-normal pt-[14px]">No Customer Has Been Created</h3>
                    <p className="max-w-[301px] text-center text-lGray text-sm font-normal pt-[14px]">Create new test to manage all the standards and parameters</p>

                    <button className="bg-[#EEF4FC] rounded-[10px] px-[24px] h-[40px] text-primaryLight text-sm font-medium border border-[rgba(32,50,143,0.30)] flex items-center justify-center cursor-pointer mt-[30px]" onClick={() => dispatch(invoiceActions.setIsCustomerCreate(true))}>Create New Customer</button>
                </div>
            }
        </>
    );
};

export default TableView;