import { useEffect, useState } from "react";
import useAxios from "../../shared/hooks/use-axios";
import { success } from "../../shared/hooks/use-toastify";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Confirmation from "../form/Conformation";
import { DefaultGroup, PencilEdit, SearchRightFilter, SettingRemove, StandardDotsIcon } from "../svg/AllSvg";
import SkeletonSettingList from "../../skeleton/components/setting/SettingList";
import { Checkbox } from "primereact/checkbox";
import Moment from 'moment';
import Search from "../form/Search";

const StorageLocationList = ({ setList, list, setIsOpenEdit, setDetail, detail, setApiCall, apiCall }: any) => {
    const [searchList, setSearchList] = useState<any[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getStorageLocation = async () => {
        try {
            const res: any = await useAxios.get("inventory/master-storage-locations/?is_master=true");

            if (res && res?.status === 200) {
                setList(res?.data);
                setSearchList(res?.data);
                setIsLoading(false);
                setApiCall(false);
            }
        } catch (error) {
            console.error("error while feching get storage location", error);
        }
    }

    const handleDelete = async () => {
        try {
            await useAxios.delete(`inventory/master-storage-locations/${detail?.id}/`);
            success("Storage Location delete successfully");
            getStorageLocation();
        } catch (error) {
            console.error("error while feching test request", error);
        }
    }

    useEffect(() => {
        getStorageLocation();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

    const handleUpdate = async (id: any, active: any) => {
        try {
            await useAxios.patch(`inventory/master-storage-locations/${id}/`, { active: !active });
            getStorageLocation();
        } catch (error) {
            console.error("error while feching test request", error);
        }
    }

    useEffect(() => {
        apiCall && getStorageLocation();
        // eslint-disable-next-line
    }, [apiCall]);

    const handleSearch = (e: any) => {
        let arr: any[] = searchList;
        if (!!e.target.value) {
            // eslint-disable-next-line 
            arr = arr.filter((item: any) => {
                if (item?.compartment_name?.toLowerCase()?.includes(e.target.value?.toLowerCase())) {
                    return true;
                }
            });
        } else {
            arr = searchList;
        }

        setList(arr);
    }


    return (
        <>
            {openConfirm && <Confirmation openConfirm={openConfirm} setOpenConfirm={setOpenConfirm} onSubmit={handleDelete} />}
            <div className='p-[19px] w-full lg:col-span-8 xxl:w-full'>
                {searchList?.length > 0 && <div className='flex items-center gap-[12px] justify-end w-full'>
                    {/* Search */}
                    <Search placeholder="Search" onChange={(e: any) => handleSearch(e)} />

                    <div className="rounded-[10px] bg-white p-[6px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] cursor-pointer">
                        <SearchRightFilter color="#6B7280" />
                    </div>
                </div>}

                <div className="w-full">
                    {isLoading ? <SkeletonSettingList /> :
                        <div className="flex flex-col">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full skeleton-table">
                                            <thead className="bg-white !rounded-[10px]">
                                                <tr className="h-[60px] !rounded-[10px]">
                                                    <th className="px-[8px] 2xl:px-[16px] h-full">
                                                        <div className='flex items-center justify-center w-full'>
                                                            <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                        </div>
                                                    </th>

                                                    <th className="px-[8px] 2xl:px-[16px] h-full !rounded-l-[10px]">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray  font-normal text-sm !text-left'>Building</p>
                                                        </div>
                                                    </th>
                                                    <th className="px-[8px] 2xl:px-[16px] h-full !rounded-l-[10px]"></th>


                                                    <th className="px-[8px] 2xl:px-[16px] h-full !rounded-l-[10px]">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray  font-normal text-sm !text-left'>Floor</p>
                                                        </div>
                                                    </th>

                                                    <th className="px-[8px] 2xl:px-[16px] h-full !rounded-l-[10px]">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray  font-normal text-sm !text-left'>Room</p>
                                                        </div>
                                                    </th>

                                                    <th className="px-[8px] 2xl:px-[16px] h-full !rounded-l-[10px]">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray  font-normal text-sm !text-left'>Storage Box Type</p>
                                                        </div>
                                                    </th>

                                                    <th className="px-[8px] 2xl:px-[16px] h-full !rounded-l-[10px]">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray  font-normal text-sm !text-left'>Location</p>
                                                        </div>
                                                    </th>

                                                    <th className="px-[8px] 2xl:px-[16px] h-full">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray font-normal text-sm !text-left'>Created Date</p>
                                                        </div>
                                                    </th>

                                                    <th className="px-[8px] 2xl:px-[16px] h-full"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(list) && list.map((item: any, index: number) => (
                                                    <tr className="!rounded-[10px] bg-white h-[55px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)]" key={index}>
                                                        <td className="px-[8px] 2xl:px-[16px] h-full !text-center">
                                                            <div className='flex items-center justify-center w-full'>
                                                                <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                            </div>
                                                        </td>
                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            <h3 className="text-primaryLight font-normal !text-sm text-left">{item?.building?.name}</h3>
                                                        </td>
                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            {item?.is_default_storage_box && <DefaultGroup />}
                                                        </td>

                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            <h3 className="text-mGray font-normal !text-sm text-left">{item?.floor?.floor_name}</h3>
                                                        </td>
                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            <h3 className="text-mGray font-normal !text-sm text-left">{item?.room?.room_name}</h3>
                                                        </td>

                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            <h3 className="text-mGray font-normal !text-sm text-left">{item?.storage_box_type?.storage_box_type}</h3>
                                                        </td>

                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            <h3 className="text-mGray font-normal !text-sm text-left">{item?.compartment_name}</h3>
                                                        </td>

                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            <h3 className="text-mGray font-normal !text-sm text-left">{Moment(item?.created_at).format('DD MMM YYYY')}</h3>
                                                        </td>

                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            <div className='flex items-center gap-[8px]'>
                                                                <label className="relative inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" checked={item?.active} className="sr-only peer" onChange={() => handleUpdate(item?.id, item?.active)} />
                                                                    <div className="w-9 h-5 bg-[#E8EBFF] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all after:peer-checked:bg-dGray" ></div>
                                                                </label>

                                                                <Menu align="end" menuButton={<MenuButton>
                                                                    <div className="cursor-pointer">
                                                                        <StandardDotsIcon />
                                                                    </div>
                                                                </MenuButton>}>
                                                                    <MenuItem onClick={() => { setDetail(item); setIsOpenEdit(true); }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><PencilEdit width="20" height="20" /><p className='text-sm'>Edit</p></div></div></MenuItem>
                                                                    <MenuItem onClick={() => { setDetail(item); setOpenConfirm(true); }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><SettingRemove width="18" height="18" /><p className='text-sm'>Delete</p></div></div></MenuItem>
                                                                </Menu>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default StorageLocationList;