/* eslint-disable react-hooks/exhaustive-deps */
import '@remirror/styles/all.css';
import { AllStyledComponent } from "@remirror/styles/emotion";
import {
    BoldExtension,
    HeadingExtension,
    ItalicExtension,
    UnderlineExtension,
    FontFamilyExtension,
    FontSizeExtension,
    LinkExtension,
    BulletListExtension,
    HardBreakExtension,
    OrderedListExtension,
    TaskListExtension,
    BlockquoteExtension,
    NodeFormattingExtension,
    StrikeExtension,
    SubExtension,
    SupExtension,
    TextCaseExtension,
    DropCursorExtension,
    ImageExtension
} from "remirror/extensions";
import {
    BasicFormattingButtonGroup,
    HeadingLevelButtonGroup,
    HistoryButtonGroup,
    useRemirror,
    Remirror,
    ThemeProvider,
    Toolbar,
    OnChangeJSON,
    useCommands,
    useActive,
    CommandButtonGroup,
    DropdownButton,
    CommandMenuItem,
    DecreaseFontSizeButton,
    IncreaseFontSizeButton,
    ListButtonGroup,
    CopyButton,
    CutButton,
    PasteButton,
    TextAlignmentButtonGroup,
    IndentationButtonGroup,
    ToggleStrikeButton,
    ToggleSubscriptButton,
    ToggleSuperscriptButton,
    TableExtension,
    CreateTableButton
} from "@remirror/react";
import { useCallback } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { labSampleActions } from '../../../shared/store/labSample-Slice';

const FONT_FAMILIES: Array<[React.CSSProperties['fontFamily'], string]> = [
    ['serif', 'Serif'],
    ['sans-serif', 'San serif'],
    ['cursive', 'Cursive'],
    ['fantasy', 'Fantasy'],
];

const FONT_SIZES = ['8', '10', '12', '14', '16', '18', '24', '30'];

const TEXT_CASES: Array<[React.CSSProperties['textTransform'], string]> = [
    ['none', 'None'],
    ['uppercase', 'Upper'],
    ['lowercase', 'Lower'],
    ['capitalize', 'Capitalize'],
];

const TextCaseButton = () => {
    const { setTextCase } = useCommands();
    const { textCase } = useActive();
    return (
        <CommandButtonGroup>
            <DropdownButton aria-label='Text case' icon='fontSize2'>
                {TEXT_CASES.map(([casing, label]) => (
                    <CommandMenuItem
                        key={casing}
                        commandName='setTextCase'
                        onSelect={() => setTextCase(casing as string)}
                        enabled={setTextCase.enabled(casing as string)}
                        active={textCase({ casing })}
                        label={<span style={{ textTransform: casing }}>{label}</span>}
                    />
                ))}
                <CommandMenuItem
                    commandName='setTextCase'
                    onSelect={() => setTextCase('small-caps')}
                    enabled={setTextCase.enabled('small-caps')}
                    active={textCase({ casing: 'small-caps' })}
                    label={<span style={{ fontVariant: 'small-caps' }}>Small caps</span>}
                />
            </DropdownButton>
        </CommandButtonGroup>
    );
};

const FontSizeButtons = () => {
    const { setFontSize } = useCommands();
    const { fontSize } = useActive();
    return (
        <DropdownButton aria-label='Set font size' icon='fontSize'>
            {FONT_SIZES.map((size) => (
                <CommandMenuItem
                    key={size}
                    commandName='setFontSize'
                    onSelect={() => setFontSize(size)}
                    enabled={setFontSize.enabled(size)}
                    active={fontSize({ size })}
                    label={size}
                    icon={null}
                    displayDescription={false}
                />
            ))}
        </DropdownButton>
    );
};

const FontFamilyButtons = () => {
    const { setFontFamily } = useCommands();
    const active = useActive();
    return (
        <CommandButtonGroup>
            <DropdownButton aria-label='Font family' icon='text'>
                {FONT_FAMILIES.map(([fontFamily, label]) => (
                    <CommandMenuItem
                        key={fontFamily}
                        commandName='setFontFamily'
                        onSelect={() => setFontFamily(fontFamily as string)}
                        enabled={setFontFamily.enabled(fontFamily as string)}
                        active={active.fontFamily({ fontFamily })}
                        label={<span style={{ fontFamily }}>{label}</span>}
                    />
                ))}
            </DropdownButton>
        </CommandButtonGroup>
    );
};

const LineHeightButtonDropdown = () => {
    const { setLineHeight } = useCommands();
    return (
        <CommandButtonGroup>
            <DropdownButton aria-label='Line height' icon='lineHeight'>
                <CommandMenuItem
                    commandName='setLineHeight'
                    onSelect={() => setLineHeight(1)}
                    enabled={setLineHeight.enabled(1)}
                    label='Narrow'
                />
                <CommandMenuItem
                    commandName='setLineHeight'
                    onSelect={() => setLineHeight(2)}
                    enabled={setLineHeight.enabled(2)}
                    label='Wide'
                />
            </DropdownButton>
        </CommandButtonGroup>
    );
};

const AddNotesComponent = ({ setTemp, type, parameters, temp }: any) => {
    const resultData = useSelector((state: any) => state.labSample.resultData);
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
    const dispatch = useDispatch();

    const extensions = () => [
        new BoldExtension({}),
        new ItalicExtension(),
        new UnderlineExtension(),
        new FontFamilyExtension(),
        new FontSizeExtension({ defaultSize: '16', unit: 'px' }),
        new OrderedListExtension(),
        new TaskListExtension(),
        new HeadingExtension({}),
        new LinkExtension({ autoLink: true, selectTextOnClick: true }),
        new HardBreakExtension(),
        new BlockquoteExtension(),
        new NodeFormattingExtension({}),
        new BulletListExtension({ }),
        new StrikeExtension(),
        new SubExtension(),
        new SupExtension(),
        new TextCaseExtension({}),
        new TableExtension({}),
        new ImageExtension({ enableResizing: true }),
        new DropCursorExtension({})
    ];

    const { manager, state } = useRemirror({
        extensions,
        content: type === "setting" ?
            temp?.length > 0 ? {
                "type": "doc",
                "content": temp
            } : ""
            :
            type === "result" ? ((parameters?.length > 0 && Object.keys(resultData)?.length > 0 && resultData?.[sampleDetail?.id] && Object.keys(resultData?.[sampleDetail?.id])?.length > 0 && !!resultData?.[sampleDetail?.id]?.[`temp_${parameters[0]?.test_type_name}`]) ?
                {
                    "type": "doc",
                    "content": JSON.parse(resultData?.[sampleDetail?.id]?.[`temp_${parameters[0]?.test_type_name}`])
                } : ""
            )
                : "",
        selection: "end",
        stringHandler: "html",
    });

    const handleEditorChange = useCallback((json: any) => {
        console.log("Editor content:", json);
        if (type && type === "result") {
            let obj: any = { ...resultData };
            let sampleObj: any = { ...obj?.[sampleDetail?.id] }
            sampleObj[`temp_${parameters[0]?.test_type_name}`] = JSON.stringify(json?.content);
            sampleObj[`temp_${parameters[0]?.test_type_name}_change`] = true;
            obj[sampleDetail?.id] = sampleObj
            dispatch(labSampleActions.setResultData(obj))
        } else {
            setTemp(json?.content)
        }
    }, []);

    return (
        <AllStyledComponent>
            <ThemeProvider>
                <Remirror
                    manager={manager}
                    initialContent={state}
                    autoRender="end"
                >
                    <Toolbar>
                        <HistoryButtonGroup />
                        <FontFamilyButtons />
                        <TextCaseButton />
                        <CommandButtonGroup>
                            <CopyButton />
                            <CutButton />
                            <PasteButton />
                        </CommandButtonGroup>
                        <CommandButtonGroup>
                            <DecreaseFontSizeButton />
                            <FontSizeButtons />
                            <IncreaseFontSizeButton />
                        </CommandButtonGroup>
                        <BasicFormattingButtonGroup />
                        <HeadingLevelButtonGroup showAll />
                        <CommandButtonGroup>
                            <ListButtonGroup />
                        </CommandButtonGroup>
                        <CommandButtonGroup>
                            <TextAlignmentButtonGroup />
                        </CommandButtonGroup>
                        <CommandButtonGroup>
                            <IndentationButtonGroup />
                        </CommandButtonGroup>
                        <CommandButtonGroup>
                            <LineHeightButtonDropdown />
                        </CommandButtonGroup>
                        <ToggleStrikeButton />
                        <ToggleSubscriptButton />
                        <ToggleSuperscriptButton />
                        <CreateTableButton />
                        <OnChangeJSON onChange={handleEditorChange} />
                    </Toolbar>
                </Remirror>
            </ThemeProvider>
        </AllStyledComponent>
    )
}

export default AddNotesComponent