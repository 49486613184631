/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from "react-redux";
import { labSampleActions } from "../../../shared/store/labSample-Slice";
import { CustomerClose, LoadingBtn, TaskArrow } from "../../svg/AllSvg";
import PrimaryBtn from "../../form/PrimaryBtn";
import { useSelector } from "react-redux";
import { useState } from "react";
import CustomForm from "../../form/CustomForm";
import useAxios from "../../../shared/hooks/use-axios";
import { success } from "../../../shared/hooks/use-toastify";
import { useTranslation } from "react-i18next";

const ReportDetailPopup = () => {
    const dispatch = useDispatch();
    const reportPopup: boolean = useSelector((state: any) => state.labSample.reportPopup);
    const customField = useSelector((state: any) => state.setting.customField);
    const selectDept: any = useSelector((state: any) => state.labSample.selectDept);
    const [customObj, setCustomObj] = useState<any>({});
    const [submit, setSubmit] = useState<boolean>(false)
    const { t } = useTranslation('common');

    const onSubmitHandler = async () => {
        let payload = {
            custom_fields: customObj
        }

        try {
            setSubmit(true)
            const res: any = await useAxios.patch(`samples/api/forwarded-samples-custom-fields/${selectDept?.id}/`, payload);

            if (res && res?.status === 200) {
                setSubmit(false)
                success("Report Detail Submitted successfully.");
                dispatch(labSampleActions.setReportPreview(true))
                dispatch(labSampleActions.setReportPopup(false))
            }
        } catch (error) {
            setSubmit(false)
            console.error("error while creating report detail", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(labSampleActions.setParameterPopup(true)); 
            dispatch(labSampleActions.setReportPopup(false))
        }
    };

    return (
        <>
            {reportPopup && <div className="fixed inset-0 z-20 overflow-y-hidden top-0 ">
                <div className="fixed inset-0 z-[31] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[21px] flex flex-col w-[320px] sm:w-[600px] md:w-[700px] lg:w-[1000px] md:h-auto z-[10]">
                            <img src={require("../../../assets/images/Mask group.png")} alt="" className="absolute bottom-0 right-0 rounded-b-[20px] !rounded-l-none opacity-[0.4]" />
                            <div className="flex items-start justify-between px-[15px] pt-[23px] rounded-t-[21px] bg-white z-[10]">
                                <div className="flex items-center gap-[4px] cursor-pointer" onClick={() => { dispatch(labSampleActions.setParameterPopup(true)); dispatch(labSampleActions.setReportPopup(false)) }}>
                                    <TaskArrow />
                                    <h3 className="text-primaryLight font-semibold text-lg">{t("lab_sample.report.report_detail")}</h3>
                                </div>
                                <div className="cursor-pointer z-[10]" onClick={() => dispatch(labSampleActions.setReportPopup(false))}>
                                    <CustomerClose />
                                </div>
                            </div>

                            <div className={`p-[28px] z-[10] ${!customField && "hidden"}`}>
                                <CustomForm modal="sampledepartmentsplit" fillModal="SampleDepartmentSplit" setCustomObj={setCustomObj} id={selectDept?.id} grid={3} sectionStyle="text-primaryLight font-normal text-base" />
                            </div>

                            <div className="w-full flex items-end justify-end px-[24px] pb-[33px] gap-[15px] z-[10]">
                                <PrimaryBtn onClick={onSubmitHandler} disabled={!submit ? false : true} title={!submit ? t("common_btn.save_next") :
                                    <>
                                        <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                        {`${t("common_btn.save")}...`}
                                    </>} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default ReportDetailPopup;
