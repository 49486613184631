import { MenuItem } from '@szhsin/react-menu';
import { AddEmp, AddTask, BillOfSupply, BookTest, CreateOrderIcon, ItemIdIcon, RaiseCreditNote, SalarySlip } from '../../svg/AllSvg';
import { useTranslation } from 'react-i18next';
import { employeeActions } from '../../../shared/store/employee-Slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { crmActions } from '../../../shared/store/crm-Slice';
import { invoiceActions } from '../../../shared/store/invoice-Slice';
import { inventoryActions } from '../../../shared/store/inventory-Slice';

export const HRMS = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    return (
        <MenuItem onClick={() => { dispatch(employeeActions.setIsEmployeeCreate(true)); }}><div className="group w-full h-full">
            <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><AddEmp className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">{t("header.laboratory_menu.add_employee")}</h3></div>
        </div></MenuItem>
    );
};

export const Lims = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    return (
        <MenuItem onClick={() => { navigate("/lims/test/service") }}>
            <div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><BookTest className="stroke-mGray group-hover:stroke-primaryDark !w-[21px] !h-[21px]" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryDark">{t("customer.dashboard.mini_sidebar.book_test") as any}</h3></div>
            </div></MenuItem>
    );
};

export const CRM = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    return (
        <MenuItem onClick={() => { dispatch(crmActions.setIsCustomerCreate(true)); }}> <div className="group w-full h-full">
            <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><AddEmp className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">{t("header.crm_menu.add_customer")}</h3></div>
        </div></MenuItem >
    );
};

export const Invoice = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <>
            <MenuItem onClick={() => { dispatch(invoiceActions.setIsCustomerCreate(true)); }}><div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><AddEmp className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Add Customer</h3></div>
            </div></MenuItem>
            <MenuItem onClick={() => { navigate("/invoice/new-proforma-invoice") }}><div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><AddTask className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Proforma Invoice</h3></div>
            </div></MenuItem>
            <MenuItem onClick={() => { navigate("/invoice/new-tax-invoice") }}>
                <div className="group w-full h-full">
                    <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><SalarySlip className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Tax Invoice</h3></div>
                </div></MenuItem>
            <MenuItem onClick={() => { dispatch(invoiceActions.setSelectCustOpen(true)) }}><div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]">
                    <div className='w-[20px] flex items-center justify-center'>
                        <h3 className='text-mGray text-xl font-normal group-hover:text-primaryLight'>₹</h3>
                    </div>
                    <h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Record Payment</h3>
                </div>
            </div></MenuItem>
            <MenuItem onClick={() => { dispatch(invoiceActions.setCreditNote(true)) }}><div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><RaiseCreditNote className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Raise Credit Note</h3></div>
            </div></MenuItem>
            <MenuItem onClick={() => { navigate("/invoice/new-bill-of-supply-invoice") }}><div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><BillOfSupply className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Bill of Supply</h3></div>
            </div></MenuItem>
        </>
    );
};

export const Inventory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <MenuItem onClick={() => { navigate("/inventory/add-product") }}><div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><ItemIdIcon className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Add Product</h3></div>
            </div></MenuItem>
            <MenuItem onClick={() => { dispatch(inventoryActions.setCreateIndent(true)); }}><div className="group w-full h-full">
                <div className="bg-white group-hover:bg-[#f4f4f4] gap-[12px] py-[9px] px-[11px] flex items-center rounded-[4px]"><CreateOrderIcon className="fill-mGray group-hover:fill-primaryLight" /><h3 className="text-mGray text-base font-normal group-hover:text-primaryLight">Create Order</h3></div>
            </div></MenuItem>
        </>
    );
};