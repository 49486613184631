/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { RegisterArrow, SampleTestClose, TestOnsite } from "../../svg/AllSvg";
import { labSampleActions } from "../../../shared/store/labSample-Slice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useAxios from "../../../shared/hooks/use-axios";
import Service from "../../../skeleton/components/customer/Service";
import { useTranslation } from "react-i18next";

const DepartmentPopup = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const deptPopup: boolean = useSelector((state: any) => state.labSample.deptPopup);
    const selectDept: any = useSelector((state: any) => state.labSample.selectDept);
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
    const [deptList, setDeptList] = useState<any[]>([])
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const getDepartment = async () => {
        try {
            const res: any = await useAxios.get(`samples/forwarded-department-list/?sample=${sampleDetail?.id}`);

            if (res && res.status === 200) {
                setIsLoading(false)
                setDeptList(res?.data?.results)
            }
        } catch (error) {
            console.error("error while fetching department", error);
        }
    };

    useEffect(() => {
        getDepartment();
    }, [])

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(labSampleActions.setDeptPopup(false))
        }
    };

    return (
        <>
            {deptPopup &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-[382px] sm:w-[600px] sm:h-auto md:w-auto overflow-y-scroll p-[25px] md:p-[40px]">
                            <h3 className="text-primaryLight font-semibold text-base xl:text-lg">{t("lab_sample.report.choose_dept")}</h3>

                            <div className="flex items-center justify-center flex-col sm:flex-row pt-[30px] gap-[16px] sm:gap-[12px]">
                                {isLoading ?
                                    <Service /> :
                                    <>
                                        {Array.isArray(deptList) && deptList.map((item: any, index: number) => (
                                            <div key={index} className={`${item?.department_id === selectDept?.department_id ? "border border-primary shadow-[0px_18px_88px_-4px_rgba(24,39,75,0.14),0px_8px_28px_-6px_rgba(24,39,75,0.12)]" : "border border-[#EFEFEF] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)]"} w-[180px] h-[160px] bg-white rounded-[10px] flex flex-col items-center justify-center gap-[4px] hover:bg-[#edfaff] cursor-pointer`} onClick={() => dispatch(labSampleActions.setSelectDept(item))}>
                                                <TestOnsite className={`${selectDept?.department_id === item?.department_id ? "fill-primaryLight" : "fill-[#CBD5E1]"}`} />
                                                <h3 className={`${item?.department_id === selectDept?.department_id ? "text-primaryLight" : "text-mGray"} font-normal text-sm`}>{item?.department}</h3>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>

                            <div className="w-full pt-[40px] flex items-end justify-end">
                                <button className="bg-primaryLight px-[20px] py-[8px] ss:px-[40px] gap-[8px] ss:py-[10px] shadow-[0px_-1px_1px_1px_rgba(67,94,171,0.25)_inset,0px_1px_1px_1px_rgba(255,255,255,0.25)_inset,0px_0px_0px_1px_#435EAB_inset,0px_1px_2px_0px_rgba(20,14,62,0.25)] rounded-[10px] focus:!shadow-[0_0_0_0.20rem_#b9ccf7] hover:!shadow-[0_0_0_0.20rem_#b9ccf7] text-white text-sm font-medium whitespace-nowrap flex items-center disabled:opacity-[0.3] disabled:cursor-not-allowed" onClick={() => { dispatch(labSampleActions.setParameterPopup(true)); dispatch(labSampleActions.setDeptPopup(false)); }} disabled={!!selectDept ? false : true}>{t("common_btn.next")} <RegisterArrow className="fill-white" /></button>
                            </div>

                            <div className="absolute top-[15px] right-[15px] cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => dispatch(labSampleActions.setDeptPopup(false))}>
                                <SampleTestClose />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default DepartmentPopup;
