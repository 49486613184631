import { useSelector } from 'react-redux';
import { NavChat, NavNote, NavTodo } from '../../svg/AllSvg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../shared/utils/theme';
import { uiActions } from '../../../shared/store/ui-Slice';

const ActivitySidebar: React.FC<any> = ({ setIsLoading }: any) => {
    const scrollY: boolean = useSelector((state: any) => state.ui.scrollY);
    const sideBarOption: string = useSelector((state: any) => state.ui.sideBarOption);
    const moduleName: string = useSelector((state: any) => state.ui.moduleName);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    let option: any[] = [
        // { name: t("activity_menu.all"), icon: <NavAll /> },
        // { name: t("activity_menu.sample"), icon: <NavSample width="21" height="21" /> },
        { name: t("activity_menu.todo"), icon: <NavTodo width="23" height="23" /> },
        // { name: t("activity_menu.mail"), icon: <NavMail width="21" height="21" /> },
        { name: t("activity_menu.note"), icon: <NavNote /> },
        { name: t("activity_menu.chat"), icon: <NavChat /> },
        // { name: t("activity_menu.reminder"), icon: <NavReminder /> },
        // { name: t("activity_menu.stats"), icon: <NavStats /> },
    ]

    const handleOpen = (name: any) => {
        if (name !== sideBarOption) {
            dispatch(uiActions.switchOption(name));
            setIsLoading(true);
        }
        dispatch(uiActions.Open());
    }

    return (
        <div className={`${scrollY ? "top-[75px] h-[calc(100vh-75px)]" : "top-[70px] h-[calc(100vh-70px)]"} flex justify-start items-start fixed right-0 z-[4]`}>
            <div className="!h-full !w-[50px] bg-white flex items-center flex-col justify-between">
                <div className="flex items-center flex-col w-full h-full">
                    {Array.isArray(option) && option.map((item: any, index: number) => (
                        <div key={index} className={`${(sideBarOption === item?.name) ? `${theme[moduleName]?.text} font-medium ${theme[moduleName]?.stroke} bg-[#F8FAFB]` : "text-lGray font-normal stroke-lGray bg-white"} py-[15px] w-full flex flex-col items-center justify-center text-center cursor-pointer hover:bg-[#F8FAFB]  ${theme[moduleName]?.hovertext} hover:font-medium ${theme[moduleName]?.hoverstroke} text-xs gap-y-[4px]`} onClick={() => handleOpen(item?.name)}>
                            {item?.icon}
                            <h3>{item?.name}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ActivitySidebar;