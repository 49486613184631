import { useSelector } from "react-redux";
import { DangerDelete } from "../svg/AllSvg";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { theme } from "../../shared/utils/theme";

const Tags: React.FC<any> = ({ option, tagList, setOption }: any) => {
    const moduleName: string = useSelector((state: any) => state.ui.moduleName);
    const [close, setClose] = useState<string>("");
    const navigate = useNavigate();
    const params = useParams();

    return (
        <div className="flex flex-wrap items-center gap-[20px]">
            {Array.isArray(tagList) && tagList.map((item: any, index: number) => (
                <div className={`${option === item?.name ? `${theme[moduleName]?.bg} text-white` : "font-normal bg-white text-lGray"} relative rounded-[10px] px-[15px] py-[11px] min-h-[40px] text-sm cursor-pointer whitespace-nowrap flex items-center justify-center font-normal`} key={index} onClick={() => { setOption(item?.name); !!params?.tab ? navigate(window.location.pathname?.replace(params?.tab, item?.name)) : !!params?.id ? navigate(window.location.pathname + "/" + item?.name + "/" + params?.id) :  navigate(window.location.pathname + "/" + item?.name)}} onMouseEnter={() => setClose(item?.name)} onMouseLeave={() => setClose("")}>
                    {item?.display_name}
                    {close !== item?.name && <p className={`absolute top-[-10px] px-[5px] min-w-[30px] h-[20px] right-[-15px] flex items-center justify-center text-[0.625rem] rounded-[5px] font-semibold whitespace-nowrap ${option === item?.name ? "bg-[rgba(215,232,255,0.80)] text-primaryLight" : "text-white bg-[#cbd5e1]"}`}>{item?.count}</p>}
                    {(close === item?.name) && <p className="absolute top-[-10px] min-w-[23px] right-[-12px] h-[20px] flex items-center justify-center text-[0.625rem] rounded-[5px] font-normal bg-[#ffe0e0] whitespace-nowrap"><DangerDelete /></p>}
                </div>
            ))
            }
            {/* <div className="cursor-pointer pl-[8px]">
                <AddMoreTag className="xl:w-[25px] xl:h-[26px] w-[20px] h-[20px] !stroke-primaryLight" />
            </div> */}
        </div >
    );
};

export default Tags;