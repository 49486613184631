/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useAxios from "../../shared/hooks/use-axios";
import { settingActions } from "../../shared/store/setting-Slice";
import { useDispatch } from "react-redux";
import { LabelLine } from "../svg/AllSvg";

const CustomFieldInfo = ({ modal, fillModal, id, grid, page }: any) => {
    const [data, setData] = useState<any[]>([]);
    const [obj, setObj] = useState<any>(null);
    const dispatch = useDispatch();

    const getData = async () => {
        try {
            const res: any = await useAxios.get(`utility/sections-wise-fields/?model=${modal}`);

            if (res && res.status === 200) {
                let arr: any[] = res?.data?.sections;
                let arr1: any[] = [];
                let obj1: any = {};
                if (res?.data?.defaults?.length > 0) {
                    obj1.id = res?.data?.sections?.length + 1
                    obj1.name = "Default"
                    obj1.fields = res?.data?.defaults

                    arr.push(obj1)
                }

                for (let i = 0; i < arr?.length; i++) {
                    const el = arr[i];
                    el.flag = false;
                    arr1.push(el)
                }

                setData(arr1)

                if (arr1?.length > 0) {
                    dispatch(settingActions.setCustomField(true))
                } else {
                    dispatch(settingActions.setCustomField(false))
                }
            }
        } catch (error) {
            console.error("error while getting custom field data", error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const fillData = async () => {
        try {
            const res: any = await useAxios.get(`samples/api/get-custom-field-values/?model=${fillModal}&id=${id}`);

            if (res && res.status === 200) {
                if (res?.data.length > 0) {
                    let obj: any = {};
                    for (let i = 0; i < res?.data.length; i++) {
                        const el = res?.data[i];
                        if (el?.datatype === "int") {
                            obj[el?.slug] = !!el?.value_int ? el?.value_int : "";
                        }
                        if (el?.datatype === "text") {
                            obj[el?.slug] = !!el?.value_text ? el?.value_text : "";
                        }
                        if (el?.datatype === "enum") {
                            obj[el?.slug] = !!el?.choice_value ? { label: el?.choice_value, value: el?.choice_value } : "";
                        }
                        if (el?.datatype === "date") {
                            obj[el?.slug] = !!el?.choice_value ? el?.value_date : "";
                        }
                    }
                    setObj(obj);
                }
            }
        } catch (error) {
            console.error("error while fill data", error);
        }
    };

    useEffect(() => {
        (!!id && !!fillModal) && fillData();
        // eslint-disable-next-line
    }, [id, fillModal]);


    return (
        <>
            {Array.isArray(data) && data?.map((item: any, index: number) => (
                <React.Fragment key={index}>
                    {
                        !!page && page === "lims" ?
                            <div className="flex items-center gap-[10px]">
                                <div className="w-[4px] h-[16px]">
                                    <LabelLine className="fill-dBlue" />
                                </div>
                                <h2 className="h-full text-dBlue text-base font-normal leading-[20px]">
                                    {item?.name}
                                </h2>
                            </div> :
                            !!page && page === "sample" ?
                                <h3 className="text-primaryLight text-sm font-normal">{item?.name}</h3>
                                :
                                <h1 className="text-primaryLight text-base border-l-2 border-primaryLight pl-[11px] mt-[30px] mb-[20px]">{item?.name}</h1>
                    }

                    <div className={`grid grid-cols-1 sm:grid-cols-2 ${grid === 1 ? "lg:grid-cols-1" : grid === 2 ? "lg:grid-cols-2" : grid === 3 ? "lg:grid-cols-3" : "lg:grid-cols-4"} gap-x-[40px] gap-y-[20px] ${data?.length !== (index + 1) && "border-b border-[#E9E9E9] pb-[40px]"}`}>
                        {Array.isArray(item?.fields) && item?.fields?.map((i: any, idx: number) => (
                            <React.Fragment key={idx}>
                                <div className={`flex flex-col w-full ${i?.width === 1 ? "lg:col-span-3" : i?.width === 2 ? "lg:col-span-2" : "lg:col-span-1"}`} >
                                    <h3 className="text-mGray text-xs font-normal">{i?.name}</h3>
                                    {!!obj ? <p className="text-dGray text-sm font-normal">{!!obj[i?.slug] ? obj[i?.slug] : "-"}</p> : <p className="text-dGray text-sm font-normal">-</p>}
                                </div>
                            </React.Fragment>
                        ))}
                    </div >
                </React.Fragment >
            ))}
        </>
    );
};

export default CustomFieldInfo;
