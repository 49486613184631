/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { HeaderCreate, HeaderLanguageIcon, HeaderNotificationIcon, HeaderProfileArrow, HeaderSearch, Menubar, RoleChangeIcon } from '../../svg/AllSvg';
import { InputText } from 'primereact/inputtext';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import { languages } from '../../../shared/utils/languages';
import { Dropdown } from 'primereact/dropdown';
import ProfilePopup from '../Common/ProfilePopup';
import { useSelector } from 'react-redux';
import { useUserDetail } from '../../../shared/hooks/use-userDetail';
import RolePopup from '../Common/RolePopup';
import { randomColor } from '../../../shared/utils/randomColor';
import ModuleSwitch from './ModuleSwitch';
import { Menu, MenuButton } from '@szhsin/react-menu';
import { theme } from '../../../shared/utils/theme';
import { CRM, HRMS, Inventory, Invoice, Lims } from './HeaderCreateMenu';
import { useNavigate } from 'react-router-dom';

const Header: React.FC<any> = ({ setIsSearch, setNotification }: any) => {
    const user: any = useSelector((state: any) => state.auth.user);
    const roles: any = useSelector((state: any) => state.auth.roles);
    const moduleName: string = useSelector((state: any) => state.ui.moduleName);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [profile, setProfile] = useState<boolean>(false);
    const [switchModule, setSwitchModule] = useState<boolean>(false);
    const [language, setLanguage] = useState<any>({ label: "EN", code: "en" });
    const [roleOpen, setRoleOpen] = useState<any>(false);
    const { i18n } = useTranslation('common');
    const ref: any = useRef();
    const ref1: any = useRef();
    const ref2: any = useRef();
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const createMenu: any = {
        "hrms": <HRMS />,
        "lims": <Lims />,
        "invoice": <Invoice />,
        "crm": <CRM />,
        "inventory": <Inventory />
    }
    useUserDetail();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                setRoleOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref2.current && !ref2.current.contains(event.target)) {
                setSwitchModule(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref2]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setProfile(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    const handleClick = (lang: any) => {
        i18n.changeLanguage(lang);
    };

    const handleSearch = (e: any) => {
        if (e.key === "Enter") {
            setIsSearch(true)
        }
    }

    useEffect(() => {
        // multilabguage setup
        if (localStorage.getItem("i18nextLng")) {
            let lang = languages.find((item: any) => item?.code === localStorage.getItem("i18nextLng"))
            if (lang) {
                i18n.changeLanguage(lang?.code);
                setLanguage(lang);
            } else {
                setLanguage({ label: "EN", code: "en" });
                i18n.changeLanguage("en");
            }
        }
    }, []);

    const handleNotification = (e: any) => {
        if (e?.key === "Enter") {
            !!setNotification && setNotification(true)
        }
    }

    return (
        <>
            <div className="w-full h-[70px] bg-white py-[14px] px-[20px] sm:px-[28px] flex items-center fixed top-0 left-0 right-0 z-10">

                {/* Backdrop */}
                <div>
                    <span
                        id="menu-remove"
                        className={`fixed top-0 left-0 z-20 ${sidebarOpen ? "block" : "hidden"
                            } h-screen w-full bg-[#00000080] lg:hidden`}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    ></span>
                </div>

                <div className='flex items-center justify-start gap-[17px] w-full'>
                    {moduleName !== "customer" && <div className='relative' ref={ref2}>
                        <div className={`rounded-[10px] !p-[8px] ${theme[moduleName]?.bg} flex items-center justify-center cursor-pointer`} onClick={() => setSwitchModule(!switchModule)}>
                            <Menubar className="stroke-white" />
                        </div>
                        {switchModule && <ModuleSwitch setSwitchModule={setSwitchModule} />}
                    </div>}

                    {moduleName === "customer" ?
                        <div className='flex flex-col cursor-pointer' onClick={() => navigate("/customer/dashboard")}>
                            {!window.location.host.toString().includes("enviro") ?
                                <img src={require("../../../assets/images/logo7.png")} alt="" className='h-[35px] w-[108px]' />
                                :
                                <img src={require("../../../assets/images/logo16.jpeg")} alt="" className='h-[40px] w-[150px]' />}

                            <div className='flex items-center justify-end w-full ml-[-5px]'>
                                <h3 className='text-lGray font-normal text-[6.393px]'>Powered By</h3>
                                <div className='flex items-center'>
                                    <h3 className='text-primaryLight italic font-bold text-[7.916px]'>Vista</h3>
                                    <span className='text-[#E4223E] italic font-bold text-[7.916px]'>ERP</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='flex items-center justify-start gap-[8px] w-full'>
                            {/* Logo */}
                            <img src={(!!moduleName && moduleName !== "customer") ? require(`../../../assets/images/${moduleName}.png`) : ""} alt="logo" className='focus:!outline-none object-cover flex items-center h-[18px] sm:h-[22px]' />
                            <div className={`w-[1.85px] ${theme[moduleName]?.bg} h-[18px] sm:h-[23px] mt-[2px]`}></div>
                            <div className={`${theme[moduleName]?.text} text-[18px] sm:text-[25px] font-light leading-normal ${(moduleName === "hrms" || moduleName === "lims" || moduleName === "crm") ? "uppercase" : "capitalize"}`}>{moduleName}</div>
                        </div>
                    }
                </div>

                <div className="items-center w-full gap-[10px] hidden lg:flex">
                    {/* Search */}
                    <span className="p-input-icon-left w-full xl:!w-[332px]">
                        <InputText className="placeholder:!text-[#ADB8CC] !w-full !py-[9px] !rounded-[10px] !border-[#CDCDCD] !text-sm placeholder:!text-sm focus:!border-[#CBD5E1] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] bg-white" placeholder={t("header.employee_search")} onKeyDown={(e: any) => handleSearch(e)} onClick={() => setIsSearch(true)} />
                        <HeaderSearch className="fill-mGray" />
                    </span>

                    {/* Create */}
                    <Menu className="header-menu" align="end" menuButton={<MenuButton disabled={(!!moduleName && !!createMenu[moduleName]) ? false : true}>
                        <div className='rounded-[10px] border border-[rgba(32,50,143,0.30)] bg-[rgba(183,194,255,0.10)] !py-[10px] !px-[9.5px] relative hidden lg:block cursor-pointer'>
                            <HeaderCreate />
                        </div>
                    </MenuButton>}>
                        {(!!moduleName && !!createMenu[moduleName]) && createMenu[moduleName]}
                    </Menu>
                </div >

                <div className="items-center justify-end w-full gap-[8px] flex pl-[8px]">
                    {/* Mobile Search */}
                    <div className='relative lg:hidden rounded-[10px] !w-9 !h-9 flex items-center justify-center cursor-pointer border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)]' onClick={() => setIsSearch(true)}>
                        <HeaderSearch className={`${theme[moduleName]?.fill}`} />
                    </div>
                    <Menu className="header-menu" align="end" menuButton={<MenuButton disabled={(!!moduleName && !!createMenu[moduleName]) ? false : true}>
                        <div className='rounded-[10px] border border-[rgba(32,50,143,0.30)] bg-[rgba(183,194,255,0.10)] !py-[7px] !px-[6.5px] relative lg:hidden block cursor-pointer'>
                            <HeaderCreate />
                        </div>
                    </MenuButton>}>
                        {(!!moduleName && !!createMenu[moduleName]) && createMenu[moduleName]}
                    </Menu>

                    {/* Language */}
                    <div className="rounded-[10px] pl-[12px] py-[5.21px] border-[0.25px] border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] hidden lg:flex items-center gap-[8px] cursor-pointer">
                        <div className='mr-[-10px]'>
                            <HeaderLanguageIcon className={`${theme[moduleName]?.fill}`} />
                        </div>
                        <Dropdown className='!border-none !px-[4px] emp-language !bg-white' value={language} onChange={(e: any) => { setLanguage(e.value); handleClick(e?.value?.code); }} options={languages} optionLabel="label" />
                    </div>

                    {/* Notification */}
                    <button className="rounded-[10px] p-[3px] lg:px-[6px] lg:py-[6px] border-[0.25px] border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] flex items-center justify-center cursor-pointer" onClick={() => !!setNotification && setNotification(true)} onKeyDown={(e: any) => handleNotification(e)}>
                        <HeaderNotificationIcon className={`${theme[moduleName]?.fill}`} />
                    </button>

                    {/* Role */}
                    {moduleName !== "customer" && <div className='relative cursor-pointer hidden lg:block' ref={ref1}>
                        <div className="relative rounded-[10px] px-[12px] py-[6.5px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] gap-[8px] flex items-center cursor-pointer" onClick={() => setRoleOpen(!roleOpen)}>
                            <RoleChangeIcon className={`${theme[moduleName]?.fill}`} />
                            <p className='text-[#7C7C7C] font-medium text-xs w-[40px] ml:w-[90px] truncate'>{user?.role}</p>
                            {roles?.length > 0 && <HeaderProfileArrow />}
                        </div>
                        {(roleOpen && roles?.length > 0) && <RolePopup roleOpen={roleOpen} setRoleOpen={setRoleOpen} sidebar={false} />}
                    </div>}

                    {/* Profile */}
                    <div className="relative rounded-[10px] py-[6px] px-[10px] border-[0.25px] profile-menu border-[#CDCDCD] hidden lg:flex items-center justify-center cursor-pointer gap-[5px] !shadow-[0px_1px_2px_-2px_rgba(24,39,75,0.12)]" ref={ref} onClick={() => setProfile(!profile)}>
                        {/* <img src={require("../../../assets/images/avatar9.png")} alt="" /> */}
                        <div style={{ background: `${randomColor[user?.username?.charAt(0)?.toLowerCase()]}` }} className='w-[26px] h-[26px] rounded-full uppercase text-white font-bold text-xs flex items-center justify-center'>{user?.username?.charAt(0)}{user?.lastName?.charAt(0)}</div>
                        <p className="text-primaryLight text-sm font-medium w-[40px] ml:w-auto truncate">{user?.username + " " + user?.lastName}</p>
                        <HeaderProfileArrow />

                        {profile && <ProfilePopup profile={profile} />}
                    </div>

                    {/* Mobile Sidebar Icon */}
                    <div className='relative lg:hidden rounded-[10px] !w-9 !h-9 flex items-center justify-center cursor-pointer border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)]' onClick={() => setSidebarOpen(true)}>
                        <span className={`absolute opacity-[0.8] w-[18px] h-[4px] ${theme[moduleName]?.bg} rounded-[1px] top-[8px] sm:top-[8px]`}></span>
                        <span className={`absolute opacity-[0.8] w-[18px] h-[4px] ${theme[moduleName]?.bg} rounded-[1px] top-[14px] sm:top-[14px]`}></span>
                        <span className={`absolute opacity-[0.8] w-[18px] h-[4px] ${theme[moduleName]?.bg} rounded-[1px] top-[20px] sm:top-[20px]`}></span>
                    </div>
                </div>

                {/* Mobile Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} language={language} setLanguage={setLanguage} handleClick={handleClick} />
            </div >
        </>
    );
};

export default Header;