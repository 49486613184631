/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ClosePopup, DownloadIcon, FileUploadIcon, GroupClose, InfoWarnIcon, LoadingBtn, NameOrgIcon, PaymentArrow, PriceWallet, RemoveMore } from "../../../svg/AllSvg";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import useAxios from "../../../../shared/hooks/use-axios";
import { Link } from "react-router-dom";
import Moment from 'moment';
import { success } from "../../../../shared/hooks/use-toastify";
import { Checkbox } from "primereact/checkbox";
import PrimaryInput from "../../../form/PrimaryInput";
import PrimaryTextarea from "../../../form/PrimaryTextarea";
import { uiActions } from "../../../../shared/store/ui-Slice";
import { tableActions } from "../../../../shared/store/table-Slice";
import { randomColor } from "../../../../shared/utils/randomColor";

const RecordPaymentPopup = () => {
  const [submit, setSubmit] = useState<boolean>(false);
  const recordPayment: boolean = useSelector((state: any) => state.invoice.recordPayment);
  const invoiceDetail: any = useSelector((state: any) => state.invoice.invoiceDetail);
  const subModule: string = useSelector((state: any) => state.ui.subModule);
  const [refNo, setRefNo] = useState<any>("")
  const [paymentDate, setPaymentDate] = useState<any>(new Date().toLocaleDateString('en-CA'))
  const [amount, setAmount] = useState<any>(+invoiceDetail?.amount_left?.replace("₹", ""))
  const [walletAmt, setWalletAmt] = useState<any>("")
  const [walletWarning, setWalletWarning] = useState<boolean>(false)
  const [note, setNote] = useState<any>("")
  const [files, setFiles] = useState<any>([]);
  const [file, setFile] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [disable, setDisable] = useState<boolean>(false);
  const [online, setOnline] = useState<boolean>(true);
  const [wallet, setWallet] = useState<boolean>(false);
  const dispatch = useDispatch();

  const simulateChunkUpload = (chunk: any) => {
    console.log(chunk, "1MB")
    return new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      if (files?.length === 1 || acceptedFiles?.length > 1) {
        return;
      } else {
        setUploadProgress(0);
        setDisable(true)
        setFiles([...files, { file: acceptedFiles, url: URL.createObjectURL(acceptedFiles[0]) }]);
        setFile([...file, acceptedFiles[0]])

        let uploadFile = acceptedFiles[0];
        if (uploadFile) {
          const chunkSize = 1024 * 1024; // 1MB chunk size
          const totalChunks = Math.ceil(uploadFile.size / chunkSize);
          let currentChunk = 0;

          const uploadNextChunk = () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, uploadFile.size);
            const chunk = uploadFile.slice(start, end);

            simulateChunkUpload(chunk)
              .then(() => {
                currentChunk++;

                if (currentChunk < totalChunks) {
                  const progress = Math.floor((currentChunk / totalChunks) * 100);
                  setUploadProgress(progress);

                  uploadNextChunk();
                } else {
                  setUploadProgress(100);
                  setDisable(false)
                }
              })
              .catch((error) => {
                console.error('Error uploading chunk', error);
              });
          };

          uploadNextChunk();
        }
      }
    }
  });

  const removeFile = (idx: number) => {
    let arr: any[] = [];
    let arr1: any[] = [];
    for (let i = 0; i < files.length; i++) {
      const el = files[i];

      if (idx !== i) {
        arr.push(el)
        arr1.push(el?.file[0])
      }
    }
    setFiles(arr);
    setFile(arr1)
  };

  const thumbs = files.map((file: any, idx: number) => (
    <div key={idx} className="border border-[#D0D0D0] rounded-[10px] h-[40px] flex flex-col items-center w-full relative bg-white shadow-[0px_8px_8px_-4px_rgba(24,39,75,0.08),0px_4px_6px_-4px_rgba(24,39,75,0.12)]">
      <div className='w-full flex items-center justify-between h-full px-[19px]'>
        <h3 className="text-dGray text-sm font-normal truncate">{file?.file[0]?.name}</h3>

        {(uploadProgress !== 100 && (idx + 1 === files?.length)) ?
          <div className="flex items-center gap-[10px]">
            <h1 className="text-primaryLight text-sm font-normal">Uploading {uploadProgress}%</h1>
            <div className="cursor-pointer" onClick={() => removeFile(idx)}>
              <GroupClose />
            </div>
          </div> :
          <div className="flex items-center gap-[10px]">
            <Link to={file?.url} download={file?.url} target="_blank" className="cursor-pointer">
              <DownloadIcon />
            </Link>
            <div className="cursor-pointer" onClick={() => removeFile(idx)}>
              <RemoveMore />
            </div>
          </div>
        }
      </div>
    </div>
  ));

  // useEffect(() => {
  //   if(wallet) {
  //     let amt: any = 0;
  //     if(amount > +invoiceDetail?.customer_details?.wallet) {
  //       amt = amount - +invoiceDetail?.customer_details?.wallet;
  //       setAmount(amt);
  //     }

  //     if(amount < +invoiceDetail?.customer_details?.wallet) {
  //       setAmount(0);
  //     }
  //   }

  //   if(!wallet) {
  //     setAmount(invoiceDetail?.invoice_amount)
  //   }
  // }, [wallet])

  const handleSubmit = async () => {
    setSubmit(true)
    try {
      let invoice: any = {
        id: invoiceDetail?.id,
        type: invoiceDetail?.invoice_type
      }
      let formdata = new FormData();
      formdata.append("customer", invoiceDetail?.customer);
      formdata.append("order", invoiceDetail?.order);
      // formdata.append("transaction_id", refNo);
      !!paymentDate && formdata.append("payment_date", paymentDate);
      formdata.append("payment_mode", online ? 0 as any : 1 as any);
      file?.length > 0 && formdata.append("file", file[0]);
      formdata.append("remarks", note);
      formdata.append("amount", +amount as any);
      if (wallet) {
        formdata.append("wallet_amount", +walletAmt as any);
      }
      formdata.append("wallet", wallet as any);
      formdata.append("invoice", JSON.stringify(invoice));

      const res: any = await useAxios.post("payment/record-invoice-paymentss/", formdata);

      if (res && res.status === 200) {
        success("Record Payment Successfully.")
        if (subModule === "tax-invoice") {
          dispatch(uiActions.setModule("invoice"))
          dispatch(uiActions.setSubModule("tax-invoice"))
          dispatch(tableActions.setSelectOption("PartialPaidInvoice"))
          dispatch(tableActions.changeOption({ name: "PartialPaidInvoice", module: 'invoice' }))
        } else {
          dispatch(uiActions.setModule("invoice"))
          dispatch(uiActions.setSubModule("bill-of-supply"))
          dispatch(tableActions.setSelectOption("PartialPaidInvoice"))
          dispatch(tableActions.changeOption({ name: "PartialPaidInvoice", module: 'invoice' }))
        }
        dispatch(invoiceActions.setTagApiCall(true))
        dispatch(invoiceActions.setApiCall("invoice"));
        dispatch(invoiceActions.setRecordPayment(false))
        setSubmit(false)
      }
    } catch (error: any) {
      setSubmit(false)
      console.error("error while submit record payment ", error);
    }
  }

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(invoiceActions.setRecordPayment(false))
    }
  };

  return (
    <>
      {recordPayment && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
        <div className="flex justify-center items-center">
          <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[645px] z-[10]">
            <div className="relative">
              <div className="cursor-pointer absolute top-[22px] right-[19px] z-[10]" onClick={() => dispatch(invoiceActions.setRecordPayment(false))}>
                <ClosePopup />
              </div>
            </div>
            <div className="flex flex-col px-[28px] py-[23px] rounded-[20px] bg-white">
              <div className="flex items-center gap-[15px]">
                <div className="flex flex-col items-start">
                  <h3 className="text-primaryLight font-semibold text-lg">Record Payment</h3>
                  <p className="text-mGray font-normal text-sm">{Moment(invoiceDetail?.created_at).format('DD MMM YYYY')}, Due on {Moment(invoiceDetail?.invoice_due_date).format('DD MMM YYYY')}</p>
                </div>
              </div>

              <div className="rounded-[10px] mt-[20px] w-full min-h-[71px] border border-[#E8E8E8] bg-[#FDFDFD] shadow-[0px_8px_16px_-6px_rgba(24,39,75,0.08),0px_6px_8px_-6px_rgba(24,39,75,0.12)] flex items-center !justify-between px-[13px] flex-col sm:flex-row gap-[4px] p-[12px]">
                <div className="flex items-center gap-[16px]">
                  {!!invoiceDetail?.customer_details?.address?.org_name ?
                    <div style={{ backgroundColor: `${randomColor[invoiceDetail?.customer_details?.address?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-2xl rounded-full w-[49px] h-[49px] flex items-center justify-center`}>{invoiceDetail?.customer_details?.address?.org_name?.charAt(0)}</div>
                    : <div style={{ backgroundColor: `${randomColor[invoiceDetail?.customer_details?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-2xl rounded-full w-[49px] h-[49px] flex items-center justify-center`}>{invoiceDetail?.customer_details?.first_name?.charAt(0)}{invoiceDetail?.customer_details?.last_name?.charAt(0)}</div>}

                  <div className="flex flex-col">
                    <h3 className="text-dGray text-sm font-medium">{invoiceDetail?.customer_details?.first_name + " " + invoiceDetail?.customer_details?.last_name}</h3>
                    {!!invoiceDetail?.customer_details?.address?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{invoiceDetail?.customer_details?.address?.org_name}</span></p>}
                  </div>
                </div>

                <div className="flex flex-col">
                  <p className="text-dGray text-xs font-normal">Invoice Number</p>
                  <h3 className="text-primaryLight text-sm font-medium">{subModule === "tax-invoice" ? invoiceDetail?.invoice_number : invoiceDetail?.bill_supply_number}</h3>
                </div>

                <div className="flex flex-col">
                  <p className="text-dGray text-xs font-normal">Total Amt</p>
                  <h3 className="text-primaryLight text-sm font-medium whitespace-nowrap">{invoiceDetail?.amount_due}</h3>
                </div>
              </div>

              <div className="mt-[25px] flex flex-col gap-[15px]">
                <h1 className="text-primaryLight text-sm font-medium">Amount to be Recorded (₹)</h1>

                <PrimaryInput type="number" min={1} title="Amounts" name="amounts" required={true} value={amount} onChange={(e: any) => setAmount(e.target.value)} exceptThisSymbols={["e", "E", "+", "-"]} />
              </div>
              <h1 className="text-danger text-sm font-medium mt-[4px]">Due Amount {invoiceDetail?.amount_left}</h1>

              <div className="flex flex-col mt-[15px]">
                <h1 className="text-primaryLight text-sm font-medium">Payment Type</h1>
                <div className="flex items-center gap-[8px] mt-[10px]">
                  <button className={`${online ? "bg-[#F6ECFF] text-primaryLight" : "bg-[#EBEBEB] text-mGray"}  rounded-[20px] px-[13px] text-sm font-normal h-[25px] flex items-center justify-center cursor-pointer`} onClick={() => setOnline(true)}>Online</button>
                  <button className={`${!online ? "bg-[#F6ECFF] text-primaryLight" : "bg-[#EBEBEB] text-mGray"} rounded-[20px] px-[13px] text-sm font-normal h-[25px] flex items-center justify-center cursor-pointer`} onClick={() => setOnline(false)}>Offline</button>
                </div>
              </div>

              <div className="bg-[#F5F5F5] rounded-[5px] w-full px-[16px] py-[13px] flex items-center justify-between mt-[20px]">
                <div className="flex flex-col gap-[4px]">
                  <div className="flex items-center gap-[4px]">
                    <PriceWallet />
                    <h4 className="text-primaryLight text-base font-semibold">{invoiceDetail?.customer_details?.wallet}</h4>
                  </div>
                  <div className={`${Math?.floor(invoiceDetail?.customer_details?.wallet) > 0 ? "opacity-1" : "opacity-[0.4]"} flex items-center gap-[8px]`}>
                    <Checkbox disabled={Math?.floor(invoiceDetail?.customer_details?.wallet) > 0 ? false : true} className='tests table-check' checked={wallet} onChange={() => setWallet(!wallet)}></Checkbox>
                    <p className="text-mGray text-xs font-normal">Use wallet balance</p>
                  </div>

                </div>
                <div className="flex items-center gap-[4px]">
                  <div className={`w-[100px] min-h-[40px] px-[18px] flex items-center justify-start rounded-[10px] border border-[#CBCBCB] ${+invoiceDetail?.customer_details?.wallet <= +walletAmt ? "border-[#FFA6A6] bg-[#FEF2F1]" : "border-[#CBCBCB] bg-white"} ${!wallet && "bg-inherit"}`}>
                    <p className="text-mGray text-xs sm:text-sm font-normal flex items-center">₹ <input type="number" min={1} className="text-mGray text-xs sm:text-sm font-normal w-full border-none bg-transparent" onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })} disabled={wallet ? false : true} value={walletAmt} onChange={(e: any) => setWalletAmt(e.target.value)} /></p>
                  </div>
                  {+invoiceDetail?.customer_details?.wallet <= +walletAmt && <div className="cursor-pointer relative" onMouseEnter={() => setWalletWarning(true)} onMouseLeave={() => setWalletWarning(false)}>
                    <InfoWarnIcon className="fill-danger" />

                    {walletWarning && <div className="absolute top-[26px] right-[-15px] bg-[#FEF2F1] rounded-[8px] py-[10px] px-[15px] whitespace-nowrap flex gap-[8px] tracking-wide z-20 divide-[#FEF2F1] before:absolute before:content-[''] before:top-[-1px] before:right-[11px] before:w-3 before:h-3 before:bg-[#FEF2F1] before:-translate-y-1/2 before:-translate-x-1/2 before:rotate-45 text-xs font-normal text-danger shadow-[0px_8px_16px_-2px_rgba(27,33,44,0.12)] items-center before:border-t before:border-l before:border-[#FFA6A6] border border-[#FFA6A6]"><InfoWarnIcon className="fill-danger" /> Max Wallet Balance allowed ₹{+invoiceDetail?.customer_details?.wallet}</div>}
                  </div>}
                </div>
              </div>

              <div className="mt-[22px] grid grid-cols-1 sm:grid-cols-2 gap-[15px]">
                <PrimaryInput type="text" title="Payment Ref. Number" name="payment_ref_no" value={refNo} onChange={(e: any) => setRefNo(e.target.value)} />
                <PrimaryInput type="date" title="Payment Date" name="payment_date" value={paymentDate} onChange={(e: any) => setPaymentDate(e.target.value)} />
              </div>

              <div {...getRootProps()} onClick={(e) => e.stopPropagation} className="rounded-[4px] border-2 border-dashed border-[rgba(8,133,134,0.30)] bg-[rgba(208,252,253,0.05)] min-h-[107px] mt-[18px] flex flex-col items-center justify-center">
                {(uploadProgress === 0 || uploadProgress === 100) && <>
                  <input {...getInputProps()} />
                  <div className="cursor-pointer" onClick={() => files?.length === 0 && open()}>
                    <FileUploadIcon />
                  </div>
                </>}
                <div className="text-dGray text-sm font-medium pt-[5px]">Drag & drop files or <span className="text-primaryLight text-sm font-medium">Browse</span></div>
                <p className="text-lGray text-xs font-normal pt-[2px] text-center">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
              </div>

              <aside className='w-full mt-[15px] flex flex-col gap-[12px]'>{thumbs}</aside>

              <div className="mt-[20px]">
                <PrimaryTextarea title="Add Note" name="note" rows={3} value={note} onChange={(e: any) => setNote(e.target.value)} />
              </div>

              <div className="w-full flex items-end justify-end mt-[26px]">
                <button className="flex items-center gap-[4px] border border-[rgba(32,50,143,0.30)] h-[40px] bg-[rgba(183,194,255,0.10)] pl-[20px] pr-[15px] text-primaryLight text-sm font-medium rounded-[10px] disabled:opacity-[0.3] disabled:cursor-not-allowed" onClick={handleSubmit} disabled={(!!amount && !disable && !submit && +amount > 0) ? false : true}>{!submit ? "Update Payment" :
                  <>
                    <LoadingBtn className="w-5 h-5 text-primaryLight animate-spin" />
                    Update Payment...
                  </>} <PaymentArrow /></button>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default RecordPaymentPopup;