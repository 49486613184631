import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MainRouter from './router';
import AOS from 'aos';
import { useEffect } from "react";

const App: React.FC<any> = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      offset: 0,
      once: true
    });
  }, []);

  return (
    <Router>
      <div>
        <ToastContainer />
        <MainRouter />
      </div>
    </Router>
  );
}

export default App;