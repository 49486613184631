import { useSelector } from "react-redux";
import { SampleTestClose } from "../../svg/AllSvg";
import { useDispatch } from "react-redux";
import { crmActions } from "../../../shared/store/crm-Slice";
import { useTranslation } from "react-i18next";

const SuccessPopup = () => {
    const successCreateGroup: boolean = useSelector((state: any) => state.crm.successCreateGroup);
    const fullName: string = useSelector((state: any) => state.crm.fullName);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(crmActions.setSuccessCreateGroup(false))
        }
    };

    return (
        <>
            {successCreateGroup &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-[382px] sm:w-[600px] md:w-[633px] overflow-y-scroll">
                            <img src={require("../../../assets/images/group-success.png")} alt="" className="absolute top-0 left-0 rounded-t-[20px] !rounded-l-none opacity-[0.4]" />
                            <div className="absolute top-[12px] right-[12px] z-[1]">
                                <div className="cursor-pointer" onClick={() => dispatch(crmActions.setSuccessCreateGroup(false))}>
                                    <SampleTestClose />
                                </div>
                            </div>
                            <div className="w-full h-full flex flex-col items-center justify-center">
                                <img src={require("../../../assets/images/success-bg.png")} alt="" />

                                <h3 className="text-primaryLight text-lg font-semibold pt-[15px]">{t("crm.customer.success.title")}</h3>
                                <p className="text-mGray font-normal text-base max-w-[384px] pt-[5px] text-center">{fullName} {t("crm.customer.success.desc")} </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SuccessPopup;