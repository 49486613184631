import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FilterOption from "./FilterOption";
import { tableActions } from "../../shared/store/table-Slice";
import { SampleTestClose } from "../svg/AllSvg";

const ViewAllList = ({ grid, table }: any) => {
    const viewAll: boolean = useSelector((state: any) => state.table.viewAll);
    const view: string = useSelector((state: any) => state.table.view);
    const dispatch = useDispatch();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(tableActions.setViewAll(false))
        }
    };

    return (
        <>
            {viewAll && <div className="fixed inset-0 z-30 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] sm:w-[600px] md:w-[745px] lg:w-[930px] xl:w-[1230px] xxl:w-[1327px]">
                        <div className="flex items-end w-full justify-end px-[20px] py-[16px] rounded-t-[15px]">
                            <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => dispatch(tableActions.setViewAll(false))}>
                                <SampleTestClose />
                            </div>
                        </div>

                        <div className="px-[20px] pb-[16px]">
                            <div className="w-full rounded-[10px] py-[16px] px-[20px] border border-[#EBEAF2]">
                                <FilterOption type="group" />
                                {(view === "card") && grid}
                                {(view === "table") && table}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default ViewAllList;
