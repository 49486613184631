export const theme: any = {
    customer: {
        "color": "#F5B911",
        "text": "!text-[#F5B911]",
        "bg": "!bg-[#F5B911]",
        "border": "!border-[#F5B911]",
        "fill": "!fill-[#F5B911]",
        "stroke": "!stroke-[#F5B911]",
        "hovertext": "hover:!text-[#F5B911]",
        "hoverbg": "hover:!bg-[#F5B911]",
        "hoverborder": "hover:!border-[#F5B911]",
        "hoverfill": "hover:!fill-[#F5B911]",
        "hoverstroke": "hover:!stroke-[#F5B911]",
        "grouphovertext": "group-hover:!text-[#F5B911]",
        "grouphoverbg": "group-hover:!bg-[#F5B911]",
        "grouphoverborder": "group-hover:!border-[#F5B911]",
        "grouphoverfill": "group-hover:!fill-[#F5B911]",
        "grouphoverstroke": "group-hover:!stroke-[#F5B911]"
    },
    crm: {
        "color": "#2E82F6",
        "text": "!text-[#2E82F6]",
        "bg": "!bg-[#2E82F6]",
        "border": "!border-[#2E82F6]",
        "fill": "!fill-[#2E82F6]",
        "stroke": "!stroke-[#2E82F6]",
        "hovertext": "hover:!text-[#2E82F6]",
        "hoverbg": "hover:!bg-[#2E82F6]",
        "hoverborder": "hover:!border-[#2E82F6]",
        "hoverfill": "hover:!fill-[#2E82F6]",
        "hoverstroke": "hover:!stroke-[#2E82F6]",
        "grouphovertext": "group-hover:!text-[#2E82F6]",
        "grouphoverbg": "group-hover:!bg-[#2E82F6]",
        "grouphoverborder": "group-hover:!border-[#2E82F6]",
        "grouphoverfill": "group-hover:!fill-[#2E82F6]",
        "grouphoverstroke": "group-hover:!stroke-[#2E82F6]"
    },
    lims: {
        "color": "#F7423A",
        "text": "!text-[#F7423A]",
        "bg": "!bg-[#F7423A]",
        "border": "!border-[#F7423A]",
        "fill": "!fill-[#F7423A]",
        "stroke": "!stroke-[#F7423A]",
        "hovertext": "hover:!text-[#F7423A]",
        "hoverbg": "hover:!bg-[#F7423A]",
        "hoverborder": "hover:!border-[#F7423A]",
        "hoverfill": "hover:!fill-[#F7423A]",
        "hoverstroke": "hover:!stroke-[#F7423A]",
        "grouphovertext": "group-hover:!text-[#F7423A]",
        "grouphoverbg": "group-hover:!bg-[#F7423A]",
        "grouphoverborder": "group-hover:!border-[#F7423A]",
        "grouphoverfill": "group-hover:!fill-[#F7423A]",
        "grouphoverstroke": "group-hover:!stroke-[#F7423A]"
    },
    hrms: {
        "color": "#FFA02F",
        "text": "!text-[#FFA02F]",
        "bg": "!bg-[#FFA02F]",
        "border": "!border-[#FFA02F]",
        "fill": "!fill-[#FFA02F]",
        "stroke": "!stroke-[#FFA02F]",
        "hovertext": "hover:!text-[#FFA02F]",
        "hoverbg": "hover:!bg-[#FFA02F]",
        "hoverborder": "hover:!border-[#FFA02F]",
        "hoverfill": "hover:!fill-[#FFA02F]",
        "hoverstroke": "hover:!stroke-[#FFA02F]",
        "grouphovertext": "group-hover:!text-[#FFA02F]",
        "grouphoverbg": "group-hover:!bg-[#FFA02F]",
        "grouphoverborder": "group-hover:!border-[#FFA02F]",
        "grouphoverfill": "group-hover:!fill-[#FFA02F]",
        "grouphoverstroke": "group-hover:!stroke-[#FFA02F]",
    },
    invoice: {
        "color": "#14B370",
        "text": "!text-[#14B370]",
        "bg": "!bg-[#14B370]",
        "border": "!border-[#14B370]",
        "fill": "!fill-[#14B370]",
        "stroke": "!stroke-[#14B370]",
        "hovertext": "hover:!text-[#14B370]",
        "hoverbg": "hover:!bg-[#14B370]",
        "hoverborder": "hover:!border-[#14B370]",
        "hoverfill": "hover:!fill-[#14B370]",
        "hoverstroke": "hover:!stroke-[#14B370]",
        "grouphovertext": "group-hover:!text-[#14B370]",
        "grouphoverbg": "group-hover:!bg-[#14B370]",
        "grouphoverborder": "group-hover:!border-[#14B370]",
        "grouphoverfill": "group-hover:!fill-[#14B370]",
        "grouphoverstroke": "group-hover:!stroke-[#14B370]"
    },
    admin: {
        "color": "#00A6B1",
        "text": "!text-[#00A6B1]",
        "bg": "!bg-[#00A6B1]",
        "border": "!border-[#00A6B1]",
        "fill": "!fill-[#00A6B1]",
        "stroke": "!stroke-[#00A6B1]",
        "hovertext": "hover:!text-[#00A6B1]",
        "hoverbg": "hover:!bg-[#00A6B1]",
        "hoverborder": "hover:!border-[#00A6B1]",
        "hoverfill": "hover:!fill-[#00A6B1]",
        "hoverstroke": "hover:!stroke-[#00A6B1]",
        "grouphovertext": "group-hover:!text-[#00A6B1]",
        "grouphoverbg": "group-hover:!bg-[#00A6B1]",
        "grouphoverborder": "group-hover:!border-[#00A6B1]",
        "grouphoverfill": "group-hover:!fill-[#00A6B1]",
        "grouphoverstroke": "group-hover:!stroke-[#00A6B1]"
    },
    documents: {
        "color": "#00B3E0",
        "text": "!text-[#00B3E0]",
        "bg": "!bg-[#00B3E0]",
        "border": "!border-[#00B3E0]",
        "fill": "!fill-[#00B3E0]",
        "stroke": "!stroke-[#00B3E0]",
        "hovertext": "hover:!text-[#00B3E0]",
        "hoverbg": "hover:!bg-[#00B3E0]",
        "hoverborder": "hover:!border-[#00B3E0]",
        "hoverfill": "hover:!fill-[#00B3E0]",
        "hoverstroke": "hover:!stroke-[#00B3E0]",
        "grouphovertext": "group-hover:!text-[#00B3E0]",
        "grouphoverbg": "group-hover:!bg-[#00B3E0]",
        "grouphoverborder": "group-hover:!border-[#00B3E0]",
        "grouphoverfill": "group-hover:!fill-[#00B3E0]",
        "grouphoverstroke": "group-hover:!stroke-[#00B3E0]"
    },
    inventory: {
        "color": "#91328C",
        "text": "!text-[#91328C]",
        "bg": "!bg-[#91328C]",
        "border": "!border-[#91328C]",
        "fill": "!fill-[#91328C]",
        "stroke": "!stroke-[#91328C]",
        "hovertext": "hover:!text-[#91328C]",
        "hoverbg": "hover:!bg-[#91328C]",
        "hoverborder": "hover:!border-[#91328C]",
        "hoverfill": "hover:!fill-[#91328C]",
        "hoverstroke": "hover:!stroke-[#91328C]",
        "grouphovertext": "group-hover:!text-[#91328C]",
        "grouphoverbg": "group-hover:!bg-[#91328C]",
        "grouphoverborder": "group-hover:!border-[#91328C]",
        "grouphoverfill": "group-hover:!fill-[#91328C]",
        "grouphoverstroke": "group-hover:!stroke-[#91328C]"
    }
}