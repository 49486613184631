import Select from "react-select";
import { useSelect } from "../../shared/hooks/use-select";

const SingleSelect = ({ options, placeholder, name, title, value, onChange, onBlur, isDisabled, minMenuHeight, menuPlacement, maxMenuHeight, isSearchable, bg, cursor, color, components, filterOption }: any) => {
    let styles = useSelect(true, true, "38px", !!cursor ? cursor : "text", !!color ? color : "#6B7280");

    return (
        <div className={`relative w-full ${isDisabled && "opacity-[0.6] bg-[#fafafa]"}`}>
            <Select
                options={options}
                name={name}
                className="w-full relative bg-transparent block appearance-none peer border border-[#CBCBCB] rounded-[6px]"
                placeholder={placeholder}
                styles={styles}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isDisabled={isDisabled}
                minMenuHeight={minMenuHeight}
                menuPlacement={menuPlacement}
                maxMenuHeight={maxMenuHeight}
                isSearchable={isSearchable}
                components={components}
                filterOption={filterOption}
            />
            <label
                htmlFor={name}
                className={`${!!bg ? bg : "bg-white"}  absolute left-[8px] top-[4px] font-normal text-xs text-mGray duration-300 transform -translate-y-4 scale-[0.85] origin-[0] px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none`}
            >
                {title}
            </label>
        </div>
    );
};

export default SingleSelect;
