import { Skeleton } from "primereact/skeleton";

const TableViewLoader = ({ height }: any) => {
    const data: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <div className={`flex flex-col justify-between ${!!height ? "min-h-[calc(100vh-203px)]" : "min-h-[calc(100vh-265px)]"} border border-[#E4E4E4] rounded-[10px]`}>
            <div className="overflow-x-scroll overflow-y-hidden">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="h-[40px] bg-[#F8F7F7]">
                            <th className="border-r border-[#DDD] w-[58px] min-w-[58px] h-full rounded-tl-[10px]">
                                <div className='flex items-center justify-center w-full'>
                                    <div className="min-w-[18px]">
                                        <Skeleton className="!w-[18px] !h-[18px] !rounded-[3px]"></Skeleton>
                                    </div>
                                </div>
                            </th>
                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] h-full">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full rounded-tr-[10px]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(data) && data.map((item: any, index: number) => (
                            <tr className={`${(index + 1 === data?.length) ? "border" : "border border-b-0"} border-l-0 border-r-0 border-[#DDD] hover:bg-[#FAFAFF] h-[40px] min-h-[40px]`} key={index}>
                                <td className="border-r border-[#DDD] w-[58px] min-w-[58px] py-[7.5px]">
                                    <div className='flex items-center justify-center w-full'>
                                        <div className="min-w-[18px]">
                                            <Skeleton className="!w-[18px] !h-[18px] !rounded-[3px]"></Skeleton>
                                        </div>
                                    </div>
                                </td>
                                <td className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] relative py-[7.5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] relative py-[7.5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] relative py-[7.5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] relative py-[7.5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] relative py-[7.5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="border-r border-[#DDD] px-[8px] 2xl:px-[16px] relative py-[7.5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[7.5px]">
                                    <div className="flex items-center justify-center gap-[12px]">
                                        <Skeleton className="!w-[25px] !h-[25px] !rounded-[5px]"></Skeleton>
                                        <Skeleton className="!w-[25px] !h-[25px] !rounded-[5px]"></Skeleton>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableViewLoader;