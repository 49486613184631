import { Skeleton } from "primereact/skeleton";

const Service = () => {
    const cols = [];
    for (let i = 0; i < 2; i++) {
        cols.push(
                <Skeleton key={i} className="!w-[180px] !h-[160px]"></Skeleton>
        )
    };

    return (
        <>{cols}</>
    );
};

export default Service;
