const PrimaryTextarea: React.FC<any> = ({ name, title, onChange, value, rows, bg, disabled, autoFocus }: any) => {
    return (
        <div className={`relative w-full ${disabled && "opacity-[0.6] bg-[#fafafa]"}`}>
            <textarea
                id={name}
                className="bg-transparent block py-[10px] px-[12px] w-full text-sm !text-mGray font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] resize-none focus:shadow-[0_0_0_0.2rem_#EAF9FF] hover:border-[#8D9FFF] focus:border-[#8D9FFF] disabled:border-[#CBCBCB]"
                placeholder=" "
                name={name}
                onChange={onChange}
                value={value}
                rows={!!rows ? rows : 4}
                disabled={disabled}
                autoFocus={autoFocus}
            />
            <label
                htmlFor={name}
                className={`${!!bg ? bg : "bg-white"} absolute whitespace-nowrap font-normal text-xs text-mGray duration-300 transform -translate-y-4 scale-[0.85] top-[4px] origin-[0] px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/3 peer-placeholder-shown:top-[15%] peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text`}
            >
                {title}
            </label>
        </div>
    );
};

export default PrimaryTextarea;