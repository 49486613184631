import { AddMoreTag, DangerClose, GroupExpand, Dots } from "../../svg/AllSvg";
import { useDispatch } from "react-redux";
import { employeeActions } from "../../../shared/store/employee-Slice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Task: React.FC<any> = () => {
    const isOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const dispatch = useDispatch();
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [todo, setTodo] = useState<any[]>([]);
    const [form, setForm] = useState<any>({ title: "", details: "", date: "" })

    useEffect(() => setFormOpen(false), [isOpen]);

    const handleSubmit = (e: any) => {
        if (e?.key === "Enter") {
            if (!!!form?.title) return;
            setTodo([...todo, { title: form?.title, details: form?.details, date: form?.date }]);
            setFormOpen(false);
            setForm({ title: "", details: "", date: "" });
        }
    }

    return (
        <div className="flex flex-col gap-[16px] py-[24px] w-full">
            <div className="flex items-center justify-between border-b border-[#E7F0FF] px-[20px] pb-[20px]">
                <h3 className="text-lg font-normal text-lGray pt-[6px]">My Task</h3>

                <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full !flex !items-center bg-[#f4f8ff] !rounded-full !justify-center !w-6 !h-6 sm:!hidden" onClick={() => dispatch(employeeActions.Close())}>
                    <DangerClose color='#133F97' />
                </div>
            </div>

            <div className="pt-[4px] px-[20px] flex flex-col gap-[12px]">
                <button className="flex items-center gap-[8px] cursor-pointer focus:bg-[#F4F4F4] hover:bg-[#F4F4F4] rounded-full py-[8px] px-[12px]" onClick={() => setFormOpen(!formOpen)}>
                    <AddMoreTag className="w-[20px] h-[20px] !stroke-primaryLight" />
                    <h3 className="text-primaryLight text-[0.938rem] font-medium">Add a task</h3>
                </button>

                {formOpen && <form data-aos="fade-down" action="" className="flex flex-col gap-[4px] pl-[32px]" onKeyDown={(e: any) => handleSubmit(e)}>
                    <input type="text" placeholder="Title" className="py-[8px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal appearance-none peer border-none bg-[#f8f8f8] rounded-md" value={form?.title} onChange={(e: any) => setForm({ ...form, title: e.target.value })} />
                    <input type="text" placeholder="Details" className="py-[8px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal appearance-none peer border-none bg-[#f8f8f8] rounded-md" value={form?.details} onChange={(e: any) => setForm({ ...form, details: e.target.value })} />
                    <input type="date" className="py-[8px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal appearance-none peer border-none bg-[#f8f8f8] rounded-md" value={form?.date} onChange={(e: any) => setForm({ ...form, date: e.target.value })} />
                </form>}

                {Array.isArray(todo) && todo.map((item: any, index: number) => (
                    <div key={index} className="border shadow-md ml-[32px] px-[12px] py-[8px] rounded-lg flex items-center justify-between">
                        <div className="flex items-center gap-[12px]">
                            <GroupExpand />
                            <h3 className="text-sm text-lGray font-medium">{item?.title}</h3>
                        </div>

                        <div className="cursor-pointer">
                            <Dots color="#94A3B8" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Task;