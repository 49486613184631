import { Skeleton } from "primereact/skeleton";

const GroupViewLoader = ({ height }: any) => {
    const data: any[] = [1, 2, 3, 4, 5];

    return (
        <div className={`flex flex-col gap-[14px] ${!!height ? height : "min-h-[calc(100vh-265px)]"} rounded-[5px] border border-[#E4E4E4] p-[10px]`}>
            {Array.isArray(data) && data?.map((item: any, index: number) => (
                <Skeleton key={index} className="!w-full !h-[50px]"></Skeleton>
            ))}
        </div>
    );
};

export default GroupViewLoader;