import { SortArrow, SortDownArrow, SortUpArrow } from "../svg/AllSvg";
import ListViewLoader from "../../skeleton/components/common/ListViewLoader";
import { Paginator } from "primereact/paginator";
import { Checkbox } from "primereact/checkbox";
import React, { useState } from "react";
import Moment from 'moment';

const ListView = ({ type, data, listLoading, setData, sortByList, sortBy, setSortBy, setChildSize, setChildFirst, setChildPage, setSize, setFirst, setPage, defaultField, frontField, fieldType, total, size, first, childTotal, childSize, childFirst, check, setCheck, setViewAll, getActions, notFound, extraRow, handleNavigate, ignore, highLight, checkBox, height }: any) => {
    const [oneCheck, setOneCheck] = useState<string>("");
    const [allCheckBox, setAllCheckBox] = useState<boolean>(false);
    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = data.map((item: any) => ({ ...item, flag: checked }));
            setData(arr);
        } else {
            arr = data.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setData(arr);
        }
    };

    const getSortIcon = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                return <SortDownArrow />
            } else if (desc === sortBy) {
                return <SortUpArrow />
            } else {
                return <SortArrow />
            }
        }
    }

    const handleSort = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (sortBy === "-created_at") {
                setSortBy(asc)
            } else if (asc === sortBy) {
                setSortBy(desc)
            } else {
                setSortBy(asc)
            }
        }
    }

    const onPageChange = (event: any) => {
        if (type === "group") {
            setChildPage(event?.page + 1);
            setChildFirst(event.first);
            setChildSize(event.rows)
        } else {
            setPage(event?.page + 1);
            setFirst(event.first);
            setSize(event.rows)
        }
    };

    const getHeader = () => {
        let arr: any = [];
        defaultField.forEach((el: any, index: number) => {
            for (const key in frontField) {
                if (key === el) {
                    arr.push(<th key={key} className={`px-[8px] 2xl:px-[16px] cursor-pointer bg-[#F9F9F9] ${defaultField?.length !== (index + 1) ? "shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]" : `${!!getActions ? "shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]" : "rounded-r-[5px] shadow-[0px_1px_0px_0px_#F1F1F1_inset,-1px_0px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]"}`}`} onClick={() => handleSort(key, `-${key}`)}>
                        <div className='flex items-center justify-start w-full gap-[8px]'>
                            <p className='text-mGray font-normal text-xs'>{frontField[key]}</p>
                            <div className="w-[13px] h-[13px]">
                                {getSortIcon(key, `-${key}`)}
                            </div>
                        </div>
                    </th>);
                }
            }
        });

        return arr;
    };

    return (
        <>
            {(listLoading || data === null) ? <ListViewLoader height={!!height ? height : ""} /> :
                <>
                    {(!listLoading && data && data?.length > 0) &&
                        <div className={`flex flex-col justify-between ${!!height ? height : "min-h-[calc(100vh-265px)]"} rounded-[5px] border border-[#E4E4E4] px-[10px] pt-[5px]`}>
                            <div className="overflow-x-scroll overflow-y-hidden">
                                <table className="table-auto w-full list-view">
                                    <thead>
                                        <tr className="h-[30px]">
                                            <th className="w-[58px] min-w-[58px] h-full rounded-l-[5px] bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset,1px_0px_0px_0px_#F1F1F1_inset]">
                                                {!!checkBox ?
                                                    <div className='flex items-center justify-center' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                                        <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                    </div>
                                                    :
                                                    <div className='flex items-center justify-center w-full'>
                                                        <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                    </div>
                                                }
                                            </th>
                                            {getHeader()}
                                            {!!getActions && <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] rounded-r-[5px] shadow-[0px_1px_0px_0px_#F1F1F1_inset,-1px_0px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]"></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(data) && data.map((item: any, index: number) => (
                                            <tr className={`${item?.flag ? "bg-[#9d7aff1a]" : `${!!handleNavigate && "hover:bg-[#B5B5B51a]"} bg-[#F1F1F11a]`} ${!!handleNavigate && "cursor-pointer"} w-full h-[35px] min-h-[35px] shadow-[0px_-1px_0px_0px_#efefef_inset]`} key={index}>
                                                <td className="w-[58px] min-w-[58px] py-[5px]">
                                                    {!!checkBox ?
                                                        <div className="flex items-center justify-center" onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")}>
                                                            {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                <div className="flex items-center justify-center min-w-[31px] min-h-[31px]">
                                                                    <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                </div>
                                                                :
                                                                <>{checkBox()}</>
                                                            }
                                                        </div>
                                                        :
                                                        <div className="flex items-center justify-center">
                                                            <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                        </div>
                                                    }
                                                </td>
                                                {Array.isArray(defaultField) && defaultField.map((i: any, idx: number) => (
                                                    <React.Fragment key={idx}>
                                                        <td onClick={() => { setViewAll(false); handleNavigate(i, item); }} className="px-[8px] 2xl:px-[16px] py-[5px]">
                                                            {!!extraRow && extraRow(i, item, index)}
                                                            {
                                                                ignore?.includes(i) ? "" :
                                                                    fieldType[i] === "date" ?
                                                                        <h3 className={`${highLight?.includes(i) ? "text-primaryLight" : "text-mGray"} font-normal text-sm whitespace-nowrap`}>{!!item[i] ? Moment(item[i]).format('DD MMM YYYY') : "-"}</h3>
                                                                        :
                                                                        <div>
                                                                            {!!item[i] ?
                                                                                <h3 className={`${highLight?.includes(i) ? "text-primaryLight" : "text-mGray"} font-normal text-sm`}>{item[i]}</h3>
                                                                                :
                                                                                <>
                                                                                    {item?.custom_fields_values?.length > 0 ? <>
                                                                                        {Array.isArray(item?.custom_fields_values) && item?.custom_fields_values.map((c: any, cidx: number) => (
                                                                                            <React.Fragment key={cidx}>
                                                                                                {c?.slug === i &&
                                                                                                    <>
                                                                                                        {fieldType[c?.slug] === "date" ?
                                                                                                            <h3 className={`${highLight?.includes(i) ? "text-primaryLight" : "text-mGray"} font-normal text-sm whitespace-nowrap`}>{!!c?.value ? Moment(c?.value).format('DD MMM YYYY') : "-"}</h3>
                                                                                                            :
                                                                                                            <h3 className={`${highLight?.includes(i) ? "text-primaryLight" : "text-mGray"} font-normal text-sm`}>{!!c?.value ? c?.value : "-"}</h3>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        ))}
                                                                                    </> : <h3 className="text-mGray font-normal text-sm">-</h3>}
                                                                                </>
                                                                            }
                                                                        </div>
                                                            }
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                                {!!getActions && getActions(item)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {
                                type === "group" ?
                                    <div className="flex items-center justify-end p-[15px] !pr-[5px]">
                                        <Paginator className="pagination" pageLinkSize={3} first={childFirst} rows={childSize} totalRecords={childTotal} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div> :
                                    <div className="flex items-center justify-end p-[15px] !pr-[5px]">
                                        <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div>
                            }
                        </div>
                    }
                </>
            }

            {(data?.length === 0 && !listLoading) && <div className={`flex flex-col items-center justify-center ${!!height ? height : "min-h-[calc(100vh-265px)]"} rounded-[5px] border border-[#E4E4E4] p-[10px]`}>
                {notFound()}
            </div>}
        </>
    );
};

export default ListView;