import { NoSearch, NoTemplate, PencilEdit, SearchRightFilter, SettingRemove, StandardDotsIcon } from '../svg/AllSvg';
import { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import SkeletonSettingList from "../../skeleton/components/setting/SettingList";
import useAxios from '../../shared/hooks/use-axios';
import Moment from 'moment';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Confirmation from '../form/Conformation';
import { settingActions } from '../../shared/store/setting-Slice';
import { useDispatch } from 'react-redux';
import { success } from '../../shared/hooks/use-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Search from '../form/Search';
import PrimaryDarkLightBtn from '../form/PrimaryDarkLightBtn';

const TemplateSettingList = ({ setApiCall, apiCall, setDetail, detail, setIsEdit, list, setList, setIsOpen, setSearchList, searchList }: any) => {
    const settingPath: string = useSelector((state: any) => state.ui.settingPath);
    const [check, setCheck] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const params = useParams();

    const getTemplateList = async () => {
        try {
            const res: any = await useAxios.get("labs/api/notes-templates/");

            if (res && res?.status === 200) {
                setList(res?.data);
                setSearchList(res?.data);
                setIsLoading(false);
                setApiCall(false);
                if (params?.id) {
                    let obj: any = res?.data?.find((item: any) => item?.id === params?.id)
                    if (!!obj) {
                        setDetail(obj)
                    } else {
                        navigate(`${settingPath}/setting/template-management`)
                    }
                }
                dispatch(settingActions.setTestApiCall(true))
            }
        } catch (error) {
            console.error("error while feching template", error);
        }
    }

    const handleDelete = async () => {
        try {
            await useAxios.delete(`labs/api/notes-templates/${detail?.id}/`);
            success("Template delete successfully");
            getTemplateList();
        } catch (error) {
            console.error("error while deleting template", error);
        }
    }

    useEffect(() => {
        getTemplateList();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

    const handleUpdate = async (id: any, active: any) => {
        try {
            await useAxios.patch(`labs/api/notes-templates/${id}/`, { active: !active });
            getTemplateList();
        } catch (error) {
            console.error("error while editing template", error);
        }
    }

    useEffect(() => {
        apiCall && getTemplateList();
        // eslint-disable-next-line
    }, [apiCall]);

    const handleSearch = (e: any) => {
        let arr: any[] = searchList;
        if (!!e.target.value) {
            // eslint-disable-next-line 
            arr = arr.filter((item: any) => {
                if (item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())) {
                    return true;
                }

                if (item?.ref_no?.toLowerCase()?.includes(e.target.value?.toLowerCase())) {
                    return true;
                }
            });
        } else {
            arr = searchList;
        }

        setList(arr);
    }


    return (
        <>
            {openConfirm && <Confirmation openConfirm={openConfirm} setOpenConfirm={setOpenConfirm} onSubmit={handleDelete} />}
            <div className='p-[19px] w-full lg:col-span-8 xxl:w-full'>
                {searchList?.length > 0 && <div className='flex items-center gap-[12px] justify-end w-full'>
                    {/* Search */}
                    <Search placeholder="Search" onChange={(e: any) => handleSearch(e)} />

                    <div className="rounded-[10px] bg-white p-[6px] border border-[#CDCDCD] !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] cursor-pointer">
                        <SearchRightFilter color="#6B7280" />
                    </div>
                </div>}

                <div className="w-full">
                    {isLoading ? <SkeletonSettingList /> :

                        <>
                            {list?.length > 0 ? <div className="flex flex-col">
                                <div className="overflow-x-auto">
                                    <div className="inline-block min-w-full">
                                        <div className="overflow-hidden">
                                            <table className="min-w-full skeleton-table">
                                                <thead className="bg-white">
                                                    <tr className="h-[60px]">
                                                        <th className="min-w-[60px] w-[60px] h-full">
                                                            <div className='flex items-center justify-center w-full'>
                                                                <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                            </div>
                                                        </th>
                                                        <th className="px-[8px] 2xl:px-[16px] h-full">
                                                            <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                <p className='text-lGray  font-normal text-sm !text-left'>Template title</p>
                                                            </div>
                                                        </th>

                                                        <th className="min-w-[100px] w-[100px] h-full">
                                                            <div className='flex items-center justify-start w-full gap-[8px]'>
                                                                <p className='text-lGray  font-normal text-sm text-left'>Created Date</p>
                                                            </div>
                                                        </th>

                                                        <th className="h-full text-center min-w-[115px] w-[115px]"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(list) && list.map((item: any, index: number) => (
                                                        <tr className="rounded-[10px] bg-white shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)]" key={index}>
                                                            <td className="min-w-[60px] w-[60px] py-[9px] text-center">
                                                                <div className='flex items-center justify-center w-full'>
                                                                    <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                </div>
                                                            </td>
                                                            <td className="px-[8px] 2xl:px-[16px] py-[9px]">
                                                                <h3 className="text-primaryLight font-normal text-sm text-left">{item?.name}</h3>
                                                            </td>

                                                            <td className="min-w-[100px] w-[100px] py-[9px]">
                                                                <h3 className="text-mGray font-normal text-sm text-left">{Moment(item?.created_at).format('DD MMM YYYY')}</h3>
                                                            </td>

                                                            <td className="py-[9px] text-center min-w-[115px] w-[115px]">
                                                                <div className='flex items-center justify-center gap-[8px]'>
                                                                    <label className="relative inline-flex items-center cursor-pointer">
                                                                        <input type="checkbox" checked={item?.active} className="sr-only peer" onChange={() => handleUpdate(item?.id, item?.active)} />
                                                                        <div className="w-9 h-5 bg-[#E8EBFF] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all after:peer-checked:bg-dGray" ></div>
                                                                    </label>

                                                                    <Menu align="end" menuButton={<MenuButton>
                                                                        <div className="cursor-pointer">
                                                                            <StandardDotsIcon />
                                                                        </div>
                                                                    </MenuButton>}>
                                                                        <MenuItem onClick={() => { setDetail(item); setIsEdit(true); navigate(`${settingPath}/setting/template-management/edit/${item?.id}`) }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><PencilEdit width="20" height="20" /><p className='text-sm'>Edit</p></div></div></MenuItem>
                                                                        <MenuItem onClick={() => { setDetail(item); setOpenConfirm(true); }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[6px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><SettingRemove width="18" height="18" /><p className='text-sm'>Delete</p></div></div></MenuItem>
                                                                    </Menu>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                                <>
                                    {searchList?.length === 0 ? <div className='flex flex-col gap-[20px] items-center justify-center w-full min-h-[calc(100vh-230px)]'>
                                        <NoTemplate />

                                        <div className='flex flex-col items-center justify-center w-full'>
                                            <h3 className='text-base text-primaryLight font-normal'>No Template Created</h3>
                                            <p className='text-mGray text-sm font-medium text-center max-w-[300px] mt-[4px] mb-[8px]'>There’s no template to show, please create one to het started</p>

                                            <PrimaryDarkLightBtn title="Create New Template" onClick={() => { setIsOpen(true); navigate(`${settingPath}/setting/template-management/create`) }} />
                                        </div>
                                    </div> :
                                        <div className="w-full min-h-[calc(100vh-265px)] flex items-center justify-center flex-col gap-[2px]">
                                            <NoSearch />
                                            <p className="text-sm font-normal text-dGray">{t("common_sections.no_search")}</p>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default TemplateSettingList;