import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth-Slice';
import employeeSlice from './employee-Slice';
import testSlice from './test-Slice';
import uiSlice from './ui-Slice';
import labSampleSlice from './labSample-Slice';
import crmSlice from './crm-Slice';
import settingSlice from './setting-Slice';
import adminSlice from './admin-Slice';
import invoiceSlice from './invoice-Slice';
import tableSlice from './table-Slice';
import inventorySlice from './inventory-Slice';

const store = configureStore({
    reducer: { auth: authSlice.reducer, employee: employeeSlice.reducer, ui: uiSlice.reducer, test: testSlice.reducer, labSample: labSampleSlice.reducer, crm: crmSlice.reducer, setting: settingSlice.reducer, admin: adminSlice.reducer, invoice: invoiceSlice.reducer, table: tableSlice.reducer, inventory: inventorySlice.reducer }
});

export default store;