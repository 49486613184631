/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const Dashboard = React.lazy(() => import("../../pages/admin/dashboard/Dashboard"));
const BIReport = React.lazy(() => import("../../pages/admin/biReport/BIReport"));
const CRM = React.lazy(() => import("../../pages/crm/dashboard/CRM"));
const CRMDetail = React.lazy(() => import("../../pages/crm/crmDetail/CRMDetail"));
const AddCustomer = React.lazy(() => import("../../pages/crm/addCustomer/AddCustomer"));
const Premium = React.lazy(() => import("../../pages/premium/Premium"));
const CreditPolicy = React.lazy(() => import("../../pages/invoice/creditPolicy/dashboard/CreditPolicy"));
const Campaigns = React.lazy(() => import("../../pages/crm/campaigns/dashboard/Campaigns"));
const CreateCampaigns = React.lazy(() => import("../../pages/crm/campaigns/createCampaigns/CreateCampaigns"));

const CRMRoutes: React.FC<any> = ({ role }) => {
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);
    const [roleName, setRoleName] = useState<string>("");
    const [existRole, setExistRole] = useState<any>(null);

    useEffect(() => {
        if (permissionRole?.length > 0) {
            let getRole: any = role?.filter((item: any) => permissionRole?.toString()?.includes(item))
            setRoleName(getRole[0])
            getRole?.length > 0 ? setExistRole(true) : setExistRole(false)
        }
    }, [permissionRole])

    return (
        <>
            {existRole != null && <>
                {(existRole) ? <>
                    {(permissionRole?.includes(roleName)) && <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/bi-report" element={<BIReport />} />
                        <Route path="/" element={<CRM />} />
                        <Route path="/:tab" element={<CRM />} />
                        <Route path="/:tab/:id" element={<CRMDetail />} />
                        <Route path="/credit-policy" element={<CreditPolicy />} />
                        <Route path="/credit-policy/:tab" element={<CreditPolicy />} />
                        <Route path="/add-customer" element={<AddCustomer />} />
                        <Route path="/campaigns" element={<Campaigns />} />
                        <Route path="/campaigns/create" element={<CreateCampaigns />} />
                        <Route path="*" element={<Navigate to="/crm/dashboard" />} />
                    </Routes>}
                </> :
                    <Routes>
                        <Route path="/premium" element={<Premium />} />
                        <Route path="*" element={<Navigate to="/crm/premium" />} />
                    </Routes>
                }
            </>}
        </>
    );
};

export default CRMRoutes;