import { Skeleton } from 'primereact/skeleton';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const GridView: React.FC<any> = ({ group }: any) => {
    const option: string = useSelector((state: any) => state.invoice.option);
    const params = useParams();

    const cols = [];
    for (let i = 0; i < (!!group && group ? 3 : 10); i++) {
        cols.push(
            <tr className="!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[50px]" key={i}>
                {!!params?.id && <>
                    <td className="px-[12px] h-full !text-center">
                        <Skeleton className='!w-[29px] !h-[29px] !rounded-full'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                </>
                }
                {!!!params?.id && <>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='3rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </td>
                    <td className="px-[8px] 2xl:px-[16px] h-full">
                        <div className='flex items-center gap-[10px]'>
                            {(option === "UnPaidInvoice" || option === "PartialPaidInvoice") ? <>
                                <Skeleton size="1.4rem"></Skeleton>
                                <Skeleton size="1.4rem"></Skeleton>
                                <Skeleton size="1.4rem"></Skeleton>
                            </> :
                                <>
                                    <Skeleton size="1.4rem"></Skeleton>
                                    <Skeleton size="1.4rem"></Skeleton>
                                </>
                            }
                        </div>
                    </td>
                </>}
            </tr>
        )
    };

    return (
        <table className="table-auto overflow-x-scroll w-full skeleton-table">
            <tbody>
                {cols}
            </tbody>
        </table>
    );
};

export default GridView;