import { createSlice } from '@reduxjs/toolkit';

interface LabSampleState {
    isOpen: boolean,
    view: string,
    groupBy: boolean,
    groupByOption: string,
    groupByOptionValue: string,
    screenShotList: any[],
    option: string,
    sortBy: string,
    sortByList: any[],
    groupByList: any[],
    sampleData: any,
    activityFeed: any[],
    todoList: any[],
    sampleDetail: any[],
    sampleGroupByData: any,
    tagList: any[],
    sampleLoading: boolean,
    sideBarOption: string,
    chatUser: any,
    chatUsersList: any[],
    select: string,
    isEmployeeCreate: boolean,
    isSuccess: boolean,
    page: number,
    total: number,
    size: number,
    filterList: any[],
    filterResult: string,
    empName: string,
    defaultField: any[],
    detailField: any[],
    frontField: any,
    successType: string,
    isEmployeeEdit: boolean,
    selectedId: string,
    groupByExpand: string,
    qrcodePopup: boolean,
    sampleDetailPopup: any,
    tab: string,
    toggleTab: string,
    confirmation: boolean,
    detail: any,
    remarkSuccess: boolean,
    remarkOpen: boolean,
    review: any[],
    selectParameter: any,
    selectedRow: any,
    selectedParameterReviewRow: any,
    selectReviewParameter: any,
    fullView: boolean,
    editTrField: any,
    selectId: string,
    tabPermissionList: any[],
    parameterList: any[],
    parameterLoading: boolean,
    parameterApiCall: boolean,
    fileUploadOpen: boolean,
    startTesting: boolean,
    testing: boolean,
    parameterReview: boolean,
    addRemark: boolean,
    parameterReviewList: any[],
    allSampleOpen: boolean,
    addQtyRemark: boolean,
    alertRemark: boolean,
    debitNoteAlert: boolean,
    allTested: boolean,
    receiveSample: boolean,
    remnantId: string,
    reviewApiCall: boolean,
    note: string,
    mainAlert: boolean,
    remnantOption: string,
    remnantTagList: any[],
    firstParameter: boolean,
    close: boolean,
    transition: boolean,
    transtionRoute: string,
    parameterPopup: boolean,
    deptPopup: boolean,
    selectDept: any,
    reportPopup: boolean,
    allParameter: any[],
    reportPreview: boolean,
    labUserOpen: boolean,
    todoDetail: boolean,
    reviewTab: number,
    sampleEdit: boolean,
    component: string,
    assignSample: boolean,
    sampleEditable: boolean,
    sharedUsers: any[],
    childPage: number,
    childTotal: number,
    childSize: number,
    viewAll: boolean,
    filterObj: any,
    sampleDetailApi: boolean,
    resultData: any,
    switchSample: boolean
}

const initialState: LabSampleState = {
    isOpen: false,
    view: "card",
    groupBy: false,
    groupByOption: "",
    groupByOptionValue: "",
    screenShotList: JSON.parse(localStorage.getItem('screenshot') as any) || [],
    option: "",
    sortBy: "-created_at",
    sortByList: [],
    groupByList: [],
    sampleData: null,
    activityFeed: [],
    todoList: [],
    sampleDetail: [],
    sampleGroupByData: null,
    tagList: [],
    sampleLoading: true,
    isSuccess: false,
    sideBarOption: "All",
    chatUser: {},
    chatUsersList: [
        { id: 1, logo: "V", bg: "bg-[#0297a7]", name: "Vista Lims", email: "harshita.kachhadiya@arohatech.com" },
        { id: 2, logo: "P", bg: "bg-[#502aaf]", name: "Project Meeting", email: "harshita.kachhadiya@arohatech.com" },
        { id: 3, logo: "N", bg: "bg-[#689f38]", name: "New Project", email: "harshita.kachhadiya@arohatech.com" },
    ],
    select: "",
    isEmployeeCreate: false,
    page: 1,
    total: 0,
    size: 10,
    filterList: [],
    filterResult: "",
    empName: "",
    defaultField: [],
    detailField: [],
    frontField: null,
    successType: "",
    isEmployeeEdit: false,
    selectedId: "",
    groupByExpand: "",
    qrcodePopup: false,
    sampleDetailPopup: 0,
    tab: "Todo",
    toggleTab: "Sample Information",
    confirmation: false,
    detail: null,
    remarkSuccess: false,
    remarkOpen: false,
    review: [],
    selectParameter: null,
    selectedRow: null,
    selectedParameterReviewRow: null,
    selectReviewParameter: null,
    fullView: !!JSON.parse(localStorage.getItem("lims-sample") as any) ? true : false,
    editTrField: {
        test_name: false,
        sealed_status: false,
        quantity: false,
        sample_unit: false,
        sample_type: false,
        sample_description: false,
    },
    selectId: "",
    tabPermissionList: [],
    parameterList: [],
    parameterLoading: true,
    parameterApiCall: false,
    fileUploadOpen: false,
    startTesting: false,
    testing: false,
    parameterReview: false,
    addRemark: false,
    parameterReviewList: [],
    allSampleOpen: false,
    addQtyRemark: false,
    alertRemark: false,
    debitNoteAlert: false,
    allTested: false,
    receiveSample: false,
    remnantId: "",
    reviewApiCall: false,
    note: "",
    mainAlert: false,
    remnantOption: "",
    remnantTagList: [],
    firstParameter: false,
    close: !!JSON.parse(localStorage.getItem("lims-sample-close") as any) ? true : false,
    transition: true,
    transtionRoute: "",
    parameterPopup: false,
    deptPopup: false,
    selectDept: null,
    reportPopup: false,
    allParameter: [],
    reportPreview: false,
    labUserOpen: false,
    todoDetail: false,
    reviewTab: 0,
    sampleEdit: false,
    component: "",
    assignSample: false,
    sampleEditable: false,
    sharedUsers: [],
    childPage: 1,
    childTotal: 0,
    childSize: 10,
    viewAll: false,
    filterObj: {},
    sampleDetailApi: false,
    resultData: {},
    switchSample: false
}

const labSampleSlice = createSlice({
    name: 'labSample',
    initialState,
    reducers: {
        Open: (state) => {
            state.isOpen = true;
        },
        Close: (state) => {
            state.isOpen = false;
        },
        toggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        groupByToggle: (state) => {
            state.groupBy = true;
        },
        groupToggle: (state) => {
            state.groupBy = false;
            state.groupByOption = "";
            state.groupByOptionValue = "";
            state.page = 1;
            state.groupByExpand = "";
            state.remarkSuccess = true;
        },
        handleView: (state, action) => {
            state.view = action.payload;
        },
        handleGroupOption: (state, action) => {
            state.groupByOption = action.payload;
        },
        handleGroupOptionValue: (state, action) => {
            state.groupByOptionValue = action.payload;
        },
        updateScreenShotList: (state, action) => {
            state.screenShotList = [...state.screenShotList, action.payload];
        },
        assignScreenShotList: (state, action) => {
            state.screenShotList = action.payload;
        },
        changeOption: (state, action) => {
            state.option = action.payload;
        },
        switchOption: (state, action) => {
            state.sideBarOption = action.payload;
        },
        setChatUser: (state, action) => {
            state.chatUser = action.payload;
        },
        setSelect: (state, action) => {
            state.select = action.payload;
        },
        setIsEmployeeCreate: (state, action) => {
            state.isEmployeeCreate = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setSortByList: (state, action) => {
            state.sortByList = action.payload;
        },
        setSampleList: (state, action) => {
            state.sampleData = action.payload;
        },
        setFeedList: (state, action) => {
            state.activityFeed = action.payload;
        },
        setTodoList: (state, action) => {
            state.todoList = action.payload;
        },
        setSampleDetail: (state, action) => {
            state.sampleDetail = action.payload;
        },
        setSampleGroupByList: (state, action) => {
            state.sampleGroupByData = action.payload;
        },
        setTagList: (state, action) => {
            state.tagList = action.payload;
        },
        setSampleLoading: (state, action) => {
            state.sampleLoading = action.payload;
        },
        setGroupByList: (state, action) => {
            state.groupByList = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setSize: (state, action) => {
            state.size = action.payload
        },
        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload
        },
        setFilterList: (state, action) => {
            state.filterList = action.payload
        },
        setFilterResult: (state, action) => {
            state.filterResult = action.payload
        },
        setFilterObj: (state, action) => {
            state.filterObj = action.payload
        },
        setEmpName: (state, action) => {
            state.empName = action.payload
        },
        setDefaultField: (state, action) => {
            state.defaultField = action.payload
        },
        setDetailField: (state, action) => {
            state.detailField = action.payload
        },
        setFrontField: (state, action) => {
            state.frontField = action.payload
        },
        setIsEmployeeEdit: (state, action) => {
            state.isEmployeeEdit = action.payload;
        },
        setSuccessType: (state, action) => {
            state.successType = action.payload
        },
        setSelectedId: (state, action) => {
            state.selectedId = action.payload
        },
        setGroupByExpand: (state, action) => {
            state.groupByExpand = action.payload
        },
        setQRCodePopup: (state, action) => {
            state.qrcodePopup = action.payload
        },
        setSampleDetailPopup: (state, action) => {
            state.sampleDetailPopup = action.payload
        },
        setTab: (state, action) => {
            state.tab = action.payload
        },
        setToggleTab: (state, action) => {
            state.toggleTab = action.payload
        },
        setConformation: (state, action) => {
            state.confirmation = action.payload
        },
        setDetail: (state, action) => {
            state.detail = action.payload
        },
        setRemarkSuccess: (state, action) => {
            state.remarkSuccess = action.payload
        },
        setRemarkOpen: (state, action) => {
            state.remarkOpen = action.payload
        },
        setReview: (state, action) => {
            state.review = action.payload
        },
        setSelectParameter: (state, action) => {
            state.selectParameter = action.payload
        },
        setSelectedParameterReviewRow: (state, action) => {
            state.selectedParameterReviewRow = action.payload
        },
        setSelectRow: (state, action) => {
            state.selectedRow = action.payload
        },
        setSelectReviewParameter: (state, action) => {
            state.selectReviewParameter = action.payload
        },
        setFullView: (state, action) => {
            state.fullView = action.payload
            if (action.payload === false) {
                state.transtionRoute = window.location.href
                state.transition = false
            }
        },
        setTransition: (state, action) => {
            state.transition = action.payload
        },
        setTransitionRoute: (state, action) => {
            state.transtionRoute = action.payload
        },
        setEditTrField: (state, action) => {
            state.editTrField = action.payload
        },
        setSelectId: (state, action) => {
            state.selectId = action.payload
        },
        setTabPermissionList: (state, action) => {
            state.tabPermissionList = action.payload
        },
        setParameterList: (state, action) => {
            state.parameterList = action.payload
        },
        setParameterLoading: (state, action) => {
            state.parameterLoading = action.payload
        },
        setParameterApiCall: (state, action) => {
            state.parameterApiCall = action.payload
        },
        setFileUploadOpen: (state, action) => {
            state.fileUploadOpen = action.payload
        },
        setStartTesting: (state, action) => {
            state.startTesting = action.payload
        },
        setTesting: (state, action) => {
            state.testing = action.payload
        },
        setParameterReview: (state, action) => {
            state.parameterReview = action.payload
        },
        setAddRemark: (state, action) => {
            state.addRemark = action.payload
        },
        setParameterReviewList: (state, action) => {
            state.parameterReviewList = action.payload
        },
        setAllSampleOpen: (state, action) => {
            state.allSampleOpen = action.payload
        },
        setAddQtyRemark: (state, action) => {
            state.addQtyRemark = action.payload
        },
        setAlertRemark: (state, action) => {
            state.alertRemark = action.payload
        },
        setDebitNoteAlert: (state, action) => {
            state.debitNoteAlert = action.payload
        },
        setAllTested: (state, action) => {
            state.allTested = action.payload
        },
        setReceiveSample: (state, action) => {
            state.receiveSample = action.payload
        },
        setRemnantId: (state, action) => {
            state.remnantId = action.payload
        },
        setReviewApiCall: (state, action) => {
            state.reviewApiCall = action.payload
        },
        setNote: (state, action) => {
            state.note = action.payload
        },
        setMainAlert: (state, action) => {
            state.mainAlert = action.payload
        },
        setRemnantOption: (state, action) => {
            state.remnantOption = action.payload
        },
        setRemnatTagList: (state, action) => {
            state.remnantTagList = action.payload
        },
        setFirstParameter: (state, action) => {
            state.firstParameter = action.payload
        },
        setClose: (state, action) => {
            state.close = action.payload
        },
        setParameterPopup: (state, action) => {
            state.parameterPopup = action.payload
        },
        setDeptPopup: (state, action) => {
            state.deptPopup = action.payload
        },
        setSelectDept: (state, action) => {
            state.selectDept = action.payload
        },
        setReportPopup: (state, action) => {
            state.reportPopup = action.payload
        },
        setAllParameter: (state, action) => {
            state.allParameter = action.payload
        },
        setReportPreview: (state, action) => {
            state.reportPreview = action.payload
        },
        setLabUserOpen: (state, action) => {
            state.labUserOpen = action.payload
        },
        setTodoDetail: (state, action) => {
            state.todoDetail = action.payload
        },
        setReviewTab: (state, action) => {
            state.reviewTab = action.payload
        },
        setSampleEdit: (state, action) => {
            state.sampleEdit = action.payload
        },
        setComponent: (state, action) => {
            state.component = action.payload
        },
        setAssignSample: (state, action) => {
            state.assignSample = action.payload
        },
        setSampleEditable: (state, action) => {
            state.sampleEditable = action.payload
        },
        setSharedUsers: (state, action) => {
            state.sharedUsers = action.payload
        },
        setChildPage: (state, action) => {
            state.childPage = action.payload
        },
        setChildTotal: (state, action) => {
            state.childTotal = action.payload
        },
        setChildSize: (state, action) => {
            state.childSize = action.payload
        },
        setViewAll: (state, action) => {
            state.viewAll = action.payload
        },
        setSampleDetailApi: (state, action) => {
            state.sampleDetailApi = action.payload
        },
        setResultData: (state, action) => {
            state.resultData = action.payload
        },
        setSwitchSample: (state, action) => {
            state.switchSample = action.payload
        },
    }
});

export default labSampleSlice;
export const labSampleActions = labSampleSlice.actions;