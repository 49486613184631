/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { SettingRightArrow, TestDetailDown } from "../svg/AllSvg";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { settingActions } from "../../shared/store/setting-Slice";
import { useNavigate } from "react-router-dom";
import useAxios from "../../shared/hooks/use-axios";
import { useModule } from "../../shared/hooks/use-module";
import { settingMenu } from "../../shared/utils/settingMenu";
import { useScreen } from "../../shared/hooks/use-Screen";

const SettingLayout = ({ prev, setPrev, custom, setCustom, setCustomType }: any) => {
    const settingPermission: string = useSelector((state: any) => state.setting.settingPermission);
    const settingPath: string = useSelector((state: any) => state.ui.settingPath);
    const [list, setList] = useState<any[]>([]);
    const [categoryId, setCategoryId] = useState<string>("");
    const [remarkId, setRemarkId] = useState<string>("");
    const title: string = useSelector((state: any) => state.setting.title);
    const isActivityOpen: boolean = useSelector((state: any) => state.ui.isOpen);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useModule("setting");
    useScreen("");

    useEffect(() => {
        getGrouptype();
        getRemarksModuleType();
    }, [])

    useEffect(() => {
        setList(settingMenu)
    }, [settingMenu])

    const getGrouptype = async () => {
        try {
            const res: any = await useAxios.get(`/utility/group-type-list/`);

            if (res && res?.status === 200) {
                if (title === "Customer Group") {
                    let arr: any[] = [];
                    for (let i = 0; i < res?.data.results.length; i++) {
                        const el = res?.data.results[i];
                        el.name = el?.text;
                        if (i === 0) {
                            el.flag = true;
                        } else {
                            el.flag = false;
                        }
                        arr.push(el)
                    }
                    !!setCustom && setCustom(arr);
                    dispatch(settingActions.setSelect(res?.data.results[0]?.text));
                }
                setCategoryId(res?.data.results[0]?.id)
            }
        } catch (error) {
            console.error("error while feching group type", error);
        }
    }

    const getRemarksModuleType = async () => {
        try {
            const res: any = await useAxios.get(`utility/module-list/`);

            if (res && res?.status === 200) {
                if (title === "Remarks Master") {
                    let arr: any[] = [];
                    for (let i = 0; i < res?.data.results.length; i++) {
                        const el = res?.data.results[i];
                        el.name = el?.text;
                        if (i === 0) {
                            el.flag = true;
                        } else {
                            el.flag = false;
                        }
                        arr.push(el)
                    }
                    !!setCustom && setCustom(arr);
                    dispatch(settingActions.setSelect(res?.data.results[0]?.text));
                }
                setRemarkId(res?.data.results[0]?.id)
            }
        } catch (error) {
            console.error("error while feching remarks module list", error);
        }
    }

    const getCustomFieldType = async () => {
        try {
            const res: any = await useAxios.get(`utility/custom-fields-table-list/`);

            if (res && res?.status === 200) {
                let arr: any[] = [];
                for (let i = 0; i < res?.data.results.length; i++) {
                    const el = res?.data.results[i];
                    el.name = el?.label;
                    if (i === 0) {
                        el.flag = true;
                    } else {
                        el.flag = false;
                    }
                    arr.push(el)
                }
                !!setCustom && setCustom(arr);
                !!setCustomType && setCustomType(arr);
                dispatch(settingActions.setSelect(res?.data.results[0]?.key));
            }
        } catch (error) {
            console.error("error while feching custom field type", error);
        }
    }

    useEffect(() => {
        if (title === "Customer Group") {
            getGrouptype();
        }
        if (title === "Remarks Master") {
            getRemarksModuleType();
        }
        if (title === "Custom Fields") {
            getCustomFieldType();
        }

        if (title === "Account Settings") {
            if (window.location.pathname === `${settingPath}/setting`) {
                !!setCustom && setCustom([
                    { id: 1, name: "Change Password", flag: true },
                    { id: 2, name: "Address Book", flag: false },
                ])
                dispatch(settingActions.setSelect("Change Password"))
            } else {
                !!setCustom && setCustom([
                    { id: 1, name: "Change Password", flag: false },
                    { id: 2, name: "Address Book", flag: true },
                ])
                dispatch(settingActions.setSelect("Address Book"))
            }
        }

        if (title === "Department Master") {
            if (window.location.pathname.includes("/administrative")) {
                !!setCustom && setCustom([
                    { id: 1, name: "Administrative", flag: true },
                    { id: 2, name: "Technical", flag: false },
                ])
                dispatch(settingActions.setSelect("Administrative"))
            } else {
                !!setCustom && setCustom([
                    { id: 1, name: "Administrative", flag: false },
                    { id: 2, name: "Technical", flag: true },
                ])
                dispatch(settingActions.setSelect("Technical"))
            }
        }

        if (title === "Billing Management") {
            if (window.location.pathname.includes("/billing-config")) {
                !!setCustom && setCustom([
                    { id: 1, name: "Billing Configuration", flag: true },
                    { id: 2, name: "TDS Management", flag: false },
                ])
                dispatch(settingActions.setSelect("Billing Configuration"))
            } else {
                !!setCustom && setCustom([
                    { id: 1, name: "Billing Configuration", flag: false },
                    { id: 2, name: "TDS Management", flag: true },
                ])
                dispatch(settingActions.setSelect("TDS Management"))
            }
        }

        if (title === "Sample Management") {
            if (window.location.pathname.includes("/sample-config")) {
                !!setCustom && setCustom([
                    { id: 1, name: "Sample Configuration", flag: true }
                ])
                dispatch(settingActions.setSelect("Sample Configuration"))
            }
        }
        // eslint-disable-next-line
    }, [title])

    const handleClick = (item: any) => {
        let arr: any[] = [];

        for (let i = 0; i < custom.length; i++) {
            const el = custom[i];

            if (item?.id === el?.id) {
                el.flag = true;
                arr.push(el);
                dispatch(settingActions.setSelect(el?.name))
            } else {
                el.flag = false;
                arr.push(el);
            }
        }

        !!setCustom && setCustom(arr);
    };

    const handleTabChange = (item: any) => {
        let arr: any[] = [];
        for (let i = 0; i < list.length; i++) {
            const el = list[i];

            if (item?.id === el?.id) {
                el.toggle = !el.toggle
            }
            if ((item?.id !== el?.id) && (el.hasOwnProperty('toggle'))) {
                el.toggle = false
            }
            arr.push(el)
        }

        setList(arr)
        dispatch(settingActions.setTitle(item?.name))

        if ((item?.name === "Account Settings" || item?.name === "Sample Management")) {
            navigate(`${settingPath}/setting`)
        }
        if (item?.name === "Custom Fields") {
            navigate(`${settingPath}/setting/custom-field`)
        }
        if (item?.name === "General Setting") {
            navigate(`${settingPath}/setting/general-setting`)
        }
        if (item?.name === "Scope Management") {
            navigate(`${settingPath}/setting/scope-master`)
        }
        if (item?.name === "Terms & Conditions") {
            navigate(`${settingPath}/setting/terms-condition`)
        }
        if (item?.name === "Accreditations Master") {
            navigate(`${settingPath}/setting/accreditations`)
        }
        if (item?.name === "Department Master") {
            navigate(`${settingPath}/setting/department/administrative`)
        }
        if (item?.name === "Standard & Parameter Master") {
            navigate(`${settingPath}/setting/standard-master`)
        }
        if (item?.name === "Test Method Master") {
            navigate(`${settingPath}/setting/test-method-master`)
        }
        if (item?.name === "Customer Group") {
            navigate(`${settingPath}/setting/group/${categoryId}`)
        }
        if (item?.name === "Remarks Master") {
            navigate(`${settingPath}/setting/remaks-template/${remarkId}`)
        }
        if (item?.name === "Template Management") {
            navigate(`${settingPath}/setting/template-management`)
        }
        if (item?.name === "Storage Settings") {
            navigate(`${settingPath}/setting/storage-location`)
        }
        if (item?.name === "Range Unit Master") {
            navigate(`${settingPath}/setting/range-unit`)
        }
        if (item?.name === "Manage Product/Service") {
            navigate(`${settingPath}/setting/manage-product`)
        }
        if (item?.name === "TDS Management") {
            navigate(`${settingPath}/setting/tds`)
        }
        if (item?.name === "Billing Management") {
            navigate(`${settingPath}/setting/billing-config`)
        }
        if (item?.name === "Sample Management") {
            navigate(`${settingPath}/setting/sample-config`)
        }
        if (item?.name === "Data Fields Masking") {
            navigate(`${settingPath}/setting/data-fields-masking`)
        }
    }

    const handleSubTab = (item: any) => {
        handleClick(item);
        setPrev(false);

        if (item?.name === "Customer Group") {
            navigate(`${settingPath}/setting/group/${item?.id}`)
        }
        if (item?.name === "Remarks Master") {
            navigate(`${settingPath}/setting/remaks-template/${item?.id}`)
        }
        if (item?.name === "Administrative") {
            navigate(`${settingPath}/setting/department/administrative`)
        }
        if (item?.name === "Technical") {
            navigate(`${settingPath}/setting/department/technical`)
        }
        if (item?.name === "Billing Configuration") {
            navigate(`${settingPath}/setting/billing-config`)
        }
        if (item?.name === "Sample Configuration") {
            navigate(`${settingPath}/setting/sample-config`)
        }
        if (item?.name === "TDS Management") {
            navigate(`${settingPath}/setting/tds`)
        }
    }

    return (
        <>
            <div className="md:col-span-2 xxl:w-[290px] border border-[#dee6e980] rounded-[10px] shadow-[0px_5px_15px_0px_#dfeaff66] hidden md:block bg-white h-full min-h-[calc(100vh-118px)]">
                <h3 className="text-primaryLight text-[1.063rem] xl:text-lg font-medium py-[16px] px-[24px] border-b border-[#dee6e980]">Settings</h3>

                <div className="flex flex-col py-[12px]">
                    {Array.isArray(list) && list.map((item: any, index: number) => (
                        <React.Fragment key={index}>
                            {settingPermission?.hasOwnProperty(item?.name) && <div className="w-full group">
                                <div className={`${(item?.name === title) && "bg-[#F4F7FF] border-l-2 !border-primary"} flex justify-between items-center py-[16px] px-[24px] cursor-pointer group-hover:bg-[#F4F7FF]`} onClick={() => handleTabChange(item)}>
                                    <div className={`flex gap-[12px] items-center ${item?.name === title ? `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-primaryLight fill-primaryLight" : "fill-primaryLight"}` : `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-lGray fill-lGray group-hover:stroke-primaryLight group-hover:fill-primaryLight" : "fill-lGray group-hover:fill-primaryLight"}`}`}>
                                        <div className="min-w-[25px] h-fit flex items-center justify-center">
                                            {<item.icon />}
                                        </div>
                                        <h3 className={`${(item?.name === title) ? "text-primaryLight" : "text-lGray"} text-[0.938rem] 2xl:text-base line-clamp-1 group-hover:text-primaryLight font-normal`}>{item?.name}</h3>
                                    </div>
                                    {(item?.name === title) && <div className={`${isActivityOpen ? "hidden lg:block xlx:hidden" : "hidden lg:block"} cursor-pointer`}>
                                        <SettingRightArrow className="stroke-primaryLight" />
                                    </div>}

                                    {(item?.name === title) &&
                                        <div className={`${isActivityOpen ? "block lg:hidden xlx:block" : "block lg:hidden"} cursor-pointer`}>
                                            {item?.toggle ? <TestDetailDown color="#20328F" /> : <SettingRightArrow className="stroke-primaryLight" />}
                                        </div>}
                                </div>
                                {(item?.toggle) && <div className={`${isActivityOpen ? "flex flex-col lg:hidden xlx:flex" : "flex lg:hidden flex-col"} w-full`}>
                                    {Array.isArray(custom) && custom.map((i: any, idx: number) => (
                                        <React.Fragment key={idx}>
                                            {settingPermission[item?.name]?.includes(i?.name) && <div className="group w-full">
                                                <div className={`${i?.flag ? "bg-[#F4F7FF] border-l-2 !border-primaryLight" : "group-hover:bg-[#F4F7FF]"} flex justify-between items-center py-[16px] px-[24px] cursor-pointer`} onClick={() => handleSubTab(i)}>
                                                    <h3 className={`${i?.flag ? "text-primaryLight" : "text-lGray"} font-normal text-[0.938rem] 2xl:text-base group-hover:text-primaryLight`}>{i?.name}</h3>
                                                </div>
                                            </div>}
                                        </React.Fragment>
                                    ))}
                                </div>}
                            </div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>

            {prev && <div className="md:col-span-2 xxl:w-[290px] border border-[#dee6e980] rounded-[10px] shadow-[0px_5px_15px_0px_#dfeaff66] md:hidden block bg-white">
                <h3 className="text-primaryLight text-[1.063rem] xl:text-lg font-medium py-[16px] px-[24px] border-b border-[#dee6e980]">Settings</h3>

                <div className="flex flex-col py-[12px]">
                    {Array.isArray(list) && list.map((item: any, index: number) => (
                        <React.Fragment key={index}>
                            {settingPermission?.hasOwnProperty(item?.name) && <div className="w-full group">
                                <div className={`${(item?.name === title) && "bg-[#F4F7FF] border-l-2 !border-primary"} flex justify-between items-center py-[16px] px-[24px] cursor-pointer group-hover:bg-[#F4F7FF]`} onClick={() => handleTabChange(item)}>
                                    <div className={`flex gap-[12px] items-center ${item?.name === title ? `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-primaryLight fill-primaryLight" : "fill-primaryLight"}` : `${(item?.name === "Account Settings" || item?.name === "Sample Management") ? "stroke-lGray fill-lGray group-hover:stroke-primaryLight group-hover:fill-primaryLight" : "fill-lGray group-hover:fill-primaryLight"}`}`}>
                                        <div className="min-w-[25px] h-fit flex items-center justify-center">
                                            {<item.icon />}
                                        </div>
                                        <h3 className={`${(item?.name === title) ? "text-primaryLight" : "text-lGray"} text-[0.938rem] 2xl:text-base whitespace-nowrap overflow-hidden text-ellipsis font-normal group-hover:text-primaryLight`}>{item?.name}</h3>
                                    </div>
                                    {(item?.name === title) && <div className={`${isActivityOpen ? "hidden lg:block xlx:hidden" : "hidden lg:block"} cursor-pointer`}>
                                        <SettingRightArrow className="stroke-primaryLight" />
                                    </div>}

                                    {(item?.name === title) &&
                                        <div className={`${isActivityOpen ? "block lg:hidden xlx:block" : "block lg:hidden"} cursor-pointer`}>
                                            {item?.toggle ? <TestDetailDown color="#20328F" /> : <SettingRightArrow className="stroke-primaryLight" />}
                                        </div>}
                                </div>
                                {(item?.toggle) && <div className={`${isActivityOpen ? "flex flex-col lg:hidden xlx:flex" : "flex lg:hidden flex-col"} w-full`}>
                                    {Array.isArray(custom) && custom.map((i: any, idx: number) => (
                                        <React.Fragment key={idx}>
                                            {settingPermission[item?.name]?.includes(i?.name) && <div className="group w-full">
                                                <div className={`${i?.flag ? "bg-[#F4F7FF] border-l-2 !border-primaryLight" : "group-hover:bg-[#F4F7FF] "} flex justify-between items-center py-[16px] px-[24px] cursor-pointer`} onClick={() => { handleSubTab(i); setPrev(false); }}>
                                                    <h3 className={`${i?.flag ? "text-primaryLight" : "text-lGray"} text-[0.938rem] 2xl:text-base whitespace-nowrap overflow-hidden text-ellipsis font-normal group-hover:text-primaryLight`}>{i?.name}</h3>
                                                </div>
                                            </div>}
                                        </React.Fragment>
                                    ))}
                                </div>}
                            </div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>}
        </>
    );
};

export default SettingLayout;