export const errors = {
    organization: "Organization Name is required",
    org_type: "Organization Type is required",
    email: "Email is required",
    wrongEmail: "Email must be a valid email",
    password: "Password is required",
    passwordLength: "Password must contain 8 characters, one Uppercase and one special case character",
    confirmPassword: "Confirm Password is required",
    phoneLength: "Mobile No. is not valid",
    phone: "Mobile No. is required",
    mobile: "Phone No. is required",
    firstName: "First Name is required",
    lastName: "Last Name is required",
    otp: "Please enter otp",
    specialization: "Specialization is required",
    eduStartDate: "education Start Date is required",
    eduEndDate: "education End Date is required",
    prodName: "Product Name is required",
    codeType: "Product Code Type is required",
    prodCode: "Product Code is required",
    prodPrice: "Product Price is required",
    pan: "Pan Number is required",
    gst: "Tax Perventage is required",
    unit: "Unit is required"
}