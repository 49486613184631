import { AssignGroup, NameOrgIcon, OptionDot, SortDownArrow, ExtandValidity, Approve, Reject, SortArrow, SortUpArrow } from "../../svg/AllSvg";
import React, { useEffect, useState } from "react";
import SkeletonGridView from "../../../skeleton/components/crm/GridView";
import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { useDispatch } from "react-redux";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { randomColor } from "../../../shared/utils/randomColor";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../shared/hooks/use-axios";
import { success } from "../../../shared/hooks/use-toastify";
import { useTranslation } from "react-i18next";
import { Paginator } from "primereact/paginator";
import { tableActions } from "../../../shared/store/table-Slice";
import { crmActions } from "../../../shared/store/crm-Slice";
import Moment from 'moment';

const GridView: React.FC<any> = ({ type }: any) => {
    const frontField: any = useSelector((state: any) => state.table.frontField);
    const data: any[] = useSelector((state: any) => state.table.data);
    const loading: boolean = useSelector((state: any) => state.table.loading);
    const option: any = useSelector((state: any) => state.table.option);
    const sortBy: string = useSelector((state: any) => state.table.sortBy);
    const sortByList: any[] = useSelector((state: any) => state.table.sortByList);
    const defaultField: any = useSelector((state: any) => state.table.defaultField);
    const total: number = useSelector((state: any) => state.table.total);
    const size: number = useSelector((state: any) => state.table.size);
    const childTotal: number = useSelector((state: any) => state.table.childTotal);
    const childSize: number = useSelector((state: any) => state.table.childSize);
    const childFirst: number = useSelector((state: any) => state.table.childFirst);
    const first: number = useSelector((state: any) => state.table.first);
    const [allCheckBox, setAllCheckBox] = useState<boolean>(false)
    const [list, setList] = useState<any[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const [oneCheck, setOneCheck] = useState<string>("");
    const [allBtnShow, setAllBtnShow] = useState<number>(0);
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const handleChange = (e: any) => {
        let itemName = e.target.name;
        let checked = e.target.checked;

        let arr: any[] = [];
        if (itemName === "checkAll") {
            setCheck(checked);
            arr = list.map((item: any) => ({ ...item, flag: checked }));
            setList(arr);
        } else {
            arr = list.map((item: any) =>
                item.id === itemName ? { ...item, flag: checked } : item
            );
            let check = arr.every((item: any) => item.flag);
            setCheck(check);
            setList(arr);
        }
    };

    const handleSubmit = async (item: any, status: any) => {
        try {
            let payload = {
                "customer_id": item?.id,
                "approval_status": status ? "approved" : "disapproved"
            }

            const res: any = await useAxios.post(`workflow/customer-approval-api/`, payload);

            if (res && res.status === 200) {
                if (status) {
                    success(t("crm.customer.success.cust_approve"));
                } else {
                    success(t("crm.customer.success.cust_disapprove"));
                }
                dispatch(tableActions.setTagApiCall(true));
                dispatch(tableActions.setSuccess(true));
            }

        } catch (error) {
            console.error("error while approval customer", error);
        }
    }

   const getSortIcon = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                return <SortDownArrow />
            } else if (desc === sortBy) {
                return <SortUpArrow />
            } else {
                return <SortArrow />
            }
        }
    }

    const handleSort = (asc: any, desc: any) => {
        if (sortByList.includes(asc)) {
            if (asc === sortBy) {
                dispatch(tableActions.setSortBy(desc))
            } else {
                dispatch(tableActions.setSortBy(asc))
            }
        }
    }

    const onPageChange = (event: any) => {
        if (type === "group") {
            dispatch(tableActions.setChildPage(event?.page + 1));
            dispatch(tableActions.setChildFirst(event.first));
            dispatch(tableActions.setChildSize(event.rows))
        } else {
            dispatch(tableActions.setPage(event?.page + 1));
            dispatch(tableActions.setFirst(event.first));
            dispatch(tableActions.setSize(event.rows))
        }
    };

    const getHeader = () => {
        let arr: any = [];
        defaultField.forEach((el: any) => {
            for (const key in frontField) {
                if (key === el) {
                    arr.push(<th key={key} className="px-[8px] 2xl:px-[16px] h-full cursor-pointer" onClick={() => handleSort(key, `-${key}`)}>
                        <div className='flex items-center justify-start w-full gap-[8px]'>
                            <p className='text-dGray font-normal text-sm !text-left'>{frontField[key]}</p>
                             <div className="w-[13px] h-[13px]">
        {getSortIcon(key, `-${key}`)}
</div>
                        </div>
                    </th>);
                }
            }
        });

        return arr;
    };

    useEffect(() => {
        let arr1: any = [];
        for (let i = 0; i < data?.length; i++) {
            let el: any = data[i];
            el = {...el, flag: false}
            let arr: any[] = [];
            for (let j = 0; j < el?.system_group_list?.length; j++) {
                const s1 = el?.system_group_list[j];

                if (s1?.type === "Credit") {
                    arr.push(s1)
                }
            }

            if (arr?.length > 0) {
                el = {...el, credit: true}
                el = {...el, creditDate: Moment(el?.created_at).format('DD MMM YYYY')}
                el = {...el, creditGroup: arr[0]}
            } else {
                el = {...el, credit: false}
            }

            arr1.push(el)
        }
        setList(arr1)
    }, [data])

    return (
        <>
            {(loading || data === null) ? <SkeletonGridView /> :
                <>
                    {(!loading && data && data?.length > 0) &&
                        <div className="flex flex-col justify-between min-h-[600px]">
                            <table className={`table-auto overflow-x-scroll w-full ${(!!!type) ? "table" : "skeleton-table"}`}>
                                {!!!params?.id && <>
                                    <thead>
                                        <tr className="h-[50px]">
                                            <th className="px-[8px] 2xl:px-[16px] h-full">
                                                <div className='flex items-center justify-center' onMouseLeave={() => setAllCheckBox(false)} onMouseEnter={() => setAllCheckBox(true)}>
                                                    <Checkbox className="tests table-check" name="checkAll" checked={check} onChange={handleChange}></Checkbox>
                                                </div>
                                            </th>
                                            {getHeader()}
                                            <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer"></th>
                                            {/* {obj?.alerts && <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                            <div className='flex items-center justify-center gap-[8px]'>
                                                <p className='text-lGray font-normal text-sm !text-center'>Alerts</p>
                                                <SortDownArrow />
                                            </div>
                                        </th>} */}
                                            {/* <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                            <div className='flex items-center justify-start gap-[8px]'>
                                                                <p className='text-lGray font-normal text-sm !text-left'>Quick Action</p>
                                                            </div>
                                                        </th> */}
                                        </tr>
                                    </thead>
                                </>}
                                <tbody>
                                    {Array.isArray(list) && list.map((item: any, index: number) => (
                                        <tr className={`!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[55px] ${(!!!type) && "hover:!bg-[#FAFAFF] cursor-pointer"} ${!!params?.id && "sec-tr"} ${params?.id === item?.id && "active-sample bg-[#FAFAFF]"}`} key={index}>
                                            {!!params?.id ?
                                                <>
                                                    <td onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")} className="px-[8px] 2xl:px-[16px] h-full relative">
                                                        <div className="flex items-center justify-center">
                                                            {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                :
                                                                <>
                                                                    {!!item?.org_name ?
                                                                        <div style={{ backgroundColor: `${randomColor[item?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.org_name?.charAt(0)}</div>
                                                                        : <div style={{ backgroundColor: `${randomColor[item?.user_detail?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.user_detail?.first_name?.charAt(0)}{item?.user_detail?.last_name?.charAt(0)}</div>}
                                                                </>
                                                            }
                                                        </div>
                                                        </td>

                                                        <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                                            <div className="flex flex-col">
                                                                <h3 className="text-dGray font-normal !text-sm text-left">{item?.user_detail?.first_name} {" "} {item?.user_detail?.last_name}</h3>
                                                                {!!item?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                                            </div>
                                                        </td>
                                                </>
                                                : <>
                                                    <td onClick={() => (!!!type) && navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} className="px-[8px] 2xl:px-[16px] h-full relative" onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")}>
                                                        <div className="flex items-center justify-center">
                                                            {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                                                <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                                                :
                                                                <>
                                                                    {!!item?.org_name ?
                                                                        <div style={{ backgroundColor: `${randomColor[item?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.org_name?.charAt(0)}</div>
                                                                        : <div style={{ backgroundColor: `${randomColor[item?.user_detail?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.user_detail?.first_name?.charAt(0)}{item?.user_detail?.last_name?.charAt(0)}</div>}
                                                                </>
                                                            }
                                                        </div>
                                                    </td>
                                                    {Array.isArray(defaultField) && defaultField.map((i: any, idx: number) => (
                                                        <React.Fragment key={idx}>
                                                            <td onClick={() => (!!!type) && navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)} onMouseEnter={() => i === "system_group" && setAllBtnShow(item?.id)} onMouseLeave={() => i === "system_group" && setAllBtnShow(0)} className="px-[8px] 2xl:px-[16px] h-full relative">
                                                                {i === "first_name" ?
                                                                    <div className="flex flex-col">
                                                                        <h3 className="text-dGray font-normal !text-sm text-left">{item?.user_detail?.[i]} {" "} {item?.user_detail?.last_name}</h3>
                                                                        {!!item?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                                                    </div>
                                                                    : i === "tds_percentage" ? <div className='w-full flex items-center justify-start'>
                                                                        <h3 className="!text-sm text-mGray font-normal">{!!item[i] ? `${item[i]}%` : "-"}</h3>
                                                                    </div> :
                                                                        i === "email" ? <div className='w-full flex items-center justify-start'>
                                                                            <h3 className="!text-sm text-mGray font-normal">{!!item?.user_detail?.[i] ? item?.user_detail?.[i] : "-"}</h3>
                                                                        </div>
                                                                            :
                                                                            i === "created_at" ?
                                                                            <h3 className="text-mGray font-normal !text-sm text-left">{!!item[i] ? Moment(item[i]).format('DD MMM YYYY') : "-"}</h3> :
                                                                            i === "system_group" ?
                                                                                <div>
                                                                                    {item?.system_group_list?.length > 0 &&
                                                                                        <>
                                                                                        {(allBtnShow !== item?.id || allBtnShow === 0) ? <div className="flex items-center gap-[8px]">

                                                                                            <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>{item?.system_group_list[0]?.value}{item?.system_group_list[0]?.amount_type === "Percentage" ? "%" : ""} &nbsp;&nbsp;{item?.system_group_list[0]?.type}</button>
                                                                                            {item?.system_group_list?.length > 1 && <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-xs font-normal rounded-[5px] py-[6px] px-[10px] whitespace-normal`}>{item?.system_group_list?.length - 1}+</button>}
                                                                                        </div> :
                                                                                            <div className="flex flex-wrap items-center gap-[8px]">
                                                                                                {Array.isArray(item?.system_group_list) && item?.system_group_list.map((i: any, idx: number) => (
                                                                                                    <button key={idx} className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>{i?.value}{i?.amount_type === "Percentage" ? "%" : ""} &nbsp;&nbsp;{i?.type}</button>
                                                                                                ))}
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {!!item[i] ?
                                                                                        <h3 className="text-mGray font-normal !text-sm text-left truncate">{item[i]}</h3>
                                                                                        :
                                                                                        <>
                                                                                            {item?.custom_fields_values?.length > 0 ? <>
                                                                                                {Array.isArray(item?.custom_fields_values) && item?.custom_fields_values.map((c: any, cidx: number) => (
                                                                                                    <React.Fragment key={cidx}>
                                                                                                        {c?.slug === i && <h3 key={cidx} className="text-mGray font-normal !text-sm text-left truncate">{!!c?.value ? c?.value : "-"}</h3>}
                                                                                                    </React.Fragment>
                                                                                                ))}
                                                                                            </> : <h3 className="text-mGray font-normal !text-sm text-left truncate">-</h3>}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                }
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                    <td className="px-[8px] 2xl:px-[16px] h-full">
                                                        <Menu align="end" menuButton={<MenuButton>
                                                            <div className="flex items-center justify-center cursor-pointer w-[25px] h-[25px]">
                                                                <OptionDot color="#6B7280" />
                                                            </div>
                                                        </MenuButton>}>
                                                            <MenuItem onClick={() => { dispatch(crmActions.setCreateGroup(true)); dispatch(crmActions.setCustomer(item)) }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray fill-mGray group-hover:fill-primaryLight'><AssignGroup width="15" height="15" /><p className="pl-[4px]">{t("menu.assign_group")}</p></div></div></MenuItem>
                                                            {item?.credit && <MenuItem onClick={() => { dispatch(crmActions.setCustomer(item)); dispatch(crmActions.setCreditGroup(item?.creditGroup)); dispatch(crmActions.setCreditLimitOpen(true)); }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] group '><ExtandValidity className="group-hover:fill-primaryLight fill-mGray group-hover:stroke-primaryLight stroke-mGray" /><p className="text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium">{t("crm.credit_limit.title")}</p></div></div></MenuItem>}
                                                            {/* <MenuItem><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><SettingRemove width="18" height="18" /><p className='text-sm'>{t("menu.delete")}</p></div></div></MenuItem> */}
                                                            {option === "pending" && <>
                                                                <MenuItem onClick={() => handleSubmit(item, true)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray group-hover:fill-primaryLight fill-mGray'><Approve /> {t("menu.approve")}</div></div></MenuItem>
                                                                <MenuItem onClick={() => handleSubmit(item, false)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium hover:stroke-primaryLight stroke-mGray'><Reject /> {t("menu.reject")}</div></div></MenuItem>
                                                            </>}
                                                        </Menu>
                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {
                                type === "group" ?
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={childFirst} rows={childSize} totalRecords={childTotal} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div> :
                                    <div className="flex items-center justify-end">
                                        <Paginator className="pagination" pageLinkSize={3} first={first} rows={size} totalRecords={total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
                                    </div>
                            }
                        </div>
                    }
                </>
            }

            {
                (data?.length === 0 && !loading) &&
                <div className="flex flex-col items-center justify-center min-h-[600px]">
                    <img src={require("../../../assets/images/notFound-cust.png")} alt="" />
                    <h3 className="text-base text-primaryLight font-normal pt-[14px]">No Customer Has Been Created</h3>
                    <p className="max-w-[301px] text-center text-mGray text-sm font-normal pt-[14px]">Create new test to manage all the standards and parameters</p>

                    <button className="bg-[#EEF4FC] rounded-[10px] px-[24px] h-[40px] text-primaryLight text-sm font-medium border border-[rgba(32,50,143,0.30)] flex items-center justify-center cursor-pointer mt-[30px]" onClick={() => dispatch(crmActions.setIsCustomerCreate(true))}>Create New Customer</button>
                </div>
            }
        </>
    );
};

export default GridView;



    //         <tr className={`!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[55px] hover:!bg-[#FAFAFF] cursor-pointer ${!!params?.id && "sec-tr"} ${params?.id === item?.id && "active-sample !bg-[#FAFAFF]"} `} key={index}>
                                        //             <td className="px-[8px] 2xl:px-[12px] h-full" onMouseEnter={() => setOneCheck(item?.id)} onMouseLeave={() => setOneCheck("")}>
                                        //                 <div className="flex items-center justify-center">
                                        //                     {(allCheckBox || check || oneCheck === item?.id || item?.flag) ?
                                        //                         <Checkbox className="tests table-check" checked={item?.flag} onChange={handleChange} name={item?.id}></Checkbox>
                                        //                         :
                                        //                         <>
                                        //                             {!!item?.org_name ?
                                        //                                 <div style={{ backgroundColor: `${randomColor[item?.org_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.org_name?.charAt(0)}</div>
                                        //                                 : <div style={{ backgroundColor: `${randomColor[item?.user_detail?.first_name?.charAt(0)?.toLowerCase()]}` }} className={`uppercase text-white font-semibold text-xs rounded-full w-[30px] h-[30px] flex items-center justify-center`}>{item?.user_detail?.first_name?.charAt(0)}{item?.user_detail?.last_name?.charAt(0)}</div>}
                                        //                         </>
                                        //                     }
                                        //                 </div>
                                        //             </td>
                                        //             {obj?.organization && <td className="px-[8px] 2xl:px-[12px] h-full" onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                        //                 <div className="flex flex-col">
                                        //                     <h3 className="text-dGray font-normal !text-sm text-left">{item?.user_detail?.first_name} {" "} {item?.user_detail?.last_name}</h3>
                                        //                     {!!item?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{item?.org_name}</span></p>}
                                        //                 </div>
                                        //             </td>}
                                        //             {!!!params?.id && <>
                                        //                 {obj?.email && <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                        //                     <div className="flex items-center gap-[15px]">
                                        //                         <h3 className="text-mGray font-normal !text-sm text-left">{item?.user_detail?.email}</h3>
                                        //                     </div>
                                        //                 </td>}
                                        //                 {obj?.phone_number && <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                        //                     <h3 className="text-mGray font-normal !text-sm text-left">{!!item?.user_detail?.profile?.mobile ? item?.user_detail?.profile?.mobile : "-"}</h3>
                                        //                 </td>}
                                        //                 {obj?.groups && <td className="px-[8px] 2xl:px-[16px] h-full" onMouseEnter={() => setAllBtnShow(item?.id)} onMouseLeave={() => setAllBtnShow(0)} onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                        //                     <div>
                                        //                         {item?.system_group_list?.length > 0 &&
                                        //                             <>
                                        //                                 {(allBtnShow !== item?.id || allBtnShow === 0) ? <div className="flex items-center gap-[8px]">

                                        //                                     <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>{item?.system_group_list[0]?.value}{item?.system_group_list[0]?.amount_type === "Percentage" ? "%" : ""} &nbsp;&nbsp;{item?.system_group_list[0]?.type}</button>
                                        //                                     {item?.system_group_list?.length > 1 && <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-xs font-normal rounded-[5px] py-[6px] px-[10px] whitespace-normal`}>{item?.system_group_list?.length - 1}+</button>}
                                        //                                 </div> : 
                                        //                                     <div className="flex flex-wrap items-center gap-[8px]">
                                        //                                         {Array.isArray(item?.system_group_list) && item?.system_group_list.map((i: any, idx: number) => (
                                        //                                             <button key={idx} className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-sm font-normal rounded-[5px] py-[4px] px-[20px] whitespace-nowrap`}>{i?.value}{i?.amount_type === "Percentage" ? "%" : ""} &nbsp;&nbsp;{i?.type}</button>
                                        //                                         ))}
                                        //                                     </div>
                                        //                                 }
                                        //                             </>
                                        //                         }
                                        //                     </div>
                                        //                 </td>}
                                        //                 {obj?.tds && <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                        //                     <h3 className="text-mGray font-normal !text-sm text-left">{`${item?.tds_details ? `${item?.tds_details}%` : "-"}`}</h3>
                                        //                 </td>}
                                        //                 {obj?.note && <td className="px-[8px] 2xl:px-[16px] h-full" onClick={() => navigate(`/crm/${!!params?.tab ? params?.tab : option?.name}/${item?.id}`)}>
                                        //                     <h3 className="text-mGray font-normal !text-sm text-left">{item?.note ? item?.note : "-"}</h3>
                                        //                 </td>}
                                        //                 {/* {obj?.alerts && <td className="px-[8px] 2xl:px-[16px] h-full">
                                        //     <div className="flex items-center gap-[8px]">
                                        //         <Alerts color={item?.alert === "0" ? "#CBD5E1" : "#EB8F00"} />
                                        //         <h3 className={`${item?.alert === "0" ? "text-[#CBD5E1]" : "text-[#EB8F00]"} text-sm font-normal`}>{item?.alert === "0" ? "No Alerts" : item?.alert + "Alerts Pending"}</h3>
                                        //     </div>
                                        // </td>} */}
                                        //                 {/* <td className="px-[8px] 2xl:px-[16px] h-full">
                                        //                     <div className="flex items-center gap-[8px]">
                                        //                         <div className="shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] bg-white w-[30px] h-[30px] flex items-center justify-center rounded-[5px] cursor-pointer">
                                        //                             <AllCheck color="#6B7280" />
                                        //                         </div>
                                        //                         <div className="shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] bg-white w-[30px] h-[30px] flex items-center justify-center rounded-[5px] cursor-pointer">
                                        //                             <PlusDoc color="#6B7280" />
                                        //                         </div>
                                        //                     </div>
                                        //                 </td> */}
                                        //                 <td className="px-[12px] h-full">
                                        //                     <Menu align="end" menuButton={<MenuButton>
                                        //                         <div className="flex items-center justify-center cursor-pointer w-[25px] h-[25px]">
                                        //                             <OptionDot color="#6B7280" />
                                        //                         </div>
                                        //                     </MenuButton>}>
                                        //                         <MenuItem onClick={() => { dispatch(tableActions.setCreateGroup(true)); dispatch(tableActions.setCustomerId(item?.id)) }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray fill-mGray group-hover:fill-primaryLight'><AssignGroup width="15" height="15" /><p className="pl-[4px]">{t("menu.assign_group")}</p></div></div></MenuItem>
                                        //                         {item?.credit && <MenuItem onClick={() => { dispatch(tableActions.setCustomerId(item?.id)); dispatch(tableActions.setCreditGroup(item?.creditGroup)); dispatch(tableActions.setCreditLimitOpen(true)); }}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] group '><ExtandValidity className="group-hover:fill-primaryLight fill-mGray group-hover:stroke-primaryLight stroke-mGray" /><p className="text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium">{t("crm.credit_limit.title")}</p></div></div></MenuItem>}
                                        //                         {/* <MenuItem><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray'><SettingRemove width="18" height="18" /><p className='text-sm'>{t("menu.delete")}</p></div></div></MenuItem> */}
                                        //                         {option === "pending" && <>
                                        //                             <MenuItem onClick={() => handleSubmit(item, true)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium group-hover:stroke-primaryLight stroke-mGray group-hover:fill-primaryLight fill-mGray'><Approve /> {t("menu.approve")}</div></div></MenuItem>
                                        //                             <MenuItem onClick={() => handleSubmit(item, false)}><div className="group w-full h-full px-[14px] py-[6px]"><div className='flex items-center gap-[10px] text-mGray text-sm font-normal whitespace-nowrap group-hover:text-primaryLight group-hover:font-medium hover:stroke-primaryLight stroke-mGray'><Reject /> {t("menu.reject")}</div></div></MenuItem>
                                        //                         </>}
                                        //                     </Menu>
                                        //                 </td>
                                        //             </>}
                                        //         </tr>