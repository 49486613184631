import { createSlice } from '@reduxjs/toolkit';

interface TestState {
    selectTest: [],
    addTestOpen: boolean,
    service: any,
    standardsList: any[],
    labList: any[],
    serviceList: any[],
    selectStandard: any[],
    selectLab: string,
    selectedTest: any,
    sampleList: any[],
    standardPopup: boolean,
    addSampleOpen: boolean,
    parameterListOpen: boolean,
    sampleId: string,
    editSampleOpen: boolean,
    editSample: any,
    standardLoading: boolean,
    labLoading: boolean,
    sampleLoading: boolean,
    testDetail: any,
    sampleDetail: any,
    standardDetail: boolean,
    standard: any,
    totalSample: any[],
    standardSampleCount: any,
    customStandard: string,
    testReqInfo: any,
    labApiCall: boolean,
    sampleApiCall: boolean,
    standardApiCall: boolean,
    testProcess: any[],
    isSuccess: boolean,
    singleQRCode: boolean,
    multiQRCode: boolean,
    creditLimitOpen: boolean,
    invoiceView: string,
    invoiceGroupBy: boolean,
    invoiceGroupByOption: string,
    invoiceGroupByOptionValue: string,
    invoiceData: any,
    invoiceLoading: boolean,
    option: string,
    view: string,
    groupBy: boolean,
    groupByOption: string,
    groupByOptionValue: string,
    sortByList: any[],
    groupByList: any[],
    filterList: any[],
    detailField: any[],
    defaultField: any[],
    filterResult: string,
    page: number,
    total: number,
    size: number,
    sortBy: string,
    testGroupByData: any[],
    groupByExpand: any,
    tagList: any[],
    testData: any,
    testLoading: boolean,
    paymentSortByList: any[],
    paymentGroupByList: any[],
    paymentFilterList: any[],
    paymentDetailField: any[],
    paymentDefaultField: any[],
    paymentFrontField: any,
    paymentFilterResult: string,
    paymentSortBy: string,
    paymentPage: number,
    paymentTotal: number,
    paymentSize: number,
    paymentGroupByData: any[],
    paymentGroupByExpand: any,
    paymentOption: string,
    paymentView: string,
    paymentGroupBy: boolean,
    paymentGroupByOption: string,
    paymentGroupByOptionValue: string,
    paymentTagList: any[],
    paymentData: any,
    paymentLoading: boolean,
    reportData: any[],
    deptPopup: boolean,
    wallet: any,
    frontField: any,
    sampleQty: any,
    sampleUnit: any,
    bookAddress: boolean
}

const initialState: TestState = {
    selectTest: [],
    addTestOpen: false,
    service: {},
    standardsList: [],
    labList: [],
    serviceList: [],
    selectStandard: [],
    selectLab: "",
    selectedTest: {},
    sampleList: [],
    standardPopup: false,
    addSampleOpen: false,
    parameterListOpen: false,
    sampleId: "",
    editSampleOpen: false,
    editSample: {},
    standardLoading: true,
    labLoading: true,
    sampleLoading: true,
    testDetail: {},
    sampleDetail: {},
    standardDetail: false,
    standard: null,
    totalSample: [],
    standardSampleCount: {},
    customStandard: "",
    testReqInfo: null,
    labApiCall: false,
    sampleApiCall: false,
    standardApiCall: false,
    testProcess: [],
    isSuccess: false,
    singleQRCode: false,
    multiQRCode: false,
    creditLimitOpen: false,
    invoiceView: "card",
    invoiceGroupBy: false,
    invoiceGroupByOption: "",
    invoiceGroupByOptionValue: "",
    invoiceData: null,
    invoiceLoading: true,
    sortByList: [],
    groupByList: [],
    filterList: [],
    detailField: [],
    defaultField: [],
    filterResult: "",
    sortBy: "-created_at",
    page: 1,
    total: 0,
    size: 10,
    testGroupByData: [],
    groupByExpand: {},
    option: "",
    view: "card",
    groupBy: false,
    groupByOption: "",
    groupByOptionValue: "",
    tagList: [],
    testData: null,
    testLoading: false,
    paymentSortByList: [],
    paymentGroupByList: [],
    paymentFilterList: [],
    paymentDetailField: [],
    paymentDefaultField: [],
    paymentFrontField: null,
    paymentFilterResult: "",
    paymentSortBy: "-created_at",
    paymentPage: 1,
    paymentTotal: 0,
    paymentSize: 10,
    paymentGroupByData: [],
    paymentGroupByExpand: {},
    paymentOption: "",
    paymentView: "card",
    paymentGroupBy: false,
    paymentGroupByOption: "",
    paymentGroupByOptionValue: "",
    paymentTagList: [],
    paymentData: null,
    paymentLoading: false,
    reportData: [],
    deptPopup: false,
    wallet: 0,
    frontField: null,
    sampleQty: "",
    sampleUnit: null,
    bookAddress: false
}

const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        handleTest: (state, action) => {
            state.selectTest = action.payload;
        },
        setAddTestOpen: (state, action) => {
            state.addTestOpen = action.payload;
        },
        setService: (state, action) => {
            state.service = action.payload
        },
        setStandardsList: (state, action) => {
            state.standardsList = action.payload
        },
        setLabList: (state, action) => {
            state.labList = action.payload
        },
        setServiceList: (state, action) => {
            state.serviceList = action.payload
        },
        setSelectStandard: (state, action) => {
            state.selectStandard = action.payload
        },
        setSelectLab: (state, action) => {
            state.selectLab = action.payload
        },
        setSelectedTest: (state, action) => {
            state.selectedTest = action.payload
        },
        setSampleList: (state, action) => {
            state.sampleList = action.payload
        },
        setStandardPopup: (state, action) => {
            state.standardPopup = action.payload
        },
        setAddSampleOpen: (state, action) => {
            state.addSampleOpen = action.payload
        },
        setParameterListOpen: (state, action) => {
            state.parameterListOpen = action.payload
        },
        setSampleId: (state, action) => {
            state.sampleId = action.payload
        },
        setEditSampleOpen: (state, action) => {
            state.editSampleOpen = action.payload
        },
        setEditSample: (state, action) => {
            state.editSample = action.payload
        },
        setStandardLoading: (state, action) => {
            state.standardLoading = action.payload
        },
        setLabLoading: (state, action) => {
            state.labLoading = action.payload
        },
        setSampleLoading: (state, action) => {
            state.sampleLoading = action.payload
        },
        setTestDetail: (state, action) => {
            state.testDetail = action.payload
        },
        setSampleDetail: (state, action) => {
            state.sampleDetail = action.payload
        },
        setStandardDetail: (state, action) => {
            state.standardDetail = action.payload
        },
        setSelectStandardDetail: (state, action) => {
            state.standard = action.payload
        },
        setTotalSample: (state, action) => {
            state.totalSample = action.payload
        },
        setStandardSampleCount: (state, action) => {
            state.standardSampleCount = action.payload
        },
        setTagList: (state, action) => {
            state.tagList = action.payload;
        },
        changeOption: (state, action) => {
            state.option = action.payload;
        },
        groupByToggle: (state) => {
            state.groupBy = true;
        },
        groupToggle: (state) => {
            state.groupBy = false;
            state.groupByOption = "";
            state.groupByOptionValue = "";
            state.page = 1;
        },
        handleView: (state, action) => {
            state.view = action.payload;
        },
        handleGroupOption: (state, action) => {
            state.groupByOption = action.payload;
        },
        handleGroupOptionValue: (state, action) => {
            state.groupByOptionValue = action.payload;
        },
        setTestList: (state, action) => {
            state.testData = action.payload;
        },
        setTestLoading: (state, action) => {
            state.testLoading = action.payload;
        },
        setCustomStandard: (state, action) => {
            state.customStandard = action.payload;
        },
        setTestReqInfo: (state, action) => {
            state.testReqInfo = action.payload;
        },
        setLabApiCall: (state, action) => {
            state.labApiCall = action.payload;
        },
        setSampleApiCall: (state, action) => {
            state.sampleApiCall = action.payload;
        },
        setStandardApiCall: (state, action) => {
            state.standardApiCall = action.payload;
        },
        setTestProcess: (state, action) => {
            state.testProcess = action.payload;
        },
        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload;
        },
        setSingleQRCode: (state, action) => {
            state.singleQRCode = action.payload;
        },
        setMultiQRCode: (state, action) => {
            state.multiQRCode = action.payload;
        },
        setCreditLimitOpen: (state, action) => {
            state.creditLimitOpen = action.payload;
        },
        setInvoiceGroupByToggle: (state) => {
            state.invoiceGroupBy = true;
        },
        setInvoiceGroupToggle: (state) => {
            state.invoiceGroupBy = false;
            state.invoiceGroupByOption = "";
            state.invoiceGroupByOptionValue = "";
        },
        setHandleView: (state, action) => {
            state.invoiceView = action.payload;
        },
        setHandleGroupOption: (state, action) => {
            state.invoiceGroupByOption = action.payload;
        },
        setHandleGroupOptionValue: (state, action) => {
            state.invoiceGroupByOptionValue = action.payload;
        },
        setInvoiceList: (state, action) => {
            state.invoiceData = action.payload;
        },
        setInvoiceLoading: (state, action) => {
            state.invoiceLoading = action.payload;
        },
        setSortByList: (state, action) => {
            state.sortByList = action.payload;
        },
        setGroupByList: (state, action) => {
            state.groupByList = action.payload;
        },
        setFilterList: (state, action) => {
            state.filterList = action.payload
        },
        setDefaultField: (state, action) => {
            state.defaultField = action.payload
        },
        setDetailField: (state, action) => {
            state.detailField = action.payload
        },
        setFilterResult: (state, action) => {
            state.filterResult = action.payload
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setSize: (state, action) => {
            state.size = action.payload
        },
        setTestGroupByList: (state, action) => {
            state.testGroupByData = action.payload;
        },
        setGroupByExpand: (state, action) => {
            state.groupByExpand = action.payload
        },
        setPaymentTagList: (state, action) => {
            state.paymentTagList = action.payload;
        },
        setPaymentSortByList: (state, action) => {
            state.paymentSortByList = action.payload;
        },
        setPaymentGroupByList: (state, action) => {
            state.paymentGroupByList = action.payload;
        },
        setPaymentFilterList: (state, action) => {
            state.paymentFilterList = action.payload
        },
        paymentChangeOption: (state, action) => {
            state.paymentOption = action.payload;
        },
        setPaymentLoading: (state, action) => {
            state.paymentLoading = action.payload;
        },
        setPaymentList: (state, action) => {
            state.paymentData = action.payload;
        },
        setPaymentTotal: (state, action) => {
            state.paymentTotal = action.payload
        },
        setPaymentDefaultField: (state, action) => {
            state.paymentDefaultField = action.payload
        },
        setPaymentDetailField: (state, action) => {
            state.paymentDetailField = action.payload
        },
        setPaymentFilterResult: (state, action) => {
            state.paymentFilterResult = action.payload
        },
        setPaymentFrontField: (state, action) => {
            state.paymentFrontField = action.payload
        },
        paymentGroupByToggle: (state) => {
            state.paymentGroupBy = true;
        },
        paymentGroupToggle: (state) => {
            state.paymentGroupBy = false;
            state.paymentGroupByOption = "";
            state.paymentGroupByOptionValue = "";
            state.paymentPage = 1;
        },
        paymentHandleView: (state, action) => {
            state.paymentView = action.payload;
        },
        paymentHandleGroupOption: (state, action) => {
            state.paymentGroupByOption = action.payload;
        },
        paymentHandleGroupOptionValue: (state, action) => {
            state.paymentGroupByOptionValue = action.payload;
        },
        setPaymentPage: (state, action) => {
            state.paymentPage = action.payload
        },
        setPaymentSize: (state, action) => {
            state.paymentSize = action.payload
        },
        setReportData: (state, action) => {
            state.reportData = action.payload
        },
        setDeptPopup: (state, action) => {
            state.deptPopup = action.payload
        },
        setWallet: (state, action) => {
            state.wallet = action.payload
        },
        setFrontField: (state, action) => {
            state.frontField = action.payload
        },
        setSampleQty: (state, action) => {
            state.sampleQty = action.payload
        },
        setSampleUnit: (state, action) => {
            state.sampleUnit = action.payload
        },
        setBookAddress: (state, action) => {
            state.bookAddress = action.payload
        },
    }
});

export default testSlice;
export const testActions = testSlice.actions;