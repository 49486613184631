import { useSelector } from "react-redux";
import { CloseBtn } from "../../svg/AllSvg";
import { employeeActions } from "../../../shared/store/employee-Slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const SuccessPopup = () => {
    const isSuccess: boolean = useSelector((state: any) => state.employee.isSuccess);
    const empName: string = useSelector((state: any) => state.employee.empName);
    const successType: string = useSelector((state: any) => state.employee.successType);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(employeeActions.setIsSuccess(false))
        }
    };

    return (
        <>
            {isSuccess &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-[382px] sm:w-[600px] md:w-[633px] overflow-y-scroll">
                            <div className="flex items-start justify-end px-[28px] pt-[25px] rounded-t-[10px]">
                                <div className="cursor-pointer" onClick={() => dispatch(employeeActions.setIsSuccess(false))}>
                                    <CloseBtn />
                                </div>
                            </div>

                            <div className="w-full h-full flex flex-col items-center justify-start pt-[15px]">
                                <img src={require("../../../assets/images/success.png")} alt="" />

                                <h3 className="text-primaryLight text-lg font-semibold pt-[15px]"> {successType === "create" ? t("laboratory.employee.success.add_title") : t("laboratory.employee.success.edit_title")} </h3>
                                <p className="text-mGray font-normal text-base max-w-[384px] pt-[5px] text-center">{empName} {t("laboratory.employee.success.successfully")} {successType === "create" ? t("laboratory.employee.success.add_desc") : t("laboratory.employee.success.edit_desc")}  </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SuccessPopup;