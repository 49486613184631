import { Close, NoWifi } from "../svg/AllSvg";

const NoInternet: React.FC<any> = ({ setIsOpen, isOpen }: any) => {
  return (
    <div className={`transform ease-in-out transition-all duration-150 fixed z-20 bottom-[16px] right-[16px] sm:bottom-[32px] sm:right-[32px] flex item-center justify-center bg-[#EDF4FF] py-[16px] px-[24px] gap-[40px] shadow-2xl rounded-lg ${isOpen ? "translate-x-0" : "-translate-x-full"}`}>
      <div className="flex items-center justify-center gap-[16px]">
        <div className="bg-primary py-[6px] px-[7px] rounded-full">
          <NoWifi />
        </div>
        <div className="flex flex-col">
          <h3 className="text-[#000000] text-sm font-medium whitespace-nowrap">You're offline now</h3>
          <p className="text-xs text-[#3F3F3F] font-normal whitespace-nowrap">Oops! Internet is disconnected.</p>
        </div>
      </div>
      <div className="bg-[#f2f2f2] border border-[#cccccc] p-[6px] rounded-full cursor-pointer" onClick={() => setIsOpen(false)}>
        <Close />
      </div>
    </div>
  );
};

export default NoInternet;