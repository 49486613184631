/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { ChipClose, DangerClose, DateIcon, SettingAddMore } from "../svg/AllSvg";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import Moment from 'moment';
import { useTranslation } from "react-i18next";

const AddFilter: React.FC<any> = ({ filterObj, setFilterObj, filterList, setChildFirst, setFirst, setChildPage, setPage, setFilterResult }) => {
    const [list, setList] = useState<any[]>([]);
    const [filterArr, setFilterArr] = useState<any[]>([]);
    const [selectOption, setSelectOption] = useState<any>({ name: "", type: "", text: "", option: [] });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [suggestionOpen, setSuggestionOpen] = useState<boolean>(false);
    const [suggestionList, setSuggestionList] = useState<any[]>([]);
    const [filterValue, setFilterValue] = useState<string>("");
    const [open, setOpen] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<number>(0);
    const ref: any = useRef();
    const { t } = useTranslation('common');

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpenFilter(false);
                // setFilterOpen(false);
                // setFilterValue("");
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        let arr: any[] = [];
        filterList.forEach((el: any) => {
            if (!filterObj.hasOwnProperty(el?.text)) {
                arr.push(el)
            }
        });
        setList(arr);
        setFilterArr(filterList)
    }, [filterList])

    const getFilterResult = () => {
        let filter: string = "";

        for (const key in filterObj) {
            for (let i = 0; i < filterArr.length; i++) {
                const el = filterArr[i];

                if (key === "Gender" && el?.text === key) {
                    if (filterObj[key]?.toLowerCase() === "male") {
                        // eslint-disable-next-line
                        filter += "gender" + "=" + 1 + "&";
                    }
                    if (filterObj[key]?.toLowerCase() === "female") {
                        // eslint-disable-next-line
                        filter += "gender" + "=" + 2 + "&";
                    }
                }

                if (typeof (filterObj[key]) === "object" && el?.type === "date" && el?.text === key) {
                    if (filterObj[key]?.length > 1) {
                        // eslint-disable-next-line
                        filter += el?.field_name[0] + "=" + filterObj[key][0] + "&" + el?.field_name[1] + "=" + filterObj[key][1] + "&";
                    } else {
                        filter += el?.field_name[0] + "=" + filterObj[key][0] + "&";
                    }
                }

                if ((el?.text === key && key !== "Gender") && (el?.type === "text" || el?.type === "int")) {
                    filter += el?.name + "=" + filterObj[key] + "&";
                }

                if (el?.type === "enum" && key !== "Gender") {
                    for (let i = 0; i < el?.option?.length; i++) {
                        const o = el?.option[i];

                        if (o?.name === filterObj[key]) {
                            filter += el?.name + "=" + o?.value + "&";
                        }
                    }
                }
            }
        }

        setFilterResult(filter);
        setPage(1);
        setFirst(0);
        !!setChildPage && setChildPage(1);
        !!setChildFirst && setChildFirst(0);
    }

    useEffect(() => {
        getFilterResult();
    }, [filterObj])

    useEffect(() => {
        if (openFilter) {
            (document.getElementById('filter') as any).focus();
        }
        if (filterOpen) {
            (document.getElementById('filterform') as any).focus();
        }
        if (suggestionOpen) {
            (document.getElementById('filtersugge') as any).focus();
        }
    }, [openFilter, filterOpen, suggestionOpen]);

    useEffect(() => {
        const handleKey = (e: any) => {
            if (e.keyCode === 38) {
                if (selectedOption > 0) {
                    setSelectedOption(selectedOption - 1);
                }
            } else if (e.keyCode === 40) {
                if (selectedOption < list.length - 1) {
                    setSelectedOption(selectedOption + 1);
                }
            }

            if (openFilter && e.key === "Enter") {
                setSelectOption({ name: list[selectedOption]?.name, type: list[selectedOption]?.type, text: list[selectedOption]?.text, option: list[selectedOption]?.option });
                setFilterOpen(true);
                setOpenFilter(false);
            }

            if (suggestionOpen && e.key === "Enter") {
                if (suggestionList[selectedOption]?.suggestion) {
                    // eslint-disable-next-line
                    if (suggestionList[selectedOption]?.type === "date") {
                        if (search?.length === 10) {
                            setFilterObj({ ...filterObj, [suggestionList[selectedOption].text]: [search] })
                        } else {
                            let startDate: any = search.slice(0, 10)
                            let endDate: any = search.slice(11, 21)
                            setFilterObj({ ...filterObj, [suggestionList[selectedOption].text]: [startDate, endDate] })
                        }
                    } else {
                        setFilterObj({ ...filterObj, [suggestionList[selectedOption].text]: search })
                    }
                    let arr = list.filter((item: any) => item?.name !== suggestionList[selectedOption]?.name);
                    setList(arr);
                    setSearch("");
                    setFilterOpen(false);
                    setFilterValue("");
                    setOpenFilter(false);
                    setSuggestionOpen(false);
                } else {
                    setSelectOption({ name: suggestionList[selectedOption]?.name, type: suggestionList[selectedOption]?.type, text: list[selectedOption]?.text, option: suggestionList[selectedOption]?.option });
                    setFilterOpen(true);
                    setOpenFilter(false);
                    setSuggestionOpen(false);
                    setSearch("");
                    setFilterValue("");
                }
            }
        };

        if (openFilter || suggestionOpen) {
            document.addEventListener('keydown', handleKey);
        }

        return () => {
            document.removeEventListener('keydown', handleKey);
        };
    });

    useEffect(() => {
        if (filterOpen) {
            document.getElementById("filterInput")?.focus();
        }

        const handleKeyDown = (event: any) => {
            if (event.key === 'Escape') {
                setFilterOpen(false);
                setOpenFilter(false);
                setSuggestionOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [filterOpen])

    const handleFilter = () => {
        setFilterOpen(false);
        if (selectOption?.type === "date") {
            let fromDate = Moment(filterValue[0]).format('YYYY-MM-DD');
            let toDate = Moment(filterValue[1]).format('YYYY-MM-DD');
            let string = toDate === "Invalid date" ? [fromDate] : fromDate === "Invalid date" ? [toDate] : [fromDate, toDate];
            setFilterObj({ ...filterObj, [selectOption.text]: string })
        } else {
            setFilterObj({ ...filterObj, [selectOption.text]: filterValue })
        }
        let arr = list.filter((item: any) => item?.name !== selectOption?.name);
        setList(arr);
        setFilterValue("");
        setSelectOption({ name: "", type: "", text: "", option: [] });
        document.getElementById("myInput")?.focus();
    };

    const handleClose = (key: any) => {
        let obj: any = {};
        obj = filterArr.find((item: any) => item?.text === key);
        let ftrObj: any = { ...filterObj };
        delete ftrObj[key];
        setFilterObj(ftrObj)
        setList([...list, obj]);
        setSelectOption({ name: "", type: "", text: "", option: [] });
    };

    const handleDelete = (e: any, key: any) => {
        if (e.key === "Enter") {
            let obj: any = {};
            obj = filterArr.find((item: any) => item?.text === key);
            let ftrObj: any = { ...filterObj };

            delete ftrObj[key];
            setFilterObj(ftrObj)
            setList([...list, obj]);
            setSelectOption({ name: "", type: "", text: "", option: [] });
        }
    };

    const handleAllDelete = (e: any) => {
        if (e?.key === "Enter") {
            setFilterObj({})
            setList(filterArr);
        }
    }

    const handleStatus = (e: any, item: any) => {
        if (e.key === "Space") {
            setFilterValue(item?.value)
        }
    };

    const handleChipDelete = (e: any) => {
        if (e?.key === "Backspace" && Object.keys(filterObj).length > 0 && !!!search) {
            let ftrObj: any = { ...filterObj };
            const keys = Object.keys(ftrObj);
            const lastKey: any = keys.pop();
            let obj: any;
            obj = filterArr.find((item: any) => item?.text === lastKey);
            delete ftrObj[lastKey];
            setFilterObj(ftrObj)
            setList([...list, obj]);
            setSelectOption({ name: "", type: "", text: "", option: [] });
        }
    }



    const handleChange = (e: any) => {
        setSearch(e.target.value);
        if (e.target.value.length > 0) {
            setOpenFilter(false);
            setSuggestionOpen(true);
            // let arr: any[] = [];
            // for (let i = 0; i < list.length; i++) {
            //     const el = list[i];
            //     if (el?.type !== "date" && el?.type !== "enum") {
            //         // eslint-disable-next-line
            //         arr.push({ ...el, suggestion: el?.text + " starts with " + '"' + e.target.value + '"' })
            //     }
            // }
            let arr: any[] = [];
            // dd-mm-yyyy
            // let DMY: any = new RegExp(/(0[1-9]|[12][0-9]|3[01])(\/|-)(0[1-9]|1[1,2])(\/|-)\d{4}/)
            // mm-dd-yyyy
            // let MDY: any = new RegExp(/(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01])(\/|-)\d{4}/)
            // yyyy-mm-dd
            let YMD: any = new RegExp(/\d{4}(\/|-)(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01])/)
            let fullYMD: any = new RegExp(/\d{4}(\/|-)(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01])(-)\d{4}(\/|-)(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01])/)
            // yyyy-dd-mm
            // let YDM: any = new RegExp(/\d{4}(\/|-)(0[1-9]|[12][0-9]|3[01])(\/|-)(0[1-9]|1[1,2])/)

            // let validDMY: any = DMY.test(e.target.value);
            // let validMDY: any = MDY.test(e.target.value);
            let validYMD: any = YMD.test(e.target.value);
            let fullValidYMD: any = fullYMD.test(e.target.value);
            // let validYDM: any = YDM.test(e.target.value);
            for (let i = 0; i < list.length; i++) {
                const el = list[i];
                if (el?.type !== "enum") {
                    // eslint-disable-next-line
                    if ((el?.type === "date") && ((validYMD && e.target.value.length === 10) || (fullValidYMD && e.target.value.length === 21))) {
                        arr.push({ ...el, suggestion: el?.text + " starts with " + '"' + e.target.value + '"' })
                    } else {
                        if (el?.type !== "date") {
                            arr.push({ ...el, suggestion: el?.text + " starts with " + '"' + e.target.value + '"' })
                        }
                    }
                }
            }
            if ((validYMD && e.target.value.length === 10) || (fullValidYMD && e.target.value.length === 21)) {
                arr = arr.filter((item: any) => item?.type === "date")
            }
            setSuggestionList(arr);
        } else {
            setOpenFilter(true);
            setSuggestionOpen(false);
        }
    }

    const handleSelect = (i: any) => {
        if (i?.suggestion) {
            if (i?.type === "date") {
                if (search?.length === 10) {
                    setFilterObj({ ...filterObj, [i.text]: [search] })
                } else {
                    let startDate: any = search.slice(0, 10)
                    let endDate: any = search.slice(11, 21)
                    setFilterObj({ ...filterObj, [i.text]: [startDate, endDate] })
                }
            } else {
                setFilterObj({ ...filterObj, [i.text]: search })
            }
            let arr = list.filter((item: any) => item?.name !== i?.name);
            setList(arr);
            setSearch("");
            setFilterOpen(false);
            setFilterValue("");
            setOpenFilter(false);
            setSuggestionOpen(false);
        } else {
            setSelectOption({ name: i?.name, type: i?.type, text: i?.text, option: i?.option });
            setFilterOpen(true);
            setOpenFilter(false);
            setSuggestionOpen(false);
            setSearch("");
            setFilterValue("");
        }
    };

    const handleInput = () => {
        setOpenFilter(!openFilter);
        setFilterOpen(false);
        setFilterValue("");
        document.getElementById("myInput")?.focus();
    }

    const handleFilterSelect = (item: any) => {
        setSelectOption({ name: item?.name, type: item?.type, text: item?.text, option: item?.option });
        setFilterOpen(true);
        setOpenFilter(false);
    }

    const handleChip = () => {
        let arr: any = [];

        let index = 0;
        for (const key in filterObj) {
            arr.push(<div className="rounded-full border border-[#cdcdcd] bg-white shadow-[0px 1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)] items-center w-fit px-[4px] py-[4px] pl-[8px] flex justify-between gap-[4px] whitespace-nowrap mr-[8px] focus:border focus:border-primary hover:border hover:border-primary my-[6px]" onKeyDown={(e: any) => handleDelete(e, key)} key={index} tabIndex={9 + (index + 1)}>
                {/* eslint-disable-next-line */}
                <h3 className="text-primary font-medium text-xs">{key + " : " + "'" + filterObj[key] + "'"}</h3>
                <div className="cursor-pointer w-[18px] h-[18px] border border-[#cdcdcd] rounded-full flex items-center justify-center" onClick={() => handleClose(key)}>
                    <ChipClose />
                </div>
            </div>);
            index++;
        }

        return arr;
    };

    return (
        <div className="relative bg-[#F3F8FF] rounded-[8px] px-[8px] flex flex-wrap items-center w-full" ref={ref}>
            <div className="flex flex-wrap">
                {Object.keys(filterObj).length > 0 && handleChip()}

                {(open && !!!search) && <button className="rounded-[10px] border border-[#cdcdcd] shadow-[0px_1.648px_4.121px_0px_rgba(38,51,77,0.03)] flex items-center w-fit px-[8px] py-[4px] gap-[4px] whitespace-nowrap my-[5px] bg-[#FCFCFC]" onClick={handleInput}><SettingAddMore color="#94a3b8" width="18" height="18" />
                    <h3 className="font-normal text-lGray text-sm">{t("filter_menu.add_filter")}</h3>
                </button>}
            </div>

            <input autoComplete="off" type="text" id="myInput" className="flex-1 border-none h-[30px] my-[5px] py-[4px] bg-[#F3F8FF] !text-xs xl:!text-[0.813rem] !text-mGray" onFocus={() => { setOpen(false); setOpenFilter(true); setFilterOpen(false); setFilterValue(""); }} onBlur={() => { setOpen(true); }} onChange={handleChange} value={search} placeholder="" tabIndex={Object.keys(filterObj).length > 0 ? (Object.keys(filterObj).length + 10) : 10} onKeyDown={(e: any) => handleChipDelete(e)} />

            {openFilter && <ul className="py-[12px] px-[8px] flex flex-col gap-[8px] overflow-y-scroll w-[13rem] max-h-max absolute top-[48px] z-20 bg-white rounded-[10px] shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)]" id="filter">
                {Array.isArray(list) && list.map((item: any, index: number) => (
                    <li className={`${(selectedOption === index) && "bg-[#F4F4F4] rounded-[4px]"} cursor-pointer hover:bg-[#F4F4F4]`} key={index} onClick={() => handleFilterSelect(item)}>
                        <h3 className={`${(selectedOption === index) ? "text-dGray" : "text-lGray"} text-sm font-normal px-[8px] py-[4px] hover:text-dGray`}>{item?.text}</h3>
                    </li>
                ))}
            </ul>}

            {suggestionOpen && <ul className="py-[12px] px-[8px] flex flex-col gap-[8px] overflow-y-scroll w-[13rem] max-h-max absolute top-[48px] z-10 bg-white rounded-[10px] shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)]" id="filtersugge">
                {Array.isArray(suggestionList) && suggestionList.map((item: any, index: number) => (
                    <li className={`${(selectedOption === index) && "bg-[#F4F4F4] rounded-[4px]"} cursor-pointer hover:bg-[#F4F4F4]`} key={index} onClick={() => handleSelect(item)}>
                        {!!item?.suggestion && <h3 className={`${(selectedOption === index) ? "text-dGray" : "text-lGray"} text-sm font-normal px-[8px] py-[4px] hover:text-dGray`}>{item?.suggestion}</h3>}

                        {!!!item?.suggestion && <h3 className={`${(selectedOption === index) ? "text-dGray" : "text-lGray"} text-sm font-normal px-[8px] py-[4px] hover:text-dGray`}>{item?.text}</h3>}
                    </li>
                ))}
            </ul>}


            {filterOpen && <div className="p-[16px] flex flex-col gap-[24px] overflow-y-scroll w-[13rem] max-h-[20rem] absolute top-[48px] z-10 bg-white rounded-[10px] shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)]" id="filterform">
                <div className="flex items-center justify-between">
                    <h3 className="text-primary text-base font-medium">{t("filter_menu.filter")}</h3>
                    <div className="cursor-pointer hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full !flex !items-center bg-[#f4f8ff] !rounded-full !justify-center !w-6 !h-6" onClick={() => setFilterOpen(false)}>
                        <DangerClose color='#133F97' />
                    </div>
                </div>
                <form className="flex flex-col gap-[24px]">
                    {(selectOption?.type !== "enum" && selectOption?.type !== "date") && <div className="relative w-full">
                        <input
                            type={selectOption?.type}
                            id="filterInput"
                            className="relative bg-transparent block py-[9px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-mGray font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] focus:shadow-[0_0_0_0.2rem_#EAF9FF] hover:border-[#8D9FFF] focus:border-[#8D9FFF] disabled:border-[#CBCBCB]"
                            placeholder=" "
                            name={selectOption?.name}
                            onChange={(e: any) => setFilterValue(e.target.value)}
                            value={filterValue}
                        />
                        <label
                            htmlFor={selectOption?.name}
                            className="absolute whitespace-nowrap font-normal !text-xs xl:!text-[0.813rem] text-dGray duration-300 transform -translate-y-4 scale-[0.85] top-[4px] z-[5] origin-[0] bg-white px-[8px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text"
                        >
                            {selectOption?.text}
                        </label>
                    </div>}

                    {selectOption?.type === "date" &&
                        <div className="flex flex-col gap-[2px]">
                            <h3 className="!text-[0.813rem] !text-mGray font-normal">{selectOption?.text}</h3>
                            <div className="w-full border border-[#CBCBCB] rounded-[6px] flex items-center justify-between">
                                <Calendar id="filterInput" className="!w-full date date-filter" value={filterValue} onChange={(e: any) => setFilterValue(e.value)} selectionMode="range" dateFormat="dd/mm/yy" autoZIndex={true} />
                                <div className="px-[4px]">
                                    <DateIcon width="25" height="25" />
                                </div>
                            </div>
                        </div>
                    }

                    {selectOption?.type === "enum" &&
                        <div className="w-full flex flex-col gap-[8px]">
                            {Array.isArray(selectOption?.option) && selectOption?.option.map((item: any, index: number) => (
                                <div className="flex items-center justify-start gap-[8px] cursor-pointer" key={index} onClick={() => setFilterValue(item?.name)}>
                                    <Checkbox className='bill filter-check' checked={filterValue === item?.name ? true : false} tabIndex={Object.keys(filterObj).length > 0 ? (Object.keys(filterObj).length + (index + 12)) : ((index + 1) + 11)} onKeyDown={(e: any) => handleStatus(e, item)}></Checkbox>
                                    <h3 className="text-[#494949] text-[0.813rem] font-medium whitespace-nowrap">{item?.name}</h3>
                                </div>
                            ))}
                        </div>
                    }
                    <div className="w-full flex items-end justify-end">
                        <button className="bg-[#EEF4FC] rounded-[10px] px-[20px] h-[35px] text-primary text-sm font-medium cursor-pointer disabled:cursor-not-allowed disabled:text-[#b1b1b1] focus:border focus:border-primary" disabled={!!filterValue ? false : true} onClick={handleFilter} type="submit">{t("common_btn.submit")}</button>
                    </div>
                </form>
            </div>}


            <div className="flex items-end justify-end">
                {Object.keys(filterObj).length > 0 && <div id="filterClose" className="cursor-pointer w-[18px] h-[18px] border border-[#cdcdcd] rounded-full flex items-center justify-center hover:!shadow-[0_0_0_0.2rem_#b9ccf7] focus:!shadow-[0_0_0_0.2rem_#b9ccf7] hover:!rounded-full focus:!rounded-full" onClick={() => {
                    setFilterObj({});
                    setList(filterArr);
                }} tabIndex={Object.keys(filterObj).length > 0 && (Object.keys(filterObj).length + 11 as any)} onKeyDown={(e: any) => handleAllDelete(e)} onFocus={() => { setFilterOpen(false); setSuggestionOpen(false); setOpenFilter(false); }}>
                    <ChipClose />
                </div>}
            </div>
        </div>
    );
};

export default AddFilter;