import { useSelector } from "react-redux";
import { CustomerClose } from "../../svg/AllSvg";
import { useDispatch } from "react-redux";
import { labSampleActions } from "../../../shared/store/labSample-Slice";
import { useEffect, useState } from "react";
import useAxios from "../../../shared/hooks/use-axios";
import SampleList from "../../customer/testRequestDetail/SampleList";
import SampleInfo from "../../customer/testRequestDetail/SampleInfo";
import ParameterList from "../../customer/testRequestDetail/ParameterList";
import { useTranslation } from "react-i18next";

const AllSamplePopup = () => {
    const [tab, setTab] = useState<any>("Sample Information")
    const [selectStandard, setSelectStandard] = useState<any>(null);
    const allSampleOpen: boolean = useSelector((state: any) => state.labSample.allSampleOpen);
    const sampleDetail = useSelector((state: any) => state.labSample.sampleDetail);
    const [standard, setStandard] = useState<any[]>([]);
    let [loader, setLoader] = useState<boolean>(true)
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const getStandard = async () => {
        try {
            const res: any = await useAxios.get(`/samples/sample-count/?test_request=${sampleDetail?.test_request}`);

            if (res && res?.status === 200) {
                setStandard(res?.data?.results?.length > 0 && res?.data?.results?.map((item: any) => ({
                    label: item?.title,
                    value: item?.id,
                    not_real_std: item?.not_real_std
                })))
            }
        } catch (error) {
            console.error("error while feching service parameters", error);
        }
    }

    useEffect(() => {
        !!sampleDetail?.test_request && getStandard();
        // eslint-disable-next-line
    }, [sampleDetail])

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(labSampleActions.setAllSampleOpen(false))
        }
    };

    return (
        <>
            {(allSampleOpen) &&
                <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                    <div className="flex justify-center items-center">
                        <div className="relative my-[24px] md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] h-auto md:w-[750px] lg:w-[1000px] xl:w-[1060px] sm:w-[600px]">
                            <div className="flex items-start justify-between pl-[24px] pr-[15px] pt-[15px] rounded-t-[21px] bg-white">
                                <h3 className="text-primaryLight font-normal text-lg">{t("lab_sample.title")}</h3>
                                <div className="cursor-pointer" onClick={() => dispatch(labSampleActions.setAllSampleOpen(false))}>
                                    <CustomerClose />
                                </div>
                            </div>

                            <div className="p-[24px] w-full">
                                <div className="rounded-[10px] border border-[#EEE] bg-white w-full flex flex-col ms:flex-row h-full">
                                    <SampleList page="test-detail-info" standard={standard} sample={sampleDetail} setSelectStandard=
                                        {setSelectStandard} selectStandard={selectStandard} setLoader={setLoader} loader={loader} />

                                    <div className="w-full ms:w-[calc(100%-297px)] h-full pb-[24px]">
                                        <div className='border-b border-[#CBD5E1] overflow-x-auto'>
                                            <div className='mx-[8px] flex items-center'>
                                                <div className={`${tab === "Sample Information" ? "border-b-[3px] border-primaryLight" : "border-b-[3px] border-white"} cursor-pointer mx-[8px]`} onClick={() => setTab("Sample Information")}>
                                                    <div className='flex items-center gap-[4px] px-[12px] py-[10px]'>
                                                        <h3 className={`${tab === "Sample Information" ? "text-primaryLight" : "text-lGray"} text-sm font-medium whitespace-nowrap`}>{t("customer.billing_info.test_req_detail.sample.title")}</h3>
                                                    </div>
                                                </div>
                                                <div className={`${tab === "Parameters" ? "border-b-[3px] border-primaryLight" : "border-b-[3px] border-white"} cursor-pointer mx-[8px]`} onClick={() => setTab("Parameters")}>
                                                    <div className='flex items-center gap-[4px] px-[12px] py-[10px]'>
                                                        <h3 className={`${tab === "Parameters" ? "text-primaryLight" : "text-lGray"} text-sm font-medium whitespace-nowrap`}>{t("customer.book_test.standard_detail.parameter")}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {tab === "Sample Information" && <SampleInfo page="test-detail-info" loader={loader} />}

                                        {tab === "Parameters" &&
                                            <ParameterList selectStandard={selectStandard} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AllSamplePopup;