import { Skeleton } from "primereact/skeleton";

const ListViewLoader = ({ height }: any) => {
    const data: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <div className={`flex flex-col justify-between ${!!height ? "min-h-[calc(100vh-203px)]" : "min-h-[calc(100vh-265px)]"} rounded-[5px] border border-[#E4E4E4] px-[10px] pt-[5px] pb-[10px]`}>
            <div className="overflow-x-scroll overflow-y-hidden">
                <table className="table-auto w-full list-view">
                    <thead>
                        <tr className="h-[30px]">
                            <th className="w-[58px] min-w-[58px] h-full rounded-l-[5px] bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset,1px_0px_0px_0px_#F1F1F1_inset]">
                                <div className='flex items-center justify-center w-full'>
                                    <div className="min-w-[18px]">
                                        <Skeleton className="!w-[18px] !h-[18px] !rounded-[3px]"></Skeleton>
                                    </div>
                                </div>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] shadow-[0px_1px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]">
                                <Skeleton height=".5rem" width='6rem'></Skeleton>
                            </th>
                            <th className="px-[8px] 2xl:px-[16px] h-full bg-[#F9F9F9] rounded-r-[5px] shadow-[0px_1px_0px_0px_#F1F1F1_inset,-1px_0px_0px_0px_#F1F1F1_inset,0px_-1px_0px_0px_#F1F1F1_inset]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(data) && data.map((item: any, index: number) => (
                            <tr className="w-full shadow-[0px_-1px_0px_0px_#efefef_inset] bg-[#F1F1F11a] h-[35px] min-h-[35px]" key={index}>
                                <td className="w-[58px] min-w-[58px] py-[5px]">
                                    <div className='flex items-center justify-center w-full'>
                                        <div className="min-w-[18px]">
                                            <Skeleton className="!w-[18px] !h-[18px] !rounded-[3px]"></Skeleton>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[5px]">
                                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                                </td>
                                <td className="px-[8px] 2xl:px-[16px] py-[5px]">
                                    <div className="flex items-center justify-center gap-[12px]">
                                        <Skeleton className="!w-[25px] !h-[25px] !rounded-[5px]"></Skeleton>
                                        <Skeleton className="!w-[25px] !h-[25px] !rounded-[5px]"></Skeleton>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ListViewLoader;