/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const Dashboard = React.lazy(() => import("../../pages/admin/dashboard/Dashboard"));
const BIReport = React.lazy(() => import("../../pages/admin/biReport/BIReport"));
const Customer = React.lazy(() => import("../../pages/invoice/customer/dashboard/Customer"));
const CustomerDetail = React.lazy(() => import("../../pages/invoice/customer/customerDetail/CustomerDetail"));
const Invoice = React.lazy(() => import("../../pages/invoice/invoice/dashboard/Invoice"));
const InvoiceDetail = React.lazy(() => import("../../pages/invoice/invoice/invoiceDetail/InvoiceDetail"));
const InvoiceTask = React.lazy(() => import("../../pages/invoice/invoice/invoiceTask/InvoiceTask"));
const Payment = React.lazy(() => import("../../pages/invoice/payment/dashboard/Payment"));
const NewInvoice = React.lazy(() => import("../../pages/invoice/invoice/newInvoice/NewInvoice"));
const ToTaxInvoice = React.lazy(() => import("../../pages/invoice/invoice/toTaxInvoice/ToTaxInvoice"));
const Premium = React.lazy(() => import("../../pages/premium/Premium"));
const CreditNote = React.lazy(() => import("../../pages/invoice/creditNote/dashboard/CreditNote"));
const AdditionalQty = React.lazy(() => import("../../pages/invoice/additionalQty/dashboard/AdditionalQty"));
const CreditPolicy = React.lazy(() => import("../../pages/invoice/creditPolicy/dashboard/CreditPolicy"));
const Order = React.lazy(() => import("../../pages/invoice/order/dashboard/Order"));

const InvoiveRoutes: React.FC<any> = ({ role }) => {
    const permissionRole: any = useSelector((state: any) => state.auth.permissionRole);
    const [roleName, setRoleName] = useState<string>("");
    const [existRole, setExistRole] = useState<any>(null);

    useEffect(() => {
        if (permissionRole?.length > 0) {
            let getRole: any = role?.filter((item: any) => permissionRole?.toString()?.includes(item))
            setRoleName(getRole[0])
            getRole?.length > 0 ? setExistRole(true) : setExistRole(false)
        }
    }, [permissionRole])

    return (
        <>
            {existRole != null && <>
                {(existRole) ? <>
                    {(permissionRole?.includes(roleName)) &&
                        <>
                            {roleName === "Lab Head" &&
                                <Routes>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/bi-report" element={<BIReport />} />
                                    <Route path="/credit-note" element={<CreditNote />} />
                                    <Route path="/credit-note/:tab" element={<CreditNote />} />
                                    <Route path="/credit-policy" element={<CreditPolicy />} />
                                    <Route path="/credit-policy/:tab" element={<CreditPolicy />} />
                                    <Route path="*" element={<Navigate to="/invoice/dashboard" />} />
                                </Routes>
                            }
                            {roleName === "Account Officer" &&
                                <Routes>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/bi-report" element={<BIReport />} />
                                    <Route path="/proforma-invoice" element={<Invoice url="proforma-invoice" />} />
                                    <Route path="/proforma-invoice/:tab" element={<Invoice url="proforma-invoice" />} />
                                    <Route path="/proforma-invoice/:tab/:id" element={<InvoiceDetail url="proforma-invoice" />} />
                                    <Route path="/proforma-invoice/task/:tab/:id" element={<InvoiceTask url="proforma-invoice" />} />
                                    <Route path="/tax-invoice" element={<Invoice url="tax-invoice" />} />
                                    <Route path="/tax-invoice/:tab" element={<Invoice url="tax-invoice" />} />
                                    <Route path="/tax-invoice/:tab/:id" element={<InvoiceDetail url="tax-invoice" />} />
                                    <Route path="/tax-invoice/task/:tab/:id" element={<InvoiceTask url="tax-invoice" />} />
                                    <Route path="/bill-of-supply" element={<Invoice url="bill-of-supply" />} />
                                    <Route path="/bill-of-supply/:tab" element={<Invoice url="bill-of-supply" />} />
                                    <Route path="/bill-of-supply/:tab/:id" element={<InvoiceDetail url="bill-of-supply" />} />
                                    <Route path="/bill-of-supply/task/:tab/:id" element={<InvoiceTask url="bill-of-supply" />} />
                                    <Route path="/customer" element={<Customer />} />
                                    <Route path="/customer/:tab" element={<Customer />} />
                                    <Route path="/customer/:tab/:id" element={<CustomerDetail />} />
                                    <Route path="/credit-note" element={<CreditNote />} />
                                    <Route path="/credit-note/:tab" element={<CreditNote />} />
                                    <Route path="/order" element={<Order />} />
                                    <Route path="/order/:tab" element={<Order />} />
                                    <Route path="/payment" element={<Payment />} />
                                    <Route path="/new-tax-invoice" element={<NewInvoice url="tax-invoice" />} />
                                    <Route path="/new-bill-of-supply-invoice" element={<NewInvoice url="bill-of-supply" />} />
                                    <Route path="/convert-to-tax-invoice/:id" element={<ToTaxInvoice url="proforma-invoice" />} />
                                    <Route path="/new-proforma-invoice" element={<NewInvoice url="proforma-invoice" />} />
                                    <Route path="/additional-qty" element={<AdditionalQty />} />
                                    <Route path="*" element={<Navigate to="/invoice/dashboard" />} />
                                </Routes>
                            }
                        </>
                    }
                </> :
                    <Routes>
                        <Route path="/premium" element={<Premium />} />
                        <Route path="*" element={<Navigate to="/invoice/premium" />} />
                    </Routes>
                }
            </>}
        </>
    );
};

export default InvoiveRoutes;