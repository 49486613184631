import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Register = React.lazy(() => import("../../pages/customer/auth/Register"));
const RegisterOtp = React.lazy(() => import("../../pages/customer/auth/RegisterOtp"));
const Login = React.lazy(() => import("../../pages/customer/auth/Login"));
const LoginOtp = React.lazy(() => import("../../pages/customer/auth/LoginOtp"));
const ForgotPassword = React.lazy(() => import("../../pages/customer/auth/ForgotPassword"));
const ForgotPasswordOtp = React.lazy(() => import("../../pages/customer/auth/ForgotPasswordOtp"));
const ResetPassword = React.lazy(() => import("../../pages/customer/auth/ResetPassword"));

const CustomerAuth: React.FC<any> = () => {
    return (
        <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/register-otp" element={<RegisterOtp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login-otp" element={<LoginOtp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password-otp" element={<ForgotPasswordOtp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/customer/auth/login" />} />
        </Routes>
    );
};

export default CustomerAuth;