/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { AddRolePlus, CheckDelete, CheckRight, CloseBtn, DrageDrop, LoadingBtn, RoleDelete, RolePencil } from "../../svg/AllSvg";
import { useSelector } from "react-redux";
import { employeeActions } from "../../../shared/store/employee-Slice";
import Select from "react-select";
import { useEffect, useState } from "react";
import { success, warning } from "../../../shared/hooks/use-toastify";
import useAxios from "../../../shared/hooks/use-axios";
import { useSelectSecondary}  from "../../../shared/hooks/use-selectSecondary";
import PrimaryLightBtn from "../../form/PrimaryLightBtn";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

const ModifyRole = ({ getUserList, getTagList }: any) => {
    const modifyRole: boolean = useSelector((state: any) => state.employee.modifyRole);
    const selectedId: string = useSelector((state: any) => state.employee.selectedId);
    const [initialRoleList, setInitialRoleList] = useState<any[]>([]);
    let [roleList, setRoleList] = useState<any[]>([]);
    const [newRoleOpen, setNewRoleOpen] = useState<boolean>(false);
    const [departmentList, setDepartmentList] = useState<any[]>([]);
    const [edit, setEdit] = useState<any>({});
    let [role, setRole] = useState<any[]>([]);
    const [selectRole, setSelectRole] = useState<any>();
    const [selectDept, setSelectDept] = useState<any>();
    const [submit, setSubmit] = useState<any>(false);
    const { t } = useTranslation('common');

    const dispatch = useDispatch();
    let stylesSec = useSelectSecondary();

    const resetRoleList = () => {
        let arr: any[] = []

        for (let i = 0; i < initialRoleList?.length; i++) {
            const el = initialRoleList[i];

            let exist: any = role.find((item: any) => item?.role?.label === el?.label)
            if (!exist) {
                arr.push(el)
            }
        }

        roleList = arr
        setRoleList(roleList)
    }

    const createEmployee = async () => {
        try {
            let arr: any[] = [];

            for (let i = 0; i < role?.length; i++) {
                const el = role[i];

                let obj: any = {}
                obj.role_id = el?.role?.value
                obj.dep_id = el?.dept?.map((item: any) => item?.value)
                arr.push(obj)
            }
            setSubmit(true)
            let formdata = new FormData();
            formdata.append("role_dept", JSON.stringify(arr));

            const res: any = await useAxios.patch(`labs/create-manpower-basic-details/?uid=${selectedId}`, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (res && res.status === 201) {
                dispatch(employeeActions.setModifyRole(false));
                success("Employee Role & Department Modify successfully")
                getUserList();
                getTagList();
                setSubmit(false)
            }
        } catch (error: any) {
            setSubmit(false)
            if (error && error?.response?.status === 400) {
                warning(error?.response?.data?.error?.toString())
                warning(error?.response?.data?.results?.user?.toString())
            }
            console.error("error while creating employee", error);
        }
    }

    const getRoleList = async () => {
        try {
            const res: any = await useAxios.get("utility/groups");

            if (res && res.status === 200) {
                let arr: any[] = res?.data?.results.map((item: any) => ({
                    value: item?.id,
                    label: item?.text,
                    role_type: item?.role_type
                }))
                roleList = arr
                setInitialRoleList(arr)
                setRoleList(roleList);
            }
        } catch (error: any) {
            console.error("error while fetching role", error);
        }
    }

    const getDepartmentList = async () => {
        try {
            const res: any = await useAxios.get(`utility/departments/?role_type=${selectRole?.role_type}`);

            if (res && res.status === 200) {
                setDepartmentList(res?.data?.results.map((item: any) => ({
                    value: item?.id,
                    label: item?.text
                })));
            }
        } catch (error: any) {
            console.error("error while fetching department", error);
        }
    }

    useEffect(() => {
        !!selectRole && getDepartmentList();
    }, [selectRole])

    useEffect(() => {
        getRoleList();
        // eslint-disable-next-line
    }, []);

    const handleSelect = () => {
        if (Object.keys(edit)?.length > 0) {
            let arr: any[] = [];
            for (let i = 0; i < role?.length; i++) {
                const el = role[i];
                let obj: any = {}
                if (el?.role?.label === edit?.role?.label) {
                    obj.role = selectRole;
                    obj.dept = selectDept;
                    arr.push(obj)
                } else {
                    arr.push(el)
                }
            }
            role = arr;
            setRole(role);
            setEdit({});
            resetRoleList();
        } else {
            let obj: any = {
                role: selectRole,
                dept: selectDept
            }
            role = [...role, obj]
            setRole(role);
            setSelectDept(null);
            setSelectRole(null);
            setNewRoleOpen(false)
            resetRoleList();
        }

    }

    const handleClose = () => {
        setSelectDept(null);
        setSelectRole(null);
        setNewRoleOpen(false)
        setEdit({});
    }

    const handleDelete = (i: any) => {
        let arr = role.filter((item: any) => item?.role?.label !== i?.role?.label)
        role = arr
        setRole(role)
        resetRoleList();
        setSelectRole({});
        setSelectDept({});
    }

    const handleDrop = async (droppedItem: any): Promise<void> => {
        try {
            if (!droppedItem.destination) return;
            let updatedList = [...role];
            const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
            updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
            setRole(updatedList);
        } catch (error: any) { }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(employeeActions.setModifyRole(false))
        }
    };


    return (
        <>
            {modifyRole && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px]  md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[648px]">
                        <div className="flex items-center justify-between px-[28px] pt-[10px] rounded-t-[20px] bg-white h-[70px]">
                            <div className="flex flex-col items-start">
                                <h3 className="text-primaryLight font-semibold text-lg">{t("laboratory.menu.modify_role")}</h3>
                            </div>
                            <div className="cursor-pointer" onClick={() => dispatch(employeeActions.setModifyRole(false))}>
                                <CloseBtn />
                            </div>
                        </div>


                        <div className="pb-[25px] px-[28px]">
                            <div className="mt-[15px] rounded-[10px] bg-[#F9FAFF] w-full p-[9px]">
                                <div className="w-full pl-[33px] pr-[22px] pb-[6px] flex items-center">
                                    <p className="text-sm text-mGray font-normal w-[30%]">{t("laboratory.employee.add_role.list.role")}</p>
                                    <p className="text-sm text-mGray font-normal w-[60%]">{t("laboratory.employee.add_role.list.dept")}</p>
                                    <p className="text-sm text-mGray font-normal text-end w-[10%]">{t("laboratory.employee.add_role.list.actions")}</p>
                                </div>

                                <div className="bg-white w-full h-[350px] p-[9px] rounded-[10px] flex flex-col gap-[10px] overflow-y-scroll">
                                    <DragDropContext onDragEnd={handleDrop}>
                                        <Droppable droppableId="div">
                                            {(provided: any) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef} className="flex flex-col gap-[10px]"
                                                >
                                                    {Array.isArray(role) && role.map((item: any, index: number) => (
                                                        <Draggable key={item?.id} draggableId={index?.toString()} index={index}>
                                                            {(provided: any) => (
                                                                <div ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps} className="w-full" key={index}>
                                                                    {edit !== item ? <div className="rounded-[10px] bg-[#fcfcfc] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)] py-[12px] w-full px-[10px] flex items-center relative">
                                                                        <div className="pr-[8px]">
                                                                            <DrageDrop color="#6B7280" />
                                                                        </div>
                                                                        <h3 className="text-primaryLight font-medium text-sm w-[30%] line-clamp-1">{item?.role?.label}</h3>
                                                                        {item?.dept?.length > 0 ? <div className="w-[60%] flex items-center gap-[5px]">
                                                                            {Array.isArray(item?.dept) && item?.dept.map((i: any, idx: number) => (
                                                                                <div key={idx} className={`${idx < 2 ? "block" : "hidden"}`}>
                                                                                    {idx < 2 && <button className={`${idx === 0 ? "bg-[#ECF6FF]" : "bg-[#FFF9D4]"} text-primaryLight flex items-center justify-center text-sm font-medium rounded-[20px] h-[25px] px-[15px] whitespace-nowrap`}>{i?.label}</button>}
                                                                                </div>
                                                                            ))}
                                                                            {item?.dept?.length > 2 && <button className="flex items-center justify-center text-sm font-medium rounded-[20px] h-[25px] px-[15px] whitespace-normal text-primaryLight bg-[#DFE]">+{item?.dept?.length - 2}</button>}
                                                                        </div> :
                                                                            <div className="w-[60%] text-sm text-[#C3C3C3] font-medium">{t("group_by_menu.none")}</div>
                                                                        }
                                                                        <div className="w-[10%] flex items-center gap-[5px]">
                                                                            <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={() => { setEdit(item); setSelectDept(item?.dept); setSelectRole(item?.role); setNewRoleOpen(false); }}>
                                                                                <RolePencil />
                                                                            </div>

                                                                            <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={() => handleDelete(item)}>
                                                                                <RoleDelete />
                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                        <div className="flex items-center justify-between w-full pt-[10px] gap-[12px]">
                                                                            <div className="w-[90%] flex items-center gap-[12px]">
                                                                                <div className="w-[35%]">
                                                                                    <Select options={roleList} placeholder={t("laboratory.employee.add_role.select_role")} name="role" styles={stylesSec} className="!w-full" onChange={(e: any) => { setSelectRole(e); setSelectDept(null); }} value={selectRole} menuPlacement="auto" maxMenuHeight={200} />
                                                                                </div>
                                                                                {selectRole && <div className="w-[65%]">
                                                                                    <Select options={departmentList} isMulti placeholder={t("laboratory.employee.add_role.select_dept")} name="department" styles={stylesSec} className="!w-full" onChange={(e: any) => setSelectDept(e)} value={selectDept} menuPlacement="auto" maxMenuHeight={200} />
                                                                                </div>}
                                                                            </div>

                                                                            {(selectRole?.role_type === "technical" ? (!!selectDept && !!selectRole) : (!!selectRole)) && <div className="w-[10%] flex items-center gap-[5px]">
                                                                                <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleSelect}>
                                                                                    <CheckRight className="fill-primaryLight" />
                                                                                </div>

                                                                                <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleClose}>
                                                                                    <CheckDelete className="fill-[#FF0000]" />
                                                                                </div>
                                                                            </div>}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                    {(newRoleOpen) && <div className="flex items-center justify-between w-full pt-[10px] gap-[12px]">
                                        <div className="w-[90%] flex items-center gap-[12px]">
                                            <div className="w-[35%]">
                                                <Select options={roleList} placeholder={t("laboratory.employee.add_role.select_role")} name="role" styles={stylesSec} className="!w-full" onChange={(e: any) => {setSelectRole(e); setSelectDept(null);}} value={selectRole} menuPlacement="auto" maxMenuHeight={200} />
                                            </div>
                                            {selectRole && <div className="w-[65%]">
                                                <Select options={departmentList} isMulti placeholder={t("laboratory.employee.add_role.select_dept")} name="department" styles={stylesSec} className="!w-full" onChange={(e: any) => setSelectDept(e)} value={selectDept} menuPlacement="auto" maxMenuHeight={200} />
                                            </div>}
                                        </div>

                                        {(selectRole?.role_type === "technical" ? (!!selectDept && !!selectRole) : (!!selectRole)) && <div className="w-[10%] flex items-center gap-[5px]">
                                            <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleSelect}>
                                                <CheckRight className="fill-primaryLight" />
                                            </div>

                                            <div className="w-[25px] h-[25px] flex items-center justify-center rounded-[6px] bg-white shadow-[0px_1px_2px_0px_rgba(39,43,139,0.15)] cursor-pointer" onClick={handleClose}>
                                                <CheckDelete className="fill-[#FF0000]" />
                                            </div>
                                        </div>}
                                    </div>}

                                    <div className="bg-[#FAFAFA] shadow-[0px_4px_4px_-2px_rgba(24,39,75,0.08),0px_2px_4px_-2px_rgba(24,39,75,0.12)] px-[20px] flex items-center justify-center h-[46px] cursor-pointer gap-[8px] rounded-[10px]" onClick={() => { setNewRoleOpen(true); setSelectRole(null); setSelectDept(null); setEdit({}); }}>
                                        <h3 className="text-primaryLight text-xs font-semibold">{t("laboratory.employee.add_role.add_new_role_btn")}</h3>
                                        <AddRolePlus />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-end h-[70px] px-[28px] rounded-b-[20px]">
                            <PrimaryLightBtn type="button" onClick={createEmployee} title={<>{submit && <LoadingBtn className="w-5 h-5 text-primaryLight animate-spin" />}{submit ? t("common_btn.save") : t("common_btn.finish")}</>} disabled={(!submit && role?.length > 0) ? false : true} />
                        </div>
                    </div>
                </div>
            </div >
            }
        </>
    );
};

export default ModifyRole;