const SeconadaryTextarea: React.FC<any> = ({ name, title, onChange, value, rows, defaultValue }: any) => {
    return (
        <div className="relative w-full">
            <textarea
                id={name}
                className="bg-transparent block py-[10px] px-[12px] w-full !text-xs xl:!text-[0.813rem] !text-[#222] font-normal appearance-none peer border border-[#CBCBCB] rounded-[6px] resize-none"
                placeholder=" "
                name={name}
                onChange={onChange}
                value={value}
                rows={!!rows ? rows : 4}
                defaultValue={defaultValue}
            />
            <label
                htmlFor={name}
                className="absolute whitespace-nowrap font-normal !text-xs xl:!text-[0.813rem] text-[#5A5A5A] duration-300 transform -translate-y-4 scale-[0.85] top-[4px] origin-[0] bg-white px-[4px] peer-focus:px-[8px] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[20%] peer-focus:top-[4px] peer-focus:scale-[0.85] peer-focus:-translate-y-[16px] pointer-events-none left-[8px] !cursor-text"
            >
                {title}
            </label>
        </div>
    );
};

export default SeconadaryTextarea;