import { useDispatch } from "react-redux";
import { AdjustPaymentIcon, LoadingBtn } from "../../../svg/AllSvg";
import { useSelector } from "react-redux";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import useAxios from "../../../../shared/hooks/use-axios";
import PrimaryBtn from "../../../form/PrimaryBtn";
import PrimaryLightBtn from "../../../form/PrimaryLightBtn";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdjustPayment = () => {
    const [submit, setSubmit] = useState<boolean>(false);
    const adjustPayment: boolean = useSelector((state: any) => state.invoice.adjustPayment);
    const adjustAmount: any = useSelector((state: any) => state.invoice.adjustAmount);
    const extraInvoice: any = useSelector((state: any) => state.invoice.extraInvoice);
    const cust: any = useSelector((state: any) => state.invoice.cust);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setSubmit(true)
        try {
            let payload: any = {
                customer: cust?.customer,
                amount: +adjustAmount
            }
            const res: any = await useAxios.post("wallet/add-to-wallet/", payload);

            if (res && res.status === 200) {
                dispatch(invoiceActions.setAdjustPayment(false))
                dispatch(invoiceActions.setAdjustAmount(0))
                dispatch(invoiceActions.setPaymentSuccess(true))
                setSubmit(false)
                navigate("/invoice/tax-invoice");
            }
        } catch (error: any) {
            setSubmit(false)
            console.error("error while adjust payment in wallet", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(invoiceActions.setAdjustPayment(false))
        }
    };

    return (
        <>
            {adjustPayment &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[#8888881a] backdrop-blur-[2.5px]"
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[10px] flex flex-col w-[320px] sm:w-[600px] md:w-[580px] h-auto">
                            <div className="w-full h-full flex flex-col items-center justify-start p-[30px]">
                                <AdjustPaymentIcon />

                                <h3 className="text-primaryLight text-lg font-semibold">Adjust additional ₹{adjustAmount}.</h3>
                                <p className="text-mGray font-normal text-base max-w-[455px] text-center">You can either adjust it in other invoice or send it to wallet.</p>

                                <div className="flex items-center justify-center w-full gap-[10px] mt-[20px]">
                                    <PrimaryLightBtn onClick={handleSubmit} title="Send to Wallet" />
                                    <PrimaryBtn disabled={(extraInvoice && !submit) ? false : true} onClick={() => { dispatch(invoiceActions.setAdjustPayment(false)); dispatch(invoiceActions.setAdjustPaymentInvoice(true)); }} title={!submit ? "Adjust In Invoice" :
                                        <>
                                            <LoadingBtn className="w-5 h-5 text-white animate-spin" />
                                            Adjust In Invoice...
                                        </>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AdjustPayment;