import { Skeleton } from 'primereact/skeleton'

const StandardDetail = () => {
    return (
        <div className="w-full p-[30px] flex items-center justify-center bg-white border border-[#EFEFEF] rounded-[10px] shadow-[0px_8px_24px_-4px_rgba(24,39,75,0.08),0px_6px_12px_-6px_rgba(24,39,75,0.12)]">
            <div className="w-full flex flex-col gap-[15px]">
                <div className="flex items-start flex-wrap gap-[25px] lm:gap-[50px]">
                    <div className="flex flex-col gap-[4px]">
                        <Skeleton height=".5rem" width='4rem'></Skeleton>
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </div>

                    <div className="flex flex-col gap-[4px]">
                        <Skeleton height=".5rem" width='4rem'></Skeleton>
                        <Skeleton height=".5rem" width='6rem'></Skeleton>
                    </div>

                    <div className="flex flex-col gap-[4px]">
                        <Skeleton height=".5rem" width='4rem'></Skeleton>
                        <Skeleton className='!w-[90px] !h-[25px]' borderRadius='100px'></Skeleton>
                    </div>

                    <div className="flex flex-col gap-[4px]">
                        <Skeleton height=".5rem" width='4rem'></Skeleton>
                        <Skeleton className='!w-[90px] !h-[25px]' borderRadius='100px'></Skeleton>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-[4px]">
                    <Skeleton height=".5rem" width='4rem'></Skeleton>
                    <Skeleton height=".5rem" width='8rem'></Skeleton>
                </div>
            </div>
        </div>
    )
}

export default StandardDetail