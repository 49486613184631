import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth-Slice";

export const useUserDetail = (): any => {
    const dispatch = useDispatch();

    useEffect(() => {
        // user role setup
        let str_userDetail = localStorage.getItem('userdetail')
        if (str_userDetail) {
            let userDetail = JSON.parse(str_userDetail);
            dispatch(authActions.userDetail({ username: userDetail?.first_name, lastName: userDetail?.last_name, role: userDetail?.role, department: userDetail?.active_department, departments: userDetail?.role_department_list, lab: userDetail?.lab, manpower_id: userDetail?.manpower_id, email: userDetail?.username, tenant_name: userDetail?.tenant_name
            }));
        }
        // eslint-disable-next-line
    }, []);
}