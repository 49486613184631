import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    title: string,
    select: string,
    refreshState: boolean,
    isOpenTestMethod: boolean,
    testApiCall: boolean,
    isOpenCertificate: boolean,
    certificateApiCall: boolean,
    isOpenRange: boolean,
    rangeApiCall: boolean,
    isOpenDept: boolean,
    deptApiCall: boolean,
    productApiCall: boolean,
    isOpenTds: boolean,
    tdsApiCall: boolean,
    isOpenRoleModule: boolean,
    roleModuleApiCall: boolean,
    sectionApiCall: boolean,
    isOpenMasking: boolean,
    maskingApiCall: boolean,
    customField: boolean,
    isOpenAddress: boolean,
    addressApiCall: boolean,
    settingPermission: any
}

const initialState: AuthState = {
    title: "",
    select: "",
    refreshState: false,
    isOpenTestMethod: false,
    testApiCall: false,
    isOpenCertificate: false,
    certificateApiCall: false,
    isOpenRange: false,
    rangeApiCall: false,
    isOpenDept: false,
    deptApiCall: false,
    productApiCall: false,
    isOpenTds: false,
    tdsApiCall: false,
    isOpenRoleModule: false,
    roleModuleApiCall: false,
    sectionApiCall: false,
    isOpenMasking: false,
    maskingApiCall: false,
    customField: true,
    isOpenAddress: false,
    addressApiCall: false,
    settingPermission: null
}

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setSelect: (state, action) => {
            state.select = action.payload;
        },
        setrefreshState: (state, action) => {
            state.refreshState = action.payload;
        },
        setIsOpenTestMethod: (state, action) => {
            state.isOpenTestMethod = action.payload;
        },
        setTestApiCall: (state, action) => {
            state.testApiCall = action.payload;
        },
        setIsOpenCertificate: (state, action) => {
            state.isOpenCertificate = action.payload;
        },
        setIsCertificateApiCall: (state, action) => {
            state.certificateApiCall = action.payload;
        },
        setIsOpenRange: (state, action) => {
            state.isOpenRange = action.payload;
        },
        setRangeApiCall: (state, action) => {
            state.rangeApiCall = action.payload;
        },
        setIsOpenDept: (state, action) => {
            state.isOpenDept = action.payload;
        },
        setDeptApiCall: (state, action) => {
            state.deptApiCall = action.payload;
        },
        setProductApiCall: (state, action) => {
            state.productApiCall = action.payload;
        },
        setIsOpenTds: (state, action) => {
            state.isOpenTds = action.payload;
        },
        setTdsApiCall: (state, action) => {
            state.tdsApiCall = action.payload;
        },
        setIsOpenRoleModule: (state, action) => {
            state.isOpenRoleModule = action.payload;
        },
        setRoleModuleApiCall: (state, action) => {
            state.roleModuleApiCall = action.payload;
        },
        setSectionApiCall: (state, action) => {
            state.sectionApiCall = action.payload;
        },
        setIsOpenMasking: (state, action) => {
            state.isOpenMasking = action.payload;
        },
        setMaskingApiCall: (state, action) => {
            state.maskingApiCall = action.payload;
        },
        setCustomField: (state, action) => {
            state.customField = action.payload;
        },
        setIsOpenAddress: (state, action) => {
            state.isOpenAddress = action.payload;
        },
        setAddressApiCall: (state, action) => {
            state.addressApiCall = action.payload;
        },
        setSettingPermission: (state, action) => {
            state.settingPermission = action.payload;
        }
    }
});

export default settingSlice;
export const settingActions = settingSlice.actions;