import { useSelectPrimary}  from "../../../shared/hooks/use-selectPrimary";
import Select from "react-select";
import { FilterTest, OptionDot, TestAlert } from "../../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { testActions } from "../../../shared/store/test-Slice";
import { useTranslation } from "react-i18next";

const SampleList = ({ page, standard, sampleApiCall, setSampleApiCall, sample, selectStandard, setSelectStandard, loader, setLoader }: any) => {
    const sampleDetail: any = useSelector((state: any) => state.test.sampleDetail);
    const [standardList, setStandardList] = useState<any[]>([]);
    const [sampleList, setSampleList] = useState<any[]>([]);
    let styles = useSelectPrimary();
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const getSampleList = async () => {
        try {
            let url: any = "";
            if (!!sample) {
                url = await useAxios.get(`samples/api/sample-parts/?standard_id=${selectStandard?.value}&test_request_id=${sample?.test_request}`);
            } else {
                url = await useAxios.get(`samples/api/sample-parts/?standard_id=${selectStandard?.value}&test_request_id=${params?.id}`);
            }
            const res: any = url;

            if (res && res?.status === 200) {
                setSampleList(res?.data)
                dispatch(testActions.setSampleDetail(res?.data[0]))
                !!setSampleApiCall && setSampleApiCall(false)
                loader = false
                setLoader(loader)
            }
        } catch (error: any) {
            console.error("error while fetching samples", error);
        }
    }

    useEffect(() => {
        sampleApiCall && getSampleList();
        // eslint-disable-next-line
    }, [sampleApiCall])

    useEffect(() => {
        !!selectStandard && getSampleList();
        // eslint-disable-next-line
    }, [selectStandard])

    useEffect(() => {
        if (standard?.length > 0) {
            setStandardList(standard)
            setSelectStandard(standard[0]);
        }
        // eslint-disable-next-line
    }, [standard])

    const handleChange = (item: any) => {
        loader = true
        setLoader(loader)
        dispatch(testActions.setSampleDetail(item))
        setTimeout(() => {
            loader = false
            setLoader(loader)
        }, 500)
    }

    return (
        <div className="w-full ms:w-[297px] h-auto ms:border-r ms:border-b-0 border-b border-[#EDEDED] px-[17px] py-[11px] overflow-y-auto">
            <h2 className="text-lGray font-semibold text-xs pb-[13px]">{t("customer.book_test.test_selected")}</h2>

            <Select
                options={standardList}
                name="complete"
                styles={styles}
                placeholder="No Standard Selected"
                className="w-full"
                value={selectStandard}
                onChange={(e: any) => setSelectStandard(e)}
            />

            <div className="flex items-center justify-between pb-[18px] pt-[15px]">
                <h2 className="text-lGray font-semibold text-xs">{t("customer.billing_info.test_req_detail.samples")}</h2>
                <div className="mr-[-2px] cursor-pointer">
                    <FilterTest />
                </div>
            </div>

            <div className="w-full flex flex-col gap-[10px]">
                {Array.isArray(sampleList) && sampleList.map((item: any, index: number) => (
                    <div key={index} className={`${sampleDetail?.id === item?.id ? "bg-[#FAFAFF] border border-[#8D9FFF]" : "border border-[#F8F8F8] bg-white"} rounded-[10px] h-[40px] flex items-center justify-between pl-[13px] pr-[8px] hover:bg-[#FAFAFF] hover:border hover:border-[#F8F8F8] cursor-pointer`} onClick={() => handleChange(item)}>
                        <h3 className={`${sampleDetail?.id === item?.id ? "text-primaryLight" : "text-dGray"} font-normal text-sm`}>{item?.test_name}</h3>
                        <div className="flex items-center gap-[9px]">
                            {!!page ? "" : <>{item?.sample_modified && <TestAlert />}</>}
                            {page === "test-detail-info" && <>{item?.sample_modified && <TestAlert />}</>}
                            <div className="rounded-[5px] !w-[25px] !h-[25px] border border-[#CBD5E1] flex items-center justify-center cursor-pointer">
                                <OptionDot color="#4B5563" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SampleList;