/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { invoiceActions } from "../../../../shared/store/invoice-Slice";
import { useSelector } from "react-redux";
import { ClosePopup, DownloadIcon, EyeIcon, FileUploadIcon, GroupClose, LoadingBtn, NameOrgIcon, PaymentArrow, PriceWallet, RemoveMore } from "../../../svg/AllSvg";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import useAxios from "../../../../shared/hooks/use-axios";
import Moment from 'moment';
import InvoiceList from "../../../../skeleton/components/invoice/invoice/InvoiceList";
import PrimaryInput from "../../../form/PrimaryInput";
import PrimaryTextarea from "../../../form/PrimaryTextarea";

const MultiRecordPayment = () => {
  const [submit, setSubmit] = useState<boolean>(false);
  const recordPaymentOpen: boolean = useSelector((state: any) => state.invoice.recordPaymentOpen);
  const cust: any = useSelector((state: any) => state.invoice.cust);
  const dispatch = useDispatch();
  const [refNo, setRefNo] = useState<any>("")
  const [paymentDate, setPaymentDate] = useState<any>(new Date().toLocaleDateString('en-CA'))
  const [amount, setAmount] = useState<any>("")
  const [note, setNote] = useState<any>("")
  const [files, setFiles] = useState<any>([]);
  const [file, setFile] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [disable, setDisable] = useState<boolean>(false);
  const [online, setOnline] = useState<boolean>(true);
  const [wallet, setWallet] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<any>(0);
  const [totalDueAmount, setTotalDueAmount] = useState<number>(0);
  const [list, setList] = useState<any[]>([])
  const [selectedInvoice, setSelectedInvoice] = useState<any[]>([])
  const [invoices, setInvoices] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate();

  const simulateChunkUpload = (chunk: any) => {
    console.log(chunk, "1MB")
    return new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      if (files?.length === 1 || acceptedFiles?.length > 1) {
        return;
      } else {
        setUploadProgress(0);
        setDisable(true)
        setFiles([...files, { file: acceptedFiles, url: URL.createObjectURL(acceptedFiles[0]) }]);
        setFile([...file, acceptedFiles[0]])

        let uploadFile = acceptedFiles[0];
        if (uploadFile) {
          const chunkSize = 1024 * 1024; // 1MB chunk size
          const totalChunks = Math.ceil(uploadFile.size / chunkSize);
          let currentChunk = 0;

          const uploadNextChunk = () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, uploadFile.size);
            const chunk = uploadFile.slice(start, end);

            simulateChunkUpload(chunk)
              .then(() => {
                currentChunk++;

                if (currentChunk < totalChunks) {
                  const progress = Math.floor((currentChunk / totalChunks) * 100);
                  setUploadProgress(progress);

                  uploadNextChunk();
                } else {
                  setUploadProgress(100);
                  setDisable(false)
                }
              })
              .catch((error) => {
                console.error('Error uploading chunk', error);
              });
          };

          uploadNextChunk();
        }
      }
    }
  });

  const removeFile = (idx: number) => {
    let arr: any[] = [];
    for (let i = 0; i < files?.length; i++) {
      const el = files[i];

      if (idx !== i) {
        arr.push(el)
      }
    }
    setFiles(arr);
  };

  const thumbs = files.map((file: any, idx: number) => (
    <div key={idx} className="border border-[#D0D0D0] rounded-[10px] h-[40px] flex flex-col items-center w-full relative bg-white shadow-[0px_8px_8px_-4px_rgba(24,39,75,0.08),0px_4px_6px_-4px_rgba(24,39,75,0.12)]">
      <div className='w-full flex items-center justify-between h-full px-[19px]'>
        <h3 className="text-dGray text-sm font-normal truncate">{file?.file[0]?.name}</h3>

        {(uploadProgress !== 100) ?
          <>
            {(idx + 1 === files?.length) && <div className="flex items-center gap-[10px]">
              <h1 className="text-primaryLight text-sm font-normal">Uploading {uploadProgress}%</h1>
              <div className="cursor-pointer" onClick={() => removeFile(idx)}>
                <GroupClose />
              </div>
            </div>}
          </> :
          <div className="flex items-center gap-[10px]">
            <Link to={file?.url} download={file?.url} target="_blank" className="cursor-pointer">
              <DownloadIcon />
            </Link>
            <div className="cursor-pointer" onClick={() => removeFile(idx)}>
              <RemoveMore />
            </div>
          </div>
        }
      </div>
    </div>
  ));

  const getInvoice = async () => {
    try {
      setIsLoading(true)
      const res: any = await useAxios.get(`invoices/merge-invoice-list/?customer_id=${cust?.customer}`);

      if (res && res?.status === 200) {
        setList(res?.data?.results)
        setIsLoading(false)
        let amount: any = 0;
        for (let i = 0; i < res?.data?.results?.length; i++) {
          const el = res?.data?.results[i];

          amount += amount + +el?.amount_left
        }

        setTotalAmount(amount)
      }
    } catch (error) {
      console.error("error while feching invoice", error);
    }
  }

  useEffect(() => {
    !!cust && getInvoice();
  }, [cust])

  // useEffect(() => {
  //   if(wallet) {
  //     let amt: any = 0;
  //     if(amount > +list[0]?.customer_details?.wallet) {
  //       amt = amount - +list[0]?.customer_details?.wallet;
  //       setAmount(amt);
  //     }

  //     if(amount < +list[0]?.customer_details?.wallet) {
  //       setAmount(0);
  //     }
  //   }

  //   if(!wallet) {
  //     setAmount(totalDueAmount)
  //   }
  // }, [wallet, totalDueAmount])

  const handleSubmit = async () => {
    setSubmit(true)
    try {
      let formdata = new FormData();
      formdata.append("customer", cust?.customer);
      formdata.append("invoices", JSON.stringify(invoices));
      // formdata.append("transaction_id", refNo);
      !!paymentDate && formdata.append("payment_date", paymentDate);
      formdata.append("payment_mode", online ? 0 as any : 1 as any);
      file?.length > 0 && formdata.append("file", file[0]);
      formdata.append("remarks", note);
      formdata.append("wallet", wallet as any);
      formdata.append("amount", +amount as any);

      const res: any = await useAxios.post("payment/record-multiple-invoice-payment/", formdata);

      if (res && res.status === 200) {
        setSubmit(false)
        dispatch(invoiceActions.setRecordPaymentOpen(false))
        if (amount > totalDueAmount) {
          if (list?.length === selectedInvoice?.length) {
            dispatch(invoiceActions.setExtraInvoice(false))
          } else {
            dispatch(invoiceActions.setExtraInvoice(true))
          }
          let amt: any = +amount - totalDueAmount
          dispatch(invoiceActions.setAdjustAmount(amt))
          dispatch(invoiceActions.setAdjustPayment(true))
          dispatch(invoiceActions.setRecordPaymentOpen(false))
        }
        else {
          dispatch(invoiceActions.setPaymentSuccess(true))
          dispatch(invoiceActions.setRecordPaymentOpen(false))
          navigate("/invoice/tax-invoice");
        }
        dispatch(invoiceActions.setTagApiCall(true))
        dispatch(invoiceActions.setApiCall("invoice"));
      }
    } catch (error: any) {
      setSubmit(false)
      console.error("error while submit record payment ", error);
    }
  }

  const handleSelect = (i: any) => {
    if (selectedInvoice?.includes(i?.id)) {
      let arr: any[] = selectedInvoice?.filter((item: any) => i?.id !== item)
      let arr1: any[] = invoices?.filter((item: any) => i?.id !== item?.id)
      setSelectedInvoice(arr)
      setInvoices(arr1)
      let dueAmt: number = 0;
      dueAmt = totalDueAmount - +i?.amount_left;
      setTotalDueAmount(dueAmt)
      setAmount(dueAmt)

      // if(wallet) {
      //   if(dueAmt < +list[0]?.customer_details?.wallet) {
      //     setAmount(0);
      //   } else {
      //     setAmount(dueAmt)
      //   }
      // } else {
      //   setAmount(dueAmt)
      // }
    } else {
      if (selectedInvoice?.length !== 10) {
        setSelectedInvoice([...selectedInvoice, i?.id])
        setInvoices([...invoices, { id: i?.id, type: i?.invoice_type }])
        let dueAmt: number = 0;
        dueAmt = totalDueAmount + +i?.amount_left;
        setTotalDueAmount(dueAmt)
        setAmount(dueAmt)
        // if(wallet) {
        //   if(dueAmt < +list[0]?.customer_details?.wallet) {
        //     setAmount(0);
        //   } else {
        //     setAmount(dueAmt)
        //   }
        // } else {
        //   setAmount(dueAmt)
        // }
      }
    }
  }

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(invoiceActions.setSelectCustOpen(true))
    }
  };

  return (
    <>
      {recordPaymentOpen && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
        <div className="flex justify-center items-center">
          <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[750px] ml:w-[1100px] xl:w-[1160px] z-[10]">
            <div className="flex flex-col px-[27px] py-[23px] rounded-[20px] bg-white">
              <div className="flex items-center justify-between">
                <div className="cursor-pointer flex items-center gap-[12px]" onClick={() => dispatch(invoiceActions.setSelectCustOpen(true))}>
                  <h3 className="text-primaryLight font-semibold text-lg">Record Payment</h3>
                </div>
                <div className="cursor-pointer" onClick={() => dispatch(invoiceActions.setRecordPaymentOpen(false))}>
                  <ClosePopup />
                </div>
              </div>

              <div className="mt-[17px] h-full flex flex-col ml:grid ml:grid-cols-7 gap-y-[15px] gap-x-[30px] w-full">
                <div className="rounded-[10px] bg-[#F6F8FF] border border-[#E5EAFF] h-full p-[11px] ml:col-span-3 w-full">
                  <div className="flex flex-col items-start">
                    <h3 className="text-primaryLight font-semibold text-base">Select Invoice</h3>
                    <p className="text-dGray font-normal text-xs">Select invoices you want to record money for</p>
                  </div>

                  <div className="pt-[14px]">
                    {
                      isLoading ? <InvoiceList /> :
                        <div className="overflow-auto h-[580px]">
                          <div className="inline-block min-w-full">
                            <div className="overflow-hidden">
                              <table className="table-auto w-full sample-table">
                                <tbody>
                                  {Array.isArray(list) && list.map((item: any, index: number) => (
                                    <tr className={`${selectedInvoice?.includes(item?.id) ? "opacity-[1]" : "opacity-[0.4]"} !rounded-[10px] bg-white shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[40px]`} key={index}>
                                      <td className="px-[8px] 2xl:px-[16px] h-full">
                                        <div className="flex items-center justify-center">
                                          <Checkbox className="tests table-check" disabled={selectedInvoice?.length === 10 ? (selectedInvoice?.includes(item?.id) ? false : true) : false} checked={selectedInvoice?.includes(item?.id) ? true : false} onClick={() => handleSelect(item)}></Checkbox>
                                        </div>
                                      </td>
                                      <td className="px-[8px] 2xl:px-[16px] h-full">
                                        <h3 className="text-lGray font-normal !text-sm text-left">{item?.invoice_type === "tax" ? item?.invoice_number : item?.bill_supply_number}</h3>
                                      </td>
                                      <td className="px-[8px] 2xl:px-[16px] h-full">
                                        <h3 className="text-lGray font-normal !text-sm text-left whitespace-nowrap">{Moment(item?.created_at).format('DD MMM YYYY')}</h3>
                                      </td>
                                      <td className="px-[8px] 2xl:px-[16px] h-full">
                                        <h3 className="text-lGray font-normal !text-sm text-left whitespace-nowrap">₹ {item?.amount_left}</h3>
                                      </td>
                                      <td className="px-[8px] 2xl:px-[16px] h-full">
                                        <div className="cursor-pointer">
                                          <EyeIcon />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
                </div>

                <div className="w-full flex flex-col ml:col-span-4">
                  <div className="rounded-[10px] w-full min-h-[71px] border border-[#E8E8E8] bg-[#FDFDFD] shadow-[0px_8px_16px_-6px_rgba(24,39,75,0.08),0px_6px_8px_-6px_rgba(24,39,75,0.12)] flex items-center !justify-center sm:!justify-between px-[13px] flex-col sm:flex-row gap-[4px] p-[12px]">
                    <div className="flex items-center gap-[16px]">
                      <img src={require("../../../../assets/images/org-logo.png")} alt="" />

                      <div className="flex flex-col">
                        <h3 className="text-dGray text-sm font-medium">{cust?.first_name + " " + cust?.last_name}</h3>
                        {cust?.org_name && <p className="flex items-center gap-[4px]"><NameOrgIcon color="#6B7280" /> <span className="text-dGray font-normal !text-xs text-left">{cust?.org_name}</span></p>}
                      </div>
                    </div>

                    <div className="flex items-center justify-between sm:gap-[40px] w-full sm:w-auto">
                      <div className="flex flex-col">
                        <p className="text-dGray text-xs font-normal">Total Amt</p>
                        <h3 className="text-primaryLight text-sm font-medium">₹ {totalAmount}</h3>
                      </div>

                      <button className="bg-[#F6ECFF] rounded-[20px] h-[26px] px-[8px] flex items-center gap-[4px]">
                        <PriceWallet />
                        <p className="text-sm text-primaryLight font-medium">₹ {!!list[0]?.customer_details?.wallet ? list[0]?.customer_details?.wallet : 0}</p>
                      </button>
                    </div>
                  </div>

                  <div className="mt-[20px] grid grid-cols-1 sm:grid-cols-2 gap-[15px]">
                    <PrimaryInput type="text" title="Payment Ref. Number" name="payment_ref_no" value={refNo} onChange={(e: any) => setRefNo(e.target.value)} />
                    <PrimaryInput type="date" title="Payment Date" name="payment_date" value={paymentDate} onChange={(e: any) => setPaymentDate(e.target.value)} />
                  </div>

                  <div className="mt-[18px] flex flex-col gap-[16px]">
                    <h1 className="text-primaryLight text-sm font-medium">Amount to be Recorded (₹)</h1>

                    <PrimaryInput type="number" min={1} title="Amounts" name="amounts" required={true} value={amount} onChange={(e: any) => setAmount(e.target.value)} exceptThisSymbols={["e", "E", "+", "-"]} />
                  </div>

                  <div className="flex items-start gap-[2px] sm:gap-0 sm:items-center flex-col sm:flex-row sm:justify-between mt-[10px]">
                    <h1 className="text-danger text-sm font-medium">Due Amount ₹ {totalDueAmount}</h1>
                    <div className={`${Math?.floor(list[0]?.customer_details?.wallet) > 0 ? "opacity-1" : "opacity-[0.4]"} flex items-center gap-[8px]`}>
                      <Checkbox disabled={Math?.floor(list[0]?.customer_details?.wallet) > 0 ? false : true} className='tests table-check' checked={wallet} onChange={() => setWallet(!wallet)}></Checkbox>
                      <p className="text-mGray text-xs font-normal">Use wallet balance</p>
                    </div>
                  </div>

                  <div className="mt-[20px]">
                    <h1 className="text-primaryLight text-sm font-medium">Payment Type</h1>

                    <div className="flex items-center gap-[8px] mt-[10px]">
                      <button className={`${online ? "bg-[#F6ECFF] text-primaryLight" : "bg-[#EBEBEB] text-mGray"}  rounded-[20px] px-[13px] text-sm font-normal h-[25px] flex items-center justify-center cursor-pointer`} onClick={() => setOnline(true)}>Online</button>
                      <button className={`${!online ? "bg-[#F6ECFF] text-primaryLight" : "bg-[#EBEBEB] text-mGray"} rounded-[20px] px-[13px] text-sm font-normal h-[25px] flex items-center justify-center cursor-pointer`} onClick={() => setOnline(false)}>Offline</button>
                    </div>
                  </div>

                  <div {...getRootProps()} onClick={(e) => e.stopPropagation} className="rounded-[4px] border-2 border-dashed border-[rgba(8,133,134,0.30)] bg-[rgba(208,252,253,0.05)] min-h-[107px] mt-[18px] flex flex-col items-center justify-center">
                    {(uploadProgress === 0 || uploadProgress === 100) && <>
                      <input {...getInputProps()} />
                      <div className="cursor-pointer" onClick={() => files?.length === 0 && open()}>
                        <FileUploadIcon />
                      </div>
                    </>}
                    <div className="text-dGray text-sm font-medium pt-[5px]">Drag & drop files or <span className="text-primaryLight text-sm font-medium">Browse</span></div>
                    <p className="text-lGray text-xs font-normal pt-[2px] text-center">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
                  </div>

                  <aside className='w-full mt-[15px] flex flex-col gap-[12px]'>{thumbs}</aside>

                  <div className="mt-[20px]">
                    <PrimaryTextarea title="Add Note" name="note" rows={3} value={note} onChange={(e: any) => setNote(e.target.value)} />
                  </div>

                  <div className="w-full flex items-end justify-end mt-[26px]">
                    <button className="flex items-center gap-[8px] border border-[rgba(32,50,143,0.30)] h-[40px] bg-[rgba(183,194,255,0.10)] pl-[20px] pr-[15px] text-primaryLight text-sm font-medium rounded-[10px] disabled:opacity-[0.3] disabled:cursor-not-allowed" onClick={handleSubmit} disabled={(!!amount && +amount > 0 &&  !disable && selectedInvoice?.length > 0 && !submit) ? false : true}>{!submit ? "Submit Payment" :
                      <>
                        <LoadingBtn className="w-5 h-5 text-primaryLight animate-spin" />
                        Submit Payment...
                      </>} <PaymentArrow /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default MultiRecordPayment;