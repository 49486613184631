import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { invoiceActions } from '../../../../shared/store/invoice-Slice';
import { ClosePopup, LoadingBtn, PaymentArrow } from '../../../svg/AllSvg';
import { useEffect, useState } from 'react';
import Select from "react-select";
import { useSelectPrimary}  from "../../../../shared/hooks/use-selectPrimary";
import useAxios from '../../../../shared/hooks/use-axios';
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { errors } from '../../../../shared/utils/errors';
import { success } from '../../../../shared/hooks/use-toastify';
import { settingActions } from '../../../../shared/store/setting-Slice';
import PrimaryInput from '../../../form/PrimaryInput';

const Product = () => {
    const [submit, setSubmit] = useState<boolean>(false);
    const productOpen: boolean = useSelector((state: any) => state.invoice.productOpen);
    const [product, setProduct] = useState<number>(1);
    const [unitList, setUnitList] = useState<any[]>([]);
    const [gstList, setGstList] = useState<any[]>([]);
    const dispatch = useDispatch();
    let styles = useSelectPrimary();

    const productSchema = yup.object().shape({
        name: yup.string().required(errors.prodName),
        code: yup.string().required(errors?.prodCode),
        price: yup.number().required(errors?.prodPrice),
        gst: yup.object().required(errors?.gst),
        unit: yup.object().required(errors?.unit),
    });


    const productObj: any = {
        name: "",
        code: "",
        price: "",
        gst: "",
        unit: ""
    }

    const handleSubmit = async (values: any) => {
        try {
            setSubmit(true)
            let payload: any = {
                "name": values?.name,
                "type": product,
                "description": "",
                "code": values?.code,
                "price": values?.price,
                "category": null,
                "gst": values?.gst?.value,
                "unit": values?.unit?.value
            }

            const res: any = await useAxios.post("invoices/api/products/", payload);

            if (res && res.status === 201) {
                dispatch(invoiceActions.setProductOpen(false))
                dispatch(settingActions.setProductApiCall(true))
                success("Product Create Successfully.")
                setSubmit(false)
            }
        } catch (error: any) {
            console.error("error while creating product", error);
            setSubmit(false)
        }
    }

    const getUnit = async () => {
        try {
            const res: any = await useAxios.get("utility/sample-unit/");

            if (res && res.status === 200) {
                setUnitList(res?.data?.results?.length > 0 ? Array.isArray(res?.data?.results) && res?.data?.results.map((item: any) => ({
                    label: item?.text,
                    value: item?.id
                })) : [])
            }
        } catch (error) {
            console.error("error while fetching sample Unit", error);
        }
    };

    const getGST = async () => {
        try {
            const res: any = await useAxios.get("utility/gst-list/");

            if (res && res.status === 200) {
                setGstList(res?.data?.results?.length > 0 ? Array.isArray(res?.data?.results) && res?.data?.results.map((item: any) => ({
                    label: item?.tax + " %",
                    value: item?.id
                })) : [])
            }
        } catch (error) {
            console.error("error while fetching GST", error);
        }
    };


    useEffect(() => {
        getUnit();
        getGST();
        // eslint-disable-next-line
    }, []);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(invoiceActions.setProductOpen(false))
        }
    };

    return (
        <>
            {productOpen && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[#8888881a] backdrop-blur-[2.5px]">
                <div className="flex justify-center items-center">
                    <div className="relative my-[24px] md:mx-auto bg-white rounded-[20px] flex flex-col w-[320px] sm:w-[600px] md:w-[645px] z-[10]">
                        <div className="relative">
                            <div className="cursor-pointer absolute top-[22px] right-[19px] z-[10]" onClick={() => dispatch(invoiceActions.setProductOpen(false))}>
                                <ClosePopup />
                            </div>
                        </div>

                        <div className="flex flex-col px-[28px] py-[23px] rounded-[20px] bg-white">
                            <div className="flex items-center gap-[15px]">
                                <div className="flex flex-col items-start">
                                    <h3 className="text-primaryLight font-semibold text-lg">Add New Product/Service</h3>
                                    <p className="text-mGray font-normal text-sm">Add new product in your product list.</p>
                                </div>
                            </div>

                            <Formik
                                initialValues={productObj}
                                validationSchema={productSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, isValid }) => (
                                    <Form>
                                        <div className='mt-[35px]'>
                                            <h3 className='text-primaryLight text-sm font-medium'>Add Product Information</h3>

                                            <div className="rounded-[10px] bg-[#F9F9F9] h-[40px] flex items-center justify-center gap-[8px] px-[5px] w-fit mt-[20px]">
                                                <button type='button' className={`${product === 1 ? "shadow-[0px_10px_32px_-4px_rgba(24,39,75,0.10),0px_6px_14px_-6px_rgba(24,39,75,0.12)] bg-white text-primaryLight" : "bg-[#F9F9F9] text-lGray"} rounded-[10px] px-[17px] h-[35px] font-semibold text-xs cursor-pointer`} onClick={() => setProduct(1)}>
                                                    Product
                                                </button>
                                                <button type='button' className={`${product === 2 ? "shadow-[0px_10px_32px_-4px_rgba(24,39,75,0.10),0px_6px_14px_-6px_rgba(24,39,75,0.12)] bg-white text-primaryLight" : "bg-[#F9F9F9] text-lGray"} rounded-[10px] px-[17px] h-[35px] font-semibold text-xs cursor-pointer`} onClick={() => setProduct(2)}>
                                                    Service
                                                </button>
                                            </div>

                                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-[20px] gap-y-[20px] sm:gap-y-[10px] mt-[20px] sm:mt-[12px]'>
                                                <div className='sm:mt-[20px] flex flex-col'>
                                                    <PrimaryInput type="text" title="Item Name" name="name" required={true} onChange={handleChange} onBlur={handleBlur} value={values.name} />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <h3 className="text-mGray text-xs font-normal pb-[4px]">Primary Unit*</h3>
                                                    <Select options={unitList} placeholder="Primary Unit" name="unit" styles={styles} className="!w-full" onChange={(e: any) => setFieldValue("unit", e)} value={values?.unit} onBlur={(e: any) => setFieldTouched("unit", e)} />
                                                    <ErrorMessage
                                                        name="unit"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>

                                                <div className='sm:mt-[20px] flex flex-col'>
                                                    <PrimaryInput type="text" title="Selling Price (exclusive of all taxes)*" name="price" onChange={handleChange} onBlur={handleBlur} value={values.price} />
                                                    <ErrorMessage
                                                        name="price"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <h3 className="text-mGray text-xs font-normal pb-[4px]">TAX Percentage*</h3>
                                                    <Select options={gstList} placeholder="TAX Percentage" name="gst" styles={styles} className="!w-full" onChange={(e: any) => setFieldValue("gst", e)} value={values?.gst} onBlur={(e: any) => setFieldTouched("gst", e)} />
                                                    <ErrorMessage
                                                        name="gst"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                            </div>

                                            <h3 className='text-primaryLight text-sm font-medium mt-[30px]'>Additional Information</h3>

                                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-[20px] mt-[20px]'>
                                                <div className='flex flex-col'>
                                                    <PrimaryInput type="text" title="HSN/SAC Code" name="code" required={true} onChange={handleChange} onBlur={handleBlur} value={values.code} />
                                                    <ErrorMessage
                                                        name="code"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full flex items-end justify-end mt-[48px]">
                                                <button className="flex items-center gap-[8px] border border-[rgba(32,50,143,0.30)] h-[40px] bg-[rgba(183,194,255,0.10)] pl-[20px] pr-[15px] text-primaryLight text-sm font-medium rounded-[10px] disabled:opacity-[0.3] disabled:cursor-not-allowed" disabled={!(isValid && dirty) ? true : (submit) ? true : false}>{!submit ? "Submit" :
                                                    <>
                                                        <LoadingBtn className="w-5 h-5 text-primaryLight animate-spin" />
                                                        Submit...
                                                    </>} <PaymentArrow /></button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default Product;