import { useEffect, useState } from "react"
import useAxios from "../../../shared/hooks/use-axios";
import { useSelector } from "react-redux";
import Parameter from "../../../skeleton/components/customer/Parameter";
import { useTranslation } from "react-i18next";

const ParameterList = ({ selectStandard }: any) => {
    const [list, setList] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const sampleDetail = useSelector((state: any) => state.test.sampleDetail);
    const [allBtnShow, setAllBtnShow] = useState<number>(0);
    const { t } = useTranslation('common');

    const parameters = async () => {
        try {
            setIsLoading(true);
            const res: any = await useAxios.get(`samples/api/tr-clauses/?sample=${sampleDetail?.id}`);

            if (res && res.status === 200) {
                let arr: any[] = [];
                for (let i = 0; i < res?.data?.results?.length; i++) {
                    const el = res?.data?.results[i];

                    if (el?.selected) {
                        arr.push(el)
                    }

                }
                setList(arr);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("error while getting parameters", error);
        }
    };

    useEffect(() => {
        !!sampleDetail?.id && parameters();
        // eslint-disable-next-line
    }, [sampleDetail])

    return (
        <>
            {isLoading ? <Parameter /> :
                <>
                    {selectStandard?.not_real_std ?
                        <div className="flex items-center justify-center h-full flex-col gap-[4px]">
                            <img src={require("../../../assets/images/custom-standard.png")} alt="" />
                            <h3 className="text-primaryLight font-base font-semibold">{t("customer.billing_info.test_req_detail.parameter.lab_choose.title") as any}</h3>
                            <p className="max-w-[374px] text-dGray font-normal text-sm text-center">{t("customer.billing_info.test_req_detail.parameter.lab_choose.desc") as any}</p>
                        </div> : <div className="flex flex-col">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full">
                                    <div className="overflow-hidden">
                                        <table className="table-auto w-full">
                                            <thead className="bg-white !rounded-[10px]">
                                                <tr className="h-[40px] !rounded-[10px] border-b border-[#E8EBFF]">
                                                    <th className="pl-[28px] pr-[8px] 2xl:pr-[16px] h-full cursor-pointer">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray font-normal text-xs !text-left'>Parameter Name</p>
                                                        </div>
                                                    </th>
                                                    <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray font-normal text-xs !text-left'>Method of Test</p>
                                                        </div>
                                                    </th>
                                                    <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                        <div className='flex items-center justify-start w-full gap-[8px]'>
                                                            <p className='text-lGray font-normal text-xs !text-left'>Accreditation</p>
                                                        </div>
                                                    </th>
                                                    {list[0]?.price_methodology === "Parameter Wise" && <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                        <div className='flex items-center justify-center w-full gap-[8px]'>
                                                            <p className='text-lGray font-normal text-xs !text-center'>Qty</p>
                                                        </div>
                                                    </th>}
                                                    <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                        <div className='flex items-center justify-center w-full gap-[8px]'>
                                                            <p className='text-lGray font-normal text-xs !text-center'>Department</p>
                                                        </div>
                                                    </th>
                                                    {list[0]?.price_methodology === "Parameter Wise" && <th className="px-[8px] 2xl:px-[16px] h-full cursor-pointer">
                                                        <div className='flex items-center justify-center w-full gap-[8px]'>
                                                            <p className='text-lGray font-normal text-xs !text-center'>Price</p>
                                                        </div>
                                                    </th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(list) && list.map((item: any, index: number) => (
                                                    <tr className={`${(list?.length === (index + 1)) ? "" : "border-b border-[#E8EBFF]"} !rounded-[10px] bg-white h-full cursor-pointer`} key={index}>
                                                        <td className="pl-[28px] pr-[8px] 2xl:pr-[16px] py-[16px] h-full">
                                                            <h3 className="text-[#767676] font-normal !text-sm text-left">{(!!item?.clause_no || !!item?.parameter) ? `${item?.parameter} (${item?.clause_no})` : "-"}</h3>
                                                        </td>
                                                        <td className="px-[8px] 2xl:px-[16px] h-full">
                                                            {item?.method_test_list?.length > 0 ? <>
                                                                {(Array.isArray(item?.method_test_list) && item?.method_test_list.map((i: any, idx: number) =>
                                                                    <h3 className="text-[#767676] font-normal !text-sm text-left" key={idx}>
                                                                        {(i?.name) + `${item?.method_test_list?.length !== idx + 1 ? ', ' : ' '}`}
                                                                    </h3>
                                                                ))}
                                                            </> : <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>}
                                                        </td>
                                                        <td className="px-[8px] 2xl:px-[16px] h-full py-[16px]" onMouseEnter={() => setAllBtnShow(item?.id)} onMouseLeave={() => setAllBtnShow(0)}>
                                                            <div>
                                                                {item?.accreditation_list?.length > 0 ?
                                                                    <>
                                                                        {(allBtnShow !== item?.id || allBtnShow === 0) ? <div className="flex items-center gap-[8px]">
                                                                            <button className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{item?.accreditation_list[0]?.name}</button>
                                                                            {item?.accreditation_list?.length > 1 && <button className={`bg-[#D7E8FF] text-primaryLight flex items-center justify-center text-xs font-normal rounded-[5px] py-[6px] px-[10px] whitespace-nowrap`}>{item?.accreditation_list?.length - 1}+</button>}
                                                                        </div> :
                                                                            <div className="flex items-center gap-[8px]">
                                                                                {Array.isArray(item?.accreditation_list) && item?.accreditation_list.map((i: any, idx: number) => (
                                                                                    <button key={idx} className={`rounded-[5px] bg-[#D7E8FF] text-primaryLight font-normal text-xs px-[13px] h-[25px] flex items-center justify-center whitespace-nowrap`}>{i?.name}</button>
                                                                                ))}
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <h3 className="text-[#767676] font-normal !text-sm text-left">-</h3>
                                                                }
                                                            </div>
                                                        </td>
                                                        {list[0]?.price_methodology === "Parameter Wise" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                            <h3 className="text-[#767676] font-normal !text-sm text-center">{item?.dynamic_quantity}</h3>
                                                        </td>}
                                                        <td className="px-[8px] 2xl:px-[16px] h-full py-[16px]">
                                                            <div className="flex items-center justify-center">
                                                                {item?.accreditation_list ? <div className="rounded-[20px] bg-[#F6ECFF] text-[#7840A7] font-normal text-xs px-[13px] h-[25px] flex items-center justify-center">{item?.test_type_name}</div> : <h3 className="text-[#767676] font-normal !text-sm text-center">-</h3>}
                                                            </div>
                                                        </td>
                                                        {list[0]?.price_methodology === "Parameter Wise" && <td className="px-[8px] 2xl:px-[16px] py-[16px]">
                                                            <h3 className="text-[#767676] font-normal !text-sm text-center">₹{item?.charges}</h3>
                                                        </td>}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </>
            }
        </>
    );
};

export default ParameterList;