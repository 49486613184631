import { Skeleton } from 'primereact/skeleton';

const Parameter: React.FC<any> = () => {
    const cols = [];
    for (let i = 0; i < 3; i++) {
        cols.push(
            <tr className="!rounded-[10px] shadow-[0px_2px_10px_0px_rgba(223,234,255,0.35)] h-[55px]" key={i}>
                <td className="pl-[28px] pr-[8px] 2xl:pr-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="px-[8px] 2xl:px-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="pr-[28px] pl-[8px] 2xl:pl-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
                <td className="pr-[28px] pl-[8px] 2xl:pl-[16px] h-full">
                    <Skeleton height=".5rem" width='6rem'></Skeleton>
                </td>
            </tr>
        )
    };

    return (
        <div className="overflow-x-auto relative w-full">
            <table className="table-auto w-full skeleton-table">
                <tbody>
                    {cols}
                </tbody>
            </table>
        </div>
    );
};

export default Parameter;